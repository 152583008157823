import {ContactPersonDepartment, ContactPersonFunction, ContactPersonSalutation} from "../store/models/ContactPerson";
import i18next from "i18next";

export const countryOptions = () => [
    {
        value: "DE",
        label: i18next.t('countryOptions.DE')
    }, {
        value: "AT",
        label: i18next.t('countryOptions.AT')
    },  {
        value: "CH",
        label: i18next.t('countryOptions.CH')
    }, {
        value: "IT",
        label: i18next.t('countryOptions.IT')
    }, {
        value: "EN",
        label: i18next.t('countryOptions.EN')
    }, {
        value: "LU",
        label: i18next.t('countryOptions.LU')
    }, {
        value: "SI",
        label: i18next.t('countryOptions.SI')
    }, {
        value: "FRA",
        label: i18next.t('countryOptions.FRA')
    },
    {
        value: "DK",
        label: i18next.t('countryOptions.DK')
    }
];

export const contactPersonFunctionOptions = () => [
    {
        value: "",
        label: i18next.t('contactPersonFunctionOptions.unknown')
    },
    {
        value: ContactPersonFunction["001"],
        label: i18next.t('contactPersonFunctionOptions.01')
    },
    {
        value: ContactPersonFunction["002"],
        label: i18next.t('contactPersonFunctionOptions.02')
    },
    {
        value: ContactPersonFunction["003"],
        label: i18next.t('contactPersonFunctionOptions.03')
    },
    {
        value: ContactPersonFunction["004"],
        label: i18next.t('contactPersonFunctionOptions.04')
    },
    {
        value: ContactPersonFunction["005"],
        label: i18next.t('contactPersonFunctionOptions.05')
    },
    {
        value: ContactPersonFunction["006"],
        label: i18next.t('contactPersonFunctionOptions.06')
    },
    {
        value: ContactPersonFunction["007"],
        label: i18next.t('contactPersonFunctionOptions.07')
    },
    {
        value: ContactPersonFunction["008"],
        label: i18next.t('contactPersonFunctionOptions.08')
    },
    {
        value: ContactPersonFunction["009"],
        label: i18next.t('contactPersonFunctionOptions.09')
    },
    {
        value: ContactPersonFunction["010"],
        label: i18next.t('contactPersonFunctionOptions.10')
    },
    {
        value: ContactPersonFunction["011"],
        label: i18next.t('contactPersonFunctionOptions.11')
    },
    {
        value: ContactPersonFunction.BE,
        label: i18next.t('contactPersonFunctionOptions.BE')
    },
    {
        value: ContactPersonFunction.FV,
        label: i18next.t('contactPersonFunctionOptions.FV')
    },
    {
        value: ContactPersonFunction.IH,
        label: i18next.t('contactPersonFunctionOptions.IH')
    },
    {
        value: ContactPersonFunction.ZI,
        label: i18next.t('contactPersonFunctionOptions.ZI')
    }, {
        value: ContactPersonFunction.ZK,
        label: i18next.t('contactPersonFunctionOptions.ZK')
    }, {
        value: ContactPersonFunction.ZR,
        label: i18next.t('contactPersonFunctionOptions.ZR')
    },
]
export const contactPersonDepartmentOptions = () => [
    {
        value: "",
        label: i18next.t('contactPersonDepartmentOptions.unknown')
    },
    {
        value: ContactPersonDepartment.unknown,
        label: i18next.t('contactPersonDepartmentOptions.unknown')
    }, {
        value: ContactPersonDepartment["0001"],
        label: i18next.t('contactPersonDepartmentOptions.0001')
    }, {
        value: ContactPersonDepartment["0002"],
        label: i18next.t('contactPersonDepartmentOptions.0002')
    }, {
        value: ContactPersonDepartment["0003"],
        label: i18next.t('contactPersonDepartmentOptions.0003')
    }, {
        value: ContactPersonDepartment["0004"],
        label: i18next.t('contactPersonDepartmentOptions.0004')
    }, {
        value: ContactPersonDepartment["0005"],
        label: i18next.t('contactPersonDepartmentOptions.0005')
    }, {
        value: ContactPersonDepartment["0006"],
        label: i18next.t('contactPersonDepartmentOptions.0006')
    }, {
        value: ContactPersonDepartment["0007"],
        label: i18next.t('contactPersonDepartmentOptions.0007')
    }, {
        value: ContactPersonDepartment["0008"],
        label: i18next.t('contactPersonDepartmentOptions.0008')
    }, {
        value: ContactPersonDepartment["0009"],
        label: i18next.t('contactPersonDepartmentOptions.0009')
    }, {
        value: ContactPersonDepartment["0010"],
        label: i18next.t('contactPersonDepartmentOptions.0010')
    },
]

export const contactPersonSalutationOptions = () => [
    {
        value: "",
        label: i18next.t('contactPersonSalutationOptions.noInformation')
    },
    {
        value: ContactPersonSalutation["0001"],
        label: i18next.t('contactPersonSalutationOptions.female')
    },
    {
        value: ContactPersonSalutation["0002"],
        label: i18next.t('contactPersonSalutationOptions.male')
    },
    // {
    //     value: "0003",
    //     label:"divers?"
    // },
]

export const yesNoOptions = () => [
    {
        value: 'true',
        label: i18next.t('common.yes')
    }, {
        value: 'false',
        label: i18next.t('common.no')
    },
]
export const yesNoEmptyOptions = () => [
    {
        value: '',
        label: ''
    }, {
        value: 'true',
        label: i18next.t('common.yes')
    }, {
        value: 'false',
        label: i18next.t('common.no')
    },
]
