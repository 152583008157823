import React, {useState} from 'react';
import {Button, Divider, FormControl, Grid, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Formik} from "formik";
import Api from "../../../store/Api";
import UploadProductListCSV from "../UploadProductListCSV";

const useStyles = makeStyles((theme) => {
    return ({
        container: {
            marginTop: theme.spacing(3)
        },
    });
});

export default function BadServiceTab() {
    const classes = useStyles();

    const refreshAdmList = () => Api.refreshAdmData()
    const [deleteCheckedContactID, setDeleteCheckedContactID] = useState('');

    return <>
        <Grid className={classes.container} container spacing={6}>
            <Grid item xs={12}>
                <Typography variant={'h6'}>CRM Daten für einen ADM updaten</Typography>
                <Typography>
                    Läuft asynchron und kann einen moment dauern, nicht zu schnell hintereinander updaten.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={{admID: ''}}
                    onSubmit={(values) => {
                        Api.updateCRMDataForAdm(values.admID);
                        values.admID = '';
                    }}
                >
                    {({
                          values,
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="admID"
                                            label={'ADM ID'}
                                            value={values.admID}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Button disabled={!values.admID} variant={"contained"} color={"primary"}
                                            type={"submit"}>Absenden</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <Typography variant={'h6'}>Interessenten löschen</Typography>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={{stContactID: ''}}
                    onSubmit={async (values) => {

                        const result = await Api.deletePotentialCustomer(values.stContactID,
                            deleteCheckedContactID === values.stContactID);

                        if(result.deleted) {
                            values.stContactID = '';
                            setDeleteCheckedContactID('');
                            //todo remove warning
                        } else if (result.hasNotesOrTodos) {
                            setDeleteCheckedContactID(values.stContactID);
                            //todo show warning
                        }
                    }}
                >
                    {({
                          values,
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="stContactID"
                                            label={'Interessenten-Nummer'}
                                            helperText={deleteCheckedContactID === '' ? undefined : 'Für den Interessenten existieren Termine/Notizen. Zum dennoch löschen, erneut absenden.'}
                                            value={values.stContactID}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Button disabled={!values.stContactID} variant={"contained"} color={"primary"}
                                            type={"submit"}>Absenden</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <Typography variant={'h6'}>Kundendatensatz aus SAP updaten</Typography>
                <Typography>
                    Läuft asynchron und kann einen moment dauern, nicht zu schnell hintereinander updaten.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={{sapCustomerID: ''}}
                    onSubmit={(values) => {
                        Api.updateContactFromSAP(values.sapCustomerID);
                        values.sapCustomerID = '';
                    }}
                >
                    {({
                          values,
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="sapCustomerID"
                                            label={'SAP Kundennummer'}
                                            value={values.sapCustomerID}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Button disabled={!values.sapCustomerID} variant={"contained"} color={"primary"}
                                            type={"submit"}>Absenden</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <Typography variant={'h6'}>ADM Liste aktualisieren</Typography>
                <Typography>
                    Läuft asynchron und kann einen moment dauern. Die Daten werden direkt aus dem SAP geladen.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant={"contained"} color={"primary"} onClick={refreshAdmList}>aktualisieren</Button>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={'h6'}>Produkt Suche - Liste aktualisieren</Typography>
                <Typography>
                    Die alten Daten werden gelöscht. Die Daten entsprechend dem Format geschrieben, es erfolgt keine Prüfung.
                    Vor dem aktualisieren gibt es noch eine minimale Vorschau.
                </Typography>
                <Typography>
                   <b>Format:</b> VERDICHTUNG (consolidationNumber SAP),	MATCHCODE,	ARTIKELNUMMER,	BEZEICHNUNG
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <UploadProductListCSV/>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Grid>
    </>
}