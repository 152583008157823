import React, {useRef} from "react";
import Order, {OrderPosition} from "../../store/models/Order";
import {
    Button,
    Checkbox,
    DialogActions,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import {Formik} from "formik";
import Modal from "../Modal";
import {Complaint, ProposedSolution, RefundRequestContactType, Whereabouts} from "../../store/models/RefundRequest";
import Api from "../../store/Api";
import {thunkCreateErrorNotification, thunkCreateSuccessNotification} from "../../store/actions/NotificationActions";
import {useDispatch} from "react-redux";

interface SendRefundPositionModalProps {
    order: Order;
    position: OrderPosition;
    customerName: string;
}

export default function SendRefundPositionModal({order, position, customerName}: SendRefundPositionModalProps) {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const complaintOptions = Object.keys(Complaint).map(key => (
        {
            // @ts-ignore
            value: Complaint[key],
            label: t('RefundRequest.Complaint.' + key)
        }
    ))
    const whereaboutsOptions = Object.keys(Whereabouts).map(key => (
        {
            // @ts-ignore
            value: Whereabouts[key],
            label: t('RefundRequest.Whereabouts.' + key)
        }
    ))
    const proposedSolutionOptions = Object.keys(ProposedSolution).map(key => (
        {
            // @ts-ignore
            value: ProposedSolution[key],
            label: t('RefundRequest.ProposedSolution.' + key)
        }
    ))
    const contactTypeOptions = Object.keys(RefundRequestContactType).map(key => (
        {
            // @ts-ignore
            value: RefundRequestContactType[key],
            label: t('RefundRequest.RefundRequestContactType.' + key)
        }
    ))


    const uploadInputRef = useRef<HTMLInputElement>(null);

    const initialValues = {
        articleName: position.materialBezeichnung,
        contactPersonName: '',
        contactType: '',
        amount: '',
        attachments: [],
        complaint: '',
        description: '',
        whereabouts: '',
        packageAmount: '',
        proposedSolution: '',
        articlenumberGift: '',
        sendGift: false
    };


    return <Modal title={t('sendRefundPositionModal.headline')}
                  maxWidth={"md"}
                  fullWidth={true}
                  contentAndButtons={(handleClose) => {
                      return (
                          <>
                              <DialogContent>
                                  <Formik
                                      initialValues={initialValues}
                                      onSubmit={async (values, {resetForm}) => {
                                          // @ts-ignore
                                          await Api.sendRefundMail({
                                              ...values,
                                              customerID: order.kundenNummer,
                                              customerName,
                                              positionID: position.posNummer,
                                              orderID: order.auftragsNummer
                                          }).then(() => {
                                              dispatch(thunkCreateSuccessNotification(t('sendRefundPositionModal.mailSendSuccess')))
                                          }).catch((reason) => {
                                              dispatch(thunkCreateErrorNotification(t('sendRefundPositionModal.mailSendError'), reason))
                                          });

                                          resetForm();
                                          if (uploadInputRef && uploadInputRef.current) {
                                              uploadInputRef.current.value = '';
                                          }
                                          handleClose();
                                      }}
                                  >
                                      {({
                                            values,
                                            handleChange,
                                            handleSubmit,
                                            setFieldValue
                                        }) => (
                                          <form id={"sendRefundPositionModalForm"} onSubmit={handleSubmit}>
                                              <Grid container spacing={6}>
                                                  <Grid item xs={12}>
                                                      <FormControl fullWidth>
                                                          <TextField
                                                              multiline={true}
                                                              fullWidth={true}
                                                              rows={3}
                                                              name="description"
                                                              label={t('sendRefundPositionModal.descriptionLbl')}
                                                              value={values.description}
                                                              onChange={handleChange}
                                                              required
                                                          />
                                                      </FormControl>
                                                  </Grid>
                                                  <Grid item xs={4}>
                                                      <FormControl fullWidth>
                                                          <TextField
                                                              name="articleName"
                                                              label={t('sendRefundPositionModal.articleNameLbl')}
                                                              value={values.articleName}
                                                              onChange={handleChange}
                                                              required
                                                          />
                                                      </FormControl>
                                                  </Grid>
                                                  <Grid item xs={4}>
                                                      <FormControl fullWidth>
                                                          <TextField
                                                              name="contactPersonName"
                                                              label={t('sendRefundPositionModal.contactPersonNameLbl')}
                                                              value={values.contactPersonName}
                                                              onChange={handleChange}
                                                              required
                                                          />
                                                      </FormControl>
                                                  </Grid>
                                                  <Grid item md={4}>
                                                      <FormControl fullWidth>
                                                          <InputLabel
                                                              required>&nbsp;&nbsp;{t('sendRefundPositionModal.contactTypeLbl')}</InputLabel>
                                                          <Select
                                                              required
                                                              name={'contactType'}
                                                              value={values.contactType}
                                                              onChange={handleChange}
                                                          >
                                                              {contactTypeOptions.map(aT => <MenuItem
                                                                  key={'contactType' + aT.label}
                                                                  value={aT.value}>&nbsp;{aT.label}</MenuItem>)}
                                                          </Select>
                                                      </FormControl>
                                                  </Grid>
                                                  <Grid item xs={2}>
                                                      <FormControl fullWidth>
                                                          <TextField
                                                              name="amount"
                                                              label={t('sendRefundPositionModal.amountLbl')}
                                                              value={values.amount}
                                                              onChange={handleChange}
                                                              required
                                                          />
                                                      </FormControl>
                                                  </Grid>
                                                  <Grid item md={5}>
                                                      <FormControl fullWidth>
                                                          <InputLabel
                                                              required>&nbsp;&nbsp;{t('sendRefundPositionModal.complaintLbl')}</InputLabel>
                                                          <Select
                                                              required
                                                              name={'complaint'}
                                                              value={values.complaint}
                                                              onChange={handleChange}
                                                          >
                                                              {complaintOptions.map(aT => <MenuItem
                                                                  key={'complaint' + aT.label}
                                                                  value={aT.value}>&nbsp;{aT.label}</MenuItem>)}
                                                          </Select>
                                                      </FormControl>
                                                  </Grid>
                                                  <Grid item md={5}>
                                                      <FormControl fullWidth>
                                                          <InputLabel
                                                              required>&nbsp;&nbsp;{t('sendRefundPositionModal.proposedSolutionLbl')}</InputLabel>
                                                          <Select
                                                              required
                                                              name={'proposedSolution'}
                                                              value={values.proposedSolution}
                                                              onChange={handleChange}
                                                          >
                                                              {proposedSolutionOptions.map(aT => <MenuItem
                                                                  key={'proposedSolution' + aT.label}
                                                                  value={aT.value}>&nbsp;{aT.label}</MenuItem>)}
                                                          </Select>
                                                      </FormControl>
                                                  </Grid>
                                                  <Grid item xs={2}>
                                                      <FormControl fullWidth>
                                                          <TextField
                                                              name="packageAmount"
                                                              label={t('sendRefundPositionModal.packageAmountLbl')}
                                                              value={values.packageAmount}
                                                              onChange={handleChange}
                                                              required
                                                          />
                                                      </FormControl>
                                                  </Grid>
                                                  <Grid item md={5}>
                                                      <FormControl fullWidth>
                                                          <InputLabel
                                                              required>&nbsp;&nbsp;{t('sendRefundPositionModal.whereaboutsLbl')}</InputLabel>
                                                          <Select
                                                              required
                                                              name={'whereabouts'}
                                                              value={values.whereabouts}
                                                              onChange={handleChange}
                                                          >
                                                              {whereaboutsOptions.map(aT => <MenuItem
                                                                  key={'whereabout' + aT.label}
                                                                  value={aT.value}>&nbsp;{aT.label}</MenuItem>)}
                                                          </Select>
                                                      </FormControl>
                                                  </Grid>
                                                  <Grid item xs={5}>
                                                      <FormControlLabel
                                                          control={<Checkbox checked={values.sendGift}
                                                                             onChange={handleChange}
                                                                             color="primary"
                                                                             name={'sendGift'}/>}
                                                          label={t('sendRefundPositionModal.sendGiftLbl')}
                                                      />
                                                  </Grid>
                                                  <Grid item xs={4}>
                                                      <FormControl>

                                                          <input ref={uploadInputRef} id="attachments"
                                                                 name="attachments" type="file" multiple
                                                                 hidden={true}
                                                                 onChange={(event) => {
                                                                     setFieldValue("attachments", Array.prototype.slice.call(event.currentTarget.files));
                                                                 }}/>
                                                          <label htmlFor="attachments">
                                                              <Button color={"primary"} variant={"contained"}
                                                                      component={"span"}>

                                                                  {t('sendRefundPositionModal.fileUploadLbl')}
                                                              </Button>
                                                          </label>
                                                          {values.attachments && values.attachments.map(a =>
                                                              // @ts-ignore
                                                              <p key={a.name}>{a.name}</p>)}

                                                      </FormControl>
                                                  </Grid>
                                                  {values.sendGift ? <>
                                                      <Grid item xs={3}></Grid>
                                                      <Grid item xs={5}>
                                                          <FormControl fullWidth>
                                                              <TextField
                                                                  name="articlenumberGift"
                                                                  label={t('sendRefundPositionModal.articlenumberGiftLbl')}
                                                                  value={values.articlenumberGift}
                                                                  onChange={handleChange}
                                                              />
                                                          </FormControl>
                                                      </Grid>
                                                  </> : null}

                                              </Grid>
                                          </form>
                                      )}
                                  </Formik>
                              </DialogContent>
                              <DialogActions>
                                  <>
                                      <Button onClick={handleClose}>
                                          {t('common.cancelBtn')}
                                      </Button>
                                      <Button type="submit" form="sendRefundPositionModalForm" color={"primary"}>
                                          {t('common.sendBtn')}
                                      </Button>
                                  </>
                              </DialogActions>
                          </>
                      );
                  }} openButton={(handleOpen) => (
        <Button variant={"contained"}
                onClick={handleOpen}
                color={"secondary"}>{t("sendRefundPositionLink.btnText")}</Button>
    )}/>

}
