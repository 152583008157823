import {computed, observable, action, decorate} from 'mobx';
import { v4 as uuid } from "uuid";

const WISHLIST_KEY = "productWislist";
const CART_KEY = "productCart";

class ProductListStore {
    wishlist: any = []
    cart: any = []

    get wishlistMap() {
        return this.wishlist.reduce((acc: any, item: any) => {
            acc[item.id] = true;
            return acc;
        }, {});
    }
    loadWishlist = () => {
        if (this.wishlist.length !== 0) return;
        const localWishlist = localStorage.getItem(WISHLIST_KEY);
        if (localWishlist) {
            this.wishlist = JSON.parse(localWishlist);
        }
    }
    clearWishlist = () => {
        this.wishlist = [];
        localStorage.setItem(WISHLIST_KEY, '[]');
    }
    addToWishlist = (item: any) => {
        this.wishlist.push(item);
        localStorage.setItem(WISHLIST_KEY, JSON.stringify(this.wishlist.toJS()));
    }
    removeFromWishlist = (id: any) => {
        const idx = this.wishlist.findIndex((item: any) => item.id === id);

        if (idx !== -1) {
            this.wishlist.splice(idx, 1);
            localStorage.setItem(WISHLIST_KEY, JSON.stringify(this.wishlist.toJS()));
        }
    }

    getWishlist = (id?: any) => {
        if (id) {
            let data = localStorage.getItem(`${WISHLIST_KEY}_custom`);
            if (data) {
                data = JSON.parse(data);
                if (data && data[id as any]) {
                    return data[id] as any;
                }
            }
        }
    }
    createWishlist = (name: string) => {
        if (!name) return;
        const newID = uuid();

        let data = localStorage.getItem(`${WISHLIST_KEY}_custom`);
        if (data) {
            data = JSON.parse(data);
            if (data) {
                // @ts-ignore
                data[newID as any] = {
                    name,
                    data: this.wishlist.toJS()
                };
                localStorage.setItem(`${WISHLIST_KEY}_custom`, JSON.stringify(data));
            }
        } else {
            localStorage.setItem(`${WISHLIST_KEY}_custom`, JSON.stringify({
                [newID]: {
                    name,
                    data: this.wishlist.toJS()
                }
            }));
        }
    }
    loadWislhists = () => {
        const data = localStorage.getItem(`${WISHLIST_KEY}_custom`);
        if (data) {
            return JSON.parse(data) ?? {};
        } else {
            return {};
        }
    }
    removeWishlist = (id?: any) => {
        if (id) {
            const data = localStorage.getItem(`${WISHLIST_KEY}_custom`);
            if (data) {
                const wishlists = JSON.parse(data);
                delete wishlists[id];
                localStorage.setItem(`${WISHLIST_KEY}_custom`, JSON.stringify(wishlists));
            }
        }
    }

    get cartTotalPrice() {
        return this.cart.reduce((acc: number, item: any) => acc + item['price'], 0);
    }
    loadCart = () => {
        const localCart = localStorage.getItem(CART_KEY);
        if (localCart) {
            this.cart = JSON.parse(localCart);
        }
    }
    clearCart = () => {
        this.cart = [];
        localStorage.setItem(CART_KEY, '[]');
    }
    addToCart = (item: any) => {
        this.cart.push(item);
        localStorage.setItem(CART_KEY, JSON.stringify(this.cart.toJS()));
    }
    removeFromCart = (id: any) => {
        const idx = this.cart.findIndex((item: any) => item.id === id);

        if (idx !== -1) {
            this.cart.splice(idx, 1);
            localStorage.setItem(CART_KEY, JSON.stringify(this.cart.toJS()));
        }
    }
}



decorate(ProductListStore, {
    wishlist: observable,
    cart: observable,
    wishlistMap: computed,
    loadWishlist: action,
    clearWishlist: action,
    addToWishlist: action,
    removeFromWishlist: action,
    getWishlist: action,
    createWishlist: action,
    cartTotalPrice: computed,
    loadCart: action,
    clearCart: action,
    addToCart: action,
    removeFromCart: action,
})

export default new ProductListStore();
