import init from '@elastic/apm-rum'
import {BUILD_ID, APM_ENV, APM_SERVER, APM_ACTIVE} from './config';
import {parseJwt, readTokenFromCookie} from "./util/UserUtil";
const apm = init({
    serviceName: 'st-frontend',
    serviceVersion: BUILD_ID,
    serverUrl: APM_SERVER,
    active: APM_ACTIVE === true,
    environment: APM_ENV

})
try {
    const {VBNumber, displayName, email} = parseJwt(readTokenFromCookie());
    apm.setUserContext({id: VBNumber, username: displayName, email});
} catch(e) {
    console.log(JSON.stringify(e));
}

export default apm;