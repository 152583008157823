export default interface IPost {
    id: string;
    title: string;
    teaser: string;
    content: any;
    published_at: string;
}

export const emptyPost: IPost = {
    "id": "",
    "title": "",
    "teaser": "",
    "content": [],
    "published_at": ""
}