import IAction from "../actions/IAction";
import {ToDo} from "../models/ToDo";
import {ADD_TODO_SUCCESS, DELETE_TODO_SUCCESS, EDIT_TODO_SUCCESS, GET_TODOS_SUCCESS} from "../actions/ToDoActions";


export interface IToDoReducerState {
    todos: ToDo[];
}

export default class ToDoReducer {
    public static readonly INITIAL_STATE: IToDoReducerState = {
        todos: [],
    };

    public static reducer(
        state: IToDoReducerState = ToDoReducer.INITIAL_STATE,
        action: IAction<any>
    ): IToDoReducerState {
        switch (action.type) {
            case GET_TODOS_SUCCESS:
                return {...state, todos: action.payload};
            case ADD_TODO_SUCCESS:
                return {...state, todos: [...state.todos, action.payload]};
            case EDIT_TODO_SUCCESS:
                return {...state, todos: [...state.todos.map(todo => todo.todoNumber === action.payload ? action.payload : todo)]};
            case DELETE_TODO_SUCCESS:
                return {...state, todos: [...state.todos.filter(todo => todo.todoNumber !== action.payload.todoNumber)]};
            default:
                return state;
        }
    }
}
