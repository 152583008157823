class WeAddress {
    weName1: string;
    weName2: string;
    weName3: string;
    weName4: string;
    street: string;
    houseNumber: string;
    zipCode: string;
    place: string;
    country: string;

    constructor({weName1, weName2, weName3, weName4, street, houseNumber, zipCode, place, country}: WeAddressDTO) {
        this.weName1 = weName1;
        this.weName2 = weName2;
        this.weName3 = weName3;
        this.weName4 = weName4;
        this.street = street;
        this.houseNumber = houseNumber;
        this.zipCode = zipCode;
        this.place = place;
        this.country = country;
    }
}

interface WeAddressDTO {
    weName1: string;
    weName2: string;
    weName3: string;
    weName4: string;
    street: string;
    houseNumber: string;
    zipCode: string;
    place: string;
    country: string;
}

export class ContactDetailsAdm {
    telephoneNumber: string;
    mobileNumber: string;
    emailAddress: string;
    faxNumber: string;


    constructor({telephoneNumber, mobileNumber, emailAddress, faxNumber}: ContactDetailsAdmDTO) {
        this.telephoneNumber = telephoneNumber;
        this.mobileNumber = mobileNumber;
        this.emailAddress = emailAddress;
        this.faxNumber = faxNumber;
    }
}

interface ContactDetailsAdmDTO {
    telephoneNumber: string;
    mobileNumber: string;
    emailAddress: string;
    faxNumber: string;
}

export class Adm {

    sapID: string;
    inactive: boolean;
    salesOrganization: string;
    gender: string;
    nachname: string;
    vorname: string;
    name3: string;
    name4: string;
    birthdate: string;
    vbart: string;
    bezirk: string;
    kslBezirk: string;
    vorgesetzter: string;
    datumAb: string;
    datumBis: string;
    zuordnungKundenservice: string;
    zuordnungESO: string;
    spesenKennz: string;
    kollektionsKennz: string;
    kostenstellen: string[];
    zusatzbezirke: string[];
    weAddress: WeAddress;

    contactDetails: ContactDetailsAdm


    constructor({sapID, inactive, salesOrganization, gender, nachname, vorname, name3, name4, birthdate, vbart, bezirk, kslBezirk, vorgesetzter, datumAb, datumBis, zuordnungKundenservice, zuordnungESO, spesenKennz, kollektionsKennz, kostenstellen, zusatzbezirke, weAddress, contactDetails}: AdmDTO) {
        this.sapID = sapID;
        this.inactive = inactive;
        this.salesOrganization = salesOrganization;
        this.gender = gender;
        this.nachname = nachname;
        this.vorname = vorname;
        this.name3 = name3;
        this.name4 = name4;
        this.birthdate = birthdate;
        this.vbart = vbart;
        this.bezirk = bezirk;
        this.kslBezirk = kslBezirk;
        this.vorgesetzter = vorgesetzter;
        this.datumAb = datumAb;
        this.datumBis = datumBis;
        this.zuordnungKundenservice = zuordnungKundenservice;
        this.zuordnungESO = zuordnungESO;
        this.spesenKennz = spesenKennz;
        this.kollektionsKennz = kollektionsKennz;
        this.kostenstellen = kostenstellen;
        this.zusatzbezirke = zusatzbezirke;
        this.weAddress = new WeAddress(weAddress);
        this.contactDetails = new ContactDetailsAdm(contactDetails);
    }
}

export interface AdmDTO {

    sapID: string;
    inactive: boolean;
    salesOrganization: string;
    gender: string;
    nachname: string;
    vorname: string;
    name3: string;
    name4: string;
    birthdate: string;
    vbart: string;
    bezirk: string;
    kslBezirk: string;
    vorgesetzter: string;
    datumAb: string;
    datumBis: string;
    zuordnungKundenservice: string;
    zuordnungESO: string;
    spesenKennz: string;
    kollektionsKennz: string;
    kostenstellen: string[];
    zusatzbezirke: string[];
    weAddress: WeAddressDTO;

    contactDetails: ContactDetailsAdmDTO
}

export const emptyAdm: Adm = new Adm({
        sapID: "",
        inactive: false,
        salesOrganization: "",
        gender: "",
        nachname: "",
        vorname: "",
        name3: "",
        name4: "",
        birthdate: "",
        vbart: "",
        bezirk: "",
        kslBezirk: "",
        vorgesetzter: "",
        datumAb: "",
        datumBis: "",
        zuordnungKundenservice: "",
        zuordnungESO: "",
        spesenKennz: "",
        kollektionsKennz: "",
        kostenstellen: [],
        zusatzbezirke: [],
        weAddress: {
            weName1: "",
            weName2: "",
            weName3: "",
            weName4: "",
            street: "",
            houseNumber: "",
            zipCode: "",
            place: "",
            country: "",
        },

        contactDetails: {
            "telephoneNumber": "",
            "mobileNumber": "",
            "emailAddress": "",
            "faxNumber": "",
        }
    }
)
