import React, { useState } from 'react';
import Api from '../store/Api';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { IAutocompleteAddressResult } from '../store/models/IAutocompleteAddressDto';
import Contact from '../store/models/Contact';
import {useTranslation} from "react-i18next";

interface ContactAutoCompleteProps {
    onOptionSelect: (selectedContact: Contact) => void;
    debounceTimeoutMs?: number;
}

export default function ContactAutoComplete({ onOptionSelect, debounceTimeoutMs = 500 }: ContactAutoCompleteProps) {
    let searchTimeout: number;

    const {t} = useTranslation();
    const [addresses, setAddresses] = useState<IAutocompleteAddressResult[]>([]);

    const searchDebounced = (value: string) => {
        clearTimeout(searchTimeout);
        searchTimeout = window.setTimeout(async () => {
            const addressResponse = await Api.autocompleteAddresses(value);
            if (addressResponse) {
                setAddresses([...addressResponse]);
            }
        }, debounceTimeoutMs);
    };
    return (<Autocomplete
            freeSolo
            options={addresses}
            getOptionLabel={(opt: IAutocompleteAddressResult) => {
                return opt.address
            }}
            onChange={(event, value) => {
                if (value && typeof value !== 'string') {
                    Api.getPlaceDetails(value?.id).then(onOptionSelect);
                }
            }}
            onInputChange={(event, value) => {
                value && searchDebounced(value);
            }}
            renderInput={(params) => <TextField {...params}
                                                name="autocompleteSearch"
                                                label={t('contactView.searchBtn')}/>}
        />
    );
}