import {parseISO} from "date-fns";
import {parseISODateOrUndefined} from "../../util/DateUtil";

export class JobEvent {
    message: string;
    timestamp: Date;


    constructor({message, timestamp}: JobEventDTO) {
        this.message = message;

        this.timestamp = parseISO(timestamp);
    }
}

export class JobReport {
    jobID: string;
    title: string;
    jobType: string;
    jobResult: string;
    startDate: Date;
    endDate?: Date;
    jobEvents: JobEvent[];


    constructor({jobID,title, jobType, jobResult, startDate, endDate, jobEvents}: JobReportDTO) {
        this.jobID = jobID;
        this.title = title;
        this.jobType = jobType;
        this.jobResult = jobResult;
        this.startDate = parseISO(startDate);
        this.endDate = parseISODateOrUndefined(endDate);
        this.jobEvents = jobEvents.map((jE: JobEventDTO) => new JobEvent(jE));
    }
}

export interface JobEventDTO {
    message: string;
    timestamp: string;
}

export interface JobReportDTO {
    jobID: string;
    title: string;
    jobType: string;
    jobResult: string;
    startDate: string;
    endDate?: string;
    jobEvents: JobEventDTO[];
}