import {parseISO} from "date-fns";

export class AssignmentConfig {

    locale: string;
    forGreyCustomer: boolean;
    forPotentialCustomer: boolean;
    forMeetings: boolean;
    forFollowUps: boolean;
    assignMeetingsFor: number;
    assignFollowUpsFor: number;
    createdAt?: Date;
    updatedAt: Date;


    constructor({
                    locale,
                    forGreyCustomer,
                    forPotentialCustomer,
                    forMeetings,
                    forFollowUps,
                    assignMeetingsFor,
                    assignFollowUpsFor,
                    createdAt,
                    updatedAt
                }: AssignmentConfigDTO) {
        this.locale = locale;
        this.forGreyCustomer = forGreyCustomer;
        this.forPotentialCustomer = forPotentialCustomer;
        this.forMeetings = forMeetings;
        this.forFollowUps = forFollowUps;
        this.assignMeetingsFor = assignMeetingsFor;
        this.assignFollowUpsFor = assignFollowUpsFor;
        this.createdAt = parseISO(createdAt);
        this.updatedAt = parseISO(updatedAt);

    }
}

export interface AssignmentConfigDTO {
    locale: string;
    forGreyCustomer: boolean;
    forPotentialCustomer: boolean;
    forMeetings: boolean;
    forFollowUps: boolean;
    assignMeetingsFor: number;
    assignFollowUpsFor: number;
    createdAt: string;
    updatedAt: string;
}

export const emptyAssignmentConfig: AssignmentConfig = {
    locale: '',
    forGreyCustomer: false,
    forPotentialCustomer: false,
    forMeetings: false,
    forFollowUps: false,
    assignMeetingsFor: 0,
    assignFollowUpsFor: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
}