import React, {useState} from 'react';
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import {useDispatch} from "react-redux";
import Address, {AddressType} from "../../store/models/Address";
import {countryOptions} from "../mappedSelectOptions";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';


interface ContactAddressEditModalProps {
    address: Address;
    headline: string;
    action: (address: Address, addressType: AddressType) => void;
    isEdit: boolean;
    forAddressType?: AddressType
}

export default function ContactAddressEditModal({address, headline, action, isEdit, forAddressType}: ContactAddressEditModalProps) {
    const [addressType, setAddressType] = React.useState(forAddressType || AddressType.BILLING)
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const AddressTypeOptions = [
        {
            value: AddressType.DELIVERY,
            label: t('addressType.delivery')
        }, {
            value: AddressType.BILLING,
            label: t('addressType.billing')
        },
    ]
    const dispatch = useDispatch();
    return (
        <Modal title={headline} contentAndButtons={(handleClose) => (

            <>
                <DialogContent>
                    <Formik
                        initialValues={address}
                        onSubmit={async (values) => {
                            setLoading(true)
                            await dispatch(action(values, addressType))
                            setLoading(false)
                            handleClose();
                        }}
                        validationSchema={Yup.object().shape({
                            street: Yup.string()
                                .required(t('validation.required')),
                            houseNumber: Yup.string()
                                .required(t('validation.required')),
                            place: Yup.string()
                                .required(t('validation.required')),
                            zipCode: Yup.string()
                                .required(t('validation.required')),
                            country: Yup.string()
                                .required(t('validation.required')),
                            companyLine1: Yup.string()
                                .required(t('validation.required')),

                        })}

                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit,
                              errors,
                              touched
                          }) => (
                            <form id="contactAddressEditForm" onSubmit={handleSubmit}>
                                <Box m={3}>
                                    <Grid container spacing={5}>
                                        {
                                            !isEdit && <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('contactAddressEditModal.addressTypeLbl')}</InputLabel>
                                                    <Select
                                                        value={addressType}
                                                        //@ts-ignore
                                                        onChange={(event => setAddressType(event.target.value))}
                                                    >
                                                        {AddressTypeOptions.map(aT => <MenuItem
                                                            key={'AddressTypeOptions' + aT.label}
                                                            value={aT.value}>{aT.label}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="companyLine1"
                                                    label={t('contactAddressEditModal.companyLine1Lbl')}
                                                    value={values.companyLine1}
                                                    onChange={handleChange}
                                                    error={(!!errors.companyLine1 && touched.companyLine1)}
                                                    helperText={(errors.companyLine1 && touched.companyLine1) && errors.companyLine1}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="companyLine2"
                                                    label={t('contactAddressEditModal.companyLine2Lbl')}
                                                    value={values.companyLine2}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="companyLine3"
                                                    label={t('contactAddressEditModal.companyLine3Lbl')}
                                                    value={values.companyLine3}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="companyLine4"
                                                    label={t('contactAddressEditModal.companyLine4Lbl')}
                                                    value={values.companyLine4}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={8}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="street"
                                                    label={t('contactAddressEditModal.streetLbl')}
                                                    value={values.street}
                                                    onChange={handleChange}
                                                    error={(!!errors.street && touched.street)}
                                                    helperText={(errors.street && touched.street) && errors.street}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="houseNumber"
                                                    label={t('contactAddressEditModal.houseNumberLbl')}
                                                    value={values.houseNumber}
                                                    onChange={handleChange}
                                                    error={(!!errors.houseNumber && touched.houseNumber)}
                                                    helperText={(errors.houseNumber && touched.houseNumber) && errors.houseNumber}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="zipCode"
                                                    label={t('contactAddressEditModal.zipCodeLbl')}
                                                    value={values.zipCode}
                                                    onChange={handleChange}
                                                    error={(!!errors.zipCode && touched.zipCode)}
                                                    helperText={(errors.zipCode && touched.zipCode) && errors.zipCode}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={8}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="place"
                                                    label={t('contactAddressEditModal.placeLbl')}
                                                    value={values.place}
                                                    onChange={handleChange}
                                                    error={(!!errors.place && touched.place)}
                                                    helperText={(errors.place && touched.place) && errors.place}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl fullWidth error={!!errors.country && touched.country}>
                                                <InputLabel>{t('contactAddressEditModal.countryLbl')}</InputLabel>
                                                <Select
                                                    name="country"
                                                    label={t('contactAddressEditModal.countryLbl')}
                                                    value={values.country}
                                                    onChange={handleChange}
                                                    error={!!errors.country && touched.country}
                                                >
                                                    {countryOptions().map(cO => <MenuItem
                                                        key={'countryOption' + cO.label}
                                                        value={cO.value}>{cO.label}</MenuItem>)}
                                                </Select>
                                                {(errors.country && touched.country) ?
                                                    <FormHelperText>{errors.country}</FormHelperText>
                                                    : null}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions>
                    {loading ?
                        <div style={{paddingRight: 40}}>
                            <CircularProgress/>
                        </div> :
                        <>
                            <Button onClick={handleClose}>
                                {t('common.cancelBtn')}
                            </Button>

                            <Button form="contactAddressEditForm" type="submit" color={"primary"}>
                                {t('common.saveBtn')}
                            </Button>
                        </>
                    }

                </DialogActions>
            </>)} openButton={(handleOpen) => (
            <IconButton component="span" onClick={handleOpen}>
                {
                    isEdit ? <EditIcon/> : <AddCircleIcon fontSize={"large"} color={"primary"}/>
                }
            </IconButton>
        )}
        />
    );
}
