import {Box, Divider, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import {DabDigitalOrder} from "../../store/models/DabDigitalOrder";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {formatDateObj} from "../Formatter";
import {PhaseIndicator} from "../PhaseIndicator";
import {PhaseStatusIndicator} from "../PhaseStatusIndicator";
import {compareDesc} from "date-fns";
import {Link} from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React from "react";


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(7, 10),
        width: '100%'
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'
    },
    divider: {
        margin: theme.spacing(2, 0, 3, 0)
    },
    todoBox: {
        height: 375,
        overflowX: "hidden"
    }
}));

export default function DabDigitalOrderStatusList({entries}: { entries: DabDigitalOrder[] }) {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Paper className={classes.smallPaper}>
            <Grid item xs={12}>
                <Typography variant={'h6'}>{t('dabDigitalOrderStatusList.headline')}</Typography>
            </Grid>
            <Divider className={classes.divider}/>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Typography>{t('dabDigitalOrderStatusList.customer')}</Typography>
                    <Typography variant="caption">{t('dabDigitalOrderStatusList.project')}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t('dabDigitalOrderStatusList.phase')}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t('dabDigitalOrderStatusList.status')}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t('dabDigitalOrderStatusList.lastAction')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider}/>
                </Grid>
            </Grid>
            <Box className={classes.todoBox}>
                {entries.sort((a, b) => compareDesc(a.lastAction, b.lastAction)).map(entry =>
                    <Grid key={entry.orderId + entry.posNummer} container spacing={2} style={{minHeight: 62}}>
                        <Grid item xs={3}>
                            <Typography>{entry.customerName}</Typography>
                            <Typography variant="caption">{entry.projectName}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <PhaseIndicator phase={entry.phase}/>
                        </Grid>
                        <Grid item xs={3}>
                            <PhaseStatusIndicator phase={entry.phase} status={entry.status}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{formatDateObj(entry.lastAction)}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="details" component={Link}
                                        to={`/contact/${entry.stContactId}`}>
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Paper>
    )
}