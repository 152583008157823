import Address, { AddressDTO, emptyAddress } from "./Address";
import ContactPerson, { ContactPersonDTO } from "./ContactPerson";
import { NoteType } from "./Note";
import { TouchpointResultType } from "./TouchpointEnums";
import { ToDoType } from "./ToDo";
import { parseISO } from "date-fns";
import { parseISODateOrUndefined } from "../../util/DateUtil";

export default class Contact {
  sapCustomerID?: string;
  stContactID: string;
  appCustomerID?: string;
  crmCustomerID?: "";
  ts: string; //TODO Date format festlegen
  customerAccountGroup: string;
  salesOrganization: string;
  companyLine1: string;
  companyLine2: string;
  companyLine3: string;
  companyLine4: string;
  vatNumber: string;
  taxNumber1: string;
  taxNumber2: string;
  taxNumberFatturaPA: string;
  mailFatturaPA: string;
  industryCode: string;
  industryCodeItaly: string;
  customerLock: string;
  customerLockDescription: string;
  region: string;
  bezirk: string;
  kslBezirk: string;
  kundenschutzBis: string; //TODO vermutlich auch date?
  VB: string;
  zugewiesenerVB: string;
  billingDeliveryEmailAddress: string;
  shouldBillingDeliveryByPost: boolean;
  hasNewsletter: boolean;
  deletionFlag: boolean;
  address: Address;
  contactDetails: ContactDetails;
  contactPersons: ContactPerson[];
  differingBillingAddresses: Address[];
  differingDeliveryAddresses: Address[];
  commentField: string;
  appImageIDs: string[]; //TODO ?
  recommendedBy?: string;
  recommendationFromADM?: string;
  recommendationTS?: Date;
  __v?: number;
  lastContactHistoryEntry?: ContactHistoryEntry;
  nextPlannedTodo?: ContactPlannedTodo;
  subscribedCampaigns: ContactCampaign[];
  foundingYear: number;
  zugewiesenBis?: string;
  legacyCrmID?: string;
  additionalCrmInfo?: AdditionalCrmInfo[];
  contactNotes?: string[];
  note?: ContactNote;
  distance?: number;

  constructor({
    sapCustomerID,
    stContactID,
    appCustomerID,
    crmCustomerID,
    ts,
    customerAccountGroup,
    salesOrganization,
    companyLine1,
    companyLine2,
    companyLine3,
    companyLine4,
    vatNumber,
    taxNumber1,
    taxNumber2,
    taxNumberFatturaPA,
    mailFatturaPA,
    industryCode,
    industryCodeItaly,
    customerLock,
    customerLockDescription,
    region,
    bezirk,
    kslBezirk,
    kundenschutzBis,
    VB,
    zugewiesenerVB,
    billingDeliveryEmailAddress,
    shouldBillingDeliveryByPost,
    hasNewsletter,
    deletionFlag,
    address,
    contactDetails,
    contactPersons,
    differingBillingAddresses,
    differingDeliveryAddresses,
    commentField,
    appImageIDs,
    recommendedBy,
    recommendationFromADM,
    recommendationTS,
    __v,
    lastContactHistoryEntry,
    nextPlannedTodo,
    subscribedCampaigns,
    foundingYear,
    zugewiesenBis,
    legacyCrmID,
    additionalCrmInfo,
    contactNotes,
    note,
    distance,
  }: ContactDTO) {
    this.sapCustomerID = sapCustomerID;
    this.stContactID = stContactID;
    this.appCustomerID = appCustomerID;
    this.crmCustomerID = crmCustomerID;
    this.ts = ts;
    this.customerAccountGroup = customerAccountGroup;
    this.salesOrganization = salesOrganization;
    this.companyLine1 = companyLine1;
    this.companyLine2 = companyLine2;
    this.companyLine3 = companyLine3;
    this.companyLine4 = companyLine4;
    this.vatNumber = vatNumber;
    this.taxNumber1 = taxNumber1;
    this.taxNumber2 = taxNumber2;
    this.taxNumberFatturaPA = taxNumberFatturaPA;
    this.mailFatturaPA = mailFatturaPA;
    this.industryCode = industryCode;
    this.industryCodeItaly = industryCodeItaly;
    this.customerLock = customerLock;
    this.customerLockDescription = customerLockDescription;
    this.region = region;
    this.bezirk = bezirk;
    this.kslBezirk = kslBezirk;
    this.kundenschutzBis = kundenschutzBis;
    this.VB = VB;
    this.zugewiesenerVB = zugewiesenerVB;
    this.billingDeliveryEmailAddress = billingDeliveryEmailAddress;
    this.shouldBillingDeliveryByPost = shouldBillingDeliveryByPost;
    this.hasNewsletter = hasNewsletter;
    this.deletionFlag = deletionFlag;
    this.address = new Address(address);
    this.contactDetails = new ContactDetails(contactDetails);
    this.contactPersons = contactPersons.map(
      (cPDTO) => new ContactPerson(cPDTO)
    );
    this.differingBillingAddresses = differingBillingAddresses.map(
      (a) => new Address(a)
    );
    this.differingDeliveryAddresses = differingDeliveryAddresses.map(
      (a) => new Address(a)
    );
    this.commentField = commentField;
    this.appImageIDs = appImageIDs;
    this.recommendedBy = recommendedBy;
    this.recommendationFromADM = recommendationFromADM;
    this.recommendationTS = parseISODateOrUndefined(recommendationTS);
    this.__v = __v;
    this.lastContactHistoryEntry = lastContactHistoryEntry
      ? new ContactHistoryEntry(lastContactHistoryEntry)
      : undefined;
    this.nextPlannedTodo = nextPlannedTodo
      ? new ContactPlannedTodo(nextPlannedTodo)
      : undefined;
    this.subscribedCampaigns = subscribedCampaigns
      ? subscribedCampaigns.map((sC) => new ContactCampaign(sC))
      : [];
    this.foundingYear = foundingYear;
    this.zugewiesenBis = zugewiesenBis;
    this.legacyCrmID = legacyCrmID;
    this.additionalCrmInfo = additionalCrmInfo
      ? additionalCrmInfo.map((aCI) => new AdditionalCrmInfo(aCI))
      : [];
    this.contactNotes = contactNotes;
    this.note = note ? new ContactNote(note) : undefined;
    this.distance = distance;
  }
}

export interface ContactDTO {
  sapCustomerID?: string;
  stContactID: string;
  appCustomerID?: string;
  crmCustomerID?: "";
  ts: string; //TODO Date format festlegen
  customerAccountGroup: string;
  salesOrganization: string;
  companyLine1: string;
  companyLine2: string;
  companyLine3: string;
  companyLine4: string;
  vatNumber: string;
  taxNumber1: string;
  taxNumber2: string;
  taxNumberFatturaPA: string;
  mailFatturaPA: string;
  industryCode: string;
  industryCodeItaly: string;
  customerLock: string;
  customerLockDescription: string;
  region: string;
  bezirk: string;
  kslBezirk: string;
  kundenschutzBis: string; //TODO vermutlich auch date?
  VB: string;
  zugewiesenerVB: string;
  billingDeliveryEmailAddress: string;
  shouldBillingDeliveryByPost: boolean;
  hasNewsletter: boolean;
  deletionFlag: boolean;
  address: AddressDTO;
  contactDetails: ContactDetailsDTO;
  contactPersons: ContactPersonDTO[];
  differingBillingAddresses: AddressDTO[];
  differingDeliveryAddresses: AddressDTO[];
  commentField: string;
  appImageIDs: string[]; //TODO ?
  recommendedBy?: string;
  recommendationFromADM?: string;
  recommendationTS?: string;
  __v?: number;
  lastContactHistoryEntry?: ContactHistoryEntryDTO;
  nextPlannedTodo?: ContactPlannedTodoDTO;
  subscribedCampaigns: ContactCampaignDTO[];
  foundingYear: number;
  zugewiesenBis?: string;
  legacyCrmID?: string;
  additionalCrmInfo?: AdditionalCrmInfoDTO[];
  contactNotes?: string[];
  note?: ContactNoteDTO;
  distance?: number;
}

export class ContactNote {
  note: string;
  lastEditAdmID?: string;
  lastEditDate?: Date;

  constructor({ note, lastEditAdmID, lastEditDate }: ContactNoteDTO) {
    this.note = note;
    this.lastEditAdmID = lastEditAdmID;
    this.lastEditDate = parseISODateOrUndefined(lastEditDate);
  }
}

export interface ContactNoteDTO {
  note: string;
  lastEditAdmID?: string;
  lastEditDate?: string;
}

interface ContactCampaignMailOpenersDTO {
  email: string;
  openedOn: string;
}

export enum CampaignType {
  MAILCHIMP = "MAILCHIMP",
}

interface ContactCampaignDTO {
  campaignType: CampaignType;
  stCampaignId: string;
  externalId: string;
  campaignName: string;
  mailOpeners?: ContactCampaignMailOpenersDTO[];
  creationDate?: string;
}

export interface ContactHistoryEntryDTO {
  noteNumber: string;
  created: string;
  type: NoteType;
  touchpointResultType?: TouchpointResultType;
}

export class ContactCampaignMailOpeners {
  email: string;
  openedOn: Date;

  constructor({ email, openedOn }: ContactCampaignMailOpenersDTO) {
    this.email = email;
    this.openedOn = parseISO(openedOn);
  }
}

export class ContactCampaign {
  campaignType: CampaignType;
  stCampaignId: string;
  externalId: string;
  campaignName: string;
  mailOpeners?: ContactCampaignMailOpeners[];
  creationDate?: Date;

  constructor({
    campaignType,
    stCampaignId,
    externalId,
    campaignName,
    mailOpeners,
    creationDate,
  }: ContactCampaignDTO) {
    this.campaignType = campaignType;
    this.stCampaignId = stCampaignId;
    this.externalId = externalId;
    this.campaignName = campaignName;
    this.mailOpeners = mailOpeners
      ? mailOpeners.map((mO) => new ContactCampaignMailOpeners(mO))
      : undefined;
    this.creationDate = parseISODateOrUndefined(creationDate);
  }
}

export interface ContactPlannedTodoDTO {
  todoNumber: string;
  dueOn: string;
  type: ToDoType;
}

export class ContactHistoryEntry {
  noteNumber: string;
  created: Date;
  type: NoteType;
  touchpointResultType?: TouchpointResultType;

  constructor({
    noteNumber,
    created,
    type,
    touchpointResultType,
  }: ContactHistoryEntryDTO) {
    this.noteNumber = noteNumber;
    this.created = parseISO(created);
    this.type = type;
    this.touchpointResultType = touchpointResultType;
  }
}

export class ContactPlannedTodo {
  todoNumber: string;
  dueOn: Date;
  type: ToDoType;

  constructor({ todoNumber, dueOn, type }: ContactPlannedTodoDTO) {
    this.todoNumber = todoNumber;
    this.dueOn = parseISO(dueOn);
    this.type = type;
  }
}

export class AdditionalCrmInfo {
  contactPersonCrmNumber?: string;
  salutation?: string;
  firstname?: string;
  lastname?: string;
  birthday?: Date;
  emailAddress?: string;
  telephoneNumber?: string;
  mobileNumber?: string;
  sapContactPersonNumber?: string;
  crmUpdateDate?: Date;
  articleWishes?: string;

  constructor({
    contactPersonCrmNumber,
    salutation,
    firstname,
    lastname,
    birthday,
    emailAddress,
    telephoneNumber,
    mobileNumber,
    sapContactPersonNumber,
    crmUpdateDate,
    articleWishes,
  }: AdditionalCrmInfoDTO) {
    this.contactPersonCrmNumber = contactPersonCrmNumber;
    this.salutation = salutation;
    this.firstname = firstname;
    this.lastname = lastname;
    this.birthday = parseISODateOrUndefined(birthday);
    this.emailAddress = emailAddress;
    this.telephoneNumber = telephoneNumber;
    this.mobileNumber = mobileNumber;
    this.sapContactPersonNumber = sapContactPersonNumber;
    this.crmUpdateDate = parseISODateOrUndefined(crmUpdateDate);
    this.articleWishes = articleWishes;
  }
}

export interface AdditionalCrmInfoDTO {
  contactPersonCrmNumber?: string;
  salutation?: string;
  firstname?: string;
  lastname?: string;
  birthday?: string;
  emailAddress?: string;
  telephoneNumber?: string;
  mobileNumber?: string;
  sapContactPersonNumber?: string;
  crmUpdateDate?: string;
  articleWishes?: string;
}

export interface ContactDetailsDTO {
  telephoneNumber: string;
  mobileNumber: string;
  emailAddress: string;
  faxNumber: string;
  url: string;
}

export class ContactDetails {
  telephoneNumber: string;
  mobileNumber: string;
  emailAddress: string;
  faxNumber: string;
  url: string;

  constructor({
    telephoneNumber,
    mobileNumber,
    emailAddress,
    faxNumber,
    url,
  }: ContactDetailsDTO) {
    this.telephoneNumber = telephoneNumber;
    this.mobileNumber = mobileNumber;
    this.emailAddress = emailAddress;
    this.faxNumber = faxNumber;
    this.url = url;
  }
}

export const emptyContactDetails = new ContactDetails({
  telephoneNumber: "",
  mobileNumber: "",
  emailAddress: "",
  faxNumber: "",
  url: "",
});
export const emptyContact: Contact = new Contact({
  stContactID: "",
  ts: "",
  customerAccountGroup: "",
  salesOrganization: "",
  companyLine1: "",
  companyLine2: "",
  companyLine3: "",
  companyLine4: "",
  vatNumber: "",
  taxNumber1: "",
  taxNumber2: "",
  taxNumberFatturaPA: "",
  mailFatturaPA: "",
  industryCode: "",
  industryCodeItaly: "",
  customerLock: "",
  customerLockDescription: "",
  region: "",
  bezirk: "",
  kslBezirk: "",
  kundenschutzBis: "",
  VB: "",
  zugewiesenerVB: "",
  billingDeliveryEmailAddress: "",
  shouldBillingDeliveryByPost: false,
  hasNewsletter: false,
  deletionFlag: false,
  address: emptyAddress,
  contactDetails: emptyContactDetails,
  contactPersons: [],
  differingBillingAddresses: [],
  differingDeliveryAddresses: [],
  commentField: "",
  appImageIDs: [], //TODO ?
  foundingYear: 0,
  subscribedCampaigns: [],
});
