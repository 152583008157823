import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import INotification, { NotificationType } from "../models/INotification";
import IStore from "../models/IStore";
import { v4 as uuid } from "uuid";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export const SHOW_INFO_SECONDS = 10;
export const SHOW_SUCCESS_SECONDS = 10;
export const SHOW_ERROR_SECONDS = 10;

export const thunkDismissNotification = (
  notification: INotification
): ThunkAction<void, IStore, null, Action<string>> => (dispatch) => {
  dispatch({
    type: DISMISS_NOTIFICATION,
    payload: notification,
  });
};

export const thunkCreateErrorNotification = (
  message: string,
  e: any
): ThunkAction<void, IStore, null, Action<string>> => async (dispatch) => {
  console.debug(e);
  dispatch(
    thunkCreateNotification(
      `${message}\n${e}`,
      NotificationType.ERROR,
      SHOW_ERROR_SECONDS
    )
  );
};

export const thunkCreateInfoNotification = (
  message: string
): ThunkAction<void, IStore, null, Action<string>> => async (dispatch) => {
  dispatch(
    thunkCreateNotification(message, NotificationType.INFO, SHOW_INFO_SECONDS)
  );
};

export const thunkCreateSuccessNotification = (
  message: string
): ThunkAction<void, IStore, null, Action<string>> => async (dispatch) => {
  dispatch(
    thunkCreateNotification(
      message,
      NotificationType.SUCCESS,
      SHOW_SUCCESS_SECONDS
    )
  );
};

export const thunkCreateNotification = (
  message: string,
  type: NotificationType,
  timeoutSeconds?: number
): ThunkAction<void, IStore, null, Action<string>> => async (dispatch) => {
  const notification = {
    message,
    type,
    id: uuid(),
    timestamp: new Date(),
  };

  dispatch({
    type: CREATE_NOTIFICATION,
    payload: notification,
  });

  timeoutSeconds &&
    timeoutSeconds !== 0 &&
    setTimeout(
      () => dispatch({ type: DISMISS_NOTIFICATION, payload: notification }),
      timeoutSeconds * 1000
    );
};
