export enum Complaint {
    ARTICLE_QUALITY = 'ARTICLE_QUALITY',
    WRONG_DELIVERY = 'WRONG_DELIVERY',
    WRONG_AMOUNT = 'WRONG_AMOUNT',
    DELIVERY_DATE = 'DELIVERY_DATE',
    MULTIPLE_REASONS = 'MULTIPLE_REASONS',
    OTHER = 'OTHER',
    TEXT_AND_DESIGN = 'TEXT_AND_DESIGN',
    ADVERTISING = 'ADVERTISING'
}


export enum Whereabouts {
    PICKUP_AT_VB = 'PICKUP_AT_VB',
    PICKUP_AT_CUSTOMER = 'PICKUP_AT_CUSTOMER',
    STAY_AT_CUSTOMER = 'STAY_AT_CUSTOMER'
}

export enum ProposedSolution {
    REFUND_AND_REPLACE = 'REFUND_AND_REPLACE',
    REFUND_NO_REPLACEMENT = 'REFUND_NO_REPLACEMENT',
    REWORK = 'REWORK',
    DISCOUNT = 'DISCOUNT',
    OTHER = 'OTHER'
}

export enum RefundRequestContactType {
    IN_PERSON = 'IN_PERSON',
    WRITTEN = 'WRITTEN',
    PHONE = 'PHONE'
}


export interface RefundRequest {
    contactPersonName: string,
    articleName : string,
    contactType: RefundRequestContactType,
    customerName: string;
    customerID: string;
    orderID: string;
    positionID: string;
    amount: string;
    complaint: Complaint;
    description: string;
    whereabouts: Whereabouts;
    packageAmount: string,
    proposedSolution: ProposedSolution,
    articlenumberGift: string;
    attachments: File[],
}

