import Contact from "../models/Contact";
import ContactList from "../models/ContactList";
import IAction from "../actions/IAction";
import {
    DELETE_CONTACT_LIST_SUCCESS,
    GET_CONTACT_LISTS_SUCCESS,
    SAVE_CONTACT_LIST_SUCCESS,
    SELECT_CONTACT_LIST_SUCCESS
} from "../actions/ContactListActions";


export interface IContactsListReducerState {
    currentContactsListResult: Contact[];
    contactLists: ContactList[];
    totalResults: number;
    selectedList?: ContactList;
    page: number;
    withDistanceKm?:boolean
}

export default class ContactListReducer {
    public static readonly INITIAL_STATE: IContactsListReducerState = {
        currentContactsListResult: [],
        contactLists: [],
        totalResults: 0,
        page: 1,
        withDistanceKm:false
    }

    public static reducer(
        state: IContactsListReducerState = ContactListReducer.INITIAL_STATE,
        action: IAction<any>
    ): IContactsListReducerState {
        switch (action.type) {
            case GET_CONTACT_LISTS_SUCCESS:
                return {...state, contactLists: action.payload}
            case SAVE_CONTACT_LIST_SUCCESS:
                return {
                    ...state,
                    contactLists: [action.payload, ...state.contactLists.filter(cL => cL.contactListID !== action.payload.contactListID)]
                }
            case SELECT_CONTACT_LIST_SUCCESS:
                return {
                    ...state,
                    selectedList: action.payload.contactList,
                    currentContactsListResult: action.payload.searchResult,
                    totalResults: action.payload.totalResults,
                    page: action.payload.page,
                    withDistanceKm: action.payload.withDistanceKm
                }
            case DELETE_CONTACT_LIST_SUCCESS:
                return {
                    ...state,
                    contactLists: state.contactLists.filter(cL => cL.contactListID !== action.payload),
                    selectedList: undefined
                }
            default:
                return state;
        }
    }
}
