import IAction from "../actions/IAction";
import BusinessExpenseReport from "../models/BusinessExpenseReport";
import {
    CREATE_MY_BER_SUCCESS,
    GET_BER_AS_ACCOUNTANT_SUCCESS,
    GET_BER_FOR_MY_ADMS_SUCCESS,
    GET_MY_BER_SUCCESS,
    UPDATE_BER_STATUS_AS_ACCOUNTANT_SUCCESS,
    UPDATE_BER_STATUS_SUCCESS, UPDATE_MY_BER_SUCCESS
} from "../actions/BusinessExpenseReportActions";


export interface IBusinessExpenseReportRecuderState {
    managmentReports: BusinessExpenseReport[];
    accountingReports: BusinessExpenseReport[];
    myReports: BusinessExpenseReport[];
}

export default class BusinessExpenseReportRecuder {
    public static readonly INITIAL_STATE: IBusinessExpenseReportRecuderState = {
        managmentReports: [],
        accountingReports: [],
        myReports: [],
    };

    public static reducer(
        state: IBusinessExpenseReportRecuderState = BusinessExpenseReportRecuder.INITIAL_STATE,
        action: IAction<any>
    ): IBusinessExpenseReportRecuderState {
        switch (action.type) {
            case GET_BER_FOR_MY_ADMS_SUCCESS:
                return {...state, managmentReports: action.payload};
            case GET_BER_AS_ACCOUNTANT_SUCCESS:
                return {...state, accountingReports: action.payload};
            case GET_MY_BER_SUCCESS:
                return {...state, myReports: action.payload}
            case CREATE_MY_BER_SUCCESS:
                return {...state, myReports: [...state.myReports, action.payload]}
            case UPDATE_MY_BER_SUCCESS:
                return {
                    ...state,
                    myReports: [...state.myReports.map(r => r.berNumber === action.payload.berNumber ? action.payload : r)]
                }
            case UPDATE_BER_STATUS_SUCCESS:
                return {
                    ...state,
                    managmentReports: [...state.managmentReports.map(r =>
                        r.berNumber === action.payload.berNumber ? action.payload : r)
                    ]
                };
            case UPDATE_BER_STATUS_AS_ACCOUNTANT_SUCCESS:
                return {
                    ...state,
                    accountingReports: state.accountingReports.map(r =>
                        r.berNumber === action.payload.report.berNumber ? action.payload.report : r)
                };
            default:
                return state;
        }
    }
}
