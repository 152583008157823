import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import { observer } from 'mobx-react-lite'
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
    Divider,
    IconButton,
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    TextField,
    Typography
} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import QueueIcon from '@material-ui/icons/Queue';
import Navigation from "../components/Navigation";
import ProductUserMenu from "../components/ProductUserMenu";
import useProductUser from "../hooks/useProductUser";


const useStyles = makeStyles((theme: Theme) => ({
    wishlistMenu: {
        padding: theme.spacing(0.5, 0.5, 0.5, 2),
        marginRight: theme.spacing(2)
    },
    wishlistMenuInput: {
        marginTop: theme.spacing(0.5),
        marginRight: theme.spacing(1)
    },
    wishlistCard: {
        position: "relative",
    },
    wishlistCardLink: {
        textDecoration: "none",
        color: "inherit",
    },
    wishlistCardImg: {
        width: "100%",
        padding: theme.spacing(2),
    },
    wishlistCardRemove: {
        position: "absolute",
        top: 0,
        right: 0,
    },

}));

export default observer(() => {
    const {wishlistID} = useParams<{wishlistID:string}>();
    const classes = useStyles();
    const productUserStore = useProductUser();
    const { wishlist, removeFromWishlist, getWishlist } = productUserStore;
    const customWishlist = getWishlist(wishlistID);

    return (
        <Navigation breadcrumbName={'Wunschliste'}>
            <Grid container spacing={3} >
                <Grid container item justifyContent="space-between">
                    { customWishlist?.name ?
                        <Typography variant="h4">
                            {customWishlist.name}
                        </Typography>
                        :
                        <AddNewWishlist />
                    }
                    <ProductUserMenu />
                </Grid>
                <Grid container item spacing={3}>
                    {wishlist.map((item: any) =>
                        <Grid key={item.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Card className={classes.wishlistCard}>
                                <CardActionArea>
                                    <a className={classes.wishlistCardLink} href={item.link}>
                                        <CardMedia className={classes.wishlistCardImg} image={item.img} component="img"/>
                                        <Divider/>
                                        <CardContent>
                                            <Typography variant="h6">
                                                {item.title}
                                            </Typography>
                                        </CardContent>
                                    </a>
                                </CardActionArea>
                                <IconButton
                                    className={classes.wishlistCardRemove}
                                    onClick={() => removeFromWishlist(item.id)}
                                >
                                    <HighlightOffIcon />
                                </IconButton>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Grid>

        </Navigation>
    )
});

export const AddNewWishlist = observer(() => {
    const [name, setName] = useState("");
    const classes = useStyles();
    const {createWishlist} = useProductUser();
    const handleChange = (ev: any) => {
        const {value} = ev.target;
        setName(value);
    }
    const handleCreateWishlist = () => {
        createWishlist(name);
    }

    return (
        <Card className={classes.wishlistMenu} variant="outlined">
            <TextField className={classes.wishlistMenuInput} id="wishlist-name" name="name" label="New wishlist name" variant="outlined" size="small" value={name} onChange={handleChange} />
            <IconButton onClick={handleCreateWishlist}>
                <QueueIcon />
            </IconButton>
            <IconButton onClick={() => document.location.assign(`/wishlist-list`)}>
                <MenuOpenIcon />
            </IconButton>
        </Card>
    )
})
