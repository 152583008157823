import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Navigation from "../components/Navigation";
import {Divider, Tab, Tabs, Typography} from "@material-ui/core";
import ContactDetailAddressTab from "../components/contactDetail/contactDetailTabs/ContactDetailAddressTab";
import ContactDetailOrderHistoryTab from "../components/contactDetail/contactDetailTabs/ContactDetailOrderHistoryTab";
import TabPanel from "../components/TabPanel";
import ContactDetailCommunicationTab from "../components/contactDetail/contactDetailTabs/ContactDetailCommunicationTab";
import {thunkGetContact} from "../store/actions/ContactActions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {useTranslation} from "react-i18next";
import ContactDetailTouchpointTab from "../components/contactDetail/contactDetailTabs/ContactDetailTouchpointTab";
import Api from "../store/Api";
import useQuery from '../hooks/useQuery';
import Order from "../store/models/Order";
import {canVBDisplayAndEditContact} from "../util/ContactUtil";
import Kaz from "../store/models/KAZ";

export default function ContactDetailsView() {
    const {stContactID} = useParams<{ stContactID: string }>();
    const {t} = useTranslation();
    const activeTab = useQuery().get('tab');
    const [loading, setLoading] = useState(false);
    const [selectedTab, setTab] = React.useState(activeTab ? activeTab : 'address');
    const dispatch = useDispatch();
    let contact = useSelector((state: IStore) => state.contact.currentContact, shallowEqual);
    useEffect(() => {
        setLoading(true)
        // @ts-ignore
        dispatch(thunkGetContact(stContactID)).then(() =>
            setLoading(false)
        );
    }, [stContactID, dispatch]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [kaz, setKaz] = useState<Kaz[]>([]);
    const canDisplayAndEditFullContact = canVBDisplayAndEditContact(contact);
    useEffect(() => {
        if (contact && contact.sapCustomerID && contact.stContactID === stContactID && canDisplayAndEditFullContact) {
            Api.getOrdersForContact(contact.sapCustomerID).then(ordersFromApi => {
                setOrders(ordersFromApi);
            })
            Api.getKAZForContact(contact.sapCustomerID).then(kazFromApi => {
                setKaz(kazFromApi);
            })
        } else {
            setKaz([]);
            setOrders([]);
        }
    }, [contact, canDisplayAndEditFullContact, stContactID])
    return <Navigation backBtn={true} breadcrumbName={t('contactDetailsView.breadcrumb')}>
        {loading ? t('common.loadingContent') :
            !contact || contact.stContactID !== stContactID ? t('contactDetailsView.customerNotFound') :
                <>
                    <h1>{contact.companyLine1}</h1>
                    {canDisplayAndEditFullContact ?
                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            onChange={(e, val) => setTab(val)}
                        >
                            <Tab value={'address'} label={t('contactDetailsView.tabAddress')}/>
                            <Tab value={'orderhistory'} label={t('contactDetailsView.tabOrderHistory')}/>
                            <Tab value={'touchpoint'} label={t('contactDetailsView.tabTouchpoint')}/>
                            <Tab value={'communication'} label={t('contactDetailsView.tabCommunication')}/>
                        </Tabs> :
                        <Typography>{t('contactDetailsView.cantDisplayAndEditFullContact')}</Typography>
                    }
                    <Divider/>
                    <TabPanel value={selectedTab} index={'address'}>
                        <ContactDetailAddressTab contact={contact} orders={orders}
                                                 canDisplayAndEditFullContact={canDisplayAndEditFullContact}/>
                    </TabPanel>
                    {canDisplayAndEditFullContact ?
                        <>
                            <TabPanel value={selectedTab} index={'orderhistory'}>
                                <ContactDetailOrderHistoryTab contact={contact} orders={orders} kaz={kaz}/>
                            </TabPanel>
                            <TabPanel value={selectedTab} index={'touchpoint'}>
                                <ContactDetailTouchpointTab contact={contact} orders={orders}/>
                            </TabPanel>
                            <TabPanel value={selectedTab} index={'communication'}>
                                <ContactDetailCommunicationTab contact={contact}/>
                            </TabPanel>
                        </> : null
                    }
                </>

        }
    </Navigation>
}
