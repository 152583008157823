import React from 'react';
import {Box, Button, FormControl, Grid, TextField} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";

interface BusinessExpenseManagerFeedbackModalProps {
    headline: string;
    afterCreate: (note: string) => void
    openButton: (handleOpen: () => void) => React.ReactNode
}


export default function BusinessExpenseManagerFeedbackModal({headline, afterCreate, openButton}: BusinessExpenseManagerFeedbackModalProps) {

    const {t} = useTranslation();

    return (
        <Modal maxWidth={"md"} fullWidth={true} title={headline} contentAndButtons={(handleClose) => (
            <>
                <DialogContent>
                    <Formik
                        initialValues={{note: ''}}
                        onSubmit={async (values) => {
                            afterCreate(values.note);
                        }}
                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit,
                          }) => (
                            <form id="createNoteModalForm" onSubmit={handleSubmit}>
                                <Box m={3}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    multiline={true}
                                                    fullWidth={true}
                                                    rows={3}
                                                    name="note"
                                                    label={t('businessExpenseManagerFeedbackModal.noteLbl')}
                                                    value={values.note}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}

                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('common.cancelBtn')}
                    </Button>
                    <Button form="createNoteModalForm" type="submit" color={"primary"}>
                        {t('common.sendBtn')}
                    </Button>
                </DialogActions>
            </>
        )} openButton={openButton}/>
    );
}
