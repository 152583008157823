import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import SchoolIcon from '@material-ui/icons/School';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {NavLink as RouterLink, useHistory} from "react-router-dom";
import {Breadcrumbs} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {toogleDrawer} from "../store/actions/UIActions";
import {grey} from "@material-ui/core/colors";
import NotificationList from "./notifications/NotificationList";
import {thunkGetCalendarEvents} from "../store/actions/CalendarEventActions";
import {hasAccess, hasLocale, UserRoles} from "../util/UserUtil";
import {useTranslation} from "react-i18next";
import EventNoteIcon from '@material-ui/icons/EventNote';
import EventIcon from '@material-ui/icons/Event';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
// import CategoryIcon from '@material-ui/icons/Category';
// import AssignmentIcon from '@material-ui/icons/Assignment';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#ffffff',
        color: '#000000',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        border: 'none'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        borderColor: grey[50]//'rgba(0, 0, 0, 0.02)',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 1,
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9) + 1,
        },
        borderColor: grey[50]//'rgba(0, 0, 0, 0.02)',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        minHeight: '75vh'
    },
    innerContent: {
        height: '100%',
        margin: theme.spacing(3),

    },
    copyrightText: {
        fontSize: 12
    },
    copyright: {
        padding: theme.spacing(0, 3),
        flexFlow: "row-reverse"
    },
    active: {
        backgroundColor: grey[50],//'rgba(0, 0, 0, 0.02)',
        borderRight: 'none'
    },
    drawerTitle: {margin: "5px auto 5px 5px"},
    drawerDivider: {
        height: 3,
        backgroundColor: grey[50]//'rgba(0, 0, 0, 0.02)',
    },
    drawerHeadline: {margin: "20px auto 10px 20px"},
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}));

const Navigation: React.FunctionComponent<{ backBtn?: boolean, breadcrumbName: string }> = props => {
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();
    const {t} = useTranslation();

    const open = useSelector((state: IStore) => state.UI.drawerOpen);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(thunkGetCalendarEvents())
    }, [dispatch]);

    const handleDrawerOpen = () => {
        dispatch(toogleDrawer());
    };

    const handleDrawerClose = () => {
        dispatch(toogleDrawer());
    };

    const goBack = () => {
        history.goBack();
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>

            <AppBar
                position="fixed"
                elevation={0}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <div style={{flexGrow: 1, display: 'flex'}}>
                        <img src={'/bag-logo.svg'} alt="avatar" style={{height: 27, flexGrow: 'inherit'}}/>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <Typography className={classes.drawerTitle}>SalesTool v1.0</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <Divider className={classes.drawerDivider}/>
                <Typography component={'h1'}
                            className={classes.drawerHeadline}>{open ? 'Sales' : 'S'}</Typography>
                <List>
                    <ListItem button component={RouterLink} exact={true} activeClassName={classes.active}
                              to={'/'}>
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText primary={t('navigation.listItemDashboard')}/>
                    </ListItem>

                    <ListItem button component={RouterLink} activeClassName={classes.active} to={'/contact'}>
                        <ListItemIcon><PeopleIcon/></ListItemIcon>
                        <ListItemText primary={t('navigation.listItemContacts')}/>
                    </ListItem>
                    <ListItem button component={RouterLink} activeClassName={classes.active}
                              to={'/contactlist'}>
                        <ListItemIcon><ListIcon/></ListItemIcon>
                        <ListItemText primary={t('navigation.listItemContactLists')}/>
                    </ListItem>
                    <ListItem button component={RouterLink} activeClassName={classes.active}
                              to={'/meetings'}>
                        <ListItemIcon><EventIcon/></ListItemIcon>
                        <ListItemText primary={t('navigation.listItemMeetingListView')}/>
                    </ListItem>
                </List>
                {/*<Divider className={classes.drawerDivider}/>*/}
                {/*<Typography component={'h1'}*/}
                {/*            className={classes.drawerHeadline}>{open ? 'Produkte' : 'P'}</Typography>*/}
                {/*<List>*/}
                {/*    <ListItem button component={RouterLink} activeClassName={classes.active}*/}
                {/*              to={'/search/product?cat_id'}>*/}
                {/*        <ListItemIcon><CategoryIcon/></ListItemIcon>*/}
                {/*        <ListItemText primary={t('navigation.listItemCategories')}/>*/}
                {/*    </ListItem>*/}
                {/*    <ListItem button component={RouterLink} activeClassName={classes.active} to={'/offerlist'}>*/}
                {/*        <ListItemIcon><AssignmentIcon/></ListItemIcon>*/}
                {/*        <ListItemText primary={t('navigation.listItemOffers')}/>*/}
                {/*    </ListItem>*/}
                {/*</List>*/}
                <Divider className={classes.drawerDivider}/>
                <Typography component={'h1'}
                            className={classes.drawerHeadline}>{open ? 'Management' : 'M'}</Typography>
                <List>
                    <ListItem button component={RouterLink} activeClassName={classes.active} to={'/order'}>
                        <ListItemIcon><AllInboxIcon/></ListItemIcon>
                        <ListItemText primary={t('navigation.listItemOrders')}/>
                    </ListItem>
                    <ListItem button component={RouterLink} activeClassName={classes.active}
                              to={'/kpi-dashboard'}>
                        <ListItemIcon><EqualizerIcon/></ListItemIcon>
                        <ListItemText primary={t('navigation.listItemKPIDashboard')}/>
                    </ListItem>
                    {
                        (hasLocale('de') || hasLocale('it') || hasLocale('ch')|| hasLocale('lu'))  &&
                        <>
                            <ListItem button component={RouterLink} activeClassName={classes.active}
                                      to={'/businessExpenses'}>
                                <ListItemIcon><DateRangeIcon/></ListItemIcon>
                                <ListItemText primary={t('navigation.listItemBusinessExpenses')}/>
                            </ListItem>
                            <ListItem button component={RouterLink} activeClassName={classes.active}
                                      to={'/dailyAdmReport'}>
                                <ListItemIcon><CalendarTodayIcon/></ListItemIcon>
                                <ListItemText primary={t('navigation.listItemDailyAdmReports')}/>
                            </ListItem>
                        </>
                    }
                </List>

                {hasAccess([
                        UserRoles.ST_SUPERUSER,
                        UserRoles.ST_MANAGER,
                        UserRoles.ST_AREA_MANAGER,
                        UserRoles.BER_SUPER_MANAGER]) &&
                    <>
                        <Divider className={classes.drawerDivider}/>
                        <Typography component={'h1'}
                                    className={classes.drawerHeadline}>{open ? t('navigation.menuHeadlineLeadership') : 'F'}</Typography>
                        <List>
                            <ListItem button component={RouterLink} activeClassName={classes.active}
                                      to={'/vbMeetings'}>
                                <ListItemIcon><EventNoteIcon/></ListItemIcon>
                                <ListItemText primary={t('navigation.listItemMeetingListManagerView')}/>
                            </ListItem>
                            <ListItem button component={RouterLink} activeClassName={classes.active}
                                      to={'/kpi-manager-dashboard'}>
                                <ListItemIcon><TrendingUpIcon/></ListItemIcon>
                                <ListItemText primary={t('navigation.listItemTerminatorsDashboard')}/>
                            </ListItem>
                            {
                                (hasLocale('de') || hasLocale('at') || hasLocale('it')|| hasLocale('ch')) &&
                                <>
                                    <ListItem button component={RouterLink} activeClassName={classes.active}
                                              to={'/businessExpensesManager'}>
                                        <ListItemIcon><BusinessCenterIcon/></ListItemIcon>
                                        <ListItemText primary={t('navigation.listItemBusinessExpensesManager')}/>
                                    </ListItem>
                                    <ListItem button component={RouterLink} activeClassName={classes.active}
                                              to={'/dailyAdmReportManager'}>
                                        <ListItemIcon><EventAvailableIcon/></ListItemIcon>
                                        <ListItemText primary={t('navigation.listItemDailyAdmReportsManager')}/>
                                    </ListItem>
                                </>
                            }
                            {hasAccess([UserRoles.ST_ASSIGNATION, UserRoles.ST_SUPERUSER]) &&
                                <ListItem button component={RouterLink} activeClassName={classes.active}
                                          to={'/assignationList'}>
                                    <ListItemIcon><AssignmentReturnIcon/></ListItemIcon>
                                    <ListItemText primary={t('navigation.listItemAssignationList')}/>
                                </ListItem>
                            }
                        </List>
                    </>
                }
                {hasAccess([
                    UserRoles.ST_SUPERUSER,
                    UserRoles.BER_SUPER_MANAGER,
                    UserRoles.BER_ACCOUNTANT,
                    UserRoles.ST_TERMINATOR]) ?


                    <>
                        <Divider className={classes.drawerDivider}/>
                        <Typography component={'h1'}
                                    className={classes.drawerHeadline}>{open ? 'Intern' : 'I'}</Typography>
                        <List>
                            {hasAccess([
                                    UserRoles.ST_SUPERUSER,
                                    UserRoles.BER_SUPER_MANAGER,
                                    UserRoles.BER_ACCOUNTANT,
                                    UserRoles.ST_TERMINATOR]) &&
                                hasLocale('de') &&
                                <ListItem button component={RouterLink} activeClassName={classes.active}
                                          to={'/businessExpensesAccountant'}>
                                    <ListItemIcon><MonetizationOnIcon/></ListItemIcon>
                                    <ListItemText primary={t('navigation.listItemBusinessExpensesAccountant')}/>
                                </ListItem>
                            }
                            {hasAccess([
                                    UserRoles.ST_SUPERUSER,
                                    UserRoles.ST_TERMINATOR]) &&
                                <ListItem button component={RouterLink} activeClassName={classes.active}
                                          to={'/terminatorMeetings'}>
                                    <ListItemIcon><CalendarTodayIcon/></ListItemIcon>
                                    <ListItemText primary={t('navigation.listItemTerminatorMeetings')}/>
                                </ListItem>
                            }
                        </List>
                    </>
                    : null}

                <Divider className={classes.drawerDivider}/>
                <Typography component={'h1'} className={classes.drawerHeadline}>{open ? 'Support' : ''}</Typography>
                <List>
                    {(hasLocale('de') || hasLocale('at') || hasLocale('ch')) &&
                        <>
                            <ListItem button component={RouterLink} activeClassName={classes.active} to={'/academy'}>
                                <ListItemIcon><SchoolIcon/></ListItemIcon>
                                <ListItemText primary={t('academy.listItemTraining')}/>
                            </ListItem>
                        </>
                    }
                    <ListItem button component={RouterLink} activeClassName={classes.active} to={'/help'}>
                        <ListItemIcon><HelpIcon/></ListItemIcon>
                        <ListItemText primary={t('navigation.listItemHelpAndFeedback')}/>
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}>
                </div>
                <NotificationList/>
                {props.backBtn ?
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link style={{cursor: "pointer"}} onClick={goBack} color="inherit">
                            {t('navigation.backBtn')}
                        </Link>
                    </Breadcrumbs>
                    : null}
                <div className={classes.innerContent}>
                    {props.children}
                </div>
                <div className={classes.copyright}>
                    <Typography align={"right"} className={classes.copyrightText}>Copyright © 2022 Berendsohn
                        AG</Typography>
                </div>
            </main>
        </div>
    );
}

export default Navigation;
