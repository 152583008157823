import React, {useEffect, useState} from "react";
import Navigation from "../components/Navigation";
import {Grid, IconButton, Paper} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Label from '../components/Label'
import {makeStyles} from "@material-ui/core/styles";
import {formatDateObj, formatToCurrency} from "../components/Formatter";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import Api from "../store/Api";
import {getMyADMID} from "../util/UserUtil";
import OrderOverview from "../store/models/OrderOverview";
import {AdmName} from "../components/AdmName";
import {subYears} from "date-fns";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345
    },
    root1: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        marginBottom: 20
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'
    },
    label: {
        marginLeft: theme.spacing(1)
    },
    container: {
        marginTop: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(1, 3),
        margin: theme.spacing(3, 0),
        minHeight: '50%'
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        fontWeight: "bold",
    },
    inputContainer: {
        flexGrow: 1,
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5)
    },
    divider: {
        height: 24,
        width: 1
    },
    imageIcon: {
        alignItems: "center",
        justify: "center",
        height: 200
    }

}));

export function CommissonValue(props: { order: OrderOverview, classes: any }) {
    const {order} = props;
    return <i>{formatToCurrency(order.provisionSum, order.waehrung)}</i>;
}

export function OrderStatusIndicator(props: { order: OrderOverview, classes: any }) {
    const {t} = useTranslation();
    const {order} = props;
    if (order.gesamtStatus === "teilweise bearbeitet") {
        return <Label color="warning">{t('orderView.orderPartlyCompleted')}</Label>
    }
    if (order.gesamtStatus === "vollständig bearbeitet") {
        return <Label color="success">{t('orderView.orderCompleted')}</Label>
    }
    if (order.gesamtStatus === "nicht bearbeitet") {
        return <Label color="error">{t('orderView.orderNotProcessed')}</Label>
    }
    if (order.gesamtStatus === "nicht relevant") {
        return <Label color="primary">{t('orderView.orderNotRelevant')}</Label>
    }
    return <p>Unmapped Status: {order.gesamtStatus}</p>
}


const thisYearDate = new Date();

const filterMap = {
    'none': {},
    'thisYear': {forYear: thisYearDate.getFullYear()},
    'lastYear': {forYear: subYears(thisYearDate, 1).getFullYear()},
    'onlyOpen': {forStatus: 'open'},
    'locked': {forStatus: 'locked'}
}

function OrderView() {

    const [orderOverviews, setOrderOverviews] = useState<OrderOverview[]>([]);

    const [filterKey, setFilterKey] = useState('none');

    const {admID} = useParams<{ admID?: string }>();

    const dispatch = useDispatch();
    useEffect(() => {
        // @ts-ignore
        const filter: any = filterMap[filterKey];
        Api.getOrderOverviewsForAdm(admID ? admID : getMyADMID(), filter).then(ordersFromApi => {
            setOrderOverviews(ordersFromApi);
        })
    }, [dispatch, filterKey, admID])

    const classes = useStyles();
    const {t} = useTranslation();

    const filterOrderList = (newKey: string) => () => {
        setFilterKey(newKey)
    }

    return <>
        <Navigation breadcrumbName={'Meine Aufträge'} backBtn={true}>
            <Grid container spacing={3} justifyContent={"center"}>

                <Grid item md={12} className={classes.root1}>

                    <Chip
                        label={t('orderView.allOrders')}
                        clickable
                        color={filterKey === 'none' ? 'primary' : 'default'}
                        onClick={filterOrderList('none')}
                    />
                    <Chip
                        label={t('orderView.openOrders')}
                        clickable
                        color={filterKey === 'onlyOpen' ? 'primary' : 'default'}
                        onClick={filterOrderList('onlyOpen')}
                    />
                    <Chip
                        label={t('orderView.ordersThisYear')}
                        clickable
                        color={filterKey === 'thisYear' ? 'primary' : 'default'}
                        onClick={filterOrderList('thisYear')}
                    />
                    <Chip
                        label={t('orderView.orderLastYear')}
                        clickable
                        color={filterKey === 'lastYear' ? 'primary' : 'default'}
                        onClick={filterOrderList('lastYear')}
                    />
                    <Chip
                        label={t('orderView.orderLocked')}
                        clickable
                        color={filterKey === 'locked' ? 'primary' : 'default'}
                        onClick={filterOrderList('locked')}
                    />

                </Grid>

            </Grid>

            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell><b>{t('orderView.company')}</b><br/>{t('orderView.orderDate')}</TableCell>
                            <TableCell><b>{t('orderView.orderNumber')}</b><br/>{t('orderView.orderType')}</TableCell>
                            <TableCell><b>{t('orderView.orderValue')}</b><br/>{t('orderView.commission')}</TableCell>
                            <TableCell><b>{t('orderView.orderStatus')}</b></TableCell>
                            <TableCell align="right"><b>{t('orderView.orderDetails')}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderOverviews.map((order) =>
                            <TableRow key={order.auftragsNummer}>
                                <TableCell component="th" scope="row">
                                    <Grid container>
                                        <Grid item xs={11}>
                                            {order.companyLine ? order.companyLine : <AdmName personalnummer={order.kundenNummer}/>}<br/>
                                            {formatDateObj(order.bestellDatum)}<br/>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Grid container>
                                        <Grid item xs={11}>
                                            {order.auftragsNummer}<br/>
                                            {order.auftragsGrund}<br/>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Grid container>
                                        <Grid item xs={11}>
                                            {formatToCurrency(order.auftragsNettowert, order.waehrung)}<br/>
                                            <CommissonValue order={order} classes={classes}/>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <OrderStatusIndicator order={order} classes={classes}/>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton aria-label="details" component={Link}
                                                to={`/order/${order.auftragsNummer}`}>
                                        <ArrowForwardIosIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Navigation>
    </>
}

export default OrderView;
