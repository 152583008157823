import ContactDetailSearch, {ContactDetailSearchDTO, emptyContactDetailSearch} from "./ContactDetailSearch";

export enum ContactListType {
    STATIC = 'STATIC',
    DYNAMIC_QUERY = 'DYNAMIC_QUERY',
    CUSTOMER_PROCESSING_QUERY = 'CUSTOMER_PROCESSING_QUERY'
}

export default class ContactList {
    query: ContactDetailSearch;
    name: string;
    contactListID?: string;
    selectedContactIds: string[];
    type?: ContactListType;
    customerProcessingQuery?: string;

    constructor({query, name, contactListID, selectedContactIds, type, customerProcessingQuery}: ContactListDTO) {
        this.query = query;
        this.name = name;
        this.contactListID = contactListID;
        this.selectedContactIds = selectedContactIds;
        this.type = type;
        this.customerProcessingQuery = customerProcessingQuery;
    }
}


export interface ContactListDTO {
    query: ContactDetailSearchDTO;
    name: string;
    contactListID?: string;
    selectedContactIds: string[];
    type?: ContactListType;
    customerProcessingQuery?: string;
}

export const emptyContactList: ContactList = {
    contactListID: '',
    name: '',
    query: emptyContactDetailSearch,
    selectedContactIds: [],
    type: ContactListType.DYNAMIC_QUERY,
}
