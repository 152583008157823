import React, {useState} from 'react';
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import Api from "../../store/Api";
import Contact from "../../store/models/Contact";

interface ContactRecommendedByAutocompleteProps {
    value?: string;
    setValue: (val: string | null) => void;
}


export default function ContactRecommendedByAutocomplete({value, setValue}: ContactRecommendedByAutocompleteProps) {

    const {t} = useTranslation();


    const [autocompleteOptions, setAutocompleteOptions] = useState<Contact[]>([]);
    const [inputValue, setInputValue] = useState(value ? value : "");

    const [contactFromValue, setContactFromValue] = useState<Contact | null>(null);
    if (value && !contactFromValue) {
        Api.getContact(value).then(contact => {
            setAutocompleteOptions([...autocompleteOptions, contact])
            setContactFromValue(contact)
        })
    }
    return <>
        <Autocomplete
            options={autocompleteOptions}
            getOptionLabel={(option) => `${option.companyLine1} - ${option.address.zipCode} ${option.address.place}`}
            getOptionSelected={(option, value) => option.stContactID === value.stContactID}
            style={{width: 300}}
            value={contactFromValue}
            onChange={(event, newValue) => {
                setContactFromValue(newValue);
                setValue(newValue ? newValue.stContactID : null);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                if (newInputValue) {
                    Api.searchContacts(newInputValue, 1).then(result => setAutocompleteOptions(result.searchResult))
                }
                setInputValue(newInputValue);
            }}
            noOptionsText={t('contactRecommendedByAutocomplete.noOptionText')}
            renderInput={(params) => <TextField {...params} label={t('contactRecommendedByAutocomplete.label')}
                                                variant="standard"/>}
        />
    </>
}
