import {Action} from "redux";
import IStore from "../models/IStore";
import {ThunkAction} from "redux-thunk";
import Api from "../Api";


export const GET_PRODUCT_SEARCH_ITEM_SUCCESS = "GET_PRODUCT_SEARCH_ITEM_SUCCESS";
export const GET_PRODUCT_SEARCH_ITEM_ERROR = "GET_PRODUCT_SEARCH_ITEM_ERROR";

export const thunkGetProductSearchItems = (): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const items = await Api.getProductSearchItems();
        dispatch({
            type: GET_PRODUCT_SEARCH_ITEM_SUCCESS,
            payload: items
        });
    } catch (e) {
        dispatch({
            type: GET_PRODUCT_SEARCH_ITEM_ERROR,
            payload: e
        })
    }
}