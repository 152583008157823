export function downloadFile(blob: Blob, name: string, type: string) {
    const newBlob = new Blob([blob], {type});

    // IE
    // @ts-ignore
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers
    const url = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    // Firefox need link to be attached to document tree
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Firefox need a delay before revoking the ObjectURL
    setTimeout(() => window.URL.revokeObjectURL(url), 100);
}
