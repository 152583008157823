import Navigation from "../components/Navigation";
import {Divider, Grid, Paper, Tab, Tabs, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import TabPanel from "../components/TabPanel";
import BadStatusTab from "../components/berendsohnAdminDashboard/tabs/BadStatusTab";
import BadSyncTab from "../components/berendsohnAdminDashboard/tabs/BadSyncTab";
import BadAssignmentTab from "../components/berendsohnAdminDashboard/tabs/BadAssignmentTab";
import BadServiceTab from "../components/berendsohnAdminDashboard/tabs/BadServiceTab";
import BadExportsTab from "../components/berendsohnAdminDashboard/tabs/BadExportsTab";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        width: '100%'
    },
}));


function BerendsohnAdminDashboardView() {


    const classes = useStyles();
    const [selectedTab, setSelectedTab] = React.useState('status');

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setSelectedTab(newValue);
    };


    return (<>
        <Navigation breadcrumbName={'B.A.D.'}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant={'h4'}>Berendsohn Admin Dashboard</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            onChange={handleChange}
                        >
                            <Tab value={'status'} label={"Status"}/>
                            <Tab value={'sync'} label={"Sync Jobs"}/>
                            <Tab value={'assignment'} label={"Zuweisungen"}/>
                            <Tab value={'service'} label={"Service"}/>
                            <Tab value={'exports'} label={"Export Listen"}/>
                        </Tabs>
                        <Divider/>
                        <TabPanel value={selectedTab} index={"status"}>
                            <BadStatusTab/>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={"sync"}>
                            <BadSyncTab/>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={"assignment"}>
                            <BadAssignmentTab/>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={"service"}>
                            <BadServiceTab/>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={"exports"}>
                            <BadExportsTab/>
                        </TabPanel>
                    </Paper>
                </Grid>

            </Grid>
        </Navigation>
    </>)
}

export default BerendsohnAdminDashboardView;
