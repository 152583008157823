import React, {useState} from 'react';
import {Autocomplete} from '@material-ui/lab';
import {TextField} from '@material-ui/core';
import {useField, useFormikContext} from 'formik';
import Api from "../../store/Api";
import IAutocompleteAddressResponse from '../../store/models/IAutocompleteAddressDto';

let searchTimeout: number;

const AddressAutocomplete = (props: any) => {
    const {name, inputFieldProps: textFieldProps, endAdornment, beforeChange, getOptionLabel} = props;
    const {isSubmitting} = useFormikContext();
    const fieldProps = useField(name);
    // eslint-disable-next-line
    const [field, __meta, helpers] = fieldProps;

    const [addresses = [], setAddresses] = useState<IAutocompleteAddressResponse>();

    const searchDebounced = (value: string) => {
        clearTimeout(searchTimeout)
        searchTimeout = window.setTimeout(async () => {
            setAddresses(await Api.autocompleteAddresses(value));
        }, 500)
    };

    return (
        <Autocomplete
            freeSolo
            disableClearable
            selectOnFocus={true}
            disabled={isSubmitting}
            getOptionLabel={getOptionLabel}
            // getOptionSelected={getOptionSelected}
            options={addresses}
            {...field}
            onChange={(event, value) => {
                beforeChange(event, value);
                helpers.setValue(value);
            }}
            onBlur={() => {
                helpers.setTouched(true)
                if (!field.value.hasOwnProperty("id")) {
                    helpers.setValue("");
                }
            }}
            renderInput={params => (
                <TextField
                    // autoFocus={field.value === ''}
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {endAdornment}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                    {...textFieldProps}
                    onChange={async event => {
                        const value = event.target.value;
                        searchDebounced(value);
                        beforeChange(event, value);
                        helpers.setValue(value);
                        helpers.setValue(value);
                    }}
                />
            )}
        />
    );
};

export default AddressAutocomplete;
