import React, { useState } from 'react';
import { Divider, Grid, InputLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from '../../../store/Api';
import { DatePicker } from '@material-ui/pickers';
import { addMonths, endOfMonth, parseISO, startOfMonth, startOfYear, subMonths } from 'date-fns';

const useStyles = makeStyles((theme) => {
    return ({
        container: {
            marginTop: theme.spacing(3),
        },
    });
});

export default function BadExportsTab() {
    const classes = useStyles();
    const startOfCurrentYear = startOfYear(new Date());
    const startOfLastMonth = startOfMonth(subMonths(new Date(), 1));
    const endOfNextMonth = endOfMonth(addMonths(new Date(), 1));

    const [activitiesCsvFrom, setActivitiesCsvFrom] = useState(startOfLastMonth);
    const [activitiesCsvTo, setActivitiesCsvTo] = useState(endOfNextMonth);

    const [berCsvDateFrom, setBerCsvDateFrom] = useState(startOfCurrentYear);


    return <Grid className={classes.container} container spacing={6} direction={'column'}>
        <Grid item container xs={12}>
            <Grid item xs={12}><Typography variant={'h6'}>CSV-Exporte</Typography></Grid>
            <Grid item xs={12}><Typography variant={'caption'}>Links aktualisieren sich bei
                Datums-Auswahl</Typography></Grid>
        </Grid>
        <Grid className={classes.container} container spacing={6}>
            <Grid item xs={12}>
                <Typography variant={'h6'}>Aktivitäten CSV</Typography>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={4}>
                    <InputLabel>Aktivitäten ab Datum:</InputLabel>
                    <DatePicker format="dd.MM.yyyy" value={activitiesCsvFrom} onChange={(value) => {
                        if (value) {
                            setActivitiesCsvFrom(parseISO(value.toISOString()));
                        }
                    }
                    }/>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel>Aktivitäten bis Datum:</InputLabel>
                    <DatePicker format="dd.MM.yyyy" value={activitiesCsvTo} onChange={(value) => {
                        if (value) {
                            setActivitiesCsvTo(parseISO(value.toISOString()));
                        }
                    }
                    }/>
                </Grid>
                <Grid item xs={4}>
                    <a href={Api.createActivitiesCsvDownloadLink(activitiesCsvFrom, activitiesCsvTo)}><Typography
                        variant={'body1'}>activities.csv</Typography></a>
                </Grid>

            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Grid>
        <Grid className={classes.container} container spacing={6}>
            <Grid item xs={12}>
                <Typography variant={'h6'}>Spesen CSV</Typography>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={4}>
                    <InputLabel>Spesen ab Datum:</InputLabel>
                    <DatePicker format="dd.MM.yyyy" value={berCsvDateFrom} onChange={(value) => {
                        if (value) {
                            setBerCsvDateFrom(parseISO(value.toISOString()));
                        }
                    }
                    }/>
                </Grid>
                <Grid item xs={4}>
                    <a href={Api.createBerCsvDownloadLink(berCsvDateFrom)}><Typography
                        variant={'body1'}>spesen.csv</Typography></a>
                </Grid>

            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Grid>
        <Grid className={classes.container} container spacing={6}>
            <Grid item xs={12}>
                <Typography variant={'h6'}>KSBV Liste</Typography>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={4}>
                    <a href={Api.getKsbvCsvDownloadLink()}><Typography
                        variant={'body1'}>ksbv_export.csv</Typography></a>
                </Grid>

            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Grid>
    </Grid>;
}