import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {formatDateObj} from "../Formatter";
import {useTranslation} from "react-i18next";
import {ToDo} from "../../store/models/ToDo";
import {AdmName} from "../AdmName";

export default function ContactHistoryTodoDisplay({todo}: { todo: ToDo }) {

    const {t} = useTranslation();
    return <Grid container spacing={3}>
        <Grid item container xs={12} spacing={3}>
            <Grid item xs={10}>
                <Typography
                    variant="subtitle1">
                    {formatDateObj(todo.done!)}: {t('contactDetailCommunicationTab.todoTypes.' + todo.type)}
                </Typography>
                <Typography
                    variant="subtitle2">
                    {t('contactDetailCommunicationTab.todoCreated')}{formatDateObj(todo.created!)}{todo.admID ?
                    <>{t('contactDetailCommunicationTab.todoCreatedBy')}<AdmName personalnummer={todo.admID}/></>
                    : null}
                </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
        <Grid item xs={12}>
            <Typography style={{whiteSpace: "pre-wrap"}}>
                {todo.text}
            </Typography>
        </Grid>
    </Grid>
}