import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite'
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
    IconButton,
    Grid,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
} from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Navigation from "../components/Navigation";
import ProductUserMenu from "../components/ProductUserMenu";
import useProductUser from "../hooks/useProductUser";



const useStyles = makeStyles((_theme: Theme) => ({
    wishlistTableRow: {
        cursor: "pointer",
    },
    wishlistTableActionsCont: {
        position: "relative",
        justifyContent: "space-between",
    },
    wishlistTableRemoveBtn: {
        position: "absolute",
        top: 0,
        right: 0,
    }
}));

export default observer(() => {
    const classes = useStyles();
    const [wishlists, setWishlists] = useState<any>({});
    const productUserStore = useProductUser();

    const handleRemove = async (id: any) => {
        delete wishlists[id];
        setWishlists({...wishlists});
        productUserStore.removeWishlist(id);
    }

    useEffect(() => {
        const wishlists = productUserStore.loadWislhists();

        if (wishlists) {
            setWishlists(wishlists);
        }
    }, [productUserStore]);

    return (
        <Navigation breadcrumbName={'Meine Wunschliste'}>
            <Grid container spacing={3} >
                <Grid container item justifyContent="flex-end">
                    <ProductUserMenu />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Name</b></TableCell>
                                    <TableCell><b>Product Nr.</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(wishlists).map((key: any) => {
                                    return (
                                        <TableRow key={key} hover={true} className={classes.wishlistTableRow}
                                            onClick={() => document.location.assign(`/wishlist/${key}`)}
                                        >
                                            <TableCell component="th" scope="row"><b>{wishlists[key].name}</b></TableCell>
                                            <TableCell className={classes.wishlistTableActionsCont}>
                                                <b>{wishlists[key].data.length}</b>
                                                <IconButton className={classes.wishlistTableRemoveBtn} onClick={ev => {
                                                    ev.stopPropagation();
                                                    handleRemove(key);
                                                }}>
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Navigation>
    )
})
