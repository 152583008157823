import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import {Action} from "redux";
import Api from "../Api";
import DailyAdmReport from "../models/DailyAdmReport";


export const GET_MY_DAR_SUCCESS = "GET_MY_DAR_SUCCESS";
export const GET_MY_DAR_ERROR = "GET_MY_DAR_ERROR";

export const CREATE_DAR_SUCCESS = "CREATE_DAR_SUCCESS";
export const CREATE_DAR_ERROR = "CREATE_DAR_ERROR";

export const UPDATE_DAR_SUCCESS = "UPDATE_DAR_SUCCESS";
export const UPDATE_DAR_ERROR = "UPDATE_DAR_ERROR";

export const GET_DAR_FOR_MY_ADMS_SUCCESS = "GET_DAR_FOR_MY_ADMS_SUCCESS";
export const GET_DAR_FOR_MY_ADMS_ERROR = "GET_DAR_FOR_MY_ADMS_ERROR";

export const thunkGetMyDailyAdmReports = (): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const reports = await Api.getMyDailyAdmReports();
        dispatch({
            type: GET_MY_DAR_SUCCESS,
            payload: reports
        });
    } catch (e) {
        dispatch({
            type: GET_MY_DAR_ERROR,
            payload: e
        });
    }
};


export const thunkCreateDailyAdmReport = (dAR: DailyAdmReport): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const report = await Api.createMyDailyAdmReports(dAR);
        dispatch({
            type: CREATE_DAR_SUCCESS,
            payload: report
        });
    } catch (e) {
        dispatch({
            type: CREATE_DAR_ERROR,
            payload: e
        });
    }
};

export const thunkUpdateDailyAdmReport = (dAR: DailyAdmReport): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const report = await Api.updateMyDailyAdmReports(dAR);
        dispatch({
            type: UPDATE_DAR_SUCCESS,
            payload: report
        });
    } catch (e) {
        dispatch({
            type: UPDATE_DAR_ERROR,
            payload: e
        });
    }
};

export const thunkDailyAdmReportsForMyAdms = (): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const reports = await Api.getDailyAdmReportsForMyADMS();
        dispatch({
            type: GET_DAR_FOR_MY_ADMS_SUCCESS,
            payload: reports
        });
    } catch (e) {
        dispatch({
            type: GET_DAR_FOR_MY_ADMS_ERROR,
            payload: e
        });
    }
};
