import IAction from "../actions/IAction";
import {GET_ADMS_SUCCESS} from "../actions/AdmActions";
import {Adm} from "../models/Adm";
import {getMyADMID} from "../../util/UserUtil";


export interface IAdmReducerState {
    adms: Adm[];
    mySubordinatedAdms: Adm[];
}

export default class AdmReducer {
    public static readonly INITIAL_STATE: IAdmReducerState = {
        adms: [],
        mySubordinatedAdms: []
    };

    public static reducer(
        state: IAdmReducerState = AdmReducer.INITIAL_STATE,
        action: IAction<any>
    ): IAdmReducerState {
        switch (action.type) {
            case GET_ADMS_SUCCESS:
                return {...state, adms: action.payload, mySubordinatedAdms: fetchMySubordinates(action.payload)};
            default:
                return state;
        }
    }
}

function findSubordinates(admsInput: Adm[], allAdms: Adm[]): Adm[] {
    const vbNumbers = admsInput.map(adm => adm.sapID);
    let adms: Adm[] = allAdms.filter((adm) => !adm.inactive && vbNumbers.includes(adm.vorgesetzter));
    adms = adms.filter(adm => !vbNumbers.includes(adm.sapID));
    if (adms.length) {
        adms = [...adms, ...findSubordinates(adms, allAdms)]
    }
    return [...adms, ...admsInput]
}

function fetchMySubordinates(allAdms: Adm[]): Adm[] {
    const myADMID = getMyADMID();
    console.log(myADMID);
    const adm = allAdms.find(adm => adm.sapID === myADMID);
    if (!adm) {
        return [];
    }
    const admModels = findSubordinates([adm], allAdms);
    return admModels.filter((adm, i, arr) => arr.findIndex(p => (p.sapID === adm.sapID)) === i);
}
