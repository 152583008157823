import INotification from "../models/INotification";
import {
  CREATE_NOTIFICATION,
  DISMISS_NOTIFICATION
} from "../actions/NotificationActions";
import { AnyAction } from "redux";

export interface INotificationsReducerState {
  notifications: INotification[];
}

export const initialState: INotificationsReducerState = {
  notifications: []
};

export default class NotificationsReducer {
  public static readonly INITIAL_STATE: INotificationsReducerState = initialState;

  public static reducer(
    state: INotificationsReducerState = NotificationsReducer.INITIAL_STATE,
    action: AnyAction
  ): INotificationsReducerState {
    switch (action.type) {
      case CREATE_NOTIFICATION:
        return {
          ...state,
          notifications: [...state.notifications, action.payload]
        };
      case DISMISS_NOTIFICATION:
        return {
          ...state,
          notifications: state.notifications.filter(
            err => err.id !== action.payload.id
          )
        };
      default:
        return state;
    }
  }
}
