import Navigation from "../components/Navigation";
import {Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PercentagePieChart from "../components/dashboard/PercentagePieChart";
import {LIGHT_BLUE, LIGHT_GREEN, YELLOW} from "../BerendsohnMUITheme";
import {useTranslation} from "react-i18next";
import {
    emptyFunnelKpiDto,
    FunnelKPIDto,
    OrderRevenueDesiredToTotal,
    TopsellerDto
} from "../store/models/IExtendedKPIDto";
import Api from "../store/Api";
import formatCurrencyByADMCountry, {getMonthToStringArr} from "../components/Formatter";
import SalesFunnels from "../components/SalesFunnels";
import {HelpText} from "../components/HelpText";
import useAdmCountry from "../hooks/useAdmCountry";
import {getMyADMID} from "../util/UserUtil";
import {useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {ToDoType} from "../store/models/ToDo";
import {endOfDay, isFuture} from "date-fns";
import {emptyQlikKpiViewDto, QlikKpiViewDto} from "../store/models/IQlikKpiViewDto";
import {FilledLineChart} from "../components/dashboard/FilledLineChart";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    cover: {
        width: 151,
    },
    paper: {
        padding: theme.spacing(3),
        width: '100%'
    },
    paperSmallKpi: {
        padding: theme.spacing(3),
        width: '100%',
        height: 130
    },
    paperFirstRow: {
        padding: theme.spacing(2),
        width: '100%',
        height: 300
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'

    },
    salesDataGreen: {
        color: LIGHT_GREEN,
        fontSize: 12,
        textAlign: "center"
    },
    salesDataYellow: {
        color: YELLOW,
        fontSize: 12,
        textAlign: "center"
    },
    salesDataBlue: {
        color: LIGHT_BLUE,
        fontSize: 12,
        textAlign: "center"
    },
    salesDataCurrency: {
        fontSize: 30,
        fontWeight: "normal",
        textAlign: "center",
        paddingBottom: 10
    },
    table: {
        //minWidth: 650,
    },
    tableHead: {
        fontWeight: "bold",
    },
    kpiText: {
        padding: '10px 20px',
        textAlign: "center",
        display: "inline-block",
        borderRadius: 5,
        backgroundColor: 'rgba(242, 242, 242, .6)',
        margin: 10,
    }
}));

function KpiValue(props: { value: string, label: string }) {
    const classes = useStyles();
    const {value, label} = props;
    return <Paper className={classes.paper}>
        <Typography className={classes.salesDataCurrency}
                    variant={"h1"}>{value}</Typography>
        <Typography align="right"
                    className={classes.salesDataGreen}>{label}</Typography>
    </Paper>
}


export function addMonthToLineChartData(orderRevenueDesiredToTotals: OrderRevenueDesiredToTotal[], monthToString: string[]): ({ name: string } & OrderRevenueDesiredToTotal)[] {
    return orderRevenueDesiredToTotals.map(ordtt => ({...ordtt, name: monthToString[ordtt.month - 1]}))
}

function parseNumber(value: string): number {
    let asNumber = Number(value);
    if (isNaN(asNumber)) {
        return 0;
    }
    return asNumber;
}

type KpiDashboardDto = QlikKpiViewDto & FunnelKPIDto & {
    topsellerVB: TopsellerDto[];
    topsellerCompany: TopsellerDto[];
}

function getMaxValueOfGraphDataRounded(kpiDataFromApi: QlikKpiViewDto & FunnelKPIDto & { topsellerVB: TopsellerDto[]; topsellerCompany: TopsellerDto[] }) {
    const maxValueOfGraphData = kpiDataFromApi.orderRevenueDesiredToTotal.reduce((prev, current) => {
        const maxValueInMonth = Math.max(current.is, current.should);
        return Math.max(prev, maxValueInMonth)
    }, 0);

    return Math.round(maxValueOfGraphData / 1000) * 1000 + 1000;
}

function KpiDashboardView() {

    const classes = useStyles();
    const {t} = useTranslation();

    const [kpiDataFromApi, setKPIDataFromApi] = useState<KpiDashboardDto>({
            ...emptyQlikKpiViewDto,
            ...emptyFunnelKpiDto,
            topsellerVB: [],
            topsellerCompany: [],

        }
    );

    const todos = useSelector((state: IStore) => state.todos.todos);
    const calendarEventsCount = useSelector((state: IStore) => state.calendarEvent.calendarEvents).length;
    const followUpCount = todos.filter(t => [ToDoType.FollowUp, ToDoType.LegacyCrmFollowup].includes(t.type) && (!t.done || isFuture(endOfDay(t.done)))).length;

    const fetchApiKPIs = () => {
        Api.getVBKPIData().then(dataFromApi =>
            setKPIDataFromApi({
                ...dataFromApi,
            })
        )
    }
    useEffect(fetchApiKPIs, []);

    const monthToString = getMonthToStringArr();

    const admCountry = useAdmCountry(getMyADMID());
    const maxValueOfGraphData = getMaxValueOfGraphDataRounded(kpiDataFromApi)

    return (<>
        <Navigation breadcrumbName={'Einstellungen'}>
            <Grid container spacing={4}>
                <Grid item md={6}>
                    <Paper className={classes.paperFirstRow}>
                        <div style={{textAlign: "center"}}>
                            <h2>{t('kpiDashboard.incomingOrders')}
                                <HelpText icon={'info'} text={t('kpiDashboard.incomingOrdersTooltip')}/>
                            </h2>
                            <Typography variant="subtitle2">
                                {t('kpiDashboard.incomingOrdersActual')} {formatCurrencyByADMCountry(kpiDataFromApi.paisyAEThisMonth, admCountry)}
                                ({t('kpiDashboard.incomingOrdersShould')}{formatCurrencyByADMCountry(kpiDataFromApi.paisyAETargetThisMonth, admCountry)})
                            </Typography>
                        </div>
                        <PercentagePieChart color={LIGHT_GREEN}
                                            value={parseNumber(kpiDataFromApi.paisyAEThisMonth) / (parseNumber(kpiDataFromApi.paisyAETargetThisMonth) ? parseNumber(kpiDataFromApi.paisyAETargetThisMonth) : 1) * 100}/>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper className={classes.paperFirstRow}>
                        <SalesFunnels potentialCustomers={kpiDataFromApi.contactsGreyProspect}
                                      followUps={followUpCount}
                                      meetings={calendarEventsCount}
                                      activeContacts={kpiDataFromApi.activeContacts}
                        />
                    </Paper>
                </Grid>
                <Grid item md={12}>
                    <Paper className={classes.smallPaper}>
                        <div style={{textAlign: "center"}}>
                            <h2>{t('kpiDashboard.incomingOrdersChart')}</h2>
                        </div>
                        <FilledLineChart
                            data={addMonthToLineChartData(kpiDataFromApi.orderRevenueDesiredToTotal, monthToString)}
                            dataValues={['is', 'should']}
                            dataValuesTranslation={[{
                                dataKey: 'is',
                                translation: t('kpiDashboard.dataKeyIs')
                            }, {dataKey: 'should', translation: t('kpiDashboard.dataKeyShould')}]}
                            admCountry={admCountry}
                            yAxisDomain={[0,maxValueOfGraphData]}
                        />
                    </Paper>
                </Grid>

                <Grid item container md={12} spacing={2}>
                    <div style={{flexGrow: 1, display: 'flex'}}></div>
                    <Grid
                        item><Typography>{t('kpiDashboard.creationDate')}{kpiDataFromApi.creationDate}</Typography></Grid>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={formatCurrencyByADMCountry(kpiDataFromApi.paisyAEThisMonth, admCountry)}
                              label={t('kpiDashboard.paisyThisMonth')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={formatCurrencyByADMCountry(kpiDataFromApi.paisyAEAccumulated, admCountry)}
                              label={t('kpiDashboard.paisyThisYear')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={formatCurrencyByADMCountry(kpiDataFromApi.lockedAE, admCountry)}
                              label={t('kpiDashboard.lockedAE')}/>
                </Grid>

                <Grid item md={4}>
                    <KpiValue value={kpiDataFromApi.ordersThisMonth} label={t('kpiDashboard.ordersThisMonth')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={kpiDataFromApi.buyingCustomersThisMonth}
                              label={t('kpiDashboard.buyingCustomersThisMonth')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={kpiDataFromApi.newCustomersThisMonth}
                              label={t('kpiDashboard.newCustomersThisMonth')}/>
                </Grid>

                <Grid item md={4}>
                    <KpiValue value={kpiDataFromApi.ordersAccumulated} label={t('kpiDashboard.ordersInThisYear')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={kpiDataFromApi.buyingCustomersThisYear}
                              label={t('kpiDashboard.buyingCustomersThisYear')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={kpiDataFromApi.newCustomersThisYear}
                              label={t('kpiDashboard.newCustomersThisYear')}/>
                </Grid>

                <Grid item md={4}>
                    <KpiValue value={formatCurrencyByADMCountry(kpiDataFromApi.sellerBudgetThisMonth, admCountry)}
                              label={t('kpiDashboard.sellerBudgetThisMonth')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={formatCurrencyByADMCountry(kpiDataFromApi.sellerBudgetThisYear, admCountry)}
                              label={t('kpiDashboard.sellerBudgetThisYear')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={formatCurrencyByADMCountry(kpiDataFromApi.sellerBudgetAvailable, admCountry)}
                              label={t('kpiDashboard.sellerBudgetAvailable')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={kpiDataFromApi.protectedCustomers}
                              label={t('kpiDashboard.protectedCustomers')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={formatCurrencyByADMCountry(kpiDataFromApi.averageOrderValueThisMonth, admCountry)}
                              label={t('kpiDashboard.averageOrderValueThisMonth')}/>
                </Grid>
                <Grid item md={4}>
                    <KpiValue value={formatCurrencyByADMCountry(kpiDataFromApi.averageOrderValueThisYear, admCountry)}
                              label={t('kpiDashboard.averageOrderValueThisYear')}/>
                </Grid>


                <Grid item md={6}>
                    <Paper className={classes.paper}>
                        <h2>{t('kpiDashboard.myTopSeller')}
                            <HelpText icon={'info'} text={t('kpiDashboard.myTopSellerTooltip')}/>
                        </h2>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell width={30}><b>{t('kpiDashboard.myTopSellerRanking')}</b></TableCell>
                                    <TableCell><b>{t('kpiDashboard.myTopSellerProducts')}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {kpiDataFromApi.topsellerVB.map((ts, index) =>
                                    <TableRow key={'topsellerVB' + index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{t('productListSearchItems.'+ts._id)}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper className={classes.paper}>
                        <h2>{t('kpiDashboard.topsellerOverall')}
                            <HelpText icon={'info'} text={t('kpiDashboard.topsellerOverallTooltip')}/>
                        </h2>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell width={30}><b>{t('kpiDashboard.topsellerOverallRanking')}</b>
                                    </TableCell>
                                    <TableCell><b>{t('kpiDashboard.topsellerOverallProducts')}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {kpiDataFromApi.topsellerCompany.map((ts, index) =>
                                    <TableRow key={'topsellerCompany' + index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{t('productListSearchItems.'+ts._id)}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        </Navigation>
    </>)
}

export default KpiDashboardView;
