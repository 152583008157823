import {parseISODateOrUndefined} from "../../util/DateUtil";
import {parseISO} from "date-fns";

interface TerminatorFollowUpDto {
    terminatorFollowUpNumber?: string;
    admId: string;
    stCustomerId: string; //stCustomerId
    customerDisplayName: string;
    dueOn: string;
    done?: string;
    note: string;
}

export class TerminatorFollowUp {
    terminatorFollowUpNumber?: string;
    admId: string;
    stCustomerId: string; //stCustomerId
    customerDisplayName: string;
    dueOn: Date;
    done?: Date;
    note: string;


    constructor({
                    terminatorFollowUpNumber,
                    admId,
                    stCustomerId,
                    customerDisplayName,
                    dueOn,
                    done,
                    note
                }: TerminatorFollowUpDto) {
        this.terminatorFollowUpNumber = terminatorFollowUpNumber;
        this.admId = admId;
        this.stCustomerId = stCustomerId;
        this.customerDisplayName = customerDisplayName;
        this.dueOn = parseISO(dueOn);
        this.done = parseISODateOrUndefined(done);
        this.note = note;
    }
}

interface AdmFollowUpsDto {
    admId: string;
    followUps: TerminatorFollowUpDto[]
}

export class AdmFollowUps {
    admId: string;
    followUps: TerminatorFollowUp[];


    constructor({admId, followUps}: AdmFollowUpsDto) {
        this.admId = admId;
        this.followUps = followUps.map(f => new TerminatorFollowUp(f));
    }
}

export class TerminatorCustomerLists {
    followUpsByAdm: AdmFollowUps[];


    constructor({followUpsByAdm}: TerminatorCustomerListsDto) {
        this.followUpsByAdm = followUpsByAdm.map(f => new AdmFollowUps(f));
    }
}

export interface TerminatorCustomerListsDto {
    followUpsByAdm: AdmFollowUpsDto[]
}