import React from 'react';
import {BusinessExpenseReportStatus} from "../../store/models/BusinessExpenseReport";
import {useTranslation} from "react-i18next";
import Label from "../Label";

const businessExpenseReportStatusLabelColorByStatus = {
    [BusinessExpenseReportStatus.ACCEPTED] : 'success',
    [BusinessExpenseReportStatus.DECLINED] : 'error',
    [BusinessExpenseReportStatus.NEEDS_REVIEW] : 'warning',
    [BusinessExpenseReportStatus.REQUESTED] : 'primary',
}

export function BusinessExpenseReportStatusLabel (props: {status: BusinessExpenseReportStatus}){
    const {status} = props;
    const {t} = useTranslation();
    let colorByStatus = businessExpenseReportStatusLabelColorByStatus[status];
    // @ts-ignore
    return <Label color={colorByStatus}>{t(`businessExpenseReportStatusLabel.${status}`)}</Label>
}
