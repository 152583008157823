import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {VisitingReportProps} from "../../store/models/Note";

interface Props {
    visitingReportProps: VisitingReportProps
}

export default function VisitingReportDisplay({visitingReportProps}: Props) {
    const {t} = useTranslation();


    return <Grid item xs={12} container>
        <Grid item xs={6}>
            <Typography style={{whiteSpace: "pre-wrap"}}>
                {t('contactDetailCommunicationTab.gotRecommendations')}
                {t(`common.${visitingReportProps.gotRecommendations ? 'yes' : 'no'}`)}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography style={{whiteSpace: "pre-wrap"}}>
                {t('contactDetailCommunicationTab.visitingReportResultLbl')}
                {t('contactDetailCommunicationTab.visitingReportResultOptions.' + visitingReportProps.visitResult)}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography component={"h6"} style={{whiteSpace: "pre-wrap"}}>
                {t('contactDetailCommunicationTab.orderProductTypesLbl')}
            </Typography>
            <Typography style={{whiteSpace: "pre-wrap"}}>
                {visitingReportProps.productTypesPresented.length ?
                    visitingReportProps.productTypesPresented.map(vRP => <span
                        key={vRP}>
                                                        {t('contactDetailCommunicationTab.OrderProductTypes.' + vRP)},&nbsp;
                                                    </span>)
                    : t('contactDetailCommunicationTab.noProductsPresented')}
            </Typography>
        </Grid>
    </Grid>
}