import React, {useState} from 'react';
import EditIcon from '@material-ui/icons/Edit';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import {Formik} from 'formik';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {useDispatch} from 'react-redux';
import Contact from '../../store/models/Contact';
import {thunkCreateContact, thunkUpdateContact} from '../../store/actions/ContactActions';
import {countryOptions} from '../mappedSelectOptions';
import {useHistory} from 'react-router-dom';
import Modal from '../Modal';
import {useTranslation} from 'react-i18next';
import IndustryCodeDropdown from './IndustryCodeDropdown';
import {makeStyles} from '@material-ui/core/styles';
import {blue} from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import ContactRecommendedByAutocomplete from './ContactRecommendedByAutocomplete';
import {getMyADMID} from '../../util/UserUtil';
import * as Yup from 'yup';
import ContactAutoComplete from '../ContactAutoComplete';
import FormikPatchTouched from '../../util/FormikPatchTouched';

interface ContactEditModalProps {
    contact: Contact;
    isEdit?: boolean;
}

const formInputVariant = 'standard';

const useStyles = makeStyles((theme) => {
    return ({
        button: {
            margin: theme.spacing(1),
        },
        container: {
            margin: theme.spacing(1, 1, 3, 1),
        },
        paper: {
            padding: theme.spacing(1, 3),
            margin: theme.spacing(3, 0),
            minHeight: '50%',
        },
        avatar: {
            backgroundColor: blue[300],
        },
    });
});

export default function ContactEditModal({contact, isEdit}: ContactEditModalProps) {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [recommendedContact, setRecommendedContact] = useState(!!contact.recommendedBy);

    return (
        <Modal fullWidth={true} maxWidth={'lg'}
               title={t(isEdit ? 'contactEditModal.editContactHeadline' : 'contactEditModal.createContactHeadline')}
               contentAndButtons={(handleClose) => (
                   <>
                       <DialogContent>
                           <Formik
                               initialValues={contact}
                               onSubmit={async (values) => {
                                   if (!recommendedContact) {
                                       values.recommendedBy = '';
                                   }
                                   if (recommendedContact && (!contact.recommendedBy || contact.recommendedBy === '')) {
                                       values.recommendationFromADM = getMyADMID();
                                       values.recommendationTS = new Date();
                                   }
                                   setLoading(true);
                                   if (isEdit) {
                                       await dispatch(thunkUpdateContact(values));
                                       setLoading(false);
                                   } else {
                                       // @ts-ignore
                                       dispatch(thunkCreateContact(values)).then((newContact: Contact) => {
                                           setLoading(false);
                                           history.push(`/contact/${newContact.stContactID}`);
                                       });

                                   }
                                   handleClose();
                               }}
                               validationSchema={Yup.object().shape({
                                   contactDetails: Yup.object().shape({
                                       emailAddress: Yup.string()
                                           .email(t('validation.email'))
                                           .required(t('validation.required')),
                                   }),
                                   address: Yup.object().shape({
                                       street: Yup.string()
                                           .required(t('validation.required')),
                                       houseNumber: Yup.string()
                                           .required(t('validation.required')),
                                       place: Yup.string()
                                           .required(t('validation.required')),
                                       zipCode: Yup.string()
                                           .required(t('validation.required'))
                                           .when('country', {
                                               is: 'DE',
                                               then: Yup.string().min(5, t('validation.invalidValue')).max(5, t('validation.invalidValue')),
                                           }),
                                       country: Yup.string()
                                           .required(t('validation.required')),
                                   }),
                                   companyLine1: Yup.string()
                                       .required(t('validation.required')),

                               })}
                           >
                               {({
                                     values,
                                     setValues,
                                     handleChange,
                                     handleSubmit,
                                     setFieldValue,
                                     setFieldTouched,
                                     errors,
                                     touched,
                                 }) => (
                                   <form id="contactEditForm" onSubmit={handleSubmit}>
                                       <FormikPatchTouched/>
                                       <Box m={3}>

                                           <Grid container spacing={5}>
                                               {!isEdit ? <Grid item md={6}>
                                                   <FormControl fullWidth>
                                                       <ContactAutoComplete onOptionSelect={(selected) => {
                                                           const emptyValues = {
                                                               companyLine1: "",
                                                               contactDetails: {
                                                                   url: "",
                                                                   telephoneNumber: ""
                                                               },
                                                               address: {
                                                                   companyLine1: "",
                                                                   street: "",
                                                                   houseNumber: "",
                                                                   place: "",
                                                                   zipCode: "",
                                                                   country: ""
                                                               }
                                                           }
                                                           let newValues = {
                                                               ...selected,
                                                               address: {...emptyValues.address, ...selected.address},
                                                               contactDetails: {...emptyValues.contactDetails, ...selected.contactDetails}
                                                           };
                                                           setValues({...newValues});
                                                           const mandatoryFields = [
                                                               "contactDetails.emailAddress",
                                                               "address.street",
                                                               "address.houseNumber",
                                                               "address.place",
                                                               "address.zipCode",
                                                               "address.country",
                                                               "companyLine1"
                                                           ];
                                                           mandatoryFields.forEach(fieldName => {
                                                               setFieldTouched(fieldName, true, true);
                                                           })
                                                       }}/>
                                                   </FormControl>

                                               </Grid> : null}
                                               <Grid item md={12} container spacing={5}>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="companyLine1"
                                                               label={t('contactEditModal.companyLine1Lbl')}
                                                               value={values.companyLine1}
                                                               onChange={handleChange}
                                                               variant={formInputVariant}
                                                               error={!!errors.companyLine1 && touched.companyLine1}
                                                               helperText={(errors.companyLine1 && touched.companyLine1) && errors.companyLine1}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="companyLine2"
                                                               label={t('contactEditModal.companyLine2Lbl')}
                                                               value={values.companyLine2}
                                                               onChange={handleChange}
                                                               variant={formInputVariant}
                                                               fullWidth
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="companyLine3"
                                                               label={t('contactEditModal.companyLine3Lbl')}
                                                               value={values.companyLine3}
                                                               onChange={handleChange}
                                                               variant={formInputVariant}
                                                               fullWidth
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="companyLine4"
                                                               label={t('contactEditModal.companyLine4Lbl')}
                                                               value={values.companyLine4}
                                                               onChange={handleChange}
                                                               variant={formInputVariant}
                                                               fullWidth
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={4}>
                                                       <IndustryCodeDropdown name={'industryCode'}
                                                                             setValue={(code?: string) => setFieldValue('industryCode', code)}
                                                                             value={values.industryCode}/>
                                                   </Grid>
                                               </Grid>


                                               <Grid container item md={6} spacing={5}>
                                                   <Grid item md={12}>
                                                       <Typography
                                                           variant={'h5'}>{t('contactEditModal.addressHeadline')}</Typography>
                                                   </Grid>
                                                   <Grid item md={8}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="address.street"
                                                               label={t('contactEditModal.streetLbl')}
                                                               value={values.address.street}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                               error={(!!errors.address?.street && touched.address?.street)}
                                                               helperText={(errors.address?.street && touched.address?.street) && errors.address?.street}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={4}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="address.houseNumber"
                                                               label={t('contactEditModal.houseNumberLbl')}
                                                               value={values.address.houseNumber}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                               error={(!!errors.address?.houseNumber && touched.address?.houseNumber)}
                                                               helperText={(errors.address?.houseNumber && touched.address?.houseNumber) && errors.address?.houseNumber}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={4}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="address.zipCode"
                                                               label={t('contactEditModal.zipCodeLbl')}
                                                               value={values.address.zipCode}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                               error={(!!errors.address?.zipCode && touched.address?.zipCode)}
                                                               helperText={(errors.address?.zipCode && touched.address?.zipCode) && errors.address?.zipCode}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={8}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="address.place"
                                                               label={t('contactEditModal.placeLbl')}
                                                               value={values.address.place}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                               error={(!!errors.address?.place && touched.address?.place)}
                                                               helperText={(errors.address?.place && touched.address?.place) && errors.address?.place}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth
                                                                    error={!!errors.address?.country && touched.address?.country}>
                                                           <InputLabel>{t('contactEditModal.countryLbl')}</InputLabel>
                                                           <Select
                                                               name="address.country"
                                                               label={t('contactEditModal.countryLbl')}
                                                               value={values.address.country}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                               error={!!errors.address?.country && touched.address?.country}
                                                           >
                                                               {countryOptions().map(cO => <MenuItem
                                                                   key={'countryOption' + cO.label}
                                                                   value={cO.value}>{cO.label}</MenuItem>)}
                                                           </Select>
                                                           {(errors.address?.country && touched.address?.country) ?
                                                               <FormHelperText>{errors.address.country}</FormHelperText>
                                                               : null}
                                                       </FormControl>
                                                   </Grid>
                                               </Grid>
                                               <Grid container item md={6} spacing={5} style={{paddingLeft: 40}}>
                                                   <Grid item md={12}>
                                                       <Typography
                                                           variant={'h5'}>{t('contactEditModal.contactDetailHeadline')}</Typography>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="contactDetails.telephoneNumber"
                                                               label={t('contactEditModal.telephoneNumberLbl')}
                                                               value={values.contactDetails.telephoneNumber}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="contactDetails.mobileNumber"
                                                               label={t('contactEditModal.mobileNumberLbl')}
                                                               value={values.contactDetails.mobileNumber}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="contactDetails.faxNumber"
                                                               label={t('contactEditModal.faxNumberLbl')}
                                                               value={values.contactDetails.faxNumber}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="contactDetails.emailAddress"
                                                               label={t('contactEditModal.emailAddressLbl')}
                                                               value={values.contactDetails.emailAddress}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                               error={!!errors.contactDetails?.emailAddress && touched.contactDetails?.emailAddress}
                                                               helperText={(errors.contactDetails?.emailAddress && touched.contactDetails?.emailAddress) && errors.contactDetails.emailAddress}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               name="contactDetails.url"
                                                               label={t('contactEditModal.urlLbl')}
                                                               value={values.contactDetails.url}
                                                               variant={formInputVariant}
                                                               onChange={handleChange}
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl component="fieldset" fullWidth>
                                                           <FormGroup>
                                                               <FormControlLabel
                                                                   control={<Checkbox checked={values.hasNewsletter}
                                                                                      color={'primary'}
                                                                                      onChange={handleChange}
                                                                                      name="hasNewsletter"/>}
                                                                   label={t('contactEditModal.hasNewsletter')}
                                                               />
                                                           </FormGroup>
                                                       </FormControl>
                                                   </Grid>

                                               </Grid>

                                               <Grid container item md={12} spacing={5}>
                                                   <Grid item md={12}>
                                                       <Typography
                                                           variant={'h5'}>{t('contactEditModal.recommendedContactHeadline')}</Typography>
                                                   </Grid>


                                                   <Grid item md={6}>
                                                       <FormControl component="fieldset" fullWidth>
                                                           <FormGroup>
                                                               <FormControlLabel
                                                                   control={<Checkbox checked={recommendedContact}
                                                                                      color={'primary'}
                                                                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                                          setRecommendedContact(event.target.checked)
                                                                                      }
                                                                                      name="recommendedContact"/>}
                                                                   label={t('contactEditModal.recommendedContactLabel')}
                                                               />
                                                           </FormGroup>
                                                       </FormControl>
                                                   </Grid>
                                                   {recommendedContact ? <Grid item md={6}>
                                                       <ContactRecommendedByAutocomplete
                                                           value={values.recommendedBy}
                                                           setValue={(stContactID: string | null) => {
                                                               setFieldValue('recommendedBy', stContactID);

                                                           }}/>
                                                   </Grid> : null}
                                               </Grid>
                                           </Grid>
                                       </Box>
                                   </form>
                               )}
                           </Formik>
                       </DialogContent>
                       <DialogActions>
                           {loading ?
                               <div style={{paddingRight: 40}}>
                                   <CircularProgress/>
                               </div> :
                               <>
                                   <Button onClick={handleClose}>
                                       {t('common.cancelBtn')}
                                   </Button>

                                   <Button form="contactEditForm" type="submit" color={'primary'}>
                                       {t('common.saveBtn')}
                                   </Button>
                               </>
                           }
                       </DialogActions>
                   </>
               )} openButton={(handleOpen) => (
            <>
                {isEdit ?
                    <IconButton component="span" onClick={handleOpen}><EditIcon/></IconButton>
                    :
                    <Button
                        variant="text"
                        onClick={handleOpen}
                        className={classes.button}
                        startIcon={<Avatar className={classes.avatar}><AddIcon/></Avatar>}
                    >
                        {t('contactEditModal.newContactBtn')}
                    </Button>

                }
            </>
        )}/>
    );
}
