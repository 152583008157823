import {OrderRevenueDesiredToTotal} from "./IExtendedKPIDto";

export interface QlikKpiViewDto{
    admID: string;

    paisyAEThisMonth: string;
    paisyAEAccumulated: string;
    paisyAETargetThisMonth: string;
    paisyAETargetAccumulated: string;
    lockedAE: string;
    ordersThisMonth: string;
    ordersAccumulated: string;
    averageOrderValueThisMonth: string;
    averageOrderValueThisYear: string;
    buyingCustomersThisMonth: string;
    buyingCustomersThisYear: string;
    newCustomersThisMonth: string;
    newCustomersThisYear: string;
    protectedCustomers: string;
    sellerBudgetThisMonth: string;
    sellerBudgetThisYear: string;
    sellerBudgetAvailable: string;

    ST_LKREH: string;
    creationDate: string;
    orderRevenueDesiredToTotal: OrderRevenueDesiredToTotal[];
}

export const emptyQlikKpiViewDto = {
    admID: "",

    paisyAEThisMonth: "0",
    paisyAEAccumulated: "0",
    paisyAETargetThisMonth: "0",
    paisyAETargetAccumulated: "0",
    lockedAE: "0",
    ordersThisMonth: "0",
    ordersAccumulated: "0",
    averageOrderValueThisMonth: "0",
    averageOrderValueThisYear: "0",
    buyingCustomersThisMonth: "0",
    buyingCustomersThisYear: "0",
    newCustomersThisMonth: "0",
    newCustomersThisYear: "0",
    protectedCustomers: "0",
    sellerBudgetThisMonth: "0",
    sellerBudgetThisYear: "0",
    sellerBudgetAvailable: "0",

    ST_LKREH: "",
    creationDate: "",

    orderRevenueDesiredToTotal: []
}