import Address, {AddressDTO, emptyAddress} from "./Address";
import {ContactDetails, ContactDetailsDTO} from "./Contact";

export default class ContactDetailSearch {
    contactDetailSearchID?: string;
    sapCustomerID: string;
    companyLine: string;
    region: string;
    bezirk: string;
    kundenschutzBis: string;
    VB: string;
    zugewiesenerVB: string;
    billingDeliveryEmailAddress: string;
    hasNewsletter?: boolean;
    address: Address;
    industryCode:string;
    contactDetails: ContactDetails;
    hasOrdersInLastYear?: string;
    geoRestriction: GeoRestriction;
    lastHistoryEntrySince: string;
    nextPlanedTodo?:string;
    myCustomers?:boolean;
    assignedCustomers?:boolean;
    greyCustomers?:boolean;
    potentialCustomers?:boolean;
    hasOrderedProduct?:string;


    constructor({contactDetailSearchID,sapCustomerID,hasOrderedProduct,companyLine,region,bezirk,kundenschutzBis,VB,zugewiesenerVB,billingDeliveryEmailAddress,hasNewsletter,address,industryCode,contactDetails,hasOrdersInLastYear,geoRestriction,lastHistoryEntrySince,nextPlanedTodo,myCustomers,assignedCustomers,greyCustomers,potentialCustomers}:ContactDetailSearchDTO) {
        this.contactDetailSearchID = contactDetailSearchID;
        this.sapCustomerID = sapCustomerID;
        this.companyLine = companyLine;
        this.region = region;
        this.bezirk = bezirk;
        this.kundenschutzBis = kundenschutzBis;
        this.VB = VB;
        this.zugewiesenerVB = zugewiesenerVB;
        this.billingDeliveryEmailAddress = billingDeliveryEmailAddress;
        this.hasNewsletter = hasNewsletter;
        this.address = address;
        this.industryCode = industryCode;
        this.contactDetails = contactDetails;
        this.hasOrdersInLastYear = hasOrdersInLastYear;
        this.geoRestriction = geoRestriction;
        this.lastHistoryEntrySince = lastHistoryEntrySince;
        this.nextPlanedTodo = nextPlanedTodo;
        this.myCustomers = myCustomers;
        this.assignedCustomers = assignedCustomers;
        this.greyCustomers = greyCustomers;
        this.potentialCustomers = potentialCustomers;
        this.hasOrderedProduct = hasOrderedProduct;
    }
}

export interface ContactDetailSearchDTO{
    contactDetailSearchID?: string;
    sapCustomerID: string;
    companyLine: string;
    region: string;
    bezirk: string;
    kundenschutzBis: string;
    VB: string;
    zugewiesenerVB: string;
    billingDeliveryEmailAddress: string;
    hasNewsletter?: boolean;
    address: AddressDTO;
    industryCode:string;
    contactDetails: ContactDetailsDTO;
    hasOrdersInLastYear?: string;
    geoRestriction: GeoRestrictionDTO;
    lastHistoryEntrySince: string;
    nextPlanedTodo?:string
    myCustomers?:boolean;
    assignedCustomers?:boolean;
    greyCustomers?:boolean;
    potentialCustomers?:boolean;
    hasOrderedProduct?:string;
}


export interface GeoRestrictionDTO{
    location?: any;
    distanceKm?: number;
    address?: string;
    useLocation?: boolean;
}

export class GeoRestriction {
    location?: any;
    distanceKm?: number;
    address?: string;
    useLocation?: boolean;

    constructor(location: any, distanceKm: number, address: string, useLocation: boolean) {
        this.location = location;
        this.distanceKm = distanceKm;
        this.address = address;
        this.useLocation = useLocation;
    }
}

export const emptyContactDetailSearch: ContactDetailSearch = new ContactDetailSearch({
    "sapCustomerID": "",
    "companyLine": "",
    "region": "",
    "bezirk": "",
    "kundenschutzBis": "",
    "VB": "",
    "zugewiesenerVB": "",
    "billingDeliveryEmailAddress": "",
    "hasNewsletter": undefined,
    "address": emptyAddress,
    "industryCode":"",
    "contactDetails":
        {
            "telephoneNumber": "",
            "mobileNumber": "",
            "emailAddress": "",
            "faxNumber": "",
            "url": ""
        },
    "hasOrdersInLastYear":'',
    'hasOrderedProduct':'',
    "geoRestriction": {
        distanceKm: 5,
        address: "",
        location: {},
        useLocation: false
    },
    lastHistoryEntrySince: '',
    nextPlanedTodo: '',
    myCustomers: false,
    assignedCustomers: false,
    greyCustomers: false,
    potentialCustomers: false
})
