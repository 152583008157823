import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    Divider,
    IconButton,
    InputAdornment,
    InputLabel,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    GridList,
    GridListTile,
    Link,
    MenuItem,
    OutlinedInput,
    Popover,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { green, blue } from '@material-ui/core/colors';
import {makeStyles, Theme} from '@material-ui/core/styles';
import 'mobx-react-lite/batchingForReactDom'
import { observer } from 'mobx-react-lite'
import Navigation from "../components/Navigation";
import ProductUserMenu from "../components/ProductUserMenu";
import useProduct from "../hooks/useProduct";
import useProductUser from "../hooks/useProductUser";
import Api from "../store/Api";



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)'
    },
    productImage: {
        maxWidth: '100%',
        pointerEvents: 'none'
    },
    productImageCont: {
        textAlign: 'center'
    },
    productImageCardCont: {
        position: 'relative'
    },
    productImageSm: {
        maxWidth: '100%',
        padding: theme.spacing(1, 1)
    },
    calcButton: {
        color: blue[600],
        borderColor: blue[300],
        '&:hover': {
            borderColor: blue[500],
        }
    },
    calcButtonActive: {
        color: green[600],
        borderColor: green[300],
        '&:hover': {
            borderColor: green[500],
        },
    },
    calcDivider: {
        margin: theme.spacing(3, 0),
    },
    printOptionsCont: {
        margin: theme.spacing(2, 0),
    },
    printOptionCont: {
        margin: theme.spacing(2, 0),
    },
    printOptionFormControl: {
        minWidth: 280,
    },
    printOptionDiv: {
        textAlign: "center"
    },
    printInfoCont: {
        padding: theme.spacing(2),
    },
    commissionInput: {
        maxWidth: "100px",
    },
    productOptions: {
        marginTop: theme.spacing(2)
    },
    wishlistBtn: {
        position: "absolute",
        top: 0,
        right: 0,
    },
}));

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`product-tabpanel-${index}`}
            aria-labelledby={`product-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default observer(function ProductDetailView() {
    const {productID} = useParams<{productID:string}>();
    const classes = useStyles();
    const [selectedTab, setTab] = useState(2);
    const [selectedImage, setImage] = useState(0);
    const productStore = useProduct();
    const productUserStore = useProductUser();
    const product = productStore.productData;
    const wishlisted = productUserStore.wishlistMap[productID];

    useEffect(() => {
        Api.getProduct(productID).then(productStore.loadProductData)
    }, [productID, productStore]);

    return (
        <Navigation backBtn={true} breadcrumbName={'Produktdetails'}>
        {product ?
            <Grid container spacing={3}>
                <Grid item lg={12}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <ProductUserMenu />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={6}>
                    <Grid container spacing={2} direction={"column"}>
                        <Grid item>
                            <Card className={classes.productImageCardCont} variant="outlined">
                                {product.images.map((img, i) =>
                                    <div hidden={selectedImage !== i} id={`pimage-panel-${i}`} aria-labelledby={`pimage-panel-${i}`} key={i}
                                    >
                                        <Box p={3} className={classes.productImageCont}>
                                            <img src={`${product.imagePath}${img}`} className={classes.productImage} alt="" />
                                        </Box>
                                    </div>
                                )}
                                <IconButton className={classes.wishlistBtn}>
                                    {wishlisted ?
                                        <FavoriteIcon onClick={() => productUserStore.removeFromWishlist(productID)}/>
                                        :
                                        <FavoriteBorderIcon onClick={() => productUserStore.addToWishlist(productStore.getWishlistItem())}/>
                                    }
                                </IconButton>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card variant="outlined">
                                <GridList className={classes.gridList} cols={4.5}>
                                    {product.images.map((img, i) => (
                                        <GridListTile key={i}>
                                            <img src={`${product.imagePath}${img}`} className={classes.productImageSm} alt="" onClick={() => setImage(i)} />
                                        </GridListTile>
                                    ))}
                                </GridList>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={6}>
                    <Card variant="outlined">
                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            onChange={(_, val) => setTab(val)}
                            aria-label="tabs"
                            centered
                        >
                            <Tab value={0} label="Daten"/>
                            <Tab value={1} label="Details"/>
                            <Tab value={2} label="Kalkulation"/>
                        </Tabs>
                        <Divider/>
                        <TabPanel value={selectedTab} index={0}>
                            <Typography variant="h5" gutterBottom>
                                {product.name}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {product.description}
                            </Typography>
                            <table>
                                <tbody>
                                    <tr><td>Farbe:</td><td>{product.color}</td></tr>
                                    <tr><td>Maße:</td><td>{product.dimensions}</td></tr>
                                    <tr><td>Material:</td><td>{product.material}</td></tr>
                                    <tr><td>Gewicht:</td><td>{product.weight}</td></tr>
                                    <tr><td>Verpackung:</td><td>{product.packaging}</td></tr>
                                    <tr><td>Zolltarifnummer:</td><td>{product.hscode}</td></tr>
                                    <tr><td>Marke:</td><td>{product.brand}</td></tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1}>
                            Item One
                        </TabPanel>
                        <TabPanel value={selectedTab} index={2}>
                            <ProductDetailPriceCalc />
                        </TabPanel>
                    </Card>
                </Grid>
            </Grid>
        : null}
        </Navigation>
    )
})



export const ProductDetailPriceCalc = observer(() => {
    const { productData, withoutPrint, selectPrintOption } = useProduct();
    const classes = useStyles();
    if (!productData) return null;
    const { priceDetail, printOptions } = productData

    return (
        <>
            <ButtonGroup aria-label="outlined button group">
                {Object.keys(printOptions).length &&
                    <Button className={withoutPrint ? classes.calcButton : classes.calcButtonActive} onClick={() => selectPrintOption('_empty')}>
                        Mit Werbeanbringung
                    </Button>
                }
                {!priceDetail.printRequired &&
                    <Button className={withoutPrint ? classes.calcButtonActive : classes.calcButton} onClick={() => selectPrintOption('_empty')}>
                        Neutral
                    </Button>
                }
            </ButtonGroup>
            {!withoutPrint &&
                <PrintOptions />
            }
            <PriceCalc />
            <PriceDisplay />
            <ProductOptions />
            <AddProductToCartButton />
        </>
    )
})



export const PrintOptions = observer(() => {
    const { productData, selectedPrintOptions, selectPrintOption } = useProduct();
    const classes = useStyles();
    if (!productData) return null;
    const { printOptions: areas, priceDetail } = productData;
    let areaList = Object.keys(areas).sort((a,b) => Number(areas[b].default) - Number(areas[a].default)).map(akey =>
        <PrintOption key={akey} areaId={akey} area={areas[akey]} selectedOption={selectedPrintOptions[akey]} onOptionSelect={(okey: string) => selectPrintOption(akey, okey)} />
    );

    return (
        <Grid container direction={"column"} className={classes.printOptionsCont}>
            {priceDetail.canCombinePrintOptions ?
                areaList :
                areaList.flatMap((e, i) => i ? [<p key={i} className={classes.printOptionDiv}>oder</p>, e] : [e])
            }
        </Grid>
    )
})

export const PrintOption = (props: any) => {
    const { productData } = useProduct();
    const [infoAnchorEl, setInfo] = useState<HTMLButtonElement | null>(null);
    const { areaId, area, selectedOption = '', onOptionSelect } = props;
    const { options } = area;
    const classes = useStyles();


    const handleInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setInfo(event.currentTarget);
    };
    const handleInfoClose = () => {
        setInfo(null);
    };


    const optionList = Object.keys(options).map(okey =>
        <MenuItem value={okey} key={okey}>
            {options[okey]}
        </MenuItem>
    );

    const open = Boolean(infoAnchorEl);
    const id = open ? `pinfo-popover-${areaId}` : undefined;

    return (
        <Grid item className={classes.printOptionCont}>
            <FormControl variant="outlined" className={classes.printOptionFormControl}>
                <InputLabel id={`popt-label-${areaId}`}>{area.name}</InputLabel>
                <Select labelId={`popt-label-${areaId}`} value={selectedOption} onChange={e => onOptionSelect(e.target.value)} label={area.name}>
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {optionList}
                </Select>
            </FormControl>
            <IconButton onClick={handleInfoClick}>
                <InfoOutlinedIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={infoAnchorEl}
                onClose={handleInfoClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid className={classes.printInfoCont}>
                    <Typography variant="h5" gutterBottom>{area.info.name}</Typography>
                    {area.datasheet &&
                        <Typography>
                            <Link href={area.datasheet} color="secondary">
                                datasheet
                            </Link>
                        </Typography>
                    }
                    <Typography>{area.info.description}</Typography>
                    <img src={`${productData?.printImagePath}${area.image}`} alt='' />
                </Grid>
            </Popover>
        </Grid>
    )
}



export const PriceCalc = observer(() => {
    const productStore = useProduct();
    const classes = useStyles();
    const {amount, minAmount, maxAmount, userMult, productData} = productStore;
    if (!minAmount) return null;
    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        productStore.amount = Number(event.target.value);
    }
    let priceSteps: number[] = [];
    if (productData?.priceDetail) {
        priceSteps = productData.priceDetail.perItem.reduce((acc, step) => {
            if (step[0] > minAmount) acc.push(step[0]);
            return acc;
        }, [minAmount]);

        if (Number(maxAmount) > 0) {
            priceSteps.push(Number(maxAmount));
        }
    }

    return (
        <>
            <Divider className={classes.calcDivider} />
            <Grid container spacing={2}>
                <Grid item container>
                    <TextField label="Menge" variant="outlined" value={amount} placeholder={`Mindestens: ${minAmount}`} onChange={handlePriceChange} />
                </Grid>

                <Grid item container spacing={1} alignItems="flex-end">
                    {priceSteps.length &&
                        <Grid item>
                            <ButtonGroup aria-label="outlined button group">
                                {priceSteps.map(price =>
                                    <Button key={price} onClick={() => productStore.amount = price}>
                                        {price}
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Grid>
                    }
                    <Grid item>
                        <CommissionInput value={userMult} onChange={(value: number) => productStore.userMult = value}  />
                    </Grid>
                </Grid>
            </Grid>
        </>

    )
})

export const CommissionInput = observer((props: { value?: number, visible?: boolean, onChange?: any }) => {
    const [multiplier, setMultiplier] = useState(props.value ?? 0);
    const [multiplierVisible, setMultiplierVisible] = useState(props.visible ?? false);
    const classes = useStyles();

    const commissionMod = multiplier >= 0 ? 200 : 400;
    const commission = Math.min((5000 + commissionMod * multiplier) / (100 + multiplier), 100);

    const handleMultiplierChange = (value: number) => {
        value = +value;
        if (value < -12) return;
        setMultiplier(value);
        props.onChange && props.onChange(value);
    }
    const handleMultiplierBlur = () => {
        if (!multiplier && multiplier !== 0) {
            setMultiplier(0);
            props.onChange && props.onChange(0);
        }
    }
    const handleMultiplierToggle = () => setMultiplierVisible(!multiplierVisible);

    return (
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
                <IconButton onClick={handleMultiplierToggle}>
                    <MoreVertIcon />
                </IconButton>
            </Grid>
            {multiplierVisible && <>
                <Grid item>
                    <IconButton onClick={ev => {
                        ev.preventDefault();
                        handleMultiplierChange(multiplier - 1);
                    }}>
                        <RemoveCircleIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <OutlinedInput margin="dense" type="number" value={multiplier}
                        className={classes.commissionInput}
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        onChange={ev => handleMultiplierChange(+ev.target.value)}
                        onBlur={handleMultiplierBlur}
                    />
                </Grid>
                <Grid item>
                    <IconButton onClick={ev => {
                        ev.preventDefault();
                        handleMultiplierChange(multiplier + 1);
                    }}>
                        <AddCircleIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography>
                        {`(${(+commission.toFixed(2)).toLocaleString()}%)`}
                    </Typography>
                </Grid>
            </>}
        </Grid>
    )
})



export const PriceDisplay = observer(() => {
    const { price, amount } = useProduct();
    const classes = useStyles();
    if (!price) return null;

    return (
        <>
            <Divider className={classes.calcDivider} />
            <Grid container spacing={1}>
                <Grid item container justifyContent="space-between">
                    <Typography>Stückpreis</Typography>
                    <Typography>{`${(+(price.full / amount).toFixed(2)).toLocaleString(undefined, { style: "currency", currency: 'EUR'})}`}</Typography>
                </Grid>
                <Grid item container justifyContent="space-between">
                    <Typography>Veredelung</Typography>
                    <Typography>{`${price.perItem.print.total.toLocaleString(undefined, { style: "currency", currency: 'EUR'})}`}</Typography>
                </Grid>
                <Grid item container justifyContent="space-between">
                    <Typography>Einrichtekosten</Typography>
                    <Typography>{`${price.setup.total.toLocaleString(undefined, { style: "currency", currency: 'EUR'})}`}</Typography>
                </Grid>
            </Grid>
        </>
    )
})



export const ProductOptions = observer(() => {
    const { productData, price, amount, toggleProductOption, selectedProductOptions } = useProduct();
    const classes = useStyles();
    if (!price || !productData) return null;
    const options = productData.priceDetail.options;

    return (
        <FormGroup className={classes.productOptions}>
            {Object.keys(options).map(id => {
                const {name, perItem} = options[id];
                const price = perItem.reduce((acc, v) => {
                    if (v[0] && amount >= v[0]) {
                        acc = v
                    }
                    return acc
                },[0,0]);

                return (
                    <FormControlLabel key={id} label={`${name} +${price[1].toLocaleString(undefined, { style: "currency", currency: 'EUR'})}`} control={
                        <Switch
                            checked={Boolean(selectedProductOptions[id])}
                            onChange={() => toggleProductOption(id)}
                            name="checkedB"
                        />
                    }/>
                );
            })}
        </FormGroup>
    )
})

export const AddProductToCartButton = observer(() => {
    const productStore = useProduct()
    const productUserStore = useProductUser();
    const classes = useStyles();
    if (!productStore.price) return null;
    const onClick = () => {
        productUserStore.addToCart(productStore.getCartItem())
    }

    return (
        <Grid item container justifyContent="flex-end">
            <Button variant="outlined" className={classes.calcButtonActive} onClick={onClick}>
                To cart
            </Button>
        </Grid>
    )
})
