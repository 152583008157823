import Order, {OrderDTO} from "./Order";

export default class DetailedOrder {
    order: Order;
    contactName: string;
    stContactID: string


    constructor({order, contactName, stContactID}: DetailedOrderDTO) {
        this.order = new Order(order);
        this.contactName = contactName;
        this.stContactID = stContactID
    }
}

export interface DetailedOrderDTO {
    order: OrderDTO;
    contactName: string;
    stContactID: string;
}
