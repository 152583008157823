import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import { Box, Grid, Paper, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Api from "../store/Api";
import { useParams } from "react-router-dom";
import { emptyPost } from "../store/models/IPost";
import IDetailedPost from "../store/models/IDetailedPost";
import ReactPlayer from 'react-player';
import ReactHtmlParser from 'react-html-parser';
import { fetchLocaleFromCookie } from "../util/UserUtil";

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        padding: theme.spacing(2, 2),
        marginBottom: 10,
        maxWidth: 1200,
        paddingBottom: 80

    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'
    },
    paperWithHeader: {
        padding: theme.spacing(1, 3, 3, 3)
    },
    divider: {
        margin: theme.spacing(0, 0, 3, 0)
    },
    h3: {
        fontWeight: "bold",
        fontSize: "2.1rem"
    },
    h4: {
        fontSize: "1.6rem",
        padding: theme.spacing(4, 0, 0, 0),
        margin: theme.spacing(0, 0, 0, 0),
    },
    author: {
        padding: theme.spacing(0, 0, 3, 12),
    },
    topHeading: {
        margin: theme.spacing(8, 12, 1, 12),
    },
    topAuthor: {
        margin: theme.spacing(0, 12, 3, 12),
    },
    teaser: {
        margin: theme.spacing(3, 12, 0, 12),
    },
    teasertext: {
        fontSize: "1.4em",
        lineHeight: "1.6em"
    },
    textcontent: {
        fontSize: "1.3em",
        lineHeight: "1.4em",
    },
    teaserImage: {
        margin: theme.spacing(3, 12, 3, 12),
    },
    textblock: {
        margin: theme.spacing(3, 12, 3, 12),
    },
    img: {
        width: "100%",
        objectFit: "cover"
    },
    video: {
        margin: theme.spacing(3, 12, 3, 12),
        height: "600px"
    },
    body1: {
        fontSize: "1.2em",
        lineHeight: "1.4em"
    },
    productArea: {
        margin: theme.spacing(3, 12, 3, 12),
    },
    productCard: {
    },
    pImg: {
        width: "100%",
        height: "auto"
    }
}));

function AcedemyDetailsView() {
    const { postID } = useParams<{ postID: string }>();

    const [detailedPost, setDetailedPost] = useState<IDetailedPost>({ post: emptyPost });

    const dispatch = useDispatch();
    useEffect(() => {
        Api.getAcademyPost(postID, fetchLocaleFromCookie()).then(postFromApi => {
            const newPost = {
                post: {
                    id: postFromApi.id,
                    title: postFromApi.title,
                    teaser: postFromApi.teaser,
                    content: postFromApi.content,
                    published_at: postFromApi.published_at
                },
            }
            setDetailedPost(newPost)
        })
    }, [dispatch, postID])

    const classes = useStyles();
    const { t } = useTranslation();

    function textContent(content: any, i:any) {
        return (
            <Grid key={i} item xs={12} className={classes.teaser}>
                <Typography variant="h4" className={classes.h4}>{content.h2}</Typography>
                <div className={classes.textcontent}>
                    {ReactHtmlParser(content.paragraph)}
                </div>
            </Grid>
        )
    }

    function quote(content: any, i:any) {
        return (
            <Grid key={i} item xs={12} className={classes.teaser}>
                <p>{content.text}</p>
                <small>{content.source}</small>
                <ReactPlayer
                    url="https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
                    width="400px"
                    height="50px"
                    playing={false}
                    controls={true}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload'
                            }
                        }
                    }}
                />
            </Grid>
        )
    }

    function videoLink(content: any, i:any) {
        return (
            <Grid key={i} container xs={12} item className={classes.video} alignContent="center" justifyContent="center">
                <p>{content.text}</p>
                <ReactPlayer
                    url={content.title}
                    width="100%"
                    height="100%"
                    controls={true}
                />
            </Grid>
        )
    }

    function postimage(content: any, i:any) {
        return (
            <Grid key={i} container item xs={12} className={classes.teaserImage} alignContent="center" justifyContent="center">
                <img className={classes.img} src={content.img.formats.tablett.url} alt="" />
            </Grid>
        )
    }

    function products(content: any, i:any) {
        return (
            <Grid key={i} container item xs={12} className={classes.productArea} >
                {content.product.map((p: any, idx: any) => {
                   return(
                       <Grid key={idx} className={classes.productCard} item xs={3}>
                            <Box  m={1}  p={2} border={1} borderColor="#ccc">
                                <p>{p.productname}</p>
                                <img className={classes.pImg} src={p.productimage[0].url} alt="" />
                            </Box>
                        </Grid>
                   )
                })}
            </Grid>
        )
    }

    return (<>
        <Navigation backBtn={true} breadcrumbName={'Einstellungen'}>
            <Paper className={classes.paper}>
                <>
                    <Grid container alignContent="center" justifyContent="center">
                        <Grid item xs={12} className={classes.topHeading}>
                            <Typography variant="h3" className={classes.h3}>
                                {detailedPost.post.title}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.topAuthor}>
                            {t('academy.publishedAt')} {detailedPost.post.published_at}
                            
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} className={classes.teaser}>
                            <div className={classes.teasertext}>
                                {ReactHtmlParser(detailedPost.post.teaser)}
                            </div>
                        </Grid>
                        {detailedPost.post.content.map((contentObject: any, index: any) => {
                            switch (contentObject.__component) {
                                case 'textcontent.text-content':
                                    return textContent(contentObject, index);
                                case 'quote.quote':
                                    return quote(contentObject, index);
                                case 'video.video':
                                    return videoLink(contentObject, index);
                                case 'postimage.postimage':
                                    return postimage(contentObject, index);
                                case 'products.products':
                                    return products(contentObject, index);
                                default:
                                    return <p>DEFAULt</p>;
                            }
                        })
                        }
                    </Grid>
                </>
            </Paper>
        </Navigation>
    </>)
}

export default AcedemyDetailsView;
