import React from 'react';
import {Box, Typography} from "@material-ui/core";
import {HelpText} from "./HelpText";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    kpiText: {
        padding: '10px 20px',
        textAlign: "center",
        display: "inline-block",
        borderRadius: 5,
        backgroundColor: 'rgba(242, 242, 242, .6)',
        margin: 5,
    }
}));

interface SalesFunnelProps {
    potentialCustomers: number;
    followUps: number;
    meetings: number;
    activeContacts: number;
}


export default function SalesFunnels(props: SalesFunnelProps) {
    const {t} = useTranslation();
    const classes = useStyles();

    return <Box style={{textAlign: "center"}}>
        <Typography style={{width: 350}}
                    className={classes.kpiText}>{props.potentialCustomers}
            <HelpText icon={'info'} text={t('salesFunnels.salesFunnelsAssignedTooltip')}>
                {t('salesFunnels.salesFunnelsAssigned')}
            </HelpText>
        </Typography>
        <br/>
        <Typography style={{width: 280}}
                    className={classes.kpiText}>{props.followUps}
            <HelpText icon={'info'}
                      text={t('salesFunnels.salesFunnelsFollowUpTooltip')}>
                {t('salesFunnels.salesFunnelsFollowUp')}
            </HelpText>
        </Typography>
        <br/>
        <Typography style={{width: 210}}
                    className={classes.kpiText}>{props.meetings}
            <HelpText icon={'info'} text={t('salesFunnels.salesFunnelsMeetingsTooltip')}>
                {t('salesFunnels.salesFunnelsMeetings')}
            </HelpText>
        </Typography>
        <br/>
        <Typography style={{width: 160}}
                    className={classes.kpiText}>{props.activeContacts}
            <HelpText icon={'info'} text={t('salesFunnels.salesFunnelsContactsTooltip')}>
                {t('salesFunnels.salesFunnelsContacts')}
            </HelpText>
        </Typography>
    </Box>
}
