import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import {Action} from "redux";
import {ToDo} from "../models/ToDo";
import {thunkCreateSuccessNotification} from "./NotificationActions";
import Api from "../Api";
import i18next from "i18next";
import {Note, NoteType} from "../models/Note";
import {formatDateObj} from "../../components/Formatter";
import {thunkAddNote} from "./NoteActions";


export const GET_TODOS_SUCCESS = 'GET_TODOS_SUCCESS';
export const GET_TODOS_ERROR = 'GET_TODOS_ERROR';

export const ADD_TODO_SUCCESS = 'ADD_TODO_SUCCESS';
export const ADD_TODO_ERROR = 'ADD_TODO_ERROR';

export const EDIT_TODO_SUCCESS = 'EDIT_TODO_SUCCESS';
export const EDIT_TODO_ERROR = 'EDIT_TODO_ERROR';

export const DELETE_TODO_SUCCESS = 'DELETE_TODO_SUCCESS';
export const DELETE_TODO_ERROR = 'DELETE_TODO_ERROR';

export const thunkGetToDos = (): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const todos = await Api.getToDos();
        dispatch({
            type: GET_TODOS_SUCCESS,
            payload: todos
        });
    } catch (e) {
        dispatch({
            type: GET_TODOS_ERROR,
            payload: e
        });
    }
};

export const thunkAddToDo = (todoToAdd: ToDo): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const todo = await Api.addToDo(todoToAdd);
        await dispatch(thunkCreateSuccessNotification(i18next.t('todoActions.createToDoSuccess')));
        dispatch({
            type: ADD_TODO_SUCCESS,
            payload: todo
        });
    } catch (e) {
        dispatch({
            type: ADD_TODO_ERROR,
            payload: e
        });
    }
};

export const thunkEditToDo = (todoToAdd: ToDo): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const todo = await Api.editToDo(todoToAdd);
        dispatch({
            type: EDIT_TODO_SUCCESS,
            payload: todo
        });
    } catch (e) {
        dispatch({
            type: EDIT_TODO_ERROR,
            payload: e
        });
    }
};
export const thunkDeleteToDo = (toDoToDelete: ToDo): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        await Api.deleteToDo(toDoToDelete);
        const newNote: Note = {
            contactNumber: toDoToDelete.forCustomer ? toDoToDelete.forCustomer : 'notFound',
            created: new Date(),
            private: false,
            text: i18next.t('todoItem.deleteMeetingNoteText', {dateDueOn: toDoToDelete.dueOn ? formatDateObj(toDoToDelete.dueOn) : ''}),
            type: NoteType.MEETING_DELETED,
        }
        await dispatch(thunkAddNote(newNote));
        dispatch({
            type: DELETE_TODO_SUCCESS,
            payload: toDoToDelete
        });
    } catch (e) {
        dispatch({
            type: DELETE_TODO_ERROR,
            payload: e
        });
    }
};
