import React from 'react';
import {Avatar, Box, Button, FormControl, Grid, TextField} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch} from "react-redux";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
import {blue} from "@material-ui/core/colors";
import {Note, NoteType} from "../../store/models/Note";
import {thunkAddNote} from "../../store/actions/NoteActions";

interface CreateNoteModalProps {
    headline: string;
    contactNumber: string;
    afterCreate: ()=>void
}

const useStyles = makeStyles((theme) => {
    return ({
        button: {
            margin: theme.spacing(1),
        },
        container: {
            margin: theme.spacing(1, 1, 3, 1)
        },
        paper: {
            padding: theme.spacing(1, 3),
            margin: theme.spacing(3, 0),
            minHeight: '50%'
        },
        avatar: {
            backgroundColor: blue[300],
        }
    });
});


export default function CreateNoteModal({headline, contactNumber,afterCreate}: CreateNoteModalProps) {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <Modal maxWidth={"md"} fullWidth={true} title={headline} contentAndButtons={(handleClose) => (
            <>
                <DialogContent>
                    <Formik
                        initialValues={{note: ''}}
                        onSubmit={async (values) => {
                            const newNote: Note = {
                                contactNumber: contactNumber,
                                created: new Date(),
                                private: false,
                                text: values.note,
                                type: NoteType.Custom
                            }
                            await dispatch(thunkAddNote(newNote));
                            if(afterCreate){
                                afterCreate();
                            }
                            handleClose();
                        }}
                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit,
                          }) => (
                            <form id="createNoteModalForm" onSubmit={handleSubmit}>
                                <Box m={3}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    multiline={true}
                                                    fullWidth={true}
                                                    rows={3}
                                                    name="note"
                                                    label={t('createNoteModal.noteLbl')}
                                                    value={values.note}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}

                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('common.cancelBtn')}
                    </Button>
                    <Button form="createNoteModalForm" type="submit" color={"primary"}>
                        {t('common.saveBtn')}
                    </Button>
                </DialogActions>
            </>
        )} openButton={(handleOpen) => (
            <Button
                variant="text"
                onClick={handleOpen}
                className={classes.button}
                startIcon={<Avatar className={classes.avatar}><AddIcon/></Avatar>}
            >
                {t('createNoteModal.newNoteBtn')}
            </Button>
        )}/>
    );
}
