import React from 'react';
import {Avatar, Grid, Paper, Typography} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {makeStyles} from "@material-ui/core/styles";
import {green, orange} from "@material-ui/core/colors";
import {formatDateObj, formatToCurrency} from "../../Formatter";
import Contact from "../../../store/models/Contact";
import Order from "../../../store/models/Order";
import {AdmName} from "../../AdmName";
import {useTranslation} from "react-i18next";
import Kaz from "../../../store/models/KAZ";
import KazListItem from "../../KazListItem";
import {OrderHistoryPositions} from "./OrderHistoryPositions";


const useStyles = makeStyles((theme) => {
    return ({
        green: {
            backgroundColor: green[500]
        },
        orange: {
            backgroundColor: orange[500]
        },
        icon: {
            margin: theme.spacing(1, 5, 1, 1),
        },
        container: {
            marginTop: theme.spacing(3)
        },
        innerAccordion: {
            padding: theme.spacing(3)
        },
        paper: {
            padding: theme.spacing(3)
        },
        kazHeadline: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3)
        }
    });
});


function OrderStatusIndicator(props: { order: Order, classes: any }) {
    const {order, classes} = props;
    if (order.gesamtStatus === "teilweise bearbeitet" || order.gesamtStatus === "nicht bearbeitet") {
        return <Avatar className={`${classes.orange} ${classes.icon}`}>
            <PriorityHighIcon/>
        </Avatar>
    }
    if (order.gesamtStatus === "vollständig bearbeitet") {
        return <Avatar className={`${classes.green} ${classes.icon}`}>
            <CheckIcon/>
        </Avatar>
    }
    return <p>Unmapped Status: {order.gesamtStatus}</p>
}

interface ContactDetailOrderHistoryTabProps {
    contact: Contact;
    orders: Order[];
    kaz: Kaz[];
}

export default function ContactDetailOrderHistoryTab({contact, orders, kaz}: ContactDetailOrderHistoryTabProps) {
    const classes = useStyles();
    const {t} = useTranslation();

    return <>
        <Grid className={classes.container} container spacing={6} direction={"column"}>
            {!orders.length && <p>{t('contactDetailOrderHistoryTab.noOrderData')}</p>}
            {orders.map((order: Order) =>
                <Grid item container key={order.auftragsNummer}>
                    <Grid item xs={2} md={1}>
                        <OrderStatusIndicator order={order} classes={classes}/>
                    </Grid>
                    <Grid item xs={10} md={11}>
                        <Paper className={classes.paper}>
                            <Grid item xs={12} container spacing={3} justifyContent={"space-between"}>
                                <Grid item
                                      md={6}><Typography>No. {order.auftragsNummer} - {formatDateObj(order.bestellDatum)}</Typography></Grid>
                                <Grid item
                                      md={6}><Typography>{formatToCurrency(order.auftragsNettowert, order.waehrung)}</Typography></Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item md={6}>
                                    {order.innendienst &&
                                        <Typography>{t("contactDetailOrderHistoryTab.insideSales")}&nbsp;{order.innendienst}</Typography>}
                                    {order.VB1 &&
                                        <Typography>VB1: <AdmName personalnummer={order.VB1}/></Typography>}
                                    {order.VB2 &&
                                        <Typography>VB2: <AdmName personalnummer={order.VB2}/></Typography>}
                                </Grid>
                                <Grid item md={6}>
                                    {order.nameBesteller &&
                                        <Typography>{t("contactDetailOrderHistoryTab.orderedBy")}&nbsp;{order.nameBesteller}</Typography>}
                                    {order.telefonBesteller &&
                                        <Typography>{t("common.phoneLbl")}&nbsp;{order.telefonBesteller}</Typography>}
                                </Grid>
                                <OrderHistoryPositions order={order} contact={contact}/>
                                {kaz.filter(k => k.orderNumber === order.auftragsNummer).length ?
                                    <>

                                        <Grid item xs={12} className={classes.kazHeadline}><Typography
                                            variant={"h5"}>{t("contactDetailOrderHistoryTab.kazHeadline")}</Typography></Grid>
                                        {kaz.filter(k => k.orderNumber === order.auftragsNummer).map(singleKaz =>
                                            <KazListItem kaz={singleKaz}/>
                                        )}
                                    </>
                                    : null}

                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Grid>
    </>
}
