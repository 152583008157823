import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from './translations/en.json'
import de from './translations/de.json'
import it from './translations/it.json'
import dk from './translations/dk.json'
import no from './translations/no.json'
import se from './translations/se.json'

import {fetchLocaleFromCookie} from "./util/UserUtil";

const resources = {
    en: {translation: en},
    de: {translation: de},
    dk: {translation: dk},
    nl: {translation: en},
    no: {translation: no},
    se: {translation: se},
    it: {translation: it},
};

const mappedFiles = ['de','en','nl','dk','no','se','it'];

let localeFromCookie = fetchLocaleFromCookie();
let lng = localeFromCookie && mappedFiles.includes(localeFromCookie)? localeFromCookie : 'de';
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lng,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
