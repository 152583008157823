import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import Api from "../Api";
import ContactPerson from "../models/ContactPerson";
import Contact from "../models/Contact";
import Address, {AddressType} from "../models/Address";
import ContactDetailSearch from "../models/ContactDetailSearch";
import {thunkCreateSuccessNotification} from "./NotificationActions";
import i18next from "i18next";

export const CREATE_CONTACT = "CREATE_CONTACT";
export const CREATE_CONTACT_ERROR = "CREATE_CONTACT_ERROR";

export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAILED = "GET_CONTACTS_FAILED";

export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAILED = "GET_CONTACT_FAILED";

export const SEARCH_CONTACTS = "SEARCH_CONTACTS";
export const DETAIL_SEARCH_CONTACTS = "DETAIL_SEARCH_CONTACTS";
export const SEARCH_CONTACTS_FAILED = "SEARCH_CONTACTS_FAILED";

export const UPDATE_CONTACT_NOTE = "UPDATE_CONTACT_NOTE";
export const UPDATE_CONTACT_NOTE_FAILED = "UPDATE_CONTACT_NOTE_FAILED";

export const UPDATE_CONTACT_PERSON = "UPDATE_CONTACT_PERSON";
export const UPDATE_CONTACT_PERSON_FAILED = "UPDATE_CONTACT_PERSON_FAILED";

export const CREATE_CONTACT_PERSON = "CREATE_CONTACT_PERSON";
export const CREATE_CONTACT_PERSON_FAILED = "CREATE_CONTACT_PERSON_FAILED";

export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_CONTACT_FAILED = "UPDATE_CONTACT_FAILED";

export const UPDATE_CONTACT_ADDRESS = "UPDATE_CONTACT_ADDRESS";
export const UPDATE_CONTACT_ADDRESS_FAILED = "UPDATE_CONTACT_ADDRESS_FAILED";

export const CREATE_CONTACT_ADDRESS = "CREATE_CONTACT_ADDRESS";
export const CREATE_CONTACT_ADDRESS_FAILED = "CREATE_CONTACT_ADDRESS_FAILED";

export const DELETE_CONTACT_ADDRESS = "DELETE_CONTACT_ADDRESS";
export const DELETE_CONTACT_ADDRESS_FAILED = "DELETE_CONTACT_ADDRESS_FAILED";


export const thunkGetContacts = (): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const contacts = await Api.getContacts();
        dispatch({
            type: GET_CONTACTS_SUCCESS,
            payload: contacts
        });
    } catch (e) {
        dispatch({
            type: GET_CONTACTS_FAILED,
            payload: e
        });
    }
};

export const thunkGetContact = (stContactID: string): ThunkAction<void, IStore, null, Action<string>> =>
    async dispatch => {
        try {
            const contact = await Api.getContact(stContactID);
            dispatch({
                type: GET_CONTACT_SUCCESS,
                payload: contact
            })
        } catch (e) {
            console.log(e);
            dispatch({
                type: GET_CONTACT_FAILED,
                payload: e
            });
        }
    }

export const thunkSearchContacts = (searchTerm: string,page:number): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const {searchResult,totalResults} = await Api.searchContacts(searchTerm,page);
        dispatch({
            type: SEARCH_CONTACTS,
            payload: {searchResult,totalResults, searchTerm,page}
        });
    } catch (e) {
        console.log(e);
        dispatch({
            type: SEARCH_CONTACTS_FAILED,
            payload: e
        });
    }
};

export const thunkDetailSearchContacts = (searchObj: ContactDetailSearch, page:number): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const {searchResult,totalResults,withDistanceKm} = await Api.detailSearchContacts(searchObj,page);
        dispatch({
            type: DETAIL_SEARCH_CONTACTS,
            payload: {searchResult, searchObj,totalResults,page,withDistanceKm}
        });
    } catch (e) {
        console.log(e);
        dispatch({
            type: SEARCH_CONTACTS_FAILED,
            payload: e
        });
    }
};
export const thunkUpdateContactNote = (stContactID: string, note: string): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const updatedContact = await Api.updateContactNote(stContactID, note);
        await dispatch(thunkCreateSuccessNotification(i18next.t('common.updateSuccessNotification')));
        dispatch({
            type: UPDATE_CONTACT_NOTE,
            payload: updatedContact
        });
        dispatch({
            type: GET_CONTACT_SUCCESS,
            payload: updatedContact
        })
    } catch (e) {
        console.log(e);
        dispatch({
            type: UPDATE_CONTACT_NOTE_FAILED,
            payload: e
        });
    }
};

export const thunkUpdateContactPerson = (stContactID: string, contactPerson: ContactPerson): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const newContactPerson = await Api.updateContactPerson(stContactID, contactPerson);
        await dispatch(thunkCreateSuccessNotification(i18next.t('common.updateSuccessNotification'))); //TODO i18n
        dispatch({
            type: UPDATE_CONTACT_PERSON,
            payload: newContactPerson
        });
    } catch (e) {
        console.log(e);
        dispatch({
            type: UPDATE_CONTACT_PERSON_FAILED,
            payload: e
        });
    }
};

export const thunkUpdateAndRefreshContactPerson = (stContactID: string, contactPerson: ContactPerson): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const newContactPerson = await Api.updateContactPerson(stContactID, contactPerson);
        await dispatch(thunkCreateSuccessNotification(i18next.t('common.updateSuccessNotification'))); //TODO i18n
        dispatch({
            type: UPDATE_CONTACT_PERSON,
            payload: newContactPerson
        });
        if(stContactID){
            const contact = await Api.getContact(stContactID);
            dispatch({
                type: GET_CONTACT_SUCCESS,
                payload: contact
            })
        }

    } catch (e) {
        console.log(e);
        dispatch({
            type: UPDATE_CONTACT_PERSON_FAILED,
            payload: e
        });
    }
};

export const thunkCreateContactPerson = (stContactID: string, contactPerson: ContactPerson): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const newContactPerson = await Api.addContactPerson(stContactID, contactPerson)
        await dispatch(thunkCreateSuccessNotification(i18next.t('contactActions.createContactPersonSuccess')));
        dispatch({
            type: CREATE_CONTACT_PERSON,
            payload: newContactPerson
        });
        if(stContactID){
            const contact = await Api.getContact(stContactID);
            dispatch({
                type: GET_CONTACT_SUCCESS,
                payload: contact
            })
        }
    } catch (e) {
        console.log(e);
        dispatch({
            type: CREATE_CONTACT_PERSON_FAILED,
            payload: e
        });
    }
};

export const thunkDeleteContactPerson = (stContactID: string, stContactPersonID: string): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        await Api.deleteContactPerson(stContactID, stContactPersonID)
        await dispatch(thunkGetContact(stContactID));
        await dispatch(thunkCreateSuccessNotification(i18next.t('contactActions.deleteContactPersonSuccess')));
    } catch (e) {
        console.log(e);
    }
};


export const thunkCreateContact = (contact: Contact): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const contactFromApi = await Api.createContact(contact);
        await dispatch(thunkCreateSuccessNotification(i18next.t('contactActions.createContactSuccess')));
        dispatch({
            type: CREATE_CONTACT,
            payload: contactFromApi
        });
        return contactFromApi;
    } catch (e) {
        console.log(e);
        dispatch({
            type: CREATE_CONTACT_ERROR,
            payload: e
        });
    }
};

export const thunkUpdateContact = (contact: Contact): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const contactFromApi = await Api.updateContact(contact);
        await dispatch(thunkCreateSuccessNotification(i18next.t('common.updateSuccessNotification')));
        dispatch({
            type: UPDATE_CONTACT,
            payload: contactFromApi
        });
    } catch (e) {
        console.log(e);
        dispatch({
            type: UPDATE_CONTACT_FAILED,
            payload: e
        });
    }
};

export const thunkUpdateContactAddress = (stContactID: string, address: Address, addressType: AddressType): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const newContactAddress = await Api.updateContactAddress(stContactID, addressType, address);
        await dispatch(thunkCreateSuccessNotification(i18next.t('common.updateSuccessNotification')));
        dispatch({
            type: UPDATE_CONTACT_ADDRESS,
            payload: {address: newContactAddress, addressType}
        });
    } catch (e) {
        console.log(e);
        dispatch({
            type: UPDATE_CONTACT_ADDRESS_FAILED,
            payload: e
        });
    }
};
export const thunkCreateContactAddress = (stContactID: string, address: Address, addressType: AddressType): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const newAddress = await Api.addContactAddress(stContactID, addressType, address);
        await dispatch(thunkCreateSuccessNotification(i18next.t('contactActions.createContactAddressSuccess')));
        dispatch({
            type: CREATE_CONTACT_ADDRESS,
            payload: {address:newAddress, addressType}
        });
    } catch (e) {
        console.log(e);
        dispatch({
            type: CREATE_CONTACT_ADDRESS_FAILED,
            payload: e
        });
    }
};

export const thunkDeleteContactAddress = (stContactID: string, address: Address): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        await Api.deleteContactAddress(stContactID, address.stContactAddressID!);
        await dispatch(thunkGetContact(stContactID));
        await dispatch(thunkCreateSuccessNotification(i18next.t('contactActions.deleteContactAddressSuccess')));
        dispatch({
            type: DELETE_CONTACT_ADDRESS,
            payload: {stContactAddressID:address.stContactAddressID}
        });
    } catch (e) {
        console.log(e);
        dispatch({
            type: DELETE_CONTACT_ADDRESS_FAILED,
            payload: e
        });
    }
};
