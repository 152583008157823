import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import {Action} from "redux";
import Api from "../Api";

export const GET_ADM_NAMES_SUCCESS = 'GET_ADM_NAMES_SUCCESS';
export const GET_ADM_NAMES_ERROR = 'GET_ADM_NAMES_ERROR';

export const thunkGetAdmNames = (): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const adms = await Api.getAdmNames();
        dispatch({
            type: GET_ADM_NAMES_SUCCESS,
            payload: adms
        });
    } catch (e) {
        dispatch({
            type: GET_ADM_NAMES_ERROR,
            payload: e
        });
    }
};