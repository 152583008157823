import {parseISO} from "date-fns";

class CalendarEventLocation {
    displayName: string;
    locationUri: string;
    locationType: string;
    uniqueId: string;
    uniqueIdType: string;
    address: { "street": string, "city": string, "state": string, "countryOrRegion": string, "postalCode": string };
    coordinates: {}

    constructor({displayName, locationUri, locationType, uniqueId, uniqueIdType, address, coordinates}: CalendarEventLocationDTO) {
        this.displayName = displayName;
        this.locationUri = locationUri;
        this.locationType = locationType;
        this.uniqueId = uniqueId;
        this.uniqueIdType = uniqueIdType;
        this.address = address;
        this.coordinates = coordinates;
    }
}

interface CalendarEventLocationDTO {
    "displayName": string,
    "locationUri": string,
    "locationType": string,
    "uniqueId": string,
    "uniqueIdType": string,
    "address": { "street": string, "city": string, "state": string, "countryOrRegion": string, "postalCode": string },
    "coordinates": {}
}


class CalendarEventAttende {
    type: string;
    status: { "response": string, "time": Date };
    emailAddress: { "name": string, "address": string };

    constructor({type, status, emailAddress}: CalendarEventAttendeDTO) {
        this.type = type;
        this.status = {
            response: status.response,
            time: parseISO(status.time)
        };
        this.emailAddress = emailAddress;
    }
}

interface CalendarEventAttendeDTO {
    type: string;
    status: { "response": string, "time": string };
    emailAddress: { "name": string, "address": string };

}


export default class CalendarEvent {
    "@odata.etag": string;
    "id": string;
    "createdDateTime": Date;
    "lastModifiedDateTime": Date;
    "changeKey": string;
    "categories": string[];
    "originalStartTimeZone": string;
    "originalEndTimeZone": string;
    "iCalUId": string;
    "reminderMinutesBeforeStart": number;
    "isReminderOn": boolean;
    "hasAttachments": boolean;
    "subject": string;
    "bodyPreview": string;
    "importance": string;
    "sensitivity": string;
    "isAllDay": boolean;
    "isCancelled": boolean;
    "isOrganizer": boolean;
    "responseRequested": boolean;
    "seriesMasterId": null;
    "showAs": string;
    "type": string;
    "webLink": string;
    "onlineMeetingUrl": null;
    "isOnlineMeeting": boolean;
    "onlineMeetingProvider": string;
    "allowNewTimeProposals": boolean;
    "recurrence": any;
    "onlineMeeting": any;
    "responseStatus": { "response": string, "time": Date };
    "body": {
        "contentType": string,
        "content": string
    };
    "start": Date;
    "end": Date;
    "location": {
        "displayName": string,
        "locationType": string,
        "uniqueId": string,
        "uniqueIdType": string,
        address?: { "street": string, "city": string, "state": string, "countryOrRegion": string, "postalCode": string };
    };
    "locations": CalendarEventLocation[];
    "attendees": CalendarEventAttende[];
    "organizer": {
        "emailAddress": {
            "name": string, "address": string
        }
    };
    "extensions"?: [
        {
            "@odata.type": "#microsoft.graph.openTypeExtension",
            "id": "Microsoft.OutlookServices.OpenTypeExtension.de.bag.salesTool.events",
            "extensionName": string,
            "forCustomer": string,
            "forCustomerDisplayName": string,
            "meetingType": string
        }
    ]


    constructor(dto: CalendarEventDTO) {
        const {id, createdDateTime, lastModifiedDateTime, changeKey, categories, originalStartTimeZone, originalEndTimeZone, iCalUId, reminderMinutesBeforeStart, isReminderOn, hasAttachments, subject, bodyPreview, importance, sensitivity, isAllDay, isCancelled, isOrganizer, responseRequested, seriesMasterId, showAs, type, webLink, onlineMeetingUrl, isOnlineMeeting, onlineMeetingProvider, allowNewTimeProposals, recurrence, onlineMeeting, responseStatus, body, start, end, location, locations, attendees, organizer, extensions} = dto;
        this["@odata.etag"] = dto["@odata.etag"];
        this.id = id;
        this.createdDateTime = parseISO(createdDateTime);
        this.lastModifiedDateTime = parseISO(lastModifiedDateTime);
        this.changeKey = changeKey;
        this.categories = categories;
        this.originalStartTimeZone = originalStartTimeZone;
        this.originalEndTimeZone = originalEndTimeZone;
        this.iCalUId = iCalUId;
        this.reminderMinutesBeforeStart = reminderMinutesBeforeStart;
        this.isReminderOn = isReminderOn;
        this.hasAttachments = hasAttachments;
        this.subject = subject;
        this.bodyPreview = bodyPreview;
        this.importance = importance;
        this.sensitivity = sensitivity;
        this.isAllDay = isAllDay;
        this.isCancelled = isCancelled;
        this.isOrganizer = isOrganizer;
        this.responseRequested = responseRequested;
        this.seriesMasterId = seriesMasterId;
        this.showAs = showAs;
        this.type = type;
        this.webLink = webLink;
        this.onlineMeetingUrl = onlineMeetingUrl;
        this.isOnlineMeeting = isOnlineMeeting;
        this.onlineMeetingProvider = onlineMeetingProvider;
        this.allowNewTimeProposals = allowNewTimeProposals;
        this.recurrence = recurrence;
        this.onlineMeeting = onlineMeeting;
        this.responseStatus = {
            response: responseStatus.response,
            time: parseISO(responseStatus.time)
        };
        this.body = body;
        this.start = parseISO(start.dateTime+'Z');
        this.end = parseISO(end.dateTime+'Z');
        this.location = location;
        this.locations = locations;
        this.attendees = attendees;
        this.organizer = organizer;
        this.extensions = extensions;
    }
}

export interface CalendarEventDTO {
    "@odata.etag": string,
    "id": string,
    "createdDateTime": string,
    "lastModifiedDateTime": string,
    "changeKey": string,
    "categories": string[],
    "originalStartTimeZone": string,
    "originalEndTimeZone": string,
    "iCalUId": string,
    "reminderMinutesBeforeStart": number,
    "isReminderOn": boolean,
    "hasAttachments": boolean,
    "subject": string,
    "bodyPreview": string,
    "importance": string,
    "sensitivity": string,
    "isAllDay": boolean,
    "isCancelled": boolean,
    "isOrganizer": boolean,
    "responseRequested": boolean,
    "seriesMasterId": null,
    "showAs": string,
    "type": string,
    "webLink": string,
    "onlineMeetingUrl": null,
    "isOnlineMeeting": boolean,
    "onlineMeetingProvider": string,
    "allowNewTimeProposals": boolean,
    "recurrence": any,
    "onlineMeeting": any,
    "responseStatus": { "response": string, "time": string },
    "body": {
        "contentType": string,
        "content": string
    },
    "start": { "dateTime": string, "timeZone": string },
    "end": { "dateTime": string, "timeZone": string },
    "location": {
        "displayName": string,
        "locationType": string,
        "uniqueId": string,
        "uniqueIdType": string
    },
    "locations": CalendarEventLocation[],
    "attendees": CalendarEventAttende[],
    "organizer": {
        "emailAddress": {
            "name": string, "address": string
        }
    },
    "extensions"?: [
        {
            "@odata.type": "#microsoft.graph.openTypeExtension",
            "id": "Microsoft.OutlookServices.OpenTypeExtension.de.bag.salesTool.events",
            "extensionName": string,
            "forCustomer": string,
            "forCustomerDisplayName": string,
            "meetingType": string
        }
    ]
}


export const emptyCalendarEvent: CalendarEvent = new CalendarEvent({
    "@odata.etag": "",
    "id": "",
    "createdDateTime": "2020-09-18T14:15:17.7264107Z",
    "lastModifiedDateTime": "2020-09-18T14:15:18.9759241Z",
    "changeKey": "",
    "categories": [],
    "originalStartTimeZone": "UTC",
    "originalEndTimeZone": "UTC",
    "iCalUId": "",
    "reminderMinutesBeforeStart": 15,
    "isReminderOn": true,
    "hasAttachments": false,
    "subject": "TestTermin",
    "bodyPreview": "",
    "importance": "normal",
    "sensitivity": "normal",
    "isAllDay": false,
    "isCancelled": false,
    "isOrganizer": true,
    "responseRequested": true,
    "seriesMasterId": null,
    "showAs": "busy",
    "type": "singleInstance",
    "webLink": "",
    "onlineMeetingUrl": null,
    "isOnlineMeeting": false,
    "onlineMeetingProvider": "unknown",
    "allowNewTimeProposals": true,
    "recurrence": null,
    "onlineMeeting": null,
    "responseStatus": {"response": "organizer", "time": "0001-01-01T00:00:00Z"},
    "body": {
        "contentType": "text",
        "content": "hallo"
    },
    "start": {"dateTime": "2020-09-23T14:14:00.0000000", "timeZone": "UTC"},
    "end": {"dateTime": "2020-09-30T14:14:00.0000000", "timeZone": "UTC"},
    "location": {
        "displayName": "Hamburg",
        "locationType": "default",
        "uniqueId": "Hamburg",
        "uniqueIdType": "private"
    },
    "locations": [],
    "attendees": [],
    "organizer": {"emailAddress": {"name": "Daniel Elsner", "address": "elsner@hnj.io"}}
})
