import Navigation from "../components/Navigation";
import {
    Accordion,
    AccordionSummary,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField
} from "@material-ui/core";
import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TabPanel from "../components/TabPanel";
import {useTranslation} from "react-i18next";
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Formik} from "formik";
import {useDispatch} from "react-redux";
import Api from "../store/Api";
import {thunkCreateSuccessNotification} from "../store/actions/NotificationActions";
import {IssueTypes} from "../store/models/Feedback";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(7, 10),
        width: '100%'
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'

    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular,
    },
    tableHeading: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightBold,
    },
}));


const glossaryKeys= ['contact',"prospectCustomer","customer", "customerProtection", "todo", "followUp","meeting","visitingReport"]

function HelpAndFeedbackView() {
    const [selectedTab, setTab] = useState('feedback');

    const {t} = useTranslation();
    const classes = useStyles();

    const issueOptions = [
        {
            value: IssueTypes.BUG,
            name: t('helpAndFeedbackView.issueTypes.'+IssueTypes.BUG)
        },
        {
            value: IssueTypes.NEW_FEATURE,
            name: t('helpAndFeedbackView.issueTypes.'+IssueTypes.NEW_FEATURE)
        },
    ]

    const glossaryData = glossaryKeys.map(key => ({
        name: t(`feedbackGlossary.${key}Headline`),
        description: t(`feedbackGlossary.${key}Description`)
    }) )

    const dispatch = useDispatch();
    const uploadInputRef = useRef<HTMLInputElement>(null);

    return (<>
        <Navigation breadcrumbName={'Einstellungen'}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Tabs
                                    value={selectedTab}
                                    indicatorColor="primary"
                                    onChange={(e, val) => setTab(val)}
                                >
                                    <Tab value={'feedback'} label={t('helpAndFeedbackView.feedbackTab')}/>
                                    <Tab value={'faq'} label={t('helpAndFeedbackView.faqTab')}/>
                                    <Tab value={'glossary'} label={t('helpAndFeedbackView.glossaryTab')}/>
                                </Tabs>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <TabPanel value={selectedTab} index={'feedback'}>
                                    <Formik
                                        initialValues={{feedbackText: '',issueType: IssueTypes.BUG, attachments: undefined}}
                                        onSubmit={async (values,{resetForm}) => {
                                            if (values.feedbackText && values.feedbackText.trim().length) {
                                                await Api.sendFeedback(values.feedbackText, values.issueType, values.attachments);
                                                resetForm();
                                                if(uploadInputRef && uploadInputRef.current) {
                                                    uploadInputRef.current.value = '';
                                                }
                                                dispatch(thunkCreateSuccessNotification(t('helpAndFeedbackView.saveFeedbackSuccess')))
                                            }
                                        }}
                                    >
                                        {({
                                              values,
                                              handleChange,
                                              handleSubmit,
                                              isSubmitting,
                                              setFieldValue
                                          }) => (
                                            <form id="feedbackForm" onSubmit={handleSubmit}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={6}>
                                                        <Typography>{t('helpAndFeedbackView.feedbackHeadline')}</Typography>

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>{t('helpAndFeedbackView.issueTypeLbl')}</InputLabel>
                                                            <Select
                                                                name="issueType"
                                                                label={t('helpAndFeedbackView.issueTypeLbl')}
                                                                value={values.issueType}
                                                                onChange={handleChange}
                                                            >
                                                                {issueOptions.map(iO =>
                                                                    <MenuItem key={iO.value} value={iO.value}>{iO.name}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                multiline={true}
                                                                rows={10}
                                                                variant={"outlined"}
                                                                name="feedbackText"
                                                                label={t('helpAndFeedbackView.feedbackTextLbl')}
                                                                value={values.feedbackText}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>{t('helpAndFeedbackView.attachmentsLbl')}</InputLabel>
                                                            <input ref={uploadInputRef} id="attachments" name="attachments" type="file" multiple onChange={(event) => {
                                                                setFieldValue("attachments", Array.prototype.slice.call(event.currentTarget.files));
                                                            }} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant={"contained"} color={"primary"}
                                                                disabled={isSubmitting}
                                                                type={"submit"}>{t('common.sendBtn')}</Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    </Formik>
                                </TabPanel>
                                <TabPanel value={selectedTab} index={'faq'}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                        >
                                            <Typography className={classes.heading}>Was kann ich machen, wenn etwas
                                                nicht funktioniert?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada
                                                lacus ex,
                                                sit amet blandit leo lobortis eget.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                        >
                                            <Typography className={classes.heading}>Wie erstelle ich neue
                                                Kundenlisten?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada
                                                lacus ex,
                                                sit amet blandit leo lobortis eget.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                        >
                                            <Typography className={classes.heading}>Warum kann ich mich nicht
                                                ausloggen?</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada
                                                lacus ex,
                                                sit amet blandit leo lobortis eget.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </TabPanel>
                                <TabPanel value={selectedTab} index={'glossary'}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tableHeading}>Name</TableCell>
                                                    <TableCell className={classes.tableHeading}>Beschreibung</TableCell>
                                                    {/*<TableCell align="right">Beschreibung</TableCell>*/}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {glossaryData.map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell>{row.description}</TableCell>
                                                        {/*<TableCell align="right">{row.description}</TableCell>*/}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
        </Navigation>
    </>)
}

export default HelpAndFeedbackView;
