import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import {Action} from "redux";
import Api from "../Api";
import {thunkCreateSuccessNotification} from "./NotificationActions";
import i18next from "i18next";
import IMeeting from "../models/IMeeting";
import CalendarEvent from "../models/CalendarEvent";
import {ToDo} from "../models/ToDo";
import {Note} from "../models/Note";

export const GET_CALENDAREVENTS_SUCCESS = "GET_CALENDAREVENTS_SUCCESS";
export const GET_CALENDAREVENTS_ERROR = "GET_CALENDAREVENTS_ERROR";

export const CREATE_CALENDAREVENTS_SUCCESS = "CREATE_CALENDAREVENTS_SUCCESS";
export const CREATE_CALENDAREVENTS_ERROR = "CREATE_CALENDAREVENTS_ERROR";

export const thunkGetCalendarEvents = (): ThunkAction<void, IStore, null, Action<string>> => async dispatch => {
    try {
        const response = await Api.getCalendarEvents();
        await dispatch({
            type: GET_CALENDAREVENTS_SUCCESS,
            payload: response
        });
    } catch (e) {
        dispatch({
            type: GET_CALENDAREVENTS_ERROR,
            payload: e
        });
    }
};

export const thunkCreateCalendarEvents = (eventToCreate: IMeeting, todo: ToDo, note: Note): ThunkAction<void, IStore, null, Action<string>> => async dispatch => {
        const response: CalendarEvent = await Api.createMeeting(eventToCreate, todo, note);
        await dispatch(thunkCreateSuccessNotification(i18next.t("createMeetingModal.createMeetingSuccessNotification")))
        await dispatch({
            type: CREATE_CALENDAREVENTS_SUCCESS,
            payload: response
        });
        return response;
    }
;
