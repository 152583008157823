import React from 'react';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

export interface DeliveredPosition {
    materialBezeichnung: string;
    posNummer: string;
    WarenausgangIST: Date;
    auftragsNummer: string;
    nameBesteller: string;
    kundenNummer: string;
    stContactID: string;
    contactName: string;
}

export default function DeliveredOrderMenu(props: {deliveredPosition: DeliveredPosition}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {t} = useTranslation();

    return (
        <div>
            <IconButton
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} component={Link} to={'/contact/'+props.deliveredPosition.stContactID}>{t('deliveredOrderMenu.showContact')}</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={'/order/'+props.deliveredPosition.auftragsNummer}>{t('deliveredOrderMenu.showOrder')}</MenuItem>
            </Menu>
        </div>
    );
}