import React from 'react';
import {Box, Button, FormControl, Grid, TextField, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import {Field, Formik} from "formik";
import {formatDateObjWithDayOfWeek} from "../Formatter";
import {TimePicker} from "formik-material-ui-pickers";
import {addHours} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";
import DailyAdmReport from "../../store/models/DailyAdmReport";
import {v4 as uuid} from "uuid";
import {getMyADMID} from "../../util/UserUtil";
import {useDispatch} from "react-redux";
import {thunkCreateDailyAdmReport, thunkUpdateDailyAdmReport} from "../../store/actions/DailyAdmReportActions";


const useStyles = makeStyles(() => ({
    kmInput: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    },
}));


interface DailyAdmReportUpdateModalProps {
    forDate: Date;
    dailyAdmReport?: DailyAdmReport;
    tile: (handleOpen: () => void) => React.ReactNode,
}

export default function DailyAdmReportUpdateModal(props: DailyAdmReportUpdateModalProps) {
    const {tile, forDate, dailyAdmReport} = props;
    const {t} = useTranslation();

    const initialValues: DailyAdmReport = dailyAdmReport ? dailyAdmReport : {
        forDate,
        darNumber: uuid(),
        admID: getMyADMID(),
        startDateTime: addHours(forDate, 8),
        endDateTime: addHours(forDate, 16),
        startKM: 0,
        endKM: 0,
        note: '',
    }

    const dispatch = useDispatch();

    const classes = useStyles();

    return (
        <>
            <Modal title={t('dailyAdmReportUpdateModal.modalHeadline')}
                   fullWidth={true}
                   maxWidth={"md"}
                   contentAndButtons={(handleClose) => (
                       <>
                           <DialogContent>
                               <Formik
                                   initialValues={initialValues}
                                   onSubmit={async (values) => {
                                       if (dailyAdmReport) {
                                           await dispatch(thunkUpdateDailyAdmReport(values))
                                       } else {
                                           await dispatch(thunkCreateDailyAdmReport(values))
                                       }
                                       handleClose();
                                   }}
                               >
                                   {({
                                         values,
                                         handleChange,
                                         handleSubmit
                                     }) => (
                                       <form id={"DailyAdmReportUpdateForm"} onSubmit={handleSubmit}>
                                           <Box>
                                               <Grid container spacing={4}>
                                                   <Grid item xs={12}>
                                                       {formatDateObjWithDayOfWeek(forDate)}
                                                   </Grid>
                                                   <Grid item xs={4}>
                                                       <Field
                                                           component={TimePicker}
                                                           inputVariant={"outlined"}
                                                           name="startDateTime"
                                                           value={values.startDateTime}
                                                           label={t('dailyAdmReportUpdateModal.workStartLbl')}
                                                           format={'dd.MM.yyyy HH:mm'}
                                                           ampm={false}
                                                           autoOk
                                                       />
                                                   </Grid>
                                                   <Grid item xs={4}>
                                                       <Field
                                                           component={TimePicker}
                                                           inputVariant={"outlined"}
                                                           name="endDateTime"
                                                           value={values.endDateTime}
                                                           label={t('dailyAdmReportUpdateModal.workEndLbl')}
                                                           format={'dd.MM.yyyy HH:mm'}
                                                           ampm={false}
                                                           autoOk
                                                       />
                                                   </Grid>
                                                   <Grid item xs={12}>
                                                       <Typography variant={"h6"}>
                                                           {t('dailyAdmReportUpdateModal.kmTraveledHeadline')}
                                                       </Typography>
                                                   </Grid>
                                                   <Grid item xs={4}>
                                                       <TextField
                                                           className={classes.kmInput}
                                                           variant={"outlined"}
                                                           name="startKM"
                                                           type={'number'}
                                                           label={t('dailyAdmReportUpdateModal.startKMLbl')}
                                                           value={values.startKM}
                                                           onChange={handleChange}
                                                       />
                                                   </Grid>
                                                   <Grid item xs={4}>
                                                       <TextField
                                                           className={classes.kmInput}
                                                           variant={"outlined"}
                                                           name="endKM"
                                                           type={'number'}
                                                           label={t('dailyAdmReportUpdateModal.endKMLbl')}
                                                           value={values.endKM}
                                                           onChange={handleChange}
                                                       />
                                                   </Grid>
                                                   <Grid item md={12}>
                                                       <Typography variant={"h6"}>
                                                           {t('dailyAdmReportUpdateModal.noteHeadline')}
                                                       </Typography>
                                                   </Grid>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <TextField
                                                               multiline={true}
                                                               rows={3}
                                                               variant={"outlined"}
                                                               name="note"
                                                               label={t('dailyAdmReportUpdateModal.noteLbl')}
                                                               value={values.note}
                                                               onChange={handleChange}
                                                               required
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                               </Grid>
                                           </Box>
                                       </form>
                                   )}
                               </Formik>
                           </DialogContent>
                           <DialogActions>
                               <Button onClick={handleClose}>
                                   {t('common.cancelBtn')}
                               </Button>
                               <Button type="submit" form="DailyAdmReportUpdateForm" color={"primary"}>
                                   {t('common.saveBtn')}
                               </Button>
                           </DialogActions>
                       </>
                   )} openButton={tile}/>
        </>
    );
}
