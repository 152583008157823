import React, {useState} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Formik} from "formik";
import * as Yup from "yup";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";
import {Note, NoteType, VisitingReportProps} from "../../store/models/Note";
import {yesNoOptions} from "../mappedSelectOptions";
import {
    fetchOrderProductTypesOptions,
    fetchVisitingReportResultOptions,
    orderProductTypes
} from "./contactTouchpoint/VisitingReportTab";
import Api from "../../store/Api";

interface ContactHistoryNoteEditModalProps {
    note: Note;
    afterEdit: () => void;
}

interface InitialFormValues {
    text: string;
    orderProductValues: any;
    visitingReportProps?: VisitingReportProps;
}

export default function ContactHistoryNoteEditModal({note, afterEdit}: ContactHistoryNoteEditModalProps) {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const visitingReportResultOptions = fetchVisitingReportResultOptions(t);
    const orderProductTypesOptions = fetchOrderProductTypesOptions(t);
    const orderProductValues: any = {};

    const initialValues: InitialFormValues = {
        text: note.text,
        orderProductValues,
    }
    if (note.type === NoteType.VisitingReport) {
        orderProductTypesOptions.forEach(value => orderProductValues[value.key] = note.visitingReportProps?.productTypesPresented.includes(value.key))
        initialValues.visitingReportProps = note.visitingReportProps;
    }

    return <Modal title={t('contactHistoryNoteEditModal.headline')} fullWidth={true}
                  contentAndButtons={(handleClose) => (
                      <>
                          <DialogContent>
                              <Formik
                                  initialValues={initialValues}
                                  onSubmit={async (values) => {
                                      setLoading(true)
                                      let noteToUpdate = {
                                          ...note,
                                          text: values.text,
                                      }
                                      if (note.type === NoteType.VisitingReport) {
                                          noteToUpdate = {
                                              ...noteToUpdate,
                                              visitingReportProps: {
                                                  gotRecommendations: values.visitingReportProps!.gotRecommendations,
                                                  productTypesPresented: orderProductTypes.filter(value => values.orderProductValues[value]),
                                                  visitResult: values.visitingReportProps!.visitResult
                                              }
                                          }
                                      }
                                      await Api.editNote(noteToUpdate);
                                      await afterEdit();
                                      setLoading(false);
                                      handleClose();
                                  }}
                                  validationSchema={Yup.object().shape({

                                      text: Yup.string()
                                          .required(t('validation.required')),
                                  })}

                              >
                                  {({
                                        values,
                                        handleChange,
                                        handleSubmit,
                                        errors,
                                        touched
                                    }) => (
                                      <form id="contactPersonEditForm" onSubmit={handleSubmit}>
                                          <Box m={3}>
                                              <Grid container spacing={5}>
                                                  {values.visitingReportProps ? <>
                                                      <Grid item xs={6}>
                                                          <FormControl fullWidth>
                                                              <InputLabel>{t('contactHistoryNoteEditModal.visitingReportResultLbl')}</InputLabel>
                                                              <Select
                                                                  name="visitingReportProps.visitResult"
                                                                  value={values.visitingReportProps.visitResult}
                                                                  onChange={handleChange}
                                                              >
                                                                  {visitingReportResultOptions.map(sO => <MenuItem
                                                                      key={'visitingReportResultOption' + sO.label}
                                                                      value={sO.value}>{sO.label}</MenuItem>)}
                                                              </Select>
                                                          </FormControl>
                                                      </Grid>
                                                      <Grid item xs={6}>
                                                          <FormControl fullWidth>
                                                              <InputLabel>{t('contactHistoryNoteEditModal.gotRecommendationsLbl')}</InputLabel>
                                                              <Select
                                                                  name={'visitingReportProps.gotRecommendations'}
                                                                  value={values.visitingReportProps.gotRecommendations}
                                                                  onChange={handleChange}
                                                              >
                                                                  {yesNoOptions().map(aT => <MenuItem
                                                                      key={'gotRecommendations' + aT.label}
                                                                      value={aT.value}>&nbsp;{aT.label}</MenuItem>)}
                                                              </Select>
                                                          </FormControl>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                          <FormGroup row>
                                                              {orderProductTypesOptions.map(oPT =>
                                                                  <FormControlLabel
                                                                      key={oPT.key}
                                                                      control={<Checkbox
                                                                          checked={values.orderProductValues[oPT.key]}
                                                                          onChange={handleChange} color="primary"
                                                                          name={'orderProductValues.' + oPT.key}/>}
                                                                      label={oPT.label}
                                                                  />
                                                              )}
                                                          </FormGroup>
                                                      </Grid>
                                                  </> : null}
                                                  <Grid item xs={12}>
                                                      <FormControl fullWidth>
                                                          <TextField
                                                              name="text"
                                                              multiline={true}
                                                              rows={3}
                                                              variant={"outlined"}
                                                              label={t('contactHistoryNoteEditModal.noteText')}
                                                              value={values.text}
                                                              onChange={handleChange}
                                                              error={(!!errors.text && touched.text)}
                                                              helperText={(errors.text && touched.text) && errors.text}
                                                          />
                                                      </FormControl>
                                                  </Grid>
                                              </Grid>
                                          </Box>
                                      </form>
                                  )}

                              </Formik>
                          </DialogContent>
                          <DialogActions>
                              {loading ?
                                  <div style={{paddingRight: 40}}>
                                      <CircularProgress/>
                                  </div> :
                                  <>
                                      <Button onClick={handleClose}>
                                          {t('common.cancelBtn')}
                                      </Button>

                                      <Button form="contactPersonEditForm" type="submit" color={"primary"}>
                                          {t('common.saveBtn')}
                                      </Button>
                                  </>
                              }
                          </DialogActions>
                      </>
                  )} openButton={(handleOpen) => (
        <IconButton component="span" onClick={handleOpen}>
            <EditIcon/>
        </IconButton>
    )}/>
}