import React, {useEffect, useState} from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CampaignData} from "../../../store/models/CampaignData";
import {formatDateObj} from "../../Formatter";
import Api from "../../../store/Api";

const useStyles = makeStyles((theme) => {
    return ({
        container: {
            marginTop: theme.spacing(3)
        },
    });
});

export default function BadStatusTab() {
    const classes = useStyles();

    const [campaignData, setCampaignData] = useState<CampaignData[]>([])

    useEffect(() => {
        Api.getMailchimpCampaignsForBAD().then(mailchimpCampaigns => setCampaignData(mailchimpCampaigns))
    }, [])

    return <>
        <Grid className={classes.container} container spacing={6} direction={"column"}>
            <Grid item xs={12}>
                <Typography variant={"h5"}>Aktive Mailchimp Kampagnen</Typography>
                <Typography>Mailchimp Kampagnen die in den letzten 3 Monaten versendet wurden und dadurch als
                    Kundenliste
                    auftauchen</Typography>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Gesendet am:</TableCell>
                        <TableCell>zuletzt gesynct am</TableCell>
                        <TableCell>in Mailchimp erstellt am</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {campaignData.map(camp =>
                        <TableRow key={camp.stCampaignId}>
                            <TableCell>{camp.campaignName}</TableCell>
                            <TableCell>{formatDateObj(camp.sendTime)}</TableCell>
                            <TableCell>{formatDateObj(camp.updatedAt)}</TableCell>
                            <TableCell>{formatDateObj(camp.externalCreationDate)}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Grid>
    </>
}