export const currencyFormater = (val: number) => {
    const f = new Intl.NumberFormat(undefined, { style: "currency", currency: "EUR" });
    const parts = f.formatToParts(val);
    const idx = parts.findIndex(p => p.type === 'fraction');

    if (idx > 0 && +(parts[idx].value) === 0 && parts[idx - 1].type === 'decimal') {
        parts.splice(idx - 1, 2);
    }

    return parts.map(({ value }) => value).join('');
}
