import React from 'react';
import type { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { alpha, makeStyles, Theme } from '@material-ui/core';
import { LIGHT_GREEN, YELLOW, RED } from '../BerendsohnMUITheme';


interface LabelProps {
    className?: string;
    color?: 'primary' | 'secondary' | 'error' | 'warning' | 'success';
    children?: ReactNode;
    style?: {};
}


// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        alignItems: 'center',
        borderRadius: 2,
        display: 'inline-flex',
        flexGrow: 0,
        whiteSpace: 'nowrap',
        cursor: 'default',
        flexShrink: 0,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightMedium,
        height: 20,
        justifyContent: 'center',
        letterSpacing: 0.5,
        minWidth: 20,
        padding: theme.spacing(0.5, 1),
        textTransform: 'uppercase'
    },
    primary: {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.08)
    },
    secondary: {
        color: theme.palette.secondary.main,
        backgroundColor: alpha(theme.palette.secondary.main, 0.08)
    },
    error: {
        color: RED,
        backgroundColor: alpha(theme.palette.error.main, 0.08)
    },
    success: {
        color: LIGHT_GREEN,
        backgroundColor: alpha(theme.palette.success.main, 0.08)
    },
    warning: {
        color: YELLOW,
        backgroundColor: alpha(theme.palette.warning.main, 0.08)
    }
}));

const Label: FC<LabelProps> = ({
    className = '',
    color = 'secondary',
    children,
    style,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <span
            className={
                clsx(classes.root, {
                    [classes[color]]: color
                }, className)
            }
            {...rest}
        >
            {children}
        </span>
    );
};

export default Label;
