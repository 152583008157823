import React from 'react';
import Modal from "../Modal";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Grid} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import DailyAdmReport from "../../store/models/DailyAdmReport";
import DailyAdmReportSummaryRow from "./DailyAdmReportSummaryRow";
import useAdmName from "../../hooks/useAdmName";


export default function DailyAdmReportManagerModal({reports}: { reports: DailyAdmReport[] }) {
    const {t} = useTranslation();

    return <Modal title={t('dailyAdmReportManagerModal.modalHeadline', {admName: useAdmName(reports[0].admID)})}
                  fullWidth={true}
                  maxWidth={"md"}
                  contentAndButtons={(handleClose) => (
                      <>
                          <DialogContent>
                              <Grid container spacing={3}>
                                  {reports.map(r =>
                                      <DailyAdmReportSummaryRow key={r.darNumber} report={r}/>
                                  )}
                              </Grid>
                          </DialogContent>
                          <DialogActions>
                              <Button onClick={handleClose}>
                                  {t('common.closeBtn')}
                              </Button>

                          </DialogActions>
                      </>
                  )} openButton={(handleOpen) => (
        <Button onClick={handleOpen} variant="contained" color={"primary"}>
            {t('common.detailsBtn')}
        </Button>
    )}/>

}
