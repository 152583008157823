import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import {Action} from "redux";
import ContactList, {ContactListType} from "../models/ContactList";
import {emptyContactDetailSearch} from "../models/ContactDetailSearch";
import Api from "../Api";
import {thunkCreateSuccessNotification} from "./NotificationActions";
import i18next from "i18next";
import {getMyADMID} from "../../util/UserUtil";


export const defaultContactLists = (): ContactList[] => [
    {
        name: i18next.t('defaultContactLists.myCustomers'),
        query: {
            ...emptyContactDetailSearch,
            myCustomers: true

        },
        selectedContactIds: [],
        type: ContactListType.DYNAMIC_QUERY
    },
    {
        name: i18next.t('defaultContactLists.assignedCustomers'),
        query: {...emptyContactDetailSearch, assignedCustomers: true},
        selectedContactIds: [],
        type: ContactListType.DYNAMIC_QUERY
    },
    {
        name: i18next.t('defaultContactLists.myPotentialCustomers'),
        query: {
            ...emptyContactDetailSearch,
            VB:getMyADMID(),
            potentialCustomers: true

        },
        selectedContactIds: [],
        type: ContactListType.DYNAMIC_QUERY
    },
]

export const GET_CONTACT_LISTS_SUCCESS = "GET_CONTACT_LISTS_SUCCESS";
export const GET_CONTACT_LISTS_ERROR = "GET_CONTACT_LISTS_ERROR";

export const SELECT_CONTACT_LIST_SUCCESS = "SELECT_CONTACT_LIST_SUCCESS";
export const SELECT_CONTACT_LIST_ERROR = "SELECT_CONTACT_LIST_ERROR";

export const SAVE_CONTACT_LIST_SUCCESS = "SAVE_CONTACT_LIST_SUCCESS";
export const SAVE_CONTACT_LIST_ERROR = "SAVE_CONTACT_LIST_ERROR";

export const DELETE_CONTACT_LIST_SUCCESS = "DELETE_CONTACT_LIST_SUCCESS";
export const DELETE_CONTACT_LIST_ERROR = "DELETE_CONTACT_LIST_ERROR";

export const thunkGetContactLists = (): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const contactLists = await Api.getContactLists();
        dispatch({
            type: GET_CONTACT_LISTS_SUCCESS,
            payload: [...defaultContactLists(), ...contactLists]
        });
    } catch (e) {
        dispatch({
            type: GET_CONTACT_LISTS_ERROR,
            payload: e
        });
    }
};

export const thunkSelectContactList = (contactList: ContactList, page: number): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const {searchResult, totalResults, withDistanceKm} = await Api.selectContactList(contactList, page)
        dispatch({
            type: SELECT_CONTACT_LIST_SUCCESS,
            payload: {contactList, searchResult, totalResults, page, withDistanceKm}
        })
    } catch (e) {
        dispatch({
            type: SELECT_CONTACT_LIST_ERROR,
            payload: e
        })
    }

}
export const thunkSaveNewContactList = (contactList: ContactList): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const newContactList = await Api.saveContactList(contactList)
        await dispatch(thunkCreateSuccessNotification(i18next.t('contactListActions.createContactListSuccess')));
        dispatch({
            type: SAVE_CONTACT_LIST_SUCCESS,
            payload: newContactList
        })
    } catch (e) {
        dispatch({
            type: SAVE_CONTACT_LIST_ERROR,
            payload: e
        })
    }

}

export const thunkDeleteContactList = (contactListID: string): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        await Api.deleteContactList(contactListID)
        await dispatch(thunkCreateSuccessNotification(i18next.t('contactListActions.deleteContactListSuccess')));
        dispatch({
            type: DELETE_CONTACT_LIST_SUCCESS,
            payload: contactListID
        })
    } catch (e) {
        dispatch({
            type: DELETE_CONTACT_LIST_ERROR,
            payload: e
        })
    }

}
