import React, {useState} from "react";
import Navigation from "../components/Navigation";
import {Grid, Paper} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {compareAsc, endOfMonth, isAfter, isBefore, startOfMonth} from "date-fns";
import IStore from "../store/models/IStore";
import {ToDo, ToDoType} from "../store/models/ToDo";
import {filterMap, MeetingListFilter} from "../components/meetingList/MeetingListFilter";
import MeetingListFilterSelection from "../components/meetingList/MeetingListFilterSelection";
import MeetingListTimeFrameFilerSelection from "../components/meetingList/MeetingListTimeFrameFilterSelection";
import MeetingListRow from "../components/meetingList/MeetingListRow";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        marginBottom: 20
    },
    container: {
        marginTop: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(1, 3),
        margin: theme.spacing(3, 0),
        minHeight: '50%'
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        fontWeight: "bold",
    },
    divider: {
        height: 24,
        width: 1
    },
}));


function fetchTimeFilterFunctions(currentMonth: Date): (todo: ToDo) => boolean {
    const startOfMonthDate = startOfMonth(currentMonth);
    const endOfMonthDate = endOfMonth(currentMonth);
    return (todo: ToDo) => {
        return !!todo.dueOn && isAfter(todo.dueOn, startOfMonthDate) && isBefore(todo.dueOn, endOfMonthDate);
    }
}


function MeetingListView() {
    const [filterKey, setFilterKey] = useState<MeetingListFilter>(MeetingListFilter.NONE);
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
    const meetings = useSelector(((state: IStore) => state.todos.todos.filter((todo: ToDo) =>
        [ToDoType.MSOfficeAppointment, ToDoType.FollowUp, ToDoType.LegacyCrmMeeting, ToDoType.LegacyCrmFollowup].includes(todo.type))));

    const timeFilterFunction = fetchTimeFilterFunctions(currentMonth);

    const filteredMeetings = meetings.filter(timeFilterFunction).filter(filterMap[filterKey]).sort((a, b) => compareAsc(new Date(a.dueOn!), new Date(b.dueOn!)))

    const classes = useStyles();
    const {t} = useTranslation();

    const filterOrderList = (newKey: MeetingListFilter) => () => {
        setFilterKey(newKey)
    }

    return <>
        <Navigation breadcrumbName={'Meine Aufträge'}>
            <Grid container spacing={3} justifyContent={"center"}>
                <MeetingListTimeFrameFilerSelection selectedMonth={currentMonth} setMonth={setCurrentMonth}/>
                <MeetingListFilterSelection selectedFilter={filterKey} filterFunc={filterOrderList}/>
            </Grid>

            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell><b>{t('meetingListView.date')}</b></TableCell>
                            <TableCell><b>{t('meetingListView.dateCreated')}</b></TableCell>
                            <TableCell><b>{t('meetingListView.customer')}</b></TableCell>
                            <TableCell><b>{t('meetingListView.type')}</b><br/>{t('meetingListView.withMail')}
                            </TableCell>
                            <TableCell><b>{t('meetingListView.status')}</b></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredMeetings.map((meeting: ToDo) =>
                            <MeetingListRow key={'meetingListRow'+meeting.todoNumber} meeting={meeting} isManagerList={false}/>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Navigation>
    </>
}

export default MeetingListView;
