import Navigation from "../components/Navigation";
import {Box, Divider, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import BusinessExpenseReport, {BusinessExpenseReportStatus} from "../store/models/BusinessExpenseReport";
import {AdmName} from "../components/AdmName";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {thunkGetBusinessExpenseReportsForMyADMs} from "../store/actions/BusinessExpenseReportActions";
import {useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {addMonths, isSameMonth, startOfMonth, subMonths} from "date-fns";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {getMonthToStringArr} from "../components/Formatter";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        minHeight: 600
    },
    activityDivider: {
        minHeight: 300
    }
}));


type ReportsByADMID = { admID: string, reports: BusinessExpenseReport[] }[];

export default function BusinessExpensesManagerOverview() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();
    const [currentMonth, setCurrentMonth] = useState<Date>(startOfMonth(Date.now()));

    function fetchOpenToTotalReports(reports: BusinessExpenseReport[]) {
        const open = reports.filter(r => r.managementStatus !== BusinessExpenseReportStatus.ACCEPTED && r.managementStatus !== BusinessExpenseReportStatus.DECLINED).length;
        return t('businessExpensesManagerOverview.openToTotal', {open: open, total: reports.length});
    }


    function sortAndSetBERByADM(reports: BusinessExpenseReport[]) {
        const reportsByAdm: ReportsByADMID = [];
        reports.filter(r=>isSameMonth(currentMonth,new Date(r.forDate))).forEach(report => {
            const index = reportsByAdm.findIndex(rByADM => rByADM.admID === report.admID)
            if (index >= 0) {
                reportsByAdm[index].reports.push(report);
            } else {
                reportsByAdm.push({
                    admID: report.admID,
                    reports: [report]
                })
            }
        })
        return reportsByAdm;
    }

    const monthToString = getMonthToStringArr();

    const businessExpenseReportsByADM = useSelector((state: IStore) => sortAndSetBERByADM(state.businessExpenseReports.managmentReports))
    useEffect(() => {
        dispatch(thunkGetBusinessExpenseReportsForMyADMs())
    }, [dispatch])

    return (<>
        <Navigation breadcrumbName={''}>
            <Grid container spacing={4}>
                <Grid container item xs={12}>
                    <Grid item xs={1}>
                        <IconButton onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
                                    aria-label={t('common.back')}>
                            <ArrowBackIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography align={"center"}>{monthToString[currentMonth.getMonth()]} {currentMonth.getFullYear()}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
                                    aria-label={t('common.forward')}>
                            <ArrowForwardIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            {businessExpenseReportsByADM.length ? businessExpenseReportsByADM.map(reports =>
                                <React.Fragment key={reports.admID}>
                                    <Grid item xs={5}>
                                        <Box fontWeight="fontWeightBold"> <Typography><AdmName
                                            personalnummer={reports.admID}/></Typography></Box>
                                        <Typography variant={"body2"}>{reports.admID}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>{fetchOpenToTotalReports(reports.reports)}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Link to={ `/businessExpensesManager/${reports.admID}/${currentMonth.getMonth()}/${currentMonth.getFullYear()}` }>
                                            <IconButton size={"small"} color={"primary"}>
                                                <ArrowForwardIosIcon/>
                                            </IconButton>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}><Divider/></Grid>
                                </React.Fragment>
                            ): <Grid item xs={12}><Typography align={"center"}>{t('businessExpensesManagerOverview.noData')}</Typography></Grid>}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Navigation>
    </>)
}

