import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import { observer } from 'mobx-react-lite'
import SignaturePad from 'signature_pad';
// import throttle from 'lodash/throttle';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
    Button,
    Card,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Grid,
    Paper,
    RadioGroup,
    Radio,
    Typography,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
} from "@material-ui/core";
import { grey } from '@material-ui/core/colors';
import SyncIcon from '@material-ui/icons/Sync';
import SearchIcon from '@material-ui/icons/Search';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Autocomplete} from "@material-ui/lab";
import Navigation from "../components/Navigation";
import ProductUserMenu from "../components/ProductUserMenu";
import {thunkSearchContacts} from "../store/actions/ContactActions";
import {thunkGetContactLists} from "../store/actions/ContactListActions";
import useProductOffer from "../hooks/useProductOffer";
import useProductUser from "../hooks/useProductUser";
import {downloadFile} from "../util/DownloadFileUtil";
import Api from "../store/Api";
import IStore from "../store/models/IStore";


const useStyles = makeStyles((theme: Theme) => ({
    productCard: {

    },
    paper: {
        padding: theme.spacing(2),
    },
    titleInput: {
        fontSize: "2rem",
        fontWeight: "bold"
    },
    titleInputContainer: {
        margin: theme.spacing(1.5, 0),
    },
    offerDate: {
        marginLeft: theme.spacing(3),
    },
    spaceBetweenC: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    },
    subHeading: {
        marginBottom: theme.spacing(1)
    },
    inputMB: {
        marginBottom: theme.spacing(2)
    },
    buttonMR: {
        marginRight: theme.spacing(2)
    },
    positionTable: {
        marginBottom: theme.spacing(3)
    },
    positionTableHeadCell: {
        backgroundColor: grey[700],
        color: theme.palette.common.white
    },
    positionTableHeadCellContainer: {
        position: "relative",
    },
    positionTableHeadCellActions: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    positionTableHeadCellActionsBtn: {
        color: "white"
    },
    positionList: {
        padding: 0,
        margin: 0,
        listStyle: "none",
    },
    positionListProductImageContainer: {
        position: "relative",
        "&.active svg, &:hover svg": {
            opacity: 1,
        }
    },
    positionListProductImage: {
        maxWidth: "80px",
        maxHeight: "80px",
    },
    positionListProductImageSelectIcon: {
        pointerEvents: "none",
        fontSize: "2em",
        position: "absolute",
        top: 0,
        right: 0,
        opacity: 0,
        transition: "0.4s opacity ease",
    },
    signPad: {

    },
    signPadContainer: {
        marginBottom: theme.spacing(2),
    },
    positionTierCopyBtn: {
        // position: "absolute"
    },
}));

export default observer(() => {
    const {offerID} = useParams<{offerID:string}>();
    const classes = useStyles();
    const productOfferStore = useProductOffer();
    const productUserStore = useProductUser();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [signPad, setSignPad] = useState<any>(null);

    if (signPad && productOfferStore.sign?.data) {
        signPad.fromData(productOfferStore.sign.data);
    }
    // let signPad;

    const handleSave = () => {
        const data = productOfferStore.getOfferData();
        data.sign = {
            data: signPad.toData(),
            image: signPad.toDataURL('image/svg+xml')
        };

        if (productOfferStore.id) {
            Api.saveProductOffer(productOfferStore.id, data);
        }
    };
    const handleAddFromCart = async () => {
        const { cart } = productUserStore;
        let productIDs = cart.reduce((acc: any, { productId }: any) => {
            if (!acc[productId]) {
                acc[productId] = true;
            }
            return acc;
        }, {});
        productIDs = Object.keys(productIDs);
        const productData = await Api.getProducts(productIDs);
        productOfferStore.addPositionsFromCart(cart, productData);
    };
    const handleOfferDowload = async () => {
        const data = productOfferStore.getOfferData();
        if (productOfferStore.id) {
            await Api.saveProductOffer(productOfferStore.id, data);
        }

        const blob = await Api.getProductOfferPdf(offerID);
        downloadFile(blob, 'offer.pdf', 'application/pdf');
    };
    const handleSignPadClear = async () => {
        signPad.clear();
    };

    useEffect(() => {
        Api.getProductOffer(offerID).then(data => {
            productOfferStore.loadOfferData(data);
        });
        if (canvasRef.current) {
            setSignPad(new SignaturePad(canvasRef.current));
        }
    }, [offerID, productOfferStore]);

    return (
        <Navigation breadcrumbName={'Meine Angebote'}>
            <Grid container spacing={3} >
                <Grid container item justifyContent="flex-end">
                    <ProductUserMenu />
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3} >
                            <Grid container item justifyContent="flex-end">
                                <Button variant="outlined" color="primary" onClick={handleAddFromCart}>
                                    Positionen zum Angebot hinzufügen
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <OfferData />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <AddressAutocomplete />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <ClientData />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <PositionsList />
                    <Paper>
                        <div className={classes.paper}>
                            <Button className={classes.buttonMR} variant="outlined" color="primary" startIcon={<PictureAsPdfOutlinedIcon />} onClick={handleOfferDowload}>
                                speichern & download PDF
                            </Button>
                            <Button variant="outlined" color="primary" startIcon={<SaveOutlinedIcon />} onClick={handleSave}>
                                speichern
                            </Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container item>
                            <Card variant="outlined" className={classes.signPadContainer}>
                                <canvas ref={canvasRef} className={classes.signPad} width={400} height={200} />
                            </Card>
                        </Grid>
                        <Button variant="outlined" color="primary" startIcon={<HighlightOffIcon />} onClick={handleSignPadClear}>
                            clear
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <EmailData />
                    </Paper>
                </Grid>
            </Grid>
        </Navigation>
    )
})

export const OfferData = observer(() => {
    const classes = useStyles();
    const productOfferStore = useProductOffer();
    const {id, title, createdOn, sentOn} = productOfferStore;

    return (
        <>
            <div>
                <FormControl component="fieldset">
                    <RadioGroup row name="offerType" defaultValue="Angebot" onChange={ev =>
                        productOfferStore.title = `${ev.target.value.slice(0,50)} Nr. ${id}`
                    }>
                        <FormControlLabel value="Angebot" control={<Radio />} label="Angebot" />
                        <FormControlLabel value="Auftrag" control={<Radio />} label="Auftrag" />
                    </RadioGroup>
                </FormControl>
            </div>
            <TextField
                id="offer-name" className={classes.titleInputContainer} fullWidth variant="outlined"
                value={title} placeholder={`Angebot Nr. ${id}`} inputProps={{ className: classes.titleInput }}
                onChange={ev => productOfferStore.title = ev.target.value.slice(0,50)}
            />
            <Typography>
                {`erstellt am: ${createdOn.toLocaleString()}`}
                {sentOn && <span className={classes.offerDate}>{`gesendet am: ${sentOn.toLocaleString()}`}</span>}
            </Typography>

        </>
    )
})

export const AddressAutocomplete = observer((props: any) => {
    const classes = useStyles();
    const productOfferStore = useProductOffer();

    return (
        <>
            <div className={classes.spaceBetweenC}>
                <Typography className={classes.subHeading} variant="h4">Kunden auswählen</Typography>
                <IconButton
                    onClick={() => productOfferStore.refreshContacts()}
                >
                    <SyncIcon />
                </IconButton>
            </div>
            <ClientSearch />
        </>
    )
})

export const ClientData = observer(() => {
    const classes = useStyles();
    const productOfferStore = useProductOffer();
    const {loaded, clientData} = productOfferStore;
    if (!loaded) return null;

    const sapCrmId = [
        clientData.sapId && `sap Nr. ${clientData.sapId}`,
        clientData.crmId && `crm Nr. ${clientData.crmId}`
    ].filter(v => !!v).join(", ");

    const handleChange = (ev: any) => {
        const {value, name} = ev.target;
        (productOfferStore.clientData as any)[name] = value;
    }

    return (
        <>
            <Typography className={classes.subHeading} variant="h4">
                {`An `}{sapCrmId && <Typography variant="h5" component="span" color="textSecondary">{`(${sapCrmId})`}</Typography>}
            </Typography>
            <TextField id="offer-cl-company" className={classes.inputMB} name="company" label="Firma" fullWidth variant="outlined" value={clientData.company} onChange={handleChange} />
            <TextField id="offer-cl-name" className={classes.inputMB} name="name" label="Ansprechpartner" fullWidth variant="outlined" value={clientData.name} onChange={handleChange} />
            <TextField id="offer-cl-address" className={classes.inputMB} name="address" label="Strasse / Nr." fullWidth variant="outlined" value={clientData.address} onChange={handleChange} />
            <TextField id="offer-cl-postcode" className={classes.inputMB} name="postcode" label="PLZ / Ort" fullWidth variant="outlined" value={clientData.postcode} onChange={handleChange} />
        </>
    )
})

export const EmailData = observer(() => {
    const classes = useStyles();
    const productOfferStore = useProductOffer();
    const {loaded, emailData} = productOfferStore;
    if (!loaded) return null;

    const handleChange = (ev: any) => {
        const {value, name} = ev.target;
        (productOfferStore.emailData as any)[name] = value;
    }
    const handleOfferSend = async () => {
        const data = productOfferStore.getOfferData();
        if (productOfferStore.id) {
            await Api.saveProductOffer(productOfferStore.id, data);
            await Api.sendProductOffer(productOfferStore.id);
        }
    };

    return (
        <>
            <Typography className={classes.subHeading} variant="h4">
                Angebot per Mail versenden
            </Typography>
            <TextField id="offer-offer-email-to" className={classes.inputMB} name="to" label="eMail" fullWidth variant="outlined" value={emailData.to} onChange={handleChange} />
            <TextField id="offer-offer-email-subject" className={classes.inputMB} name="subject" label="Betreff" fullWidth variant="outlined" value={emailData.subject} onChange={handleChange} />
            <TextField id="offer-offer-email-message" className={classes.inputMB} name="message" label="Nachricht" fullWidth multiline  variant="outlined" value={emailData.message} onChange={handleChange} />
            <Button variant="outlined" color="primary" startIcon={<MailOutlineIcon />} onClick={handleOfferSend}>
                Mail versenden & speichern
            </Button>
        </>
    )
})

export const PositionsList = observer(() => {
    const productOfferStore = useProductOffer();
    const {loaded, positions, removePosition, movePositionUp, movePositionDown} = productOfferStore;
    if (!loaded) return null;

    return (
        <>
            {positions.map((position, i) =>
                <Position
                    key={i} idx={i} length={positions.length} data={position}
                    onMoveUp={() => movePositionUp(i)} onMoveDown={() => movePositionDown(i)} onRemove={() => removePosition(i)}
                />
            )}
        </>
    )
})

export const Position = observer((props: any) => {
    const classes = useStyles();
    const {idx, data, length, onRemove, onMoveUp, onMoveDown} = props;
    const {
        productId, printOptionsList, productImgIdxMap, productData, priceTiers,
        removePriceTier, copyPriceTier, movePriceTierUp, movePriceTierDown
    } = data;
    const {name, link, description, images, imagePath} = productData;

    const onImageSelect = (idx: any) => {
        props.data.productImgIdxMap = {
            ...props.data.productImgIdxMap,
            [idx]: !props.data.productImgIdxMap[idx]
        };
    }

    const productDetailNameMap: any = {
        'color': "Farbe",
        'dimensions': "Maße",
        'material': "Material",
        'weight': "Gewicht",
        'packaging': "Verpackung",
        'hscode': "Zolltarifnummer",
        'info': "Info",
        'compliance': "Compliance"
    };
    const productDetailsList = Object.keys(productDetailNameMap).filter(key => productData[key]).map(key =>
        <TableRow key={key}>
            <TableCell>{productDetailNameMap[key]}:&nbsp;</TableCell>
            <TableCell>{key === 'weight' ? `${productData['weight']} ${productData['weightUnit']}` : productData[key]}</TableCell>
        </TableRow>
    );

    return (
        <Paper className={classes.positionTable}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.positionTableHeadCell}>Pos.</TableCell>
                            <TableCell className={classes.positionTableHeadCell}>Name</TableCell>
                            <TableCell className={classes.positionTableHeadCell}>Nr.</TableCell>
                            <TableCell className={`${classes.positionTableHeadCell} ${classes.positionTableHeadCellContainer}`}>
                                Druck
                                <div className={classes.positionTableHeadCellActions}>
                                    <IconButton className={classes.positionTableHeadCellActionsBtn} onClick={onRemove}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                    <a href={link}>
                                        <IconButton className={classes.positionTableHeadCellActionsBtn} color="secondary">
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </a>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {idx + 1}
                                {length > 1 &&
                                    <>
                                    {+idx !== 0 &&
                                        <IconButton onClick={onMoveUp}>
                                            <ExpandLessIcon />
                                        </IconButton>
                                    }
                                    {+idx !== length - 1 &&
                                        <IconButton>
                                            <ExpandMoreIcon onClick={onMoveDown} />
                                        </IconButton>
                                    }
                                    </>
                                }
                            </TableCell>
                            <TableCell>{name}</TableCell>
                            <TableCell>{productId}</TableCell>
                            <TableCell>
                                <ul className={classes.positionList}>
                                    {printOptionsList.map((popt: any, i: any) =>
                                        <li key={i}>{`Werbeanbringung ${i + 1}: ${popt.aname} ${popt.oname}`}</li>
                                    )}
                                </ul>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.paper}>
                <Grid container spacing={3} >
                    <Grid item xs={12} lg={6}>
                        <Typography>{description}</Typography>
                        <Table><TableBody>{productDetailsList.length && productDetailsList}</TableBody></Table>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={2}>
                            {Object.keys(images).map(idx =>
                                <Grid item key={idx} className={`${classes.positionListProductImageContainer} ${productImgIdxMap[idx] ? "active" : ""}`}>
                                    <img className={classes.positionListProductImage} alt="" src={`${imagePath}${images[idx]}`} onClick={() => onImageSelect(idx)} />
                                    {productImgIdxMap[idx] ?
                                        <CheckCircleOutlineIcon color="secondary" className={classes.positionListProductImageSelectIcon} /> :
                                        <RadioButtonUncheckedIcon color="secondary" className={classes.positionListProductImageSelectIcon} />
                                    }
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Pos.</TableCell>
                        <TableCell>Menge</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {priceTiers.map((ptier: any, i: number) =>
                        <PriceTier
                            key={i} idx={i} data={ptier} length={priceTiers.length}
                            onMoveUp={() => movePriceTierUp(i)} onMoveDown={() => movePriceTierDown(i)}
                            onDuplicate={() => copyPriceTier(i)} onRemove={() => removePriceTier(i)}
                        />
                    )}
                </TableBody>
            </Table>
        </Paper>
    )
})

export const PriceTier = observer((props: any) => {
    const classes = useStyles();
    const {data, idx, length, onDuplicate, onRemove, onMoveUp, onMoveDown} = props;
    const {minAmount, amount, price} = data;

    const [_amount, setAmount] = useState(2);
    const handlePriceChange = (ev: any) => {
        setAmount(ev.target.value)
        props.data.amount = ev.target.value;
    }
    const handlePriceBlur = () => {
        if (!_amount || _amount < minAmount) props.data.amount = minAmount;
    }

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {idx + 1}
                {length > 1 &&
                    <>
                    {+idx !== 0 &&
                        <IconButton onClick={onMoveUp}>
                            <ExpandLessIcon />
                        </IconButton>
                    }
                    {+idx !== length - 1 &&
                        <IconButton>
                            <ExpandMoreIcon onClick={onMoveDown} />
                        </IconButton>
                    }
                    </>
                }
            </TableCell>
            <TableCell>
                <TextField
                    placeholder={`min. ${minAmount}`} value={amount} type="number" inputMode="numeric" variant="outlined" size="small"
                    onChange={handlePriceChange} onBlur={handlePriceBlur} InputProps={{
                        endAdornment: <InputAdornment position="end">St.</InputAdornment>,
                    }}
                />
            </TableCell>
            <TableCell>{price?.item.toLocaleString(undefined, { style: "currency", currency: 'EUR' })}</TableCell>
            <TableCell>
                <div className={classes.spaceBetweenC}>
                    {price?.full.toLocaleString(undefined, { style: "currency", currency: 'EUR' })}
                    <div>
                        <IconButton className={classes.positionTierCopyBtn} onClick={onDuplicate}>
                            <FileCopyOutlinedIcon />
                        </IconButton>
                        {length > 1 &&
                            <IconButton className={classes.positionTierCopyBtn} onClick={onRemove}>
                                <RemoveCircleOutlineOutlinedIcon />
                            </IconButton>
                        }
                    </div>
                </div>
            </TableCell>
        </TableRow>
    )
})

export const ClientSearch = observer((props: any) => {
    const productOfferStore = useProductOffer();
    const [value, setValue] = React.useState<any>(null);
    let searchResult = useSelector((state: IStore) => {
        return state.contact.searchResult
    });
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(thunkGetContactLists())
    }, [dispatch]);

    const handleContactSelect = (event: any, contactData: any) => {
        if (contactData) {
            productOfferStore.selectAutocomplete(contactData);
            setValue(contactData.companyLine1);
        } else {
            setValue(null);
        }
    };

    const search = async (searchTerm: string) => {
        setIsLoading(true);
        await dispatch(thunkSearchContacts(searchTerm, 1));
        setIsLoading(false);
    };

    return (
        <Autocomplete
            id="google-map-demo"
            filterOptions={(x) => x}
            options={searchResult}
            autoComplete
            includeInputInList
            value={value}
            onChange={handleContactSelect}
            onInputChange={(event, newInputValue) => {
                search(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params}
                    label="Kontaktsuche" variant="outlined" fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon color="inherit" />}
                            {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderOption={(option) => {
                const {companyLine1, companyLine2, companyLine3, contactPersons} = option;
                const contactPerson = contactPersons.find((p: any) => p.function === "BE") || contactPersons.find((p: any) => p.function === "IH");
                let name, email;

                if (contactPerson) {
                    const {title, firstname, lastname, emailAddress} = contactPerson;
                    name = [title, firstname, lastname].filter(v => v.length).join(" ");
                    email = emailAddress ? emailAddress : t('pages.offer.contactSearchNoEmail');
                }

                return (
                    <Grid container alignItems="center">
                        <Grid item>

                        </Grid>
                        <Grid item xs>
                            {companyLine1}
                            <Typography variant="body2" color="textSecondary" style={{ fontWeight: 700 }}>
                                {(companyLine2 && companyLine2 + " ") + companyLine3}
                            </Typography>
                            {contactPerson &&
                                <Typography variant="body2" color="textSecondary">
                                    {name}
                                    <br />
                                    {email}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
});
