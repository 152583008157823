import React from 'react';
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export default function ContactPersonDepartment({departmentVal}:{departmentVal: string}) {
    const {t} = useTranslation();
    const identifier = `contactPersonDepartmentOptions.${departmentVal}`;
    const translation = t(identifier);
    const displayVal = translation !== identifier ? translation : departmentVal;
    return <Typography variant={"body1"}>{displayVal}</Typography>
}
