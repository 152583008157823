import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Contact from "../store/models/Contact";
import {Checkbox, Grid, IconButton, Toolbar, Typography} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Link} from "react-router-dom";
import {formatDate, formatDateObj} from "./Formatter";
import {AdmName} from "./AdmName";
import {fetchStatusIndicatorColor} from "../util/ContactUtil";
import {useTranslation} from "react-i18next";
import AddContactsToContactListModal from "./AddContactsToContactListModal";
import ContactList from "../store/models/ContactList";
import DeleteModal from "./DeleteModal";

const useStyles = makeStyles((theme) => {
    return {
        table: {
            minWidth: 650,
        },
        tableHead: {
            fontWeight: "bold"
        },
        selected: {
            backgroundColor: 'rgba(25, 118, 210, 0.08)'
        },
        tableHeaderToolbar: {
            padding: theme.spacing(3)
        },
        title: {
            flex: '1 1  100%',
        }
    }
});


interface ContactTableProps {
    contacts: Contact[],
    selectedContacts: string[];
    setSelectedContacts: (contactIds: string[]) => void;
    selectedContactList?: ContactList;
    deleteFromContactList?: (stContactID: string) => void;
    checkboxesButton?: JSX.Element;
    detailsActions?: (c: Contact) => JSX.Element;
    hideCheckboxes?: boolean;
    statusIndicatorForAdmID?: string;
    withDistanceKm?: boolean
}

function fetchDistanceDisplayValue(distance: number) {
    const roundedDistance = Math.round(distance);
    if (roundedDistance === 0) {
        return '< 1km'
    } else {
        return `${roundedDistance}km`
    }
}

export default function ContactTable(props: ContactTableProps) {
    const {
        contacts,
        selectedContacts = [],
        setSelectedContacts,
        selectedContactList,
        deleteFromContactList,
        checkboxesButton,
        withDistanceKm
    } = props;
    const classes = useStyles();
    const {t} = useTranslation();

    const toogleCheckbox = (stContactId: string) => {
        if (selectedContacts.includes(stContactId)) {
            setSelectedContacts(selectedContacts.filter(id => id !== stContactId))
        } else {
            setSelectedContacts([...selectedContacts, stContactId]);
        }
    }
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = contacts.map((c) => c.stContactID);
            setSelectedContacts(newSelecteds);
            return;
        }
        setSelectedContacts([]);
    };

    const isCustomIdContactList = selectedContactList && selectedContactList.selectedContactIds && selectedContactList.selectedContactIds.length > 0;

    const selectedContactsCount = selectedContacts.length;
    const rowCount = contacts.length
    return (
        <TableContainer component={Paper}>
            {selectedContactsCount > 0 && !props.hideCheckboxes &&

                <Toolbar className={[classes.selected, classes.tableHeaderToolbar].join(' ')}>
                    <Typography>{selectedContactsCount} Kontakte </Typography>
                    <div style={{flexGrow: 1, display: 'flex'}}></div>
                    {checkboxesButton ?
                        checkboxesButton
                        : <AddContactsToContactListModal selectedContactIds={selectedContacts}/>
                    }
                </Toolbar>}
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {props.hideCheckboxes ?
                            null :
                            <TableCell>
                                {rowCount > 0 && <Checkbox
                                    color={"primary"}
                                    indeterminate={selectedContactsCount > 0 && selectedContactsCount < rowCount}
                                    checked={rowCount > 0 && selectedContactsCount === rowCount}
                                    onChange={(handleSelectAllClick)}
                                />}
                            </TableCell>
                        }
                        <TableCell><b>{t('contactTable.tableHeaderCompany')}</b></TableCell>
                        <TableCell><b>{t('contactTable.tableHeaderAddress')}</b></TableCell>
                        {/*<TableCell align="right"><b>Newsletter bestätigt?</b></TableCell>*/}
                        {/* <TableCell align="right"><b>{t('contactTable.tableHeaderSapId')}</b></TableCell> */}
                        <TableCell>
                            <b>{t('contactTable.tableHeaderLastContact')}</b><br/>
                            {t('contactTable.tableHeaderNextTodo')}
                        </TableCell>
                        <TableCell><b>{t('contactTable.tableHeaderCustomerProtection')}</b></TableCell>
                        {withDistanceKm ?
                            <TableCell><b>{t('contactTable.tableHeaderWithDistance')}</b></TableCell>
                            : null}
                        {isCustomIdContactList ?
                            <TableCell><b>{t('contactTable.tableHeaderDeleteFromContactList')}</b></TableCell> : null}
                        <TableCell><b>{t('contactTable.tableHeaderDetails')}</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contacts.map((row) => (
                        <TableRow key={row.stContactID}
                                  className={selectedContacts.includes(row.stContactID) ? classes.selected : ''}>
                            {props.hideCheckboxes ?
                                null :
                                <TableCell component="th" scope="row">
                                    <Checkbox checked={selectedContacts.includes(row.stContactID)} color={"primary"}
                                              onClick={() => toogleCheckbox(row.stContactID)}/>
                                </TableCell>
                            }
                            <TableCell component="th" scope="row">
                                <Grid container>
                                    <Grid item xs={1}>
                                        <div style={{
                                            height: '100%',
                                            width: 5,
                                            backgroundColor: fetchStatusIndicatorColor(row, props.statusIndicatorForAdmID)
                                        }}></div>
                                    </Grid>
                                    <Grid item xs={11}>
                                        {row.companyLine1}<br/>
                                        {row.companyLine2}<br/>
                                        {row.companyLine3}<br/>
                                        {row.companyLine4}<br/>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell>
                                {row.address.street} {row.address.houseNumber}<br/>
                                {row.address.zipCode} {row.address.place}
                            </TableCell>
                            {/*<TableCell align="right">{row.hasNewsletter ? 'Ja' : 'Nein'}</TableCell>*/}
                            {/* <TableCell align="right">{row.sapCustomerID}</TableCell> */}
                            <TableCell>
                                <Typography
                                    variant={"body2"}>{row.lastContactHistoryEntry ? formatDateObj(row.lastContactHistoryEntry.created) : ''}</Typography>
                                <Typography
                                    variant={"caption"}>{row.nextPlannedTodo ? t('contactTable.plannedTodoTypes.' + row.nextPlannedTodo.type) + formatDateObj(row.nextPlannedTodo.dueOn) : ''}</Typography>
                            </TableCell>
                            <TableCell>
                                {row.VB && row.kundenschutzBis && row.kundenschutzBis !== '0000-00-00' &&
                                    <Typography
                                        variant={"body2"}>{t('contactTable.protectedUntil')}&nbsp;{formatDate(row.kundenschutzBis)}</Typography>
                                }
                                {row.VB &&
                                    <><Typography variant={"caption"}>{t('contactTable.protectedFor')}&nbsp;<AdmName
                                        personalnummer={row?.VB}/></Typography>
                                        <br/></>
                                }
                                {row.zugewiesenerVB &&
                                    <Typography variant={"caption"}>{t('contactTable.assignedFor')}&nbsp;<AdmName
                                        personalnummer={row?.zugewiesenerVB}/></Typography>
                                }
                                {row.zugewiesenerVB && row.zugewiesenBis && row.zugewiesenBis !== '0000-00-00' &&
                                    <Typography
                                        variant={"body2"}>{t('contactTable.protectedUntil')}&nbsp;{formatDate(row.zugewiesenBis)}</Typography>
                                }
                            </TableCell>
                            {withDistanceKm ? <TableCell>
                                {row.distance ? fetchDistanceDisplayValue(row.distance) : ''}
                            </TableCell> : null}
                            {isCustomIdContactList && deleteFromContactList ?
                                <TableCell align="right">
                                    <DeleteModal action={() => deleteFromContactList(row.stContactID)}/>
                                </TableCell>
                                : null}
                            <TableCell align="right">
                                {props.detailsActions ? props.detailsActions(row) :
                                    <IconButton aria-label="details" component={Link}
                                                to={`/contact/${row.stContactID}`}>
                                        <ArrowForwardIosIcon/>
                                    </IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
