export enum MeetingType {
    PhoneCall = "PHONE_CALL",
    VideoCall = "VIDEO_CALL",
    InPerson = "IN_PERSON",
}

export default interface IMeeting {
    subject: string,
    startDate?: Date | string | null,
    endDate?: Date | string | null,
    location?: string,
    attendees?: string,
    body?:string;
    forCustomer: string,
    forCustomerDisplayName: string;
    meetingType: MeetingType
}
