import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {formateDateWithTime} from "../../Formatter";
import Api from "../../../store/Api";
import {JobReport} from "../../../store/models/JobReport";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => {
    return ({
        container: {
            marginTop: theme.spacing(3)
        },
    });
});


export default function BadSyncTab() {
    const classes = useStyles();
    const [jobReports, setJobReports] = useState<JobReport[]>([]);

    const updateJobReports = () => {
        Api.getJobReportsForBad().then(setJobReports)
    }

    useEffect(() => {
        updateJobReports()
    }, [])


    return <Grid className={classes.container} container spacing={6} direction={"column"}>
        <Grid item xs={12}>
            <Typography variant={"h5"}>Sync Jobs</Typography>
        </Grid>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Job</TableCell>
                    <TableCell>Job Art</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>gestartet</TableCell>
                    <TableCell>Details</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {jobReports.map(job =>
                    <TableRow key={job.jobID}>
                        <TableCell>{job.title}</TableCell>
                        <TableCell>{job.jobType}</TableCell>
                        <TableCell>{job.jobResult}</TableCell>
                        <TableCell>{formateDateWithTime(job.startDate)}</TableCell>
                        <TableCell>
                            <Accordion style={{width: '100%'}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <Typography>{job.jobEvents.length} Events</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        {job.jobEvents.map((jE, index) =>
                                            <Grid item xs={12} key={job.jobID + 'JE' + index}>
                                                {jE.message ?
                                                    <Typography>
                                                        {formateDateWithTime(jE.timestamp)} - {jE.message}
                                                    </Typography> : ''}
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </Grid>
}