import React, {useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    FormControl,
    Grid,
    IconButton,
    TextField
} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import {useTranslation} from "react-i18next";
import Modal from "../Modal";
import EditIcon from "@material-ui/icons/Edit";
import {useDispatch} from "react-redux";
import {thunkUpdateContactNote} from "../../store/actions/ContactActions";

interface EditContactNoteModalProps {
    oldNote: string,
    lastEditADM?: string,
    lastEditDate?: Date,
    stContactID: string
}


export default function EditContactNoteModal({oldNote = '', lastEditADM, lastEditDate, stContactID}: EditContactNoteModalProps) {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);

    return (
        <Modal title={t('editContactNoteModal.headline')}
               maxWidth={"md"}
               fullWidth={true}
               contentAndButtons={(handleClose) => (
                   <>
                       <DialogContent>
                           <Formik
                               initialValues={{
                                   note: oldNote
                               }}
                               onSubmit={async (values) => {
                                   setLoading(true)
                                   await dispatch(thunkUpdateContactNote(stContactID, values.note))
                                   setLoading(false)
                                   handleClose();
                               }}
                           >
                               {({
                                     values,
                                     handleChange,
                                     handleSubmit,
                                 }) => (
                                   <form id={"editContactNoteFrom"} onSubmit={handleSubmit}>
                                       <Box>
                                           <Grid container direction={"column"} spacing={1}>
                                               <Grid item>
                                                   <FormControl fullWidth>
                                                       <TextField
                                                           multiline={true}
                                                           fullWidth={true}
                                                           rows={3}
                                                           name="note"
                                                           label={t('editContactNoteModal.noteLbl')}
                                                           value={values.note}
                                                           onChange={handleChange}
                                                           required
                                                       />
                                                   </FormControl>
                                               </Grid>
                                           </Grid>
                                       </Box>
                                   </form>
                               )}
                           </Formik>
                       </DialogContent>
                       <DialogActions>
                           {loading ?
                               <div style={{paddingRight: 40}}>
                                   <CircularProgress/>
                               </div> :
                               <>
                                   <Button onClick={handleClose}>
                                       {t('common.cancelBtn')}
                                   </Button>
                                   <Button type="submit" form="editContactNoteFrom" color={"primary"}>
                                       {t('common.saveBtn')}
                                   </Button>
                               </>}

                       </DialogActions>
                   </>
               )} openButton={(handleOpen) => (
            <IconButton component="span" onClick={handleOpen}>
                <EditIcon/>
            </IconButton>
        )}/>

    );
}
