import {parseISO} from "date-fns";

export enum Phases {
    OPEN = 'Open',
    BRIEFING = 'Briefing',
    MATERIAL = 'Material',
    PRODUCTION = 'Produktion',
    REVIEW = 'Review',
    GO_LIVE = 'Go_Live',
    DONE = 'Done',
}

export enum PhaseState {
    OPEN = 'Open',
    COMPLETE = 'Complete',
    SCHEDULING = 'Scheduling',
    SCHEDULED = 'Scheduled',
    POST_PROCESSING = 'Post_Processing',
    KS_INCIDENT = 'KS_Incident',
    DS_INCIDENT = 'DS_Incident',
    TEXTING_INTERNAL = 'Texting_internal',
    TEXTING_EXTERNAL = 'Texting_external',
    TEXTING_EXTERNAL_REMINDER_1 = 'Texting_external_reminder_1',
    TEXTING_EXTERNAL_REMINDER_2 = 'Texting_external_reminder_2',
    AWAITING_CUSTOMER_INPUT = 'Awaiting_customer_input',
    AWAITING_CUSTOMER_INPUT_REMINDER_1 = 'Awaiting_customer_input_reminder_1',
    AWAITING_CUSTOMER_INPUT_REMINDER_2 = 'Awaiting_customer_input_reminder_2',
    IN_PROGRESS = 'In_progress',
    ITERATION = 'Iteration',
    DOMAIN_TRANSFER_AND_EMAIL_BACKUP = 'Domain_transfer_and_email_backup',
    ONGOING = 'Ongoing',
}


interface SalesToolProjectStatusDto {
    status: PhaseState;
    phase: Phases;
    productName: string;
    productType: string;
    lastPhaseStateUpdate: string;
    sapPosNumber: string;
}

export interface SalesToolOrderStatusDto {
    stContactId: string;
    sapCustomerID: string;
    customerName: string;
    orderId: string;
    projects: SalesToolProjectStatusDto[];
}

export interface DabDigitalOrder {
    stContactId: string;
    sapCustomerID: string;
    customerName?: string;
    projectName: string;
    orderId: string;
    posNummer: string;
    lastAction: Date;
    phase: Phases;
    status: PhaseState;
}

export class DabDigitalOrderConverter {
    static fromJson(json: SalesToolOrderStatusDto): DabDigitalOrder[] {
        return json.projects.map((project) => {
            return {
                stContactId: json.stContactId,
                customerName: json.customerName,
                projectName: project.productName,
                orderId: json.orderId,
                posNummer: project.sapPosNumber,
                lastAction: parseISO(project.lastPhaseStateUpdate),
                phase: project.phase,
                status: project.status,
                sapCustomerID: json.sapCustomerID,
            };
        });
    }
}