import {ToDo, ToDoType} from "../../store/models/ToDo";
import {isFuture, isPast} from "date-fns";

export enum MeetingListFilter {
    NONE = 'none',
    ONLY_OPEN = 'onlyOpen',
    OPEN_VISITING_REPORT = 'openVisitingReport',
    FOLLOW_UP = 'followUp',
    MEETINGS = 'meetings',
}


export const filterMap = {
    [MeetingListFilter.NONE]: (t: ToDo) => true,
    [MeetingListFilter.ONLY_OPEN]: (t: ToDo) => [ToDoType.MSOfficeAppointment,ToDoType.LegacyCrmMeeting].includes(t.type) && t.dueOn && isFuture(new Date(t.dueOn)),
    [MeetingListFilter.OPEN_VISITING_REPORT]: (t: ToDo) => [ToDoType.MSOfficeAppointment,ToDoType.LegacyCrmMeeting].includes(t.type) && t.dueOn && isPast(new Date(t.dueOn)) && !t.done,
    [MeetingListFilter.FOLLOW_UP]: (t: ToDo) => [ToDoType.FollowUp,ToDoType.LegacyCrmFollowup].includes(t.type),
    [MeetingListFilter.MEETINGS]: (t: ToDo) => [ToDoType.MSOfficeAppointment,ToDoType.LegacyCrmMeeting].includes(t.type),
}
