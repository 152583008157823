import {format, parse} from "date-fns";
import i18next from "i18next";
import {fetchLocaleObjForUser} from "../util/UserUtil";


const dateFnsLocale = fetchLocaleObjForUser();

const intlLocale = dateFnsLocale.code || "de-DE";

const formatter = new Intl.NumberFormat(intlLocale, {
    style: 'currency',
    currency: 'EUR',
});

const localeToCurrencyMap = [
    {country: 'DK', currency: "DKK"},
    {country: 'CH', currency: "CHF"},
    {country: 'NO', currency: "NOK"},
    {country: 'PL', currency: "PLN"},
    {country: 'SE', currency: "SEK"},
]

export default function formatCurrencyByADMCountry(value: number | string, admCountry: string) {
    if (isNaN(Number(value))) {
        return 'invalid Value';
    }
    const currency = localeToCurrencyMap.find(v => v.country === admCountry)?.currency;
    if (currency) {
        return formatToCurrency(value, currency);
    }
    return formatter.format(Number(value));
}

export function formatToCurrency(value: number | string, currency: string) {
    if (isNaN(Number(value))) {
        return 'invalid Value';
    }
    let formatter = new Intl.NumberFormat(intlLocale, {
        style: 'currency',
        currency: currency || 'EUR',
    });
    return formatter.format(Number(value));
}

const dateFormatter = new Intl.DateTimeFormat(intlLocale, {
    year: "numeric",
    month: "long",
    day: "2-digit"
});
const dateFormatterNumbers = new Intl.DateTimeFormat(intlLocale, {
    year: "numeric",
    month: "numeric",
    day: "2-digit"
});

export function formatDashboardDate() {
    return format(Date.now(), 'iiii, dd.MM.yyy', {locale: dateFnsLocale})
}

export function formatDate(dateString: string) {
    if (dateString === '0000-00-00') {
        return '';
    }
    const date = Date.parse(dateString);
    return dateFormatter.format(date)
}

export function formatDateObj(date: Date) {
    try {
        return dateFormatter.format(new Date(date));
    } catch (e) {
        return ""
    }
}

export function formatDateObjWithDayOfWeek(date: Date) {
    return format(new Date(date), 'iii, dd.MM.yyyy', {locale: dateFnsLocale})
}

export function formatDateObjAsNumbers(date: Date) {
    if (!date) return "";
    return dateFormatterNumbers.format(new Date(date));
}


export function formatDateForURL(date: Date) {
    return format(date, 'dd-MM-yyyy', {locale: dateFnsLocale})
}

export function parseDateFromURL(dateString: string) {
    return parse(dateString, 'dd-MM-yyyy', new Date());
}

export function formatDateTimeOnly(date: Date) {
    if(date){
        return format(new Date(date), 'HH:mm', {locale: dateFnsLocale})
    } else {
        return '';
    }
}

export function formatDateOnly(date?: Date) {
    if (date) {
        return format(new Date(date), 'dd.MM.yyyy', {locale: dateFnsLocale});
    } else {
        return '';
    }
}

export function formateDateWithTime(date: Date) {
    return format(date, 'dd.MM.yyyy HH:mm', {locale: dateFnsLocale})
}


export const getMonthToStringArr = () => {
    return [
        i18next.t('common.months.january'),
        i18next.t('common.months.february'),
        i18next.t('common.months.march'),
        i18next.t('common.months.april'),
        i18next.t('common.months.may'),
        i18next.t('common.months.june'),
        i18next.t('common.months.july'),
        i18next.t('common.months.august'),
        i18next.t('common.months.september'),
        i18next.t('common.months.october'),
        i18next.t('common.months.november'),
        i18next.t('common.months.december'),
    ]
};

