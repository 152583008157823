import {parseISO} from "date-fns";

interface CustomerSurveyDto {
    customerSurveyID: string;
    name: string;
    active: boolean;
}

interface CustomerSurveyEntryDto {
    customerSurveyEntryNumber: string;
    forCustomer: string;
    note: string;
    result: number;
    byAdm: string;
    created: string;
    sentEvaluationLink: boolean;
}


class CustomerSurveyEntry {
    customerSurveyEntryNumber: string;
    forCustomer: string;
    note: string;
    result: number;
    byAdm: string;
    created: Date;
    sentEvaluationLink: boolean;


    constructor({customerSurveyEntryNumber, forCustomer, note, result, byAdm, created,sentEvaluationLink}: CustomerSurveyEntryDto) {
        this.customerSurveyEntryNumber = customerSurveyEntryNumber;
        this.forCustomer = forCustomer;
        this.note = note;
        this.result = result;
        this.byAdm = byAdm;
        this.created = parseISO(created);
        this.sentEvaluationLink = sentEvaluationLink;
    }
}

export interface CustomerSurveyForCustomerDto {
    survey: CustomerSurveyDto;
    entries: CustomerSurveyEntryDto[]
}

export class CustomerSurveyForCustomer {
    survey: CustomerSurveyDto;
    entries: CustomerSurveyEntry[]


    constructor({survey, entries}: CustomerSurveyForCustomerDto) {
        this.survey = survey;
        this.entries = entries.map(e => new CustomerSurveyEntry(e));
    }
}