import {useSelector} from "react-redux";
import IStore from "../store/models/IStore";

export default function useAdmCountry(admID: string) {
    const adms = useSelector((store: IStore) => store.adms.adms)
    const adm = adms.find(aD => aD.sapID === admID);
    if (adm && adm.weAddress) {
        return adm.weAddress.country
    } else {
        return '';
    }
}