import {parseISO} from "date-fns";
import {parseISODateOrUndefined} from "../../util/DateUtil";

export class OrderPositionConfiguration {
    nrWF: string;
    nameWF: string;
    keyTextcode: string;
    textcode: string;
    keyVeredelung: string;
    veredelung: string;
    anzahlFarben: string;
    drkfarben: string[];
    sonderfarben: string[];


    constructor({
                    nrWF,
                    nameWF,
                    keyTextcode,
                    textcode,
                    keyVeredelung,
                    veredelung,
                    anzahlFarben,
                    drkfarben,
                    sonderfarben
                }: OrderPositionConfigurationDTO) {
        this.nrWF = nrWF;
        this.nameWF = nameWF;
        this.keyTextcode = keyTextcode;
        this.textcode = textcode;
        this.keyVeredelung = keyVeredelung;
        this.veredelung = veredelung;
        this.anzahlFarben = anzahlFarben;
        this.drkfarben = drkfarben;
        this.sonderfarben = sonderfarben;
    }
}


export interface OrderPositionConfigurationDTO {
    nrWF: string;
    nameWF: string;
    keyTextcode: string;
    textcode: string;
    keyVeredelung: string;
    veredelung: string;
    anzahlFarben: string;
    drkfarben: string[];
    sonderfarben: string[];
}

export const emptyOrderPositionConfiguration: OrderPositionConfiguration = new OrderPositionConfiguration({
    "nrWF": "",
    "nameWF": "",
    "keyTextcode": "",
    "textcode": "",
    "keyVeredelung": "",
    "veredelung": "",
    "anzahlFarben": "",
    "drkfarben": [],
    "sonderfarben": []
})

export class OrderPosition {
    posNummer: string;
    posTyp: string;
    materialNummer: string;
    materialBezeichnung: string;
    menge: string;
    mengenEinheit: string;
    artikelFarbe: string;
    linie: string;
    terminart: string;
    wunschlieferDatum?: Date;
    gesamtStatusPos: string;
    WarenausgangIST?: Date;
    liefermenge: string;
    EKSSperrdatum: string;
    EKSSperrgrund: string;
    artikelwert: string;
    artikelpreisStueck: string;
    posNettowert: number;
    NettoStueckPreis: string;
    zusatzKosten: string;
    bruttoWertPos: string;
    bruttoStueckPreis: string;
    provisionsAE: string;
    provFaktorESO: string;
    kennzeichenZA: string;
    kundenschutzverletzung: string;
    FKBezirk: string;
    qMeldungsnummer: string;
    fashionGroessen: string[]
    anzahlWerbefelder: string;
    konfiguration: OrderPositionConfiguration[];
    absagegrund: string;
    absagegrundBezeichnung: string;
    groessenSetSendedatum: string;
    hauptPosNr: string;

    constructor({
                    posNummer,
                    posTyp,
                    materialNummer,
                    materialBezeichnung,
                    menge,
                    mengenEinheit,
                    artikelFarbe,
                    linie,
                    terminart,
                    wunschlieferDatum,
                    gesamtStatusPos,
                    WarenausgangIST,
                    liefermenge,
                    EKSSperrdatum,
                    EKSSperrgrund,
                    artikelwert,
                    artikelpreisStueck,
                    posNettowert,
                    NettoStueckPreis,
                    zusatzKosten,
                    bruttoWertPos,
                    bruttoStueckPreis,
                    provisionsAE,
                    provFaktorESO,
                    kennzeichenZA,
                    kundenschutzverletzung,
                    FKBezirk,
                    qMeldungsnummer,
                    fashionGroessen,
                    anzahlWerbefelder,
                    konfiguration,
                    absagegrund,
                    absagegrundBezeichnung,
                    groessenSetSendedatum,
                    hauptPosNr
                }: OrderPositionDTO) {
        this.posNummer = posNummer;
        this.posTyp = posTyp;
        this.materialNummer = materialNummer;
        this.materialBezeichnung = materialBezeichnung;
        this.menge = menge;
        this.mengenEinheit = mengenEinheit;
        this.artikelFarbe = artikelFarbe;
        this.linie = linie;
        this.terminart = terminart;
        this.wunschlieferDatum = parseISODateOrUndefined(wunschlieferDatum);
        this.gesamtStatusPos = gesamtStatusPos;
        this.WarenausgangIST = parseISODateOrUndefined(WarenausgangIST);
        this.liefermenge = liefermenge;
        this.EKSSperrdatum = EKSSperrdatum;
        this.EKSSperrgrund = EKSSperrgrund;
        this.artikelwert = artikelwert;
        this.artikelpreisStueck = artikelpreisStueck;
        this.posNettowert = posNettowert;
        this.NettoStueckPreis = NettoStueckPreis;
        this.zusatzKosten = zusatzKosten;
        this.bruttoWertPos = bruttoWertPos;
        this.bruttoStueckPreis = bruttoStueckPreis;
        this.provisionsAE = provisionsAE;
        this.provFaktorESO = provFaktorESO;
        this.kennzeichenZA = kennzeichenZA;
        this.kundenschutzverletzung = kundenschutzverletzung;
        this.FKBezirk = FKBezirk;
        this.qMeldungsnummer = qMeldungsnummer;
        this.fashionGroessen = fashionGroessen;
        this.anzahlWerbefelder = anzahlWerbefelder;
        this.konfiguration = konfiguration;
        this.absagegrund = absagegrund;
        this.absagegrundBezeichnung = absagegrundBezeichnung;
        this.groessenSetSendedatum = groessenSetSendedatum;
        this.hauptPosNr = hauptPosNr;
    }
}


export interface OrderPositionDTO {
    posNummer: string;
    posTyp: string;
    materialNummer: string;
    materialBezeichnung: string;
    menge: string;
    mengenEinheit: string;
    artikelFarbe: string;
    linie: string;
    terminart: string;
    wunschlieferDatum: string;
    gesamtStatusPos: string;
    WarenausgangIST: string;
    liefermenge: string;
    EKSSperrdatum: string;
    EKSSperrgrund: string;
    artikelwert: string;
    artikelpreisStueck: string;
    posNettowert: number;
    NettoStueckPreis: string;
    zusatzKosten: string;
    bruttoWertPos: string;
    bruttoStueckPreis: string;
    provisionsAE: string;
    provFaktorESO: string;
    kennzeichenZA: string;
    kundenschutzverletzung: string;
    FKBezirk: string;
    qMeldungsnummer: string;
    fashionGroessen: string[],
    anzahlWerbefelder: string;
    konfiguration: OrderPositionConfigurationDTO[];
    absagegrund: string;
    absagegrundBezeichnung: string;
    groessenSetSendedatum: string;
    hauptPosNr: string;
}

const emptyOrderPositionDTO = {
    "posNummer": "",
    "posTyp": "",
    "materialNummer": "",
    "materialBezeichnung": "",
    "menge": "",
    "mengenEinheit": "",
    "artikelFarbe": "",
    "linie": "",
    "terminart": "",
    "wunschlieferDatum": "",
    "gesamtStatusPos": "",
    "WarenausgangIST": "",
    "liefermenge": "",
    "EKSSperrdatum": "",
    "EKSSperrgrund": "",
    "artikelwert": "",
    "artikelpreisStueck": "",
    "posNettowert": 0,
    "NettoStueckPreis": "",
    "zusatzKosten": "",
    "bruttoWertPos": "",
    "bruttoStueckPreis": "",
    "provisionsAE": "",
    "provFaktorESO": "",
    "kennzeichenZA": "",
    "kundenschutzverletzung": "",
    "FKBezirk": "",
    "qMeldungsnummer": "",
    "fashionGroessen": [],
    "anzahlWerbefelder": "",
    "konfiguration": [emptyOrderPositionConfiguration],
    "absagegrund": "",
    "absagegrundBezeichnung": "",
    "groessenSetSendedatum": "",
    "hauptPosNr": ""
}

export const emptyOrderPosition: OrderPosition = new OrderPosition(emptyOrderPositionDTO)


export default class Order {
    auftragsNummer: string;
    kundenNummer: string;
    bestellNummer: string;
    bestellDatum: Date;
    auftragsArt: string;
    auftragsGrund: string;
    VB1: string;
    VB2: string;
    support: string;
    teamTerminierer: string;
    innendienst: string;
    auftragsNettowert: string;
    auftragsBruttowert: string;
    waehrung: string;
    gesamtStatus: string;
    nameBesteller: string;
    telefonBesteller: string;
    positions: OrderPosition[]


    constructor({
                    auftragsNummer,
                    kundenNummer,
                    bestellNummer,
                    bestellDatum,
                    auftragsArt,
                    auftragsGrund,
                    VB1,
                    VB2,
                    support,
                    teamTerminierer,
                    innendienst,
                    auftragsNettowert,
                    auftragsBruttowert,
                    waehrung,
                    gesamtStatus,
                    nameBesteller,
                    telefonBesteller,
                    positions
                }: OrderDTO) {
        this.auftragsNummer = auftragsNummer;
        this.kundenNummer = kundenNummer;
        this.bestellNummer = bestellNummer;
        this.bestellDatum = parseISO(bestellDatum);
        this.auftragsArt = auftragsArt;
        this.auftragsGrund = auftragsGrund;
        this.VB1 = VB1;
        this.VB2 = VB2;
        this.support = support;
        this.teamTerminierer = teamTerminierer;
        this.innendienst = innendienst;
        this.auftragsNettowert = auftragsNettowert;
        this.auftragsBruttowert = auftragsBruttowert;
        this.waehrung = waehrung;
        this.gesamtStatus = gesamtStatus;
        this.nameBesteller = nameBesteller;
        this.telefonBesteller = telefonBesteller;
        this.positions = positions.map(p=> new OrderPosition(p));
    }
}

export interface OrderDTO {
    auftragsNummer: string;
    kundenNummer: string;
    bestellNummer: string;
    bestellDatum: string;
    auftragsArt: string;
    auftragsGrund: string;
    VB1: string;
    VB2: string;
    support: string;
    teamTerminierer: string;
    innendienst: string;
    auftragsNettowert: string;
    auftragsBruttowert: string;
    waehrung: string;
    gesamtStatus: string;
    nameBesteller: string;
    telefonBesteller: string;
    positions: OrderPositionDTO[]
}

export const emptyOrder: Order = new Order({
    "auftragsNummer": "",
    "kundenNummer": "",
    "bestellNummer": "",
    "bestellDatum": "2020-09-18T14:15:17.7264107Z",
    "auftragsArt": "",
    "auftragsGrund": "",
    "VB1": "",
    "VB2": "",
    "support": "",
    "teamTerminierer": "",
    "innendienst": "",
    "auftragsNettowert": "",
    "auftragsBruttowert": "",
    "waehrung": "",
    "gesamtStatus": "",
    "nameBesteller": "",
    "telefonBesteller": "",
    "positions": [emptyOrderPositionDTO]
})
