import React from 'react';
import {Avatar, Box, Button, DialogActions, FormControl, Grid, TextField, Typography} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {emptyContactDetailSearch} from "../store/models/ContactDetailSearch";
import {thunkSaveNewContactList} from "../store/actions/ContactListActions";
import Modal from "./Modal";
import {Autocomplete} from "@material-ui/lab";
import IStore from "../store/models/IStore";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {makeStyles} from "@material-ui/core/styles";
import {blue} from "@material-ui/core/colors";

interface AddContactsToContactListModalProps {
    selectedContactIds?: string[]
}

const useStyles = makeStyles((theme) => {
    return ({
        button: {
            margin: theme.spacing(1),
        },
        container: {
            margin: theme.spacing(1, 1, 3, 1)
        },
        paper: {
            padding: theme.spacing(1, 3),
            margin: theme.spacing(3, 0),
            minHeight: '50%'
        },
        avatar: {
            backgroundColor: blue[300],
        }
    });
});

export default function AddContactsToContactListModal({selectedContactIds = []}: AddContactsToContactListModalProps) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes=useStyles();
    const contactLists = useSelector((state: IStore) => state.contactLists.contactLists);
    const contactListsOptions = contactLists.filter(cL => cL.selectedContactIds && cL.selectedContactIds.length > 0);
    return (
        <Modal title={t('addContactsToContactListModal.headline')}
               contentAndButtons={(handleClose) => (
                   <>
                       <DialogContent>
                           <Formik
                               initialValues={{
                                   name: '',
                                   query: emptyContactDetailSearch,
                                   selectedContactIds,
                                   contactListID: ''
                               }}
                               onSubmit={(values) => {
                                   const contactList = contactLists.find(cL => cL.contactListID === values.contactListID || cL.name === values.name);
                                   if (contactList && contactList.name === values.name) {
                                       const idSet = new Set<string>([...contactList.selectedContactIds, ...values.selectedContactIds]);
                                       values.selectedContactIds = Array.from(idSet.values());
                                       values.contactListID = contactList.contactListID ? contactList.contactListID : '';
                                   } else {
                                       values.contactListID = '';
                                   }
                                   dispatch(thunkSaveNewContactList(values))
                                   handleClose();
                               }}
                           >
                               {({
                                     values,
                                     handleChange,
                                     handleSubmit,
                                     setFieldValue
                                 }) => (
                                   <form id={"saveAsContactListForm"} onSubmit={handleSubmit}>
                                       <Box>
                                           <Grid container direction={"column"} spacing={1}>
                                               <Grid item>
                                                   <Typography>{t('addContactsToContactListModal.saveOrEditDescription')}</Typography>
                                               </Grid>
                                               <Grid item>
                                                   <FormControl fullWidth>
                                                       <Autocomplete
                                                           freeSolo
                                                           disableClearable
                                                           fullWidth
                                                           getOptionLabel={(option: any) => {
                                                               return option.hasOwnProperty("name") ? option.name : option;
                                                           }}
                                                           onChange={(event, option: any) => {
                                                               setFieldValue('name', option.hasOwnProperty("name") ? option.name : option)
                                                               setFieldValue('contactListID', option.hasOwnProperty("contactListID") ? option.contactListID : '')
                                                           }}
                                                           options={contactListsOptions}
                                                           renderInput={(params) => (
                                                               <TextField
                                                                   {...params}
                                                                   name="name"
                                                                   label={t('addContactsToContactListModal.listNameLbl')}
                                                                   value={values.name}
                                                                   fullWidth
                                                                   onChange={handleChange}
                                                               />
                                                           )}
                                                       />
                                                   </FormControl>
                                               </Grid>
                                           </Grid>
                                       </Box>
                                   </form>
                               )}
                           </Formik>
                       </DialogContent>
                       <DialogActions>
                           <Button onClick={handleClose}>
                               {t('common.cancelBtn')}
                           </Button>
                           <Button type="submit" form="saveAsContactListForm" color={"primary"}>
                               {t('common.saveBtn')}
                           </Button>
                       </DialogActions>
                   </>
               )} openButton={(handleOpen) => (
            <Button
                variant="text"
                onClick={handleOpen}
                className={classes.button}
                startIcon={<Avatar className={classes.avatar}><SaveAltIcon/></Avatar>}
            >
                {t('addContactsToContactListModal.addContactList')}
            </Button>
        )}/>

    );
}
