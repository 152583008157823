import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite'
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
    IconButton,
    Grid,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
} from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Navigation from "../components/Navigation";
import ProductUserMenu from "../components/ProductUserMenu";
import Api from "../store/Api";


const useStyles = makeStyles((_theme: Theme) => ({
    productCard: {

    },
    offerTableHeadCell: {
        // backgroundColor: grey[700],
        // color: theme.palette.common.white
    },
    offerTableRow: {
        cursor: "pointer",
    },
    offerTableActionsCont: {
        position: "relative",
        justifyContent: "space-between",
    },
    offerTableRemoveBtn: {
        position: "absolute",
        top: 0,
        right: 0,
    }
}));

export default observer(() => {
    const classes = useStyles();
    const [offers, setOffers] = useState<any[]>([]);
    const handleRemove = async (id: any) => {
        setOffers(offers.filter(o => o.id !== id));
        await Api.removeProductOffer(id);
    }

    useEffect(() => {
        Api.getProductOffers().then(data => setOffers(data));
    }, []);

    return (
        <Navigation breadcrumbName={'Meine Angebote'}>
            <Grid container spacing={3} >
                <Grid container item justifyContent="flex-end">
                    <ProductUserMenu />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>nr</b></TableCell>
                                    <TableCell><b>Bezeichnung</b></TableCell>
                                    <TableCell><b>Firma</b></TableCell>
                                    <TableCell><b>erstellt</b></TableCell>
                                    <TableCell><b>versendet</b></TableCell>
                                    <TableCell><b>Positionen</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offers.map(o => {
                                    return (
                                        <TableRow key={o.id} hover={true} className={classes.offerTableRow}
                                            onClick={() => document.location.assign(`/offer/${o.id}`)}
                                        >
                                            <TableCell component="th" scope="row">{o.id}</TableCell>
                                            <TableCell><b>{o.title ?? `Angebot Nr. ${o.id}`}</b></TableCell>
                                            <TableCell>{o.data?.clientData?.company}</TableCell>
                                            <TableCell>{o['created_on']}</TableCell>
                                            <TableCell>{o['sent_on']}</TableCell>
                                            <TableCell className={classes.offerTableActionsCont}>
                                                {o.data?.positions?.length}
                                                <IconButton className={classes.offerTableRemoveBtn} onClick={ev => {
                                                    ev.stopPropagation();
                                                    handleRemove(o.id);
                                                }}>
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Navigation>
    )
})
