import React, {useState} from 'react';
import ContactPerson from "../../store/models/ContactPerson";
import EditIcon from "@material-ui/icons/Edit";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch} from "react-redux";
import {
    contactPersonDepartmentOptions,
    contactPersonFunctionOptions,
    contactPersonSalutationOptions
} from "../mappedSelectOptions";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';

interface ContactPersonEditModalProps {
    contactPerson: ContactPerson
    headline: string;
    action: (contactPerson: ContactPerson) => void;
    isEdit: boolean;
}

export default function ContactPersonEditModal({contactPerson, isEdit, headline, action}: ContactPersonEditModalProps) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    return (
        <Modal title={headline} contentAndButtons={(handleClose) => (
            <>
                <DialogContent>
                    <Formik
                        initialValues={contactPerson}
                        onSubmit={async (values) => {
                            setLoading(true)
                            await dispatch(action(values))
                            setLoading(false);
                            handleClose();
                        }}
                        validationSchema={Yup.object().shape({
                            emailAddress: Yup.string()
                                .email(t('validation.email')),
                            lastname: Yup.string()
                                .required(t('validation.required')),
                        })}

                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit,
                              errors,
                              touched
                          }) => (
                            <form id="contactPersonEditForm" onSubmit={handleSubmit}>
                                <Box m={3}>
                                    <Grid container spacing={5}>
                                        <Grid item md={3}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('contactPersonEditModal.salutationLbl')}</InputLabel>
                                                <Select
                                                    name="salutation"
                                                    value={values.salutation}
                                                    onChange={handleChange}
                                                >
                                                    {contactPersonSalutationOptions().map(sO => <MenuItem
                                                        key={'salutationOption' + sO.label}
                                                        value={sO.value}>{sO.label}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl>
                                                <TextField
                                                    name="firstname"
                                                    label={t('contactPersonEditModal.firstNameLbl')}
                                                    value={values.firstname}
                                                    onChange={handleChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={5}>
                                            <FormControl>
                                                <TextField
                                                    name="lastname"
                                                    label={t('contactPersonEditModal.lastNameLbl')}
                                                    value={values.lastname}
                                                    onChange={handleChange}
                                                    error={(!!errors.lastname && touched.lastname)}
                                                    helperText={(errors.lastname && touched.lastname) && errors.lastname}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('contactPersonEditModal.functionLbl')}</InputLabel>
                                                <Select
                                                    name="function"
                                                    value={values.function}
                                                    onChange={handleChange}
                                                >
                                                    {contactPersonFunctionOptions().map(fO => <MenuItem
                                                        key={'functionOption' + fO.label}
                                                        value={fO.value}>{fO.label}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('contactPersonEditModal.departmentLbl')}</InputLabel>
                                                <Select
                                                    name="department"
                                                    value={values.department}
                                                    onChange={handleChange}
                                                >
                                                    {contactPersonDepartmentOptions().map(dO => <MenuItem
                                                        key={'departmentOption' + dO.label}
                                                        value={dO.value}>{dO.label}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="telephoneNumber"
                                                    label={t('contactPersonEditModal.telephoneNumberLbl')}
                                                    value={values.telephoneNumber}
                                                    onChange={handleChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="mobileNumber"
                                                    label={t('contactPersonEditModal.mobileNumberLbl')}
                                                    value={values.mobileNumber}
                                                    onChange={handleChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name="emailAddress"
                                                    label={t('contactPersonEditModal.emailAddressLbl')}
                                                    value={values.emailAddress}
                                                    onChange={handleChange}
                                                    error={(!!errors.emailAddress && touched.emailAddress)}
                                                    helperText={(errors.emailAddress && touched.emailAddress) && errors.emailAddress}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControl component="fieldset" fullWidth>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={values.hasNewsletter}
                                                                           color={"primary"}
                                                                           onChange={handleChange}
                                                                           name="hasNewsletter"/>}
                                                        label={t('contactPersonEditModal.hasNewsletter')}
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}

                    </Formik>
                </DialogContent>
                <DialogActions>
                    {loading ?
                        <div style={{paddingRight: 40}}>
                            <CircularProgress/>
                        </div> :
                        <>
                            <Button onClick={handleClose}>
                                {t('common.cancelBtn')}
                            </Button>

                            <Button form="contactPersonEditForm" type="submit" color={"primary"}>
                                {t('common.saveBtn')}
                            </Button>
                        </>
                    }
                </DialogActions>
            </>
        )} openButton={(handleOpen) => (
            <IconButton component="span" onClick={handleOpen}>
                {
                    isEdit ? <EditIcon/> : <AddCircleIcon fontSize={"large"} color={"primary"}/>
                }
            </IconButton>
        )}/>
    );
}
