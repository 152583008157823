import React, {useState} from 'react';
import {Autocomplete} from "@material-ui/lab";
import {Chip, Grid, IconButton, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import AddCircleIcon from "@material-ui/icons/AddCircle";

interface EmailPickerProps {
    emails: string[],
    updateValue: (mails: string[]) => void,
    prefillEmails?: string[],
}

export default function EmailPicker(props: EmailPickerProps) {
    const {t} = useTranslation();

    const [newEmail, setNewEmail] = useState('');
    //ugly hack,dont ask...
    const [autocompleteReset, setAutocompleteReset] = useState(1);

    const [selectedMails, setSelectedMails] = useState<string[]>(props.prefillEmails || [])

    const handleDelete = (mail: string) => {
        let mails = selectedMails.filter(m => m !== mail);
        setSelectedMails(mails);
        props.updateValue(mails)
    }

    const handleAddMail = () => {
        const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (validEmail.test(newEmail)) {
            if (!selectedMails.includes(newEmail)) {
                const newMails = [...selectedMails, newEmail];
                setSelectedMails(newMails)
                props.updateValue(newMails)
            }
            setAutocompleteReset(autocompleteReset + 1);
        }
    }

    return <Grid container spacing={3}>
        <Grid item xs={6}>
            <Autocomplete
                key={autocompleteReset}
                freeSolo
                options={props.emails}
                onChange={(event, newValue) => {
                    if (newValue) {
                        setNewEmail(newValue)
                    }
                }}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        handleAddMail();
                        event.preventDefault();
                    }
                }}
                autoComplete={false}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            type={'email'}
                            autoComplete="false"
                            onChange={(event: any) => {
                                setNewEmail(event.currentTarget.value)
                            }}
                            label={t('emailPicker.formAttendeesLbl')}
                            variant={"outlined"}
                        />
                    )
                }}
            />
        </Grid>
        <Grid item xs={2}>
            <IconButton component="span" onClick={handleAddMail}>
                <AddCircleIcon fontSize={"large"} color={"primary"}/>
            </IconButton>
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={t('emailPicker.formAttendeesChosenLbl')}
                variant={"outlined"}
                disabled={true}
                fullWidth
                InputProps={{
                    startAdornment: (<>
                        {selectedMails.map(mail =>
                            <Chip
                                key={'mailChip' + mail}
                                label={mail}
                                clickable
                                onDelete={() => handleDelete(mail)}
                            />
                        )
                        }
                        <span style={{width: 5}}>&nbsp;</span>
                    </>)
                }}
            />

        </Grid>
    </Grid>
}
