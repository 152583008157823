import React from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import Contact from "../store/models/Contact";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import AssignContactsToAdmModal from "./AssignContactsToAdmModal";

const useStyles = makeStyles((theme) => ({
    paperWithHeader: {
        padding: theme.spacing(1, 3, 3, 3)
    },

}));


interface AssignContactToAdmProps {
    contact: Contact
}

export default function AssignContactToAdm({contact}: AssignContactToAdmProps) {
    const classes = useStyles();
    const {t} = useTranslation();

    const mySubordinatedAdms = useSelector((store: IStore) => store.adms.mySubordinatedAdms);

    return <Grid item xs={12}>
        <Paper className={classes.paperWithHeader}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant={"h6"}
                                style={{paddingTop: 13}}>{t('assignContactToAdm.headline')}</Typography>
                </Grid>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <AssignContactsToAdmModal adms={mySubordinatedAdms}
                                              selectedContactIds={[contact.stContactID]}/>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}
