import React, {useState} from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {Formik} from "formik";
import {useDispatch} from "react-redux";
import {yesNoEmptyOptions} from "../mappedSelectOptions";
import ContactDetailSearch, {emptyContactDetailSearch} from "../../store/models/ContactDetailSearch";
import {thunkDetailSearchContacts} from "../../store/actions/ContactActions";
import {useTranslation} from "react-i18next";
import IndustryCodeDropdown from "../contactDetail/IndustryCodeDropdown";
import {makeStyles} from "@material-ui/core/styles";
import ProductItemListDropdown from "./ProductItemListDropdown";

interface ContactDetailSearchModalProps {
    currentDetailSearch?: ContactDetailSearch;
    searchAction?: (searchValues: ContactDetailSearch) => Promise<void>
}

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
    }
}));

export default function ContactSearchPanel({currentDetailSearch, searchAction}: ContactDetailSearchModalProps) {
    const dispatch = useDispatch();

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {t} = useTranslation();

    return (
        <Formik
            initialValues={currentDetailSearch || emptyContactDetailSearch}
            onSubmit={async (values) => {
                setIsLoading(true);
                if (searchAction) {
                    await searchAction(values)
                } else {
                    await dispatch(thunkDetailSearchContacts({
                        ...values,
                        geoRestriction: {}
                    }, 1))
                }
                setIsLoading(false);
            }}
        >
            {({
                  values,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}
                      onReset={() => resetForm({values: emptyContactDetailSearch})}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <Typography
                                    variant={"h6"}>{t('contactSearchPanel.headline')}</Typography>
                            </Grid>
                            <Grid item md={12} container spacing={2}>
                                <Grid item md={8}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="companyLine"
                                            label={t('contactSearchPanel.companyLineLbl')}
                                            value={values.companyLine}
                                            variant={"outlined"}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="sapCustomerID"
                                            label={t('contactSearchPanel.sapCustomerIDLbl')}
                                            value={values.sapCustomerID}
                                            onChange={handleChange}
                                            variant={"outlined"}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item md={12} spacing={2}>
                                <Grid item md={5}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="address.street"
                                            label={t('contactSearchPanel.streetLbl')}
                                            value={values.address.street}
                                            onChange={handleChange}
                                            variant={"outlined"}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="address.zipCode"
                                            label={t('contactSearchPanel.zipCodeLbl')}
                                            value={values.address.zipCode}
                                            onChange={handleChange}
                                            variant={"outlined"}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="address.place"
                                            label={t('contactSearchPanel.placeLbl')}
                                            value={values.address.place}
                                            onChange={handleChange}
                                            variant={"outlined"}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item md={12} spacing={2}>
                                <Grid item md={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="contactDetails.emailAddress"
                                            label={t('contactSearchPanel.emailAddressLbl')}
                                            value={values.contactDetails.emailAddress}
                                            onChange={handleChange}
                                            variant={"outlined"}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="contactDetails.url"
                                            label={t('contactSearchPanel.urlLbl')}
                                            value={values.contactDetails.url}
                                            onChange={handleChange}
                                            variant={"outlined"}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={2}>
                                    <IndustryCodeDropdown name={'industryCode'}
                                                          variant={"outlined"}
                                                          setValue={(code?: string) => setFieldValue('industryCode', code)}
                                                          value={values.industryCode}/>
                                </Grid>
                            </Grid>
                            <Grid container item md={12} spacing={2}>
                                <Grid item md={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.myCustomers} onChange={handleChange}
                                                           name="myCustomers" color={"primary"}/>}
                                        label={t('contactSearchPanel.statusMyCustomersLbl')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.assignedCustomers} onChange={handleChange}
                                                           name="assignedCustomers" color={"primary"}/>}
                                        label={t('contactSearchPanel.statusAssignedLbl')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.greyCustomers} onChange={handleChange}
                                                           name="greyCustomers" color={"primary"}/>}
                                        label={t('contactSearchPanel.statusGreyCustomersLbl')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.potentialCustomers} onChange={handleChange}
                                                           name="potentialCustomers" color={"primary"}/>}
                                        label={t('contactSearchPanel.statusPotentialCustomersLbl')}/>
                                </Grid>
                            </Grid>
                            <Grid container item md={12} spacing={2}>
                                <Grid item md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>&nbsp;&nbsp;{t('contactSearchPanel.nextPlanedTodoLbl')}</InputLabel>
                                        <Select
                                            name={'nextPlanedTodo'}
                                            value={values.nextPlanedTodo}
                                            onChange={handleChange}
                                            variant={"outlined"}
                                        >
                                            {yesNoEmptyOptions().map(aT => <MenuItem
                                                key={'nextPlanedTodo' + aT.label}
                                                value={aT.value}>&nbsp;{aT.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                    <FormControl fullWidth>
                                        <FormControl fullWidth>
                                            <TextField
                                                name="lastHistoryEntrySince"
                                                variant={"outlined"}
                                                label={t('contactSearchPanel.lastHistoryEntrySinceLbl')}
                                                value={values.lastHistoryEntrySince}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>&nbsp;&nbsp;{t('contactSearchPanel.hasOrdersInLastYearLbl')}</InputLabel>
                                        <Select
                                            name={'hasOrdersInLastYear'}
                                            value={values.hasOrdersInLastYear}
                                            onChange={handleChange}
                                            variant={"outlined"}
                                        >
                                            {yesNoEmptyOptions().map(aT => <MenuItem
                                                key={'hasOrdersInLastYearOptions' + aT.label}
                                                value={aT.value}>&nbsp;{aT.label}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={2}>
                                    <ProductItemListDropdown
                                        name={'hasOrderedProduct'}
                                        setValue={(consolidationNumber?: string) => setFieldValue('hasOrderedProduct', consolidationNumber)}
                                        value={values.hasOrderedProduct || ''}
                                        variant={'outlined'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item md={12} container spacing={2}>
                                <div style={{flexGrow: 1, display: 'flex'}}></div>
                                <Grid item>
                                    <Button type={'reset'}
                                            variant={"text"}
                                            color={"primary"}>
                                        {t('contactSearchPanel.resetFormBtn')}
                                    </Button>
                                    {isLoading ? <CircularProgress/> :
                                        <Button type="submit" variant={"contained"} color={"primary"}>
                                            {t('contactSearchPanel.searchBtn')}
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </form>
            )}
        </Formik>
    );
}
