import React, {useEffect, useState} from 'react';
import Navigation from "../components/Navigation";
import {CircularProgress, Grid, Typography} from "@material-ui/core";
import {thunkDetailSearchContacts, thunkSearchContacts} from "../store/actions/ContactActions";
import {useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import ContactTable from "../components/ContactTable";
import ContactLocationSearchModal from "../components/contactSearch/ContactLocationSearchModal";
import {emptyContact} from "../store/models/Contact";
import {buildUserFeedbackStringFromSearchResult} from "../util/SearchUtil";
import SaveAsContactListModal from "../components/contactSearch/SaveAsContactListModal";
import ContactEditModal from "../components/contactDetail/ContactEditModal";
import {Pagination} from "@material-ui/lab";
import {searchPaginationPageSize} from "../store/Api";
import {useTranslation} from "react-i18next";
import {thunkGetContactLists} from "../store/actions/ContactListActions";
import ContactSearchPanel from "../components/contactSearch/ContactSearchPanel";
import {emptyContactDetailSearch} from "../store/models/ContactDetailSearch";


function ContactView() {
    let searchResult = useSelector((state: IStore) => {
        return state.contact.searchResult
    });
    const lastSearchWasDetail = useSelector((state: IStore) => {
        return state.contact.lastSearchWasDetail
    });
    const totalResults = useSelector((state: IStore) => {
        return state.contact.totalResults
    });
    const withDistanceKm = useSelector((state:IStore) => state.contact.withDistanceKm);

    const {t} = useTranslation();
    const page = useSelector((state: IStore) => state.contact.page);
    const currentDetailSearch = useSelector((state: IStore) => state.contact.currentDetailSearch);
    const lastSearchTerm = useSelector((state: IStore) => state.contact.lastSearchTerm);

    const [selectedContacts, setSelectedContacts] = useState<string[]>([])

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(thunkGetContactLists())
    }, [dispatch]);

    const switchPage = async (pageNumber: number) => {
        setIsLoading(true);
        if (lastSearchWasDetail) {
            if (currentDetailSearch) {
                await dispatch(thunkDetailSearchContacts(currentDetailSearch, pageNumber))
            }
        } else {
            await dispatch(thunkSearchContacts(lastSearchTerm, pageNumber));
        }
        setIsLoading(false);
    }

    return (<>
        <Navigation breadcrumbName={t('contactView.breadcrumb')}>


            <Grid container spacing={3} direction={"column"}>

                <Grid container spacing={5} alignItems="center" justifyContent="center">
                    <Grid item>
                        <ContactSearchPanel currentDetailSearch={currentDetailSearch}/>
                    </Grid>
                    <Grid item>
                        <ContactLocationSearchModal setIsLoading={setIsLoading}
                                                    currentDetailSearch={emptyContactDetailSearch}/>
                    </Grid>
                    <div style={{flexGrow: 1, display: 'flex'}}></div>
                    <Grid item>
                        <ContactEditModal contact={emptyContact} isEdit={false}/>
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item md={8}>
                        <Typography> {buildUserFeedbackStringFromSearchResult(totalResults, lastSearchTerm, currentDetailSearch, lastSearchWasDetail, t)}</Typography>
                    </Grid>
                    <div style={{flexGrow: 1, display: 'flex'}}>
                    </div>
                    <Grid item>
                        {lastSearchWasDetail && currentDetailSearch &&
                        <SaveAsContactListModal detailSearch={currentDetailSearch}/>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {isLoading ? <CircularProgress/> :
                            <ContactTable selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts}
                                          withDistanceKm={withDistanceKm}
                                          contacts={[...searchResult]}/>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Pagination count={Math.floor(totalResults / searchPaginationPageSize) + 1} page={page}
                                    onChange={(e, page) => switchPage(page)}/>
                    </Grid>
                </Grid>
            </Grid>
        </Navigation>
    </>)
}

export default ContactView;
