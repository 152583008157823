import {parseISO} from "date-fns";


export default class DailyAdmReport {
    darNumber: string;
    note: string;
    forDate: Date;
    startDateTime: Date;
    endDateTime: Date;
    startKM: number;
    endKM: number;
    admID: string;


    constructor({darNumber,note,forDate,startDateTime,endDateTime,startKM,endKM,admID}: DailyAdmReportDTO) {
        this.darNumber = darNumber;
        this.note = note;
        this.forDate = parseISO(forDate);
        this.startDateTime = parseISO(startDateTime);
        this.endDateTime = parseISO(endDateTime);
        this.startKM = startKM;
        this.endKM = endKM;
        this.admID = admID;
    }
}
export interface DailyAdmReportDTO {
    darNumber: string;
    note: string;
    forDate: string;
    startDateTime: string;
    endDateTime: string;
    startKM: number;
    endKM: number;
    admID: string;
}
