import React from 'react';
import {Typography} from "@material-ui/core";
import i18next from "i18next";


export function mapIndustryCodeToText(code: string) {
    const identifier = 'industryCodes.' + code + '.alt0';
    const translation = i18next.t(identifier);
    return translation !== identifier ? translation : code;
}

export default function IndustryCode(props: { code?: string }) {
    return props.code ? <Typography>{mapIndustryCodeToText(props.code)}</Typography> : null
}
