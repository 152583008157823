import {parseISO} from "date-fns";

export default class Kaz {
    orderNumber: string;
    sapTsDay: Date;
    fileLocation: string;
    uri: string;
    fileNumber: number;
    sapContactID: string;
    originalFileName: string;

    constructor({orderNumber, sapTsDay, fileLocation, uri, fileNumber, sapContactID, originalFileName}: KazDTO) {
        this.orderNumber = orderNumber;
        this.sapTsDay = parseISO(sapTsDay);
        this.fileLocation = fileLocation;
        this.uri = uri;
        this.fileNumber = fileNumber;
        this.sapContactID = sapContactID;
        this.originalFileName = originalFileName;
    }
}


export interface KazDTO {
    orderNumber: string;
    sapTsDay: string;
    fileLocation: string;
    uri: string;
    fileNumber: number;
    sapContactID: string;
    originalFileName: string;
}