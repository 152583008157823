import Navigation from "../components/Navigation";
import {Box, Button, Divider, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import React, {Fragment, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {formatDateObj, getMonthToStringArr} from "../components/Formatter";
import {BusinessExpenseReportStatus} from "../store/models/BusinessExpenseReport";
import {AdmName} from "../components/AdmName";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {BusinessExpenseReportStatusLabel} from "../components/businessExpense/BusinessExpenseReportStatusLabel";
import {useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {
    thunkGetBusinessExpenseReportsForMyADMs,
    thunkUpdateBusinessExpenseReportStatusAsManager
} from "../store/actions/BusinessExpenseReportActions";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        minHeight: 600
    }
}));

export default function BusinessExpensesManagerVBList() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();

    const {forAdm, forMonth, forYear} = useParams<{forAdm:string, forMonth:string, forYear:string}>();

    const acceptReport = async (berNumber?: string) => {
        if (berNumber) {
            await dispatch(thunkUpdateBusinessExpenseReportStatusAsManager("accept", berNumber))
        }
    }
    const businessExpenseReportsForADM = useSelector((state: IStore) =>{
        return state.businessExpenseReports.managmentReports.filter(r => {
            let dateObj = new Date(r.forDate)
            return r.admID === forAdm && (dateObj.getMonth().toString() === forMonth && dateObj.getFullYear().toString() === forYear);
        })
    })

    useEffect(() => {
        dispatch(thunkGetBusinessExpenseReportsForMyADMs());
    }, [dispatch])

    const monthToString = getMonthToStringArr();

    return (<>
        <Navigation backBtn={true} breadcrumbName={''}>
            <Grid container spacing={4}>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"}><AdmName
                                    personalnummer={forAdm}/> - {monthToString[parseInt(forMonth)]} {forYear}</Typography>
                                <Typography>({forAdm})</Typography>
                            </Grid>
                            {businessExpenseReportsForADM.map(report =>
                                <Fragment key={'report' + report.berNumber}>
                                    <Grid item xs={3}>
                                        <Box fontWeight="fontWeightBold">
                                            <Typography>{formatDateObj(report.forDate)}</Typography>
                                        </Box>
                                        <Typography variant={"body2"}>{report.admID}</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography>
                                            {t('businessExpensesManagerVBList.activities', {count: report.activities.length})}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {report.managementStatus === BusinessExpenseReportStatus.REQUESTED ?
                                            <Button color={"primary"} variant={"contained"}
                                                    onClick={() => acceptReport(report.berNumber)}>{t('businessExpensesManagerVBList.acceptBtn')}</Button>
                                            : <BusinessExpenseReportStatusLabel status={report.managementStatus}/>
                                        }
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Link to={'/businessExpensesManager/detail/' + report.berNumber}>
                                            <IconButton size={"small"} color={"primary"}>
                                                <ArrowForwardIosIcon/>
                                            </IconButton>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}><Divider/></Grid>
                                </Fragment>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Navigation>
    </>)
}

