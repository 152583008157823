import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import {Grid} from "@material-ui/core";
import {AdmName} from "../AdmName";
import {formatDateObj, formatDateObjWithDayOfWeek, formatDateTimeOnly} from "../Formatter";
import MeetingIndicator from "./MeetingIndicator";
import CalendarEventMenu from "../CalendarEventMenu";
import TableRow from "@material-ui/core/TableRow";
import {useTranslation} from "react-i18next";
import {ToDo, ToDoType} from "../../store/models/ToDo";
import {fetchStatusIndicatorColor} from "../../util/ContactUtil";
import Contact from "../../store/models/Contact";

interface MeetingListRowProps {
    meeting: ToDo;
    isManagerList: boolean;
    selectedAdm?: string
    contact?: Contact;
}

export default function MeetingListRow({meeting, isManagerList, contact, selectedAdm}: MeetingListRowProps) {


    const {t} = useTranslation();

    let meetingTypeLabel = '';
    if (meeting.meetingProps?.meetingType) {
        meetingTypeLabel = t('msOfficeMeetingTypes.' + meeting.meetingProps?.meetingType);
    } else {
        if ([ToDoType.FollowUp, ToDoType.LegacyCrmFollowup, ToDoType.LegacyCrmMeeting].includes(meeting.type)) {
            meetingTypeLabel = t('meetingListRow.meetingTypes.' + meeting.type);
        }
    }


    return <TableRow key={meeting.todoNumber}>
        {isManagerList ?
            <TableCell component="th" scope="row">
                <Grid container>
                    {contact ?
                        <Grid item xs={1}>
                            <div style={{
                                height: '100%',
                                width: 5,
                                backgroundColor: fetchStatusIndicatorColor(contact, selectedAdm)
                            }}></div>
                        </Grid>
                        : null}
                    <Grid item xs={11}>
                        <AdmName personalnummer={meeting.admID}/>
                    </Grid>
                </Grid>
            </TableCell>
            : null}
        <TableCell component="th" scope="row">
            <Grid container>
                <Grid item xs={11}>
                    {meeting.dueOn ? formatDateObjWithDayOfWeek(meeting.dueOn) : ''}<br/>
                    {meeting.dueOn ? formatDateTimeOnly(meeting.dueOn) : ''}{meeting.meetingProps?.endDate ? ` - ${formatDateTimeOnly(meeting.meetingProps?.endDate)}` : ''}
                </Grid>
            </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
            <Grid container>
                <Grid item xs={11}>
                    {meeting.created ? formatDateObj(meeting.created) : ''}<br/>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
            <Grid container>
                <Grid item xs={11}>
                    {meeting.type === ToDoType.MSOfficeAppointment && meeting.meetingProps ?
                        <>
                            {meeting.meetingProps?.customerDisplayName}<br/>
                            {meeting.meetingProps?.customerPlz} {meeting.meetingProps?.customerCity}
                        </>
                        : <>{meeting.text}<br/>{contact?.address?.zipCode} {contact?.address?.place}</>}
                </Grid>
            </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
            <Grid container>
                <Grid item xs={11}>
                    {meetingTypeLabel}<br/>
                    {meeting.meetingProps?.withMail ? t('common.yes') : t('common.no')}
                </Grid>
            </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
            <Grid container>
                <Grid item xs={11}>
                    <MeetingIndicator meeting={meeting}/>
                </Grid>
            </Grid>
        </TableCell>
        <TableCell align="right">
            {isManagerList && meeting.type !== ToDoType.MSOfficeAppointment ?
                <CalendarEventMenu stContactId={meeting.forCustomer!}/>
                : <CalendarEventMenu stContactId={meeting.forCustomer!} todoToDelete={meeting}/>
            }
        </TableCell>
    </TableRow>
}
