import React from 'react';
import {TouchpointResultType} from "../../../store/models/TouchpointEnums";
import {Button, Checkbox, FormControl, FormControlLabel, Grid, TextField} from "@material-ui/core";
import TouchpointTab from "./TouchpointTab";
import {useTranslation} from "react-i18next";
import {Field} from "formik";
import {DatePicker,DateTimePicker} from "formik-material-ui-pickers";
import {ToDo, ToDoType} from "../../../store/models/ToDo";
import Contact from "../../../store/models/Contact";
import {useDispatch} from "react-redux";
import {thunkAddToDo} from "../../../store/actions/ToDoActions";
import {Note, NoteType} from "../../../store/models/Note";
import {thunkAddNote} from "../../../store/actions/NoteActions";
import {useHistory} from "react-router-dom";
import {startOfDay} from "date-fns";
import * as Yup from "yup";

interface FollowUpTabProps {
    touchpointResultType: TouchpointResultType;
    contact?: Contact;
    emailPickerMails: string[];
}

export default function FollowUpTab({touchpointResultType, contact}: FollowUpTabProps) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    return (<TouchpointTab
        tabIndex={TouchpointResultType.Follow_Up}
        activeTab={touchpointResultType}
        validationSchema={Yup.object().shape({
            startDate: Yup.string()
                .nullable()
                .required(t('validation.required')),
        })}
        handleSubmit={async (values) => {
            if(! values.withTime){
                values.startDate = startOfDay(values.startDate);
            }
            const newToDo: ToDo = {
                admID: "",
                created: new Date(),
                forCustomer: contact?.stContactID,
                dueOn: values.startDate,
                text: '' + contact?.companyLine1,
                type: ToDoType.FollowUp
            };
            const newNote: Note = {
                contactNumber: contact?.stContactID || '',
                created: new Date(),
                private: false,
                text: values.note,
                type: NoteType.TouchpointResult,
                meetingDetails: {
                    meetingStartDate: values.startDate,
                    todoType: ToDoType.FollowUp
                },
                touchpointResultType
            }
            await dispatch(thunkAddNote(newNote));
            await dispatch(thunkAddToDo(newToDo));
            history.goBack();
        }}
        initialValues={{
            note: t('contactDetailTouchpointTab.followUpNoteDefaultValue'),
            startDate: null,
            withTime: false
        }}
    >
        {((values, handleChange, setFieldValue, isSubmitting,errors,touched) =>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.withTime}
                                    onChange={handleChange}
                                    name="withTime"
                                    color="primary"
                                />
                            }
                            label={t('contactDetailTouchpointTab.followUpWithTime')}
                        />
                        <br/>
                        {values.withTime ?
                            <Field
                                component={DateTimePicker}
                                inputVariant={"outlined"}
                                name="startDate"
                                value={values.startDate}
                                label={t('contactDetailTouchpointTab.formStartLbl')}
                                format={'dd.MM.yyyy HH:mm'}
                                ampm={false}
                                required
                                error={!!errors.startDate && touched.startDate}
                                helperText={(errors.startDate && touched.startDate) && errors.startDate}
                            />
                            :
                            <Field
                                component={DatePicker}
                                inputVariant={"outlined"}
                                name="startDate"
                                value={values.startDate}
                                label={t('contactDetailTouchpointTab.formStartLbl')}
                                format={'dd.MM.yyyy'}
                                ampm={false}
                                required
                                error={!!errors.startDate && touched.startDate}
                                helperText={(errors.startDate && touched.startDate) && errors.startDate}
                            />
                        }

                    </Grid>

                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField
                                multiline={true}
                                rows={3}
                                variant={"outlined"}
                                name="note"
                                label={t('contactDetailTouchpointTab.noteLbl')}
                                value={values.note}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={"contained"} color={"primary"}
                                type={"submit"}>{t('common.saveBtn')}</Button>
                    </Grid>
                </Grid>
        )}
    </TouchpointTab>)
}
