import {TouchpointResultType} from "./TouchpointEnums";
import {parseISODateOrUndefined} from "../../util/DateUtil";
import {parseISO} from "date-fns";
import {MeetingType} from "./IMeeting";
import {ToDoType} from "./ToDo";

export enum NoteType {
    VisitingReport = 'VisitingReport',
    Custom = 'Custom',
    TouchpointResult = 'TouchpointResult',
    MEETING_DELETED = 'MEETING_DELETED',
    LEGACY_CRM_CONTACTS = 'LEGACY_CRM_CONTACTS',
    LEGACY_CRM_NOTE = 'LEGACY_CRM_NOTE',
    FromOldCrm = 'FromOldCrm'
}

export enum ProductTypesPresented {

    BAG_PRODUCTS = 'bagProducts',
    DIGITAL_PRODUCTS = 'digitalProducts',
    FASHION = 'fashion',
    ESO = 'eso',
    OFFICE_EQUIPMENT = 'officeEquipment',
}

export enum VisitingReportResults {
    OFFER = 'offer',
    SAMPLE_SENT = 'sample_sent',
    ORDER = 'order',
    NO_INTEREST = 'no_interest',
    NEW_APPOINTMENT = 'new_appointment',
    NOT_REACHED = 'not_reached',
    MEETING_CANCELED = 'meeting_canceled',
    AFTERSALES = 'aftersales'
}

export interface VisitingReportPropsDTO {
    gotRecommendations?: boolean;
    productTypesPresented: ProductTypesPresented[];
    visitResult: VisitingReportResults
}

export class VisitingReportProps {
    gotRecommendations?: boolean;
    productTypesPresented: ProductTypesPresented[];
    visitResult: VisitingReportResults

    constructor({gotRecommendations, productTypesPresented, visitResult}: VisitingReportPropsDTO) {
        this.gotRecommendations = gotRecommendations;
        this.productTypesPresented = productTypesPresented;
        this.visitResult = visitResult;
    }
}

export interface MeetingDetailsDTO {
    meetingStartDate: string;
    meetingEndDate?: string;
    meetingType?: MeetingType;
    todoType: ToDoType;
}

export class MeetingDetails {
    meetingStartDate: Date;
    meetingEndDate?: Date;
    meetingType?: MeetingType;
    todoType: ToDoType;


    constructor({meetingStartDate, meetingEndDate, meetingType, todoType}: MeetingDetailsDTO) {
        this.meetingStartDate = parseISO(meetingStartDate);
        this.meetingEndDate = parseISODateOrUndefined(meetingEndDate);
        this.meetingType = meetingType;
        this.todoType = todoType;
    }
}

export class Note {
    noteNumber?: string;
    text: string;
    created: Date;
    admID?: string;
    private: boolean;
    contactNumber: string;
    type: NoteType;
    completesTodo?: string;
    meetingDetails?: MeetingDetails;
    visitingReportProps?: VisitingReportProps;
    touchpointResultType?: TouchpointResultType;
    forDate?: Date;


    constructor(dto: NoteDTO) {
        const {noteNumber, text, created, admID, contactNumber, completesTodo, touchpointResultType, forDate, visitingReportProps, meetingDetails} = dto;
        this.noteNumber = noteNumber;
        this.text = text;
        this.created = parseISO(created);
        this.admID = admID;
        this.private = dto.private;
        this.contactNumber = contactNumber;
        this.type = dto.type;
        this.completesTodo = completesTodo;
        this.touchpointResultType = touchpointResultType;
        this.forDate = parseISODateOrUndefined(forDate);
        if (visitingReportProps) {
            this.visitingReportProps = new VisitingReportProps(visitingReportProps);
        }
        if (meetingDetails) {
            this.meetingDetails = new MeetingDetails(meetingDetails)
        }
    }
}


export interface NoteDTO {
    noteNumber?: string;
    text: string;
    created: string;
    admID?: string;
    private: boolean;
    contactNumber: string;
    type: NoteType;
    completesTodo?: string;
    meetingDetails?: MeetingDetailsDTO;
    touchpointResultType?: TouchpointResultType;
    forDate?: string;
    visitingReportProps?: VisitingReportPropsDTO
}


export const emptyNote: Note = new Note({
    contactNumber: "",
    created: "2020-09-18T14:15:17.7264107Z",
    private: false,
    text: "test123",
    type: NoteType.Custom,
    forDate: "2020-09-18T14:15:17.7264107Z"
})
