import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {formatDateObj} from "../Formatter";
import MeetingDetailsDisplay from "./MeetingDetailsDisplay";
import VisitingReportDisplay from "./VisitingReportDisplay";
import {Note} from "../../store/models/Note";
import {useTranslation} from "react-i18next";
import {AdmName} from "../AdmName";
import {isAfter, startOfDay, subDays} from "date-fns";
import ContactHistoryNoteEditModal from "./ContactHistoryNoteEditModal";
import {getMyADMID} from "../../util/UserUtil";

export default function ContactHistoryNoteDisplay({note, afterEdit}: { note: Note, afterEdit: () => void }) {

    const {t} = useTranslation();

    const fetchNoteType = (note: Note) => {
        if (note.touchpointResultType) {
            return t('contactDetailCommunicationTab.noteTypes.' + note.touchpointResultType)
        } else {
            return t('contactDetailCommunicationTab.noteTypes.' + note.type)
        }
    }
    const sevenDaysAgo = subDays(startOfDay(new Date()), 7);
    const canEdit = isAfter(note.created, sevenDaysAgo) && getMyADMID() === note.admID;

    return <Grid container spacing={3}>
        <Grid item container xs={12} justifyContent="space-between">
            <Grid item><Typography
                variant="subtitle1">{formatDateObj(note.created)}: {fetchNoteType(note)} </Typography></Grid>
            <Grid item>{canEdit ? <ContactHistoryNoteEditModal note={note}
                                                               afterEdit={afterEdit}></ContactHistoryNoteEditModal> : null}</Grid>
            {note.admID ?
                <Grid item xs={12}><Typography
                    variant="subtitle2">{t('contactDetailCommunicationTab.createdBy')}<AdmName
                    personalnummer={note.admID}/> </Typography></Grid>
                : null}
        </Grid>
        {note.meetingDetails ?
            <MeetingDetailsDisplay meetingDetails={note.meetingDetails}/>
            : null}
        {note.visitingReportProps ?
            <VisitingReportDisplay visitingReportProps={note.visitingReportProps}/>
            : null}
        <Grid item xs={12}>
            <Typography style={{whiteSpace: "pre-wrap"}}>
                {note.text}
            </Typography>
        </Grid>
    </Grid>
}