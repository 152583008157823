import React from 'react';
import Chip from "@material-ui/core/Chip";
import {MeetingListFilter} from "./MeetingListFilter";
import {Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        marginBottom: 20
    },
}));


interface MeetingListFilterSelectionProps {
    selectedFilter: MeetingListFilter,
    filterFunc: (filter: MeetingListFilter) => () => void
}


export default function MeetingListFilterSelection({filterFunc, selectedFilter}: MeetingListFilterSelectionProps) {
    const {t} = useTranslation();
    const classes = useStyles();

    return <Grid item md={12} className={classes.root}>
        <Chip
            label={t('meetingListFilterSelection.openMeetingsBtn')}
            clickable
            color={selectedFilter === MeetingListFilter.ONLY_OPEN ? 'primary' : 'default'}
            onClick={filterFunc(MeetingListFilter.ONLY_OPEN)}
        />
        <Chip
            label={t('meetingListFilterSelection.meetingsBtn')}
            clickable
            color={selectedFilter === MeetingListFilter.MEETINGS ? 'primary' : 'default'}
            onClick={filterFunc(MeetingListFilter.MEETINGS)}
        />
        <Chip
            label={t('meetingListFilterSelection.openVisitingReportBtn')}
            clickable
            color={selectedFilter === MeetingListFilter.OPEN_VISITING_REPORT ? 'primary' : 'default'}
            onClick={filterFunc(MeetingListFilter.OPEN_VISITING_REPORT)}
        />
        <Chip
            label={t('meetingListFilterSelection.followUpBtn')}
            clickable
            color={selectedFilter === MeetingListFilter.FOLLOW_UP ? 'primary' : 'default'}
            onClick={filterFunc(MeetingListFilter.FOLLOW_UP)}
        />
        <Chip
            label={t('meetingListFilterSelection.allMeetingsBtn')}
            clickable
            color={selectedFilter === MeetingListFilter.NONE ? 'primary' : 'default'}
            onClick={filterFunc(MeetingListFilter.NONE)}
        />
    </Grid>
}
