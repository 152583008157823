import Contact, {ContactDTO} from './models/Contact';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import * as Base64 from 'crypto-js/enc-base64';
import {LOGIN_SECRET_KEY, LOGIN_URL, PROD_BACKEND_URL, ST_BACKEND_URL} from '../config';
import IMeeting, {MeetingType} from './models/IMeeting';
import ContactPerson, {ContactPersonDTO} from './models/ContactPerson';
import Address, {AddressDTO, AddressType} from './models/Address';
import ContactDetailSearch from './models/ContactDetailSearch';
import ContactList, {ContactListDTO} from './models/ContactList';
import {SearchResult, SearchResultDTO} from './models/SearchResult';
import {readTokenFromCookie} from '../util/UserUtil';
import {ToDo, ToDoDTO} from './models/ToDo';
import {Note, NoteDTO} from './models/Note';
import BusinessExpenseReport, {BusinessExpenseReportDTO} from './models/BusinessExpenseReport';
import CalendarEvent, {CalendarEventDTO} from './models/CalendarEvent';
import {Adm, AdmDTO} from './models/Adm';
import Order, {OrderDTO} from './models/Order';
import DetailedOrder, {DetailedOrderDTO} from './models/DetailedOrder';
import Activity, {ActivityDTO} from './models/Activity';
import {IssueTypes} from './models/Feedback';
import DailyAdmReport, {DailyAdmReportDTO} from './models/DailyAdmReport';
import Kaz, {KazDTO} from './models/KAZ';
import {AssignmentConfig} from './models/AssignmentConfig';
import {JobReport, JobReportDTO} from './models/JobReport';
import {AdmName} from './models/AdmName';
import OrderOverview, {OrderOverviewDTO} from './models/OrderOverview';
import {TerminatorCustomerLists, TerminatorCustomerListsDto} from './models/TerminatorCustomerLists';
import {CustomerSurveyForCustomer, CustomerSurveyForCustomerDto} from './models/CustomerSurvey';
import {DeletePotentialCustomerResult} from './models/DeletePotentialCustomerResult';
import IAutocompleteAddressResponse from './models/IAutocompleteAddressDto';
import {RefundRequest} from './models/RefundRequest';
import {format} from 'date-fns';
import {MeetingListFilter} from "../components/meetingList/MeetingListFilter";
import {DabDigitalOrder, DabDigitalOrderConverter, SalesToolOrderStatusDto} from "./models/DabDigitalOrder";

export const contactApiUrl = `${ST_BACKEND_URL}/v1/contacts`;
export const contactListApiUrl = `${ST_BACKEND_URL}/v1/contactlists`;
export const admApiUrl = `${ST_BACKEND_URL}/v1/adms`;
export const calendarEventsApiUrl = `${ST_BACKEND_URL}/v1/calendarevents`;
export const orderApiUrl = `${ST_BACKEND_URL}/v1/orders`;
export const kazApiUrl = `${ST_BACKEND_URL}/v1/kaz`;
export const detailedOrderApiUrl = `${ST_BACKEND_URL}/v1/detailedOrders`;
export const orderOverviewApiUrl = `${ST_BACKEND_URL}/v1/orderOverviews`;
export const todoApiUrl = `${ST_BACKEND_URL}/v1/todos`;
export const noteApiUrl = `${ST_BACKEND_URL}/v1/notes`;
export const feedbackApiUrl = `${ST_BACKEND_URL}/v1/feedback`;
export const dashboardKPIApiUrl = `${ST_BACKEND_URL}/v1/vbkpis`;
export const kpiApiUrl = `${ST_BACKEND_URL}/v1/kpis/extended`;
export const businessExpenseReportUrl = `${ST_BACKEND_URL}/v1/expense-reports`;
export const dailyAdmReportUrl = `${ST_BACKEND_URL}/v1/daily-adm-reports`;
export const assignationApiUrl = `${ST_BACKEND_URL}/v1/assignation`;
export const badApiUrl = `${ST_BACKEND_URL}/v1/bad`;
export const terminatorApiUrl = `${ST_BACKEND_URL}/v1/terminator`;
export const productListApiUrl = `${ST_BACKEND_URL}/v1/productList`;
export const customerSurveyApiUrl = `${ST_BACKEND_URL}/v1/customerSurvey`
export const refundOrderPositionApiUrl = `${ST_BACKEND_URL}/v1/refundOrderPosition`
export const digitalOrderStatusApiUrl = `${ST_BACKEND_URL}/v1/digitalOrderStatus`
export const customerProcessingDataUrl = `${ST_BACKEND_URL}/v1/customer-processing`


export const productApiUrl = `${PROD_BACKEND_URL}/products`;
export const categoryApiUrl = `${PROD_BACKEND_URL}/category`;
export const searchApiUrl = `${PROD_BACKEND_URL}/search`;
export const offerApiUrl = `${PROD_BACKEND_URL}/offer`;

export const detailSearchContactApiURL = contactApiUrl + '/findContacts';

export const searchPaginationPageSize = 50;

export const academyApiUrl = "https://bag-academy.herokuapp.com";


const Api = {
    createMeeting: async (meeting: IMeeting,todo: ToDo, note: Note): Promise<any> => {
        const requestOptions = buildRequestOptions("POST");
        return fetch(calendarEventsApiUrl, {
            ...requestOptions,
            body: JSON.stringify({meeting,todo,note}),
        }).then(handleResponse).then((response: CalendarEventDTO) => new CalendarEvent(response));
    },
    getContacts: async (): Promise<Contact[]> => {
        const requestOptions = buildRequestOptions("GET");
        return fetch(contactApiUrl, requestOptions).then(handleResponse).then((response: ContactDTO[]) => response.map(c => new Contact(c)));
    },
    searchContacts: async (searchTerm: string, page: number): Promise<SearchResult> => {
        const requestOptions = buildRequestOptions("GET");
        return await fetch(contactApiUrl + '/findContacts' + objectToQueryString({
            searchTerm,
            page,
            pageSize: searchPaginationPageSize
        }),
            {...requestOptions,}).then(handleResponse).then((response: SearchResultDTO) => new SearchResult(response));
    },
    detailSearchContacts: async (searchObj: ContactDetailSearch, page: number): Promise<SearchResult> => {
        const requestOptions = buildRequestOptions("POST");
        return await fetch(detailSearchContactApiURL + objectToQueryString({page, pageSize: searchPaginationPageSize}),
            {
                ...requestOptions,
                body: JSON.stringify(searchObj)
            }).then(handleResponse).then((response: SearchResultDTO) => new SearchResult(response));
    },
    async getContact(stContactID: string) {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${contactApiUrl}/${stContactID}`, requestOptions).then(handleResponse).then((response: ContactDTO) => new Contact(response));
    },
    // Academy
    async getAcademyPosts(locale: string) {
        return fetch(`${academyApiUrl}/posts?countries.country_code_in=${locale}&categories.name=academy`).then(handleResponse);
    },
    async getAcademyPost(postID: string, locale: string) {
        return fetch(`${academyApiUrl}/posts/${postID}?countries.country_code_in=${locale}`).then(handleResponse);
    },
    // End Academy
    async getProduct(productID: string) {
        return fetch(`${productApiUrl}/de/${productID}/detail`).then(handleResponse);
    },
    async getProducts(productIDs: string[]) {
        return fetch(`${productApiUrl}/de/detail`, {
            method: 'POST',
            body: JSON.stringify(productIDs),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(handleResponse);
    },
    async getProductCategory(categoryID: string) {
        return fetch(`${categoryApiUrl}/de/${categoryID}`).then(handleResponse);
    },
    async getCategoryProducts(categoryID: string, idx: number = 0, limit: number = 18) {
        const url = new URL(`${categoryApiUrl}/de/${categoryID}/products`);
        url.searchParams.append("idx", idx.toString());
        url.searchParams.append("limit", limit.toString());
        // @ts-ignore
        return fetch(url).then(handleResponse);
    },
    async getCategoryListByParrentId(parrentID: number) {
        return fetch(`${categoryApiUrl}/de/parrent/${parrentID}`).then(handleResponse);
    },
    async getSearchProducts(filter: any, idx: number = 0, limit: number = 18) {
        const searchParams = new URLSearchParams({
            idx: idx.toString(),
            limit: limit.toString(),
            ...filter
        });
        const url = new URL(`${searchApiUrl}/de/all?${searchParams.toString()}`);
        // @ts-ignore
        return fetch(url).then(handleResponse);
    },
    async getProductOffer(offerID: string) {
        const offer = await fetch(`${offerApiUrl}/${offerID}`).then(handleResponse);
        if (offer) {
            let productIDs = offer.positions.reduce((acc: any, {productId}: any) => {
                if (!acc[productId]) {
                    acc[productId] = true;
                }
                return acc;
            }, {});
            productIDs = Object.keys(productIDs);
            const productDetailsData = await this.getProducts(productIDs);
            offer.productData = productDetailsData;
        }
        return offer
    },
    async getProductOffers() {
        const offerList = await fetch(`${offerApiUrl}/list`).then(handleResponse);
        return offerList
    },
    async removeProductOffer(offerID: string) {
        return fetch(`${offerApiUrl}/${offerID}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(handleResponse);
    },
    async saveProductOffer(offerID: string, offerData: any) {
        const response = await fetch(`${offerApiUrl}/${offerID}`, {
            method: 'PUT',
            body: JSON.stringify(offerData),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(handleResponse);
        return response;
    },
    async createProductOffer(offerData: any) {
        const response = await fetch(`${offerApiUrl}`, {
            method: 'POST',
            body: JSON.stringify(offerData),
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(handleResponse);
        return response;
    },
    async getProductOfferPdf(offerID: string) {
        const blob = await fetch(`${offerApiUrl}/de/${offerID}/pdf`).then(res => res.blob());
        return blob;
    },
    async sendProductOffer(offerID: string) {
        await fetch(`${offerApiUrl}/de/${offerID}/send`, {
            method: 'POST'
        });
    },
    login: async (user: any): Promise<any> => {
        const calculatedHash = calculateHash(user.username, user.password, LOGIN_SECRET_KEY);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-DIGEST': calculatedHash
            }
        };
        return fetch(`${LOGIN_URL}`, {
            ...requestOptions,
            body: JSON.stringify(user) //TODO
        }).then(handleResponse);
    },
    async getCalendarEvents(): Promise<CalendarEvent[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(calendarEventsApiUrl, requestOptions).then(handleResponse).then(response => response.value.map((cEDTO: CalendarEventDTO) => new CalendarEvent(cEDTO)));
    },
    fetchGoogleMapsAddressLink(contact: Contact | undefined) {
        if (contact && contact.address) {
            const addressString = `${contact.address.street} ${contact.address.houseNumber} ${contact.address.zipCode} ${contact.address.place}`
            return `http://google.com/maps/search/?api=1&query=${encodeURI(addressString)}`;
        }
        return "";
    },
    async deletePotentialCustomer(stContactID: string, deleteNotesAndTodos: boolean): Promise<DeletePotentialCustomerResult> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(
            `${badApiUrl}/deleteLeadContact/?stContactID=${stContactID}&deleteNotesAndTodos=${deleteNotesAndTodos}`,
            {
                ...requestOptions,

            }).then(handleResponse);
    },
    async updateContact(contact: Contact): Promise<Contact> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${contactApiUrl}/${contact.stContactID}`, {
            ...requestOptions,
            body: JSON.stringify(contact)
        }).then(handleResponse).then((res: ContactDTO) => new Contact(res));
    },
    async createContact(contact: Contact): Promise<Contact> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${contactApiUrl}`, {
            ...requestOptions,
            body: JSON.stringify(contact)
        }).then(handleResponse).then((res: ContactDTO) => new Contact(res));
    },
    async updateContactNote(stContactID: string, note: string): Promise<Contact> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${contactApiUrl}/${stContactID}/updateContactNote`, {
            ...requestOptions,
            body: JSON.stringify({note})
        }).then(handleResponse).then((res: ContactDTO) => new Contact(res));
    },
    async updateContactPerson(stContactID: string, contactPerson: ContactPerson): Promise<ContactPerson> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${contactApiUrl}/${stContactID}/updateContactPerson`, {
            ...requestOptions,
            body: JSON.stringify(contactPerson)
        }).then(handleResponse).then((res: ContactPersonDTO) => new ContactPerson(res));
    },
    async updateContactAddress(stContactID: string, addressType: AddressType, contactAddress: Address): Promise<Address> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${contactApiUrl}/${stContactID}/updateContactAddress/${addressType}`, {
            ...requestOptions,
            body: JSON.stringify(contactAddress)
        }).then(handleResponse).then((res: AddressDTO) => new Address(res));
    },
    async addContactPerson(stContactID: string, contactPerson: ContactPerson): Promise<ContactPerson> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${contactApiUrl}/${stContactID}/addContactPerson`, {
            ...requestOptions,
            body: JSON.stringify(contactPerson)
        }).then(handleResponse).then((res: ContactPersonDTO) => new ContactPerson(res));
    },
    async deleteContactPerson(stContactID: string, stContactPersonID: string): Promise<ContactPerson> {
        const requestOptions = buildRequestOptions("DELETE");
        return fetch(`${contactApiUrl}/${stContactID}/deleteContactPerson/${stContactPersonID}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: ContactPersonDTO) => new ContactPerson(res));
    },
    async addContactAddress(stContactID: string, addressType: AddressType, contactAddress: Address): Promise<Address> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${contactApiUrl}/${stContactID}/addContactAddress/${addressType}`, {
            ...requestOptions,
            body: JSON.stringify(contactAddress)
        }).then(handleResponse).then((res: AddressDTO) => new Address(res));
    },
    async getContactLists(): Promise<ContactList[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${contactListApiUrl}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: ContactListDTO[]) => res.map(cL => new ContactList(cL)));
    },
    async saveContactList(contactList: ContactList): Promise<ContactList> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${contactListApiUrl}`, {
            ...requestOptions,
            body: JSON.stringify(contactList)
        }).then(handleResponse).then((res: ContactListDTO) => new ContactList(res));
    },
    async deleteContactList(contactListID: string) {
        const requestOptions = buildRequestOptions("DELETE");
        return fetch(`${contactListApiUrl}/${contactListID}`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async selectContactList(contactList: ContactList, page: number): Promise<SearchResult> {
        const requestOptions = buildRequestOptions("POST");
        return await fetch(contactApiUrl + '/findContactsForContactList' + objectToQueryString({
            page,
            pageSize: searchPaginationPageSize
        }),
            {
                ...requestOptions,
                body: JSON.stringify(contactList)
            }).then(handleResponse).then((res: SearchResultDTO) => new SearchResult(res));
    },
    async getAdms(): Promise<Adm[]> {
        const reuqestOptions = buildRequestOptions('GET');
        return fetch(`${admApiUrl}`, {
            ...reuqestOptions
        }).then(handleResponse).then((res: AdmDTO[]) => res.map(adm => new Adm(adm)));
    },
    async getAdmNames(): Promise<AdmName[]> {
        const reuqestOptions = buildRequestOptions('GET');
        return fetch(`${admApiUrl}/names`, {
            ...reuqestOptions
        }).then(handleResponse);
    },
    async deleteContactAddress(stContactID: string, stContactAddressID: string) {
        const requestOptions = buildRequestOptions("DELETE");
        return fetch(`${contactApiUrl}/${stContactID}/deleteContactAddress/${stContactAddressID}`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async getOrdersForContact(sapCustomerID: string): Promise<Order[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${orderApiUrl}/forContact/${sapCustomerID}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: OrderDTO[]) => res.map(o => new Order(o)));
    },
    async getOrdersWithWarenausgangLastFourWeeks(admId: string) {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${orderApiUrl}/withWarenausgang/${admId}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: DetailedOrderDTO[]) => res.map(o => new DetailedOrder(o)));
    },
    async getKAZForOrder(orderNumber: string, sapContactID: string): Promise<Kaz[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${kazApiUrl}?orderNumber=${orderNumber}&contactNumber=${sapContactID}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: KazDTO[]) => res.map(o => new Kaz(o)));
    },
    async getKAZForContact(sapContactID: string): Promise<Kaz[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${kazApiUrl}?contactNumber=${sapContactID}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: KazDTO[]) => res.map(o => new Kaz(o)));
    },
    async getOrderOverviewsForAdm(admId: string, filter: any): Promise<OrderOverview[]> {
        if (admId === 'A9999') {
            console.log('getOrderForADM ID workaround for ADM ID A9999')
            admId = ''
        }
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${orderOverviewApiUrl}/forAdm/${admId}${objectToQueryString(filter)}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: OrderOverviewDTO[]) => res.map(o => new OrderOverview(o)));
    },
    async getDetailedOrder(auftragsnummer: string): Promise<DetailedOrder> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${detailedOrderApiUrl}/${auftragsnummer}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: DetailedOrderDTO) => new DetailedOrder(res));
    },
    async autocompleteAddresses(addressInput: string): Promise<IAutocompleteAddressResponse> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${ST_BACKEND_URL}/v1/addressCompletion?address=${encodeURIComponent(addressInput)}`, requestOptions)
            .then(handleResponse);
    },
    async getPlaceDetails(placeId: string): Promise<Contact> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${ST_BACKEND_URL}/v1/addressCompletion/place/${placeId}`, requestOptions)
            .then(handleResponse);
    },
    async getToDos(): Promise<ToDo[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${todoApiUrl}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: ToDoDTO[]) => res.map(t => new ToDo(t)));
    },
    async getDoneToDos(stContactID: string): Promise<ToDo[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${todoApiUrl}/forContactHistory/${stContactID}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: ToDoDTO[]) => res.map(t => new ToDo(t)));
    },
    async addToDo(todo: ToDo): Promise<ToDo> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${todoApiUrl}`, {
            body: JSON.stringify(todo),
            ...requestOptions,
        }).then(handleResponse).then((res: ToDoDTO) => new ToDo(res));
    },
    async editToDo(todo: ToDo): Promise<ToDo> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${todoApiUrl}`, {
            body: JSON.stringify(todo),
            ...requestOptions,
        }).then(handleResponse).then((res: ToDoDTO) => new ToDo(res));
    },
    async deleteToDo(todo: ToDo) {
        const requestOptions = buildRequestOptions("DELETE");
        return fetch(`${todoApiUrl}/${todo.todoNumber}`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async getMeetingsForAdms(year: number, month: number, selectedAdmId: string, filterKey: MeetingListFilter, page: number): Promise<{ searchResult: { todo: ToDo, contact?: Contact }[], totalCount: number }> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${todoApiUrl}/meetingsForMyAdms/${year}/${month}${objectToQueryString({
            selectedAdmId,
            page,
            pageSize: searchPaginationPageSize,
            filterKey
        })}`, {
            ...requestOptions,
        }).then(handleResponse).then(({
                                          totalCount,
                                          searchResult
                                      }: { searchResult: { todo: ToDoDTO, contact?: ContactDTO }[], totalCount: number }) => ({
            totalCount, searchResult: searchResult.map(tAC => ({
                todo: new ToDo(tAC.todo),
                contact: tAC.contact ? new Contact(tAC.contact) : undefined
            }))
        }));
    },
    async getNotes(stContactID?: string): Promise<Note[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${noteApiUrl}` + (stContactID ? `/${stContactID}` : ''), {
            ...requestOptions,
        }).then(handleResponse).then((res: NoteDTO[]) => res.map(n => new Note(n)));
    },
    async addNote(note: Note): Promise<Note> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${noteApiUrl}`, {
            body: JSON.stringify(note),
            ...requestOptions,
        }).then(handleResponse).then((res: NoteDTO) => new Note(res));
    },
    async editNote(note: Note): Promise<Note> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${noteApiUrl}`, {
            body: JSON.stringify(note),
            ...requestOptions,
        }).then(handleResponse).then((res: NoteDTO) => new Note(res));
    },
    async sendFeedback(feedbackText: string, issueType: IssueTypes, attachments?: File[]) {
        const requestOptions = buildRequestOptions("POST", false);

        const form = new FormData();
        form.append("feedbackText", feedbackText);
        form.append("issueType", issueType);
        attachments?.forEach(file => {
            form.append("attachments", file, file.name)
        })

        return fetch(`${feedbackApiUrl}`, {
            body: form,
            ...requestOptions,
        }).then(handleResponse);
    },
    async getDashboardKPIs() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${dashboardKPIApiUrl}`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async sendContactToSAP(stContactNumber: string) {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${contactApiUrl}/sendToSap/${stContactNumber}`, {
            ...requestOptions
        })
    },
    async getVBKPIData() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${kpiApiUrl}/vb`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async getManagerKPIData() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${kpiApiUrl}/manager`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async getVBKPICardData() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${kpiApiUrl}/manager/vbKPICard`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async getOrderOverviewsForSubordinatedAdms(days: number): Promise<OrderOverview[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${orderOverviewApiUrl}/forSubordinatedAdms?days=${days}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: OrderOverviewDTO[]) => res.map(d => new OrderOverview(d)));
    },
    async getMyBusinessExpenseReports(): Promise<BusinessExpenseReport[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${businessExpenseReportUrl}/`, {
            ...requestOptions,
        }).then(handleResponse).then((res: BusinessExpenseReportDTO[]) => res.map(r => new BusinessExpenseReport(r)));
    },
    async getMyActivities(forDate: string): Promise<Activity[]> {
        const requestOptions = buildRequestOptions("GET");
        const [day, month, year] = forDate.split('-');
        return fetch(`${businessExpenseReportUrl}/activities/${year}/${month}/${day}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: ActivityDTO[]) => res.map(a => new Activity(a)));
    },
    async postMyBusinessExpenseReport(bER: BusinessExpenseReport) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${businessExpenseReportUrl}/`, {
            body: JSON.stringify(bER),
            ...requestOptions,
        }).then(handleResponse);
    },
    async updateMyBusinessExpenseReport(bER: BusinessExpenseReport): Promise<BusinessExpenseReport> {
        const requestOptions = buildRequestOptions("PUT");
        return fetch(`${businessExpenseReportUrl}/${bER.berNumber}`, {
            body: JSON.stringify(bER),
            ...requestOptions,
        }).then(handleResponse).then((res: BusinessExpenseReportDTO) => new BusinessExpenseReport(res));
    },
    async getBusinessExpenseReportsForMyADMs(): Promise<BusinessExpenseReport[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${businessExpenseReportUrl}/management`, {
            ...requestOptions,
        }).then(handleResponse).then((res: BusinessExpenseReportDTO[]) => res.map(b => new BusinessExpenseReport(b)));
    },
    async getBusinessExpenseReportsAsAccountant(): Promise<BusinessExpenseReport[]> {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${businessExpenseReportUrl}/accounting`, {
            ...requestOptions,
        }).then(handleResponse).then((res: BusinessExpenseReportDTO[]) => res.map(b => new BusinessExpenseReport(b)));
    },
    async updateBusinessExpenseReportStatusAsManager(berNumber: string, status: 'accept' | 'decline' | 'requestReview', note?: string): Promise<BusinessExpenseReport> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${businessExpenseReportUrl}/${berNumber}/management/${status}`, {
            body: JSON.stringify({message: note}),
            ...requestOptions,
        }).then(handleResponse).then((res: BusinessExpenseReportDTO) => new BusinessExpenseReport(res));
    },
    async updateBusinessExpenseReportStatusAsAccountant(berNumber: string, status: 'accept' | 'decline' | 'requestReview'): Promise<BusinessExpenseReport> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${businessExpenseReportUrl}/${berNumber}/accounting/${status}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: BusinessExpenseReportDTO) => new BusinessExpenseReport(res));
        ;
    },
    async getMyDailyAdmReports() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${dailyAdmReportUrl}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: DailyAdmReportDTO[]) => res.map(dto => new DailyAdmReport(dto)));
    },
    async createMyDailyAdmReports(dAR: DailyAdmReport): Promise<DailyAdmReport> {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${dailyAdmReportUrl}/`, {
            body: JSON.stringify(dAR),
            ...requestOptions,
        }).then(handleResponse).then((res: DailyAdmReportDTO) => new DailyAdmReport(res));
    },
    async updateMyDailyAdmReports(dAR: DailyAdmReport): Promise<DailyAdmReport> {
        const requestOptions = buildRequestOptions("PUT");
        return fetch(`${dailyAdmReportUrl}/${dAR.darNumber}`, {
            body: JSON.stringify(dAR),
            ...requestOptions,
        }).then(handleResponse).then((res: DailyAdmReportDTO) => new DailyAdmReport(res));
    },
    async getDailyAdmReportsForMyADMS() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${dailyAdmReportUrl}/managment`, {
            ...requestOptions,
        }).then(handleResponse).then((res: DailyAdmReportDTO[]) => res.map(dto => new DailyAdmReport(dto)));
    },
    async getCustomersForAdm(admID: string) {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${contactApiUrl}/customersFor/${admID}`, {
            ...requestOptions,
        }).then(handleResponse).then((res: ContactDTO[]) => res.map(dto => new Contact(dto)));
    },
    async assignCustomersToAdm(assignToAdm: string, until: Date, customerIds: string[], note: string) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${assignationApiUrl}/assignCustomers/`, {
            body: JSON.stringify({assignToAdm, until, customerIds, note}),
            ...requestOptions,
        }).then(handleResponse);
    },
    async getAssignationConfigsForBAD() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${badApiUrl}/assignationConfigs/`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async getMailchimpCampaignsForBAD() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${badApiUrl}/activeMailchimpCampaigns/`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async upsertAssignationConfigForBAD(config: AssignmentConfig) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${badApiUrl}/assignationConfig/`, {
            body: JSON.stringify(config),
            ...requestOptions,
        }).then(handleResponse);
    },
    async replaceProductItemListFromBAD(data: any[]) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${badApiUrl}/replaceProductList/`, {
            ...requestOptions,
            body: JSON.stringify({
                productListItems: data
            }),
        }).then(handleResponse);
    },
    async updateCRMDataForAdm(vbNumber: string) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${badApiUrl}/updateCRMDataForAdm/`, {
            body: JSON.stringify({
                vbNumber
            }),
            ...requestOptions,
        }).then(handleResponse);
    },
    async updateContactFromSAP(sapCustomerID: string) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${badApiUrl}/updateContactFromSAP/`, {
            body: JSON.stringify({
                sapCustomerID
            }),
            ...requestOptions,
        }).then(handleResponse);
    },
    async refreshAdmData() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${badApiUrl}/refreshAdmData/`, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async getJobReportsForBad() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${badApiUrl}/jobReports/`, {
            ...requestOptions,
        }).then(handleResponse).then((res: JobReportDTO[]) => res.map(jR => new JobReport(jR)));
    },
    async getProductSearchItems() {
        const requestOptions = buildRequestOptions('GET');
        return fetch(`${productListApiUrl}/`, {
            ...requestOptions
        }).then(handleResponse);
    },
    async createTerminatorFollowUp(dto: {
        forAdm: string;
        dueOn: Date;
        note: string;
        forCustomer: string;
        customerDisplayName: string;
    }) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${terminatorApiUrl}/addFollowUp/`, {
            body: JSON.stringify(dto),
            ...requestOptions,
        }).then(handleResponse);
    },
    async createTerminatorMeeting(dto: {
        startDate: Date;
        endDate: Date;
        forCustomer: string; //stContactID
        forAdm: string; //VBNr
        note: string;
        sendMail: boolean;
        attendees: string;
        meetingType: MeetingType;
        subject: string;
        body: string;
    }) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${terminatorApiUrl}/createMeeting/`, {
            body: JSON.stringify(dto),
            ...requestOptions,
        }).then(handleResponse);
    },
    async searchAsTerminator(searchObj: ContactDetailSearch, forAdm: string, page: number, pageSize: number) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${terminatorApiUrl}/search` + objectToQueryString({page, pageSize, forAdm}), {
            body: JSON.stringify(searchObj),
            ...requestOptions,
        }).then(handleResponse).then((response: SearchResultDTO) => new SearchResult(response));
    },
    async getContactsAsTerminator(stContactIds: string[]) {
        const requestOptions = buildRequestOptions("POST");
        return fetch(`${terminatorApiUrl}/getContacts/`, {
            body: JSON.stringify(stContactIds),
            ...requestOptions,
        }).then(handleResponse).then((response: ContactDTO[]) => response.map(r => new Contact(r)));
    },
    async getTerminatorCustomerLists() {
        const requestOptions = buildRequestOptions("GET");
        return fetch(`${terminatorApiUrl}/customerLists/`, {
            ...requestOptions,
        }).then(handleResponse).then((response: TerminatorCustomerListsDto) => new TerminatorCustomerLists(response));
    },
    async completeTerminatorFollowUp(followUpId: string) {
        const requestOptions = buildRequestOptions("PUT");
        return fetch(`${terminatorApiUrl}/completeFollowUp/` + followUpId, {
            ...requestOptions,
        }).then(handleResponse);
    },
    async getCustomerSurveysForCustomer(stContactID: string) {
        const requestOptions = buildRequestOptions('GET');
        return fetch(`${customerSurveyApiUrl}/${stContactID}`, {
            ...requestOptions
        }).then(handleResponse).then((response: CustomerSurveyForCustomerDto[]) => response.map(c => new CustomerSurveyForCustomer(c)))
    },
    async addCustomerSurveysForCustomer(stContactID: string, result: number, note: string, byAdm: string, sentEvaluationLink: boolean) {
        const requestOptions = buildRequestOptions('POST');
        return fetch(`${customerSurveyApiUrl}/`, {
            ...requestOptions,
            body: JSON.stringify({
                stContactID,
                result,
                note,
                byAdm,
                sentEvaluationLink
            })
        }).then(handleResponse)
    },

    createBusinessExpenseDownloadLink(year: number) {
        return `${businessExpenseReportUrl}/accounting/${year}`;
    },
    createBerCsvDownloadLink(from: Date) {

        return `${badApiUrl}/berCsv?dateFrom=${format(from, 'yyyy-MM-dd')}`;
    },
    getKsbvCsvDownloadLink() {
        return `${badApiUrl}/revokedCustomersCsv`;
    },
    createActivitiesCsvDownloadLink(from: Date, to: Date) {
        return `${badApiUrl}/activitiesCsv?dateFrom=${format(from, 'yyyy-MM-dd')}&dateTo=${format(to, 'yyyy-MM-dd')}`;
    },
    sendRefundMail(refundRequest: RefundRequest) {
        const form = new FormData();
        form.append('articleName', refundRequest.articleName);
        form.append('contactPersonName', refundRequest.contactPersonName);
        form.append('contactType', refundRequest.contactType);
        form.append('customerName', refundRequest.customerName);
        form.append('customerID', refundRequest.customerID);
        form.append('orderID', refundRequest.orderID);
        form.append('positionID', refundRequest.positionID);
        form.append("amount", refundRequest.amount)
        form.append("complaint", refundRequest.complaint)
        form.append("description", refundRequest.description)
        form.append("whereabouts", refundRequest.whereabouts)
        form.append("packageAmount", refundRequest.packageAmount)
        form.append("proposedSolution", refundRequest.proposedSolution)
        form.append("articlenumberGift", refundRequest.articlenumberGift)
        refundRequest.attachments?.forEach(file => {
            form.append("attachments", file, file.name)
        })
        const requestOptions = buildRequestOptions('POST', false);
        return fetch(`${refundOrderPositionApiUrl}/`, {
            ...requestOptions,
            body: form
        }).then(handleResponse)
    },
    getCustomersForAssignationList({
                                       admProtectedFor,
                                       admAssignedTo,
                                       admAssignedUntil,
                                       regionCode
                                   }: any) {

        const requestOptions = buildRequestOptions('POST');
        return fetch(`${contactApiUrl}/customersFor/assignationList/`, {
            ...requestOptions,
            body: JSON.stringify({
                admProtectedFor,
                admAssignedTo,
                admAssignedUntil,
                regionCode
            })
        }).then(handleResponse).then((res: ContactDTO[]) => res.map(dto => new Contact(dto)));
    },
    getDigitalOrderStatusFromDab(myADMID: string): Promise<DabDigitalOrder[]> {
        const requestOptions = buildRequestOptions('GET');
        return fetch(`${digitalOrderStatusApiUrl}/forDashboard/${myADMID}`, {
            ...requestOptions
        }).then(handleResponse).then((res: any) => res.reduce(( result: DabDigitalOrder[],order: SalesToolOrderStatusDto,) => {
            result.push(...DabDigitalOrderConverter.fromJson(order))
            return result;
        }, []));
    },
    getDigitalOrderStatusFromDabForCustomer(sapCustomerID: string): Promise<DabDigitalOrder[]> {
        const requestOptions = buildRequestOptions('GET');
        return fetch(`${digitalOrderStatusApiUrl}/forCustomer/${sapCustomerID}`, {
            ...requestOptions
        }).then(handleResponse).then((res: any) => res.reduce(( result: DabDigitalOrder[],order: SalesToolOrderStatusDto,) => {
            result.push(...DabDigitalOrderConverter.fromJson(order))
            return result;
        }, []));
    },
    getCustomerProcessingStats() {
        const requestOptions = buildRequestOptions('GET');
        return fetch(`${customerProcessingDataUrl}/personalStats`, {
            ...requestOptions
        }).then(handleResponse);
    }
};


const calculateHash = (username: string, password: string, secret: string): string => {
    const hash = HmacSHA256(username + password, secret);
    return Base64.stringify(hash);
}

const handleResponse = (response: Response) => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                console.log(401);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
};

const buildRequestOptions = (method: string, defaultContentType = true) => {
    const token = readTokenFromCookie();

    const headers: HeadersInit = new Headers();
    if (defaultContentType) {
        headers.set('Content-Type', 'application/json');
    }
    headers.set('Authorization', "Bearer " + token);

    const requestOptions: RequestInit = {
        method,
        credentials: "same-origin",
        headers
    };
    return requestOptions;
};

const objectToQueryString = (obj: object) => {
    return Object.keys(obj).length <= 0
        ? ""
        : `?${Object.entries(obj)
            .filter(([_, value]) => value !== undefined)
            .map(([key, value]) => key + "=" + value)
            .join("&")}`;
};

export default Api;
