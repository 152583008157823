export enum TouchpointType {
    Phone = "PHONE",
    Visit = "VISIT",
    CompleteTodo = "COMPLETE_TODO"
}

export enum TouchpointResultType {
    Not_Reached = "NOT_REACHED",
    Follow_Up = "FOLLOW_UP",
    Meeting = "MEETING",
    Visiting_Report = "VISITING_REPORT",
    // Status_Change="STATUS_CHANGE"
}
