import { Chip, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PhaseState, Phases } from '../store/models/DabDigitalOrder';


export function PhaseStatusIndicator({ status, phase }: { status?: PhaseState; phase: Phases }) {
    const { t } = useTranslation();

    let label = status
        ? t(
            `projectStatus.${phase}.${status}`,
        )
        : '';

    return (
        <>
            {
                status ? (
                    <Tooltip title={label.length > 15 ? label : ""}>
                        <Chip
                            label={label}
                            variant={'outlined'}
                            color={'default'}
                            style={{ maxWidth: 120 }}
                        />
                    </Tooltip>
                ) : null
            }
        </>
    )
}

