import {OrderPosition} from "../store/models/Order";
import {DabDigitalOrder} from "../store/models/DabDigitalOrder";
import {Grid} from "@material-ui/core";
import {PhaseIndicator} from "./PhaseIndicator";
import {PhaseStatusIndicator} from "./PhaseStatusIndicator";


export function OrderHistoryDabStatusIndicator({
                                                   position,
                                                   orderId,
                                                   digitalOrderStatusList
                                               }: { position: OrderPosition, orderId: string, digitalOrderStatusList: DabDigitalOrder[] }) {
    const entry = digitalOrderStatusList.find((entry) => entry.orderId === orderId && entry.posNummer === position.posNummer);
    if (entry) {
        return <Grid container item xs={12}>
            <Grid item xs={6}>
                <PhaseIndicator phase={entry.phase}/>
            </Grid>
            <Grid item xs={6}>
                <PhaseStatusIndicator phase={entry.phase} status={entry.status}/>
            </Grid>
        </Grid>
    } else {
        return null;
    }

}