import React, {FunctionComponent} from 'react';
import {Box} from "@material-ui/core";

interface TabPanelProps {
    value:string,
    index: string
}

const TabPanel : FunctionComponent<TabPanelProps> = ({value,index,children} ) => {
    return <Box hidden={value!==index}>{children}</Box>
}

export default TabPanel;