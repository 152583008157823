import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Phases } from '../store/models/DabDigitalOrder';

export const LIGHT_GREEN = '#64DD17';
export const PINK = '#ff99cc';
export const PURPLE = '#cc99ff';
export const LIGHT_BLUE = '#64B6F7';
export const GREY = '#BDBDBD';
export const RED = '#E53935';
export const YELLOW = '#FFD600';
export const WHITE = '#ffffff';
const colorToPhaseMapping = {
  [Phases.OPEN]: GREY,
  [Phases.BRIEFING]: YELLOW,
  [Phases.MATERIAL]: LIGHT_BLUE,
  [Phases.PRODUCTION]: LIGHT_GREEN,
  [Phases.REVIEW]: PINK,
  [Phases.GO_LIVE]: PURPLE,
  [Phases.DONE]: GREY,
};

interface PhaseIndicatorProps {
  phase: Phases;
}

export function PhaseIndicator(props: PhaseIndicatorProps) {
  const { t } = useTranslation();
  const color = colorToPhaseMapping[props.phase];
  return (
    <Chip
      label={t(`phases.${props.phase}`)}
      style={{
        backgroundColor: color,
        color: WHITE,
      }}
    />
  );
}
