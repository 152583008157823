import {Grid, Typography} from "@material-ui/core";
import {formatDate, formatDateObj, formatToCurrency} from "../../Formatter";
import {OrderHistoryDabStatusIndicator} from "../../OrderHistoryDabStatusIndicator";
import {ContactOrderStatusIndicator} from "../ContactOrderStatusIndicator";
import Label from "../../Label";
import SendRefundPositionModal from "../SendRefundPositionModal";
import React, {useEffect, useState} from "react";
import Order, {OrderPosition} from "../../../store/models/Order";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {DabDigitalOrder} from "../../../store/models/DabDigitalOrder";
import Api from "../../../store/Api";
import Contact from "../../../store/models/Contact";

const useStyles = makeStyles((theme) => {
    return ({
        innerAccordion: {
            padding: theme.spacing(3)
        },
    });
});


function fetchTotalNettoValue(position: OrderPosition, allPositions: OrderPosition[]) {
    const subPositions = allPositions.filter(pos => pos.hauptPosNr === position.posNummer)
    if (subPositions.length > 0) {
        return subPositions.reduce((sum, pos) => sum + +pos.posNettowert, 0)
    } else {
        return position.posNettowert
    }
}

export function OrderHistoryPositions({
                                          order,
                                          contact
                                      }: { order: Order, contact: Contact }) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [digitalOrderStatusList, setDigitalOrderStatusList] = useState<DabDigitalOrder[]>([]);
    const fetchDigitalOrderStatusFromDab = () => {
        Api.getDigitalOrderStatusFromDabForCustomer(contact.sapCustomerID || '').then(data => {
            setDigitalOrderStatusList(data)
        })
    }
    useEffect(fetchDigitalOrderStatusFromDab, [contact.sapCustomerID])

    function getLabelAndValueIfDefinied(value: string, labelKey: string, withNewLine?: boolean) {
        if (value) {
            return <>
                {t('orderDetailsView.' + labelKey)}<span>{value}</span>
                {withNewLine ? <br/> : null}
            </>
        } else {
            return null;
        }
    }

    const condensedOrderPositions = order.positions.filter(position => !position.hauptPosNr)
    return <> <Grid item xs={12} style={{marginTop: 25}}><Typography
        variant={"h5"}>{t("contactDetailOrderHistoryTab.orderPositions")}</Typography></Grid>
        {condensedOrderPositions.map((position, indexPosition) =>
            <Grid key={order.bestellNummer + 'postion' + indexPosition}
                  className={classes.innerAccordion} item xs={12}>
                <Grid container spacing={3} justifyContent={"space-around"}>
                    <Grid md={1}
                          item><Typography>{position.menge} {position.mengenEinheit}</Typography></Grid>
                    <Grid md={4}
                          item>
                        {position.materialBezeichnung}
                        <br/>
                        {position.materialNummer} / {position.artikelFarbe}
                        <br/>
                        {formatToCurrency(position.artikelpreisStueck, order.waehrung)}
                        <br/>
                        {position.fashionGroessen && position.fashionGroessen.length ?
                            getLabelAndValueIfDefinied(position.fashionGroessen.join(', '), "fashionGroessen", true)
                            : null
                        }
                        {position.konfiguration.map((konf, index) => (
                            <Typography
                                key={'key:' + position.posNummer + konf.keyTextcode + konf.textcode + index}
                                variant="body2">
                                {getLabelAndValueIfDefinied(konf.textcode, "konfTextcode", true)}
                                {getLabelAndValueIfDefinied(konf.nameWF, "konfNameWF", true)}
                                {konf.veredelung && konf.anzahlFarben ? <>
                                    {getLabelAndValueIfDefinied(konf.veredelung, "konfVeredelung")}&nbsp;-&nbsp;
                                    {getLabelAndValueIfDefinied(konf.anzahlFarben, "konfAnzahlFarben", true)}
                                </> : null}
                                {konf.drkfarben && konf.drkfarben.length && konf.sonderfarben && konf.sonderfarben ? <>
                                    {getLabelAndValueIfDefinied(konf.drkfarben.join(', '), "konfDRKFarben")}&nbsp;-&nbsp;
                                    {getLabelAndValueIfDefinied(konf.sonderfarben.join(', '), "konfSonderfarben", true)}
                                </> : null}

                            </Typography>
                        ))}


                    </Grid>
                    <Grid md={2}
                          item><Typography>{formatToCurrency(fetchTotalNettoValue(position, order.positions), order.waehrung)}</Typography></Grid>
                    <Grid md={3}
                          item>
                        {digitalOrderStatusList.find((entry) => entry.orderId === order.auftragsNummer && entry.posNummer === position.posNummer)?
                            <OrderHistoryDabStatusIndicator position={position}
                                                            digitalOrderStatusList={digitalOrderStatusList}
                                                            orderId={order.auftragsNummer}/>
                            :
                            <>
                                <Typography><ContactOrderStatusIndicator
                                    status={position.gesamtStatusPos}
                                    cancellationReason={position.absagegrund}/></Typography>

                                {position.wunschlieferDatum ? getLabelAndValueIfDefinied(formatDateObj(position.wunschlieferDatum), 'wunschlieferDatum', true) : null}
                                {position.WarenausgangIST ? getLabelAndValueIfDefinied(formatDateObj(position.WarenausgangIST), 'WarenausgangIST', true) : null}
                                {position.groessenSetSendedatum ? getLabelAndValueIfDefinied(formatDate(position.groessenSetSendedatum), 'groessenSetSendedatum', true) : null}

                                {position.absagegrund ? <>
                                    {position.absagegrund} - {position.absagegrundBezeichnung}
                                    <br/>
                                </> : null}
                                {position.EKSSperrdatum && position.EKSSperrgrund ?
                                    <>
                                        <br/>
                                        <Label
                                            color="error">{formatDate(position.EKSSperrdatum)} - {t('eksSperrgrund.' + position.EKSSperrgrund)}</Label>
                                    </>
                                    : null}
                            </>}
                    </Grid>
                    <Grid md={2}
                          item><SendRefundPositionModal order={order}
                                                        customerName={contact.companyLine1}
                                                        position={position}/></Grid>
                </Grid>
            </Grid>)}</>
}