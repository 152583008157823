import Navigation from "../components/Navigation";
import {Button, Divider, FormControl, Grid, IconButton, Paper, TextField, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {formatDateObjWithDayOfWeek, parseDateFromURL} from "../components/Formatter";
import {Field, Formik} from "formik";
import {TimePicker} from "formik-material-ui-pickers";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import Activity from "../store/models/Activity";
import {
    differenceInMinutes,
    isBefore,
    setHours,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subMonths,
    subWeeks
} from "date-fns";
import Api from "../store/Api";
import BusinessExpenseReport, {BusinessExpenseReportStatus} from "../store/models/BusinessExpenseReport";
import {getMyADMID} from "../util/UserUtil";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import IStore from "../store/models/IStore";
import {
    thunkCreateBusinessExpenseReport,
    thunkGetMyBusinessExpenseReports,
    thunkUpdateBusinessExpenseReport
} from "../store/actions/BusinessExpenseReportActions";

const useStyles = makeStyles(theme => ({
    paperSmallKpi: {
        padding: theme.spacing(3),
        width: '100%',
        height: 100
    },
    paper: {
        padding: theme.spacing(3)
    },
    kpiValue: {
        fontSize: 24
    },
    kpiLabel: {
        fontSize: 14
    },
    kmInput: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    },
    activityDivider: {
        minHeight: 300
    }
}));

// const dummyKPIData = {
//     orders: 2,
//     meetings: 4,
//     followUps: 3,
//     revenue: 2345.78
// }


function computeWorkingHours(startWorkDate: Date | null, endWorkDate: Date | null) {
    if (startWorkDate && endWorkDate) {
        return Math.round((differenceInMinutes(new Date(endWorkDate), new Date(startWorkDate)) / 60 + Number.EPSILON) * 10) / 10;
    }
}

function computeKMTraveled(startKM: number, endKM: number) {
    return endKM - startKM;
}

function computeKMTraveledMinusPrivat(startKM: number, endKM: number, privateKM: number) {
    return endKM - startKM - privateKM;
}

export default function BusinessExpensesVBDetailView() {
    const classes = useStyles();

    const {forDate, berNumber} = useParams<{ forDate: string; berNumber: string }>();
    const dateObj = parseDateFromURL(forDate);
    const {t} = useTranslation();

    const berToEdit: BusinessExpenseReport | undefined = useSelector((state: IStore) => state.businessExpenseReports.myReports.find(r => r.berNumber === berNumber));
    const [myActivities, setMyActivities] = useState<Activity[]>([]);
    const [chosenActivities, setChosenActivities] = useState<Activity[]>(berToEdit ? berToEdit.activities : []);

    // const kpiData = dummyKPIData;

    const dispatch = useDispatch();


    let businessExpenseReport = berToEdit ? berToEdit : {
        forDate: dateObj,
        admID: getMyADMID(),
        managementStatus: BusinessExpenseReportStatus.REQUESTED,
        startWorkDate: setHours(dateObj, 9),
        endWorkDate: setHours(dateObj, 17),
        startKM: 0,
        endKM: 0,
        privateKM: 0,
        activities: [],
    };
    const initialFormValues: BusinessExpenseReport & { extraActivity: string } = {
        ...businessExpenseReport,
        extraActivity: ''
    };


    const history = useHistory();

    const cancel = () => {
        history.goBack();
    }

    useEffect(() => {
        if (!berToEdit && berNumber) {
            dispatch(thunkGetMyBusinessExpenseReports());
        }
    }, [dispatch, berToEdit, berNumber])

    useEffect(() => {
        Api.getMyActivities(forDate).then((activities: Activity[]) => {
            if (berToEdit) {
                setChosenActivities(berToEdit?.activities);
            }
            setMyActivities(activities.filter(a => {
                if (berToEdit) {
                    return berToEdit.activities.findIndex(act => act.stActivitiyID === a.stActivitiyID) === -1
                } else {
                    return true;
                }
            }))
        })
    }, [forDate, berToEdit])

    const chooseActivity = (activitiy: Activity) => {
        if (!activitiy.isCustomActivity) {
            setMyActivities(myActivities.filter(act => act.stActivitiyID !== activitiy.stActivitiyID))
        }
        setChosenActivities([...chosenActivities, activitiy]);
    }

    const removeActivity = (activitiy: Activity) => {
        setChosenActivities(chosenActivities.filter(act => act.stActivitiyID !== activitiy.stActivitiyID))
        if (!activitiy.isCustomActivity) {
            setMyActivities([...myActivities, activitiy]);
        }
    }

    const addExtraActivity = (extraActivity: string, setFieldValue: (field: string, value: any) => void) => {
        if (extraActivity && extraActivity.trim().length === 0) {
            return;
        }
        chooseActivity({
            stActivitiyID: uuid(),
            customerInfo: {
                customerName: "",
                street: "",
                houseNumber: "",
                place: "",
                zipCode: "",
                country: ""

            },
            date: dateObj,
            text: extraActivity,
            isCustomActivity: true
        })
        setFieldValue("extraActivity", "");
    }
    const isMoreThanSixWeeksAgo = businessExpenseReport && isBefore(businessExpenseReport.forDate, startOfDay(startOfWeek(subWeeks(new Date(), 6))))
    const isMoreThanSixMonthAgo = businessExpenseReport && isBefore(businessExpenseReport.forDate, startOfDay(startOfMonth(subMonths(new Date(), 6))))
    const notEditable = (isMoreThanSixWeeksAgo && !berToEdit) || (berToEdit && isMoreThanSixMonthAgo && (berToEdit.managementStatus === BusinessExpenseReportStatus.ACCEPTED
        || berToEdit.managementStatus === BusinessExpenseReportStatus.DECLINED));

    // const dispatch = useDispatch();

    return (<>
        <Navigation backBtn={true} breadcrumbName={''}>
            <Grid container spacing={4}>
                <Grid item xs={12}><Typography variant={"h5"}
                                               align={"center"}>{formatDateObjWithDayOfWeek(dateObj)}</Typography></Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {berNumber && !berToEdit ? <Typography>Lade Daten</Typography> :
                            <Formik
                                initialValues={initialFormValues}
                                onSubmit={async (values) => {
                                    if (notEditable) {
                                        return;
                                    }
                                    values.activities = chosenActivities;
                                    if (berToEdit) {
                                        await dispatch(thunkUpdateBusinessExpenseReport(values));
                                    } else {
                                        await dispatch(thunkCreateBusinessExpenseReport(values))
                                    }
                                    history.goBack()
                                }}
                            >
                                {({
                                      values,
                                      handleChange,
                                      setFieldValue,
                                      handleSubmit,
                                  }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>


                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Typography variant={"h6"}>
                                                        {t('businessExpensesVBDetailView.chooseWorkingHoursHeadline')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <Field
                                                        component={TimePicker}
                                                        inputVariant={"outlined"}
                                                        name="startWorkDate"
                                                        value={values.startWorkDate}
                                                        label={t('businessExpensesVBDetailView.workStartLbl')}
                                                        format={'dd.MM.yyyy HH:mm'}
                                                        ampm={false}
                                                        autoOk
                                                    />
                                                </Grid>
                                                <Grid item md={3}>
                                                    <Field
                                                        component={TimePicker}
                                                        inputVariant={"outlined"}
                                                        name="endWorkDate"
                                                        value={values.endWorkDate}
                                                        label={t('businessExpensesVBDetailView.workEndLbl')}
                                                        format={'dd.MM.yyyy HH:mm'}
                                                        ampm={false}
                                                    />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <br/>
                                                    {values.startWorkDate && values.endWorkDate ? t('businessExpensesVBDetailView.workingHoursChosen', {hours: computeWorkingHours(values.startWorkDate, values.endWorkDate)}) : t('businessExpensesVBDetailView.noWorkingHoursChosen')}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant={"h6"}>
                                                        {t('businessExpensesVBDetailView.kmTraveledHeadline')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        className={classes.kmInput}
                                                        variant={"outlined"}
                                                        name="startKM"
                                                        type={'number'}
                                                        label={t('businessExpensesVBDetailView.startKMLbl')}
                                                        value={values.startKM}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        className={classes.kmInput}
                                                        variant={"outlined"}
                                                        name="endKM"
                                                        type={'number'}
                                                        label={t('businessExpensesVBDetailView.endKMLbl')}
                                                        value={values.endKM}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <br/>
                                                    <Typography>{t('businessExpensesVBDetailView.kmLabel', {km: computeKMTraveled(values.startKM, values.endKM)})}</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        className={classes.kmInput}
                                                        variant={"outlined"}
                                                        name="privateKM"
                                                        type={'number'}
                                                        label={t('businessExpensesVBDetailView.privateKMLbl')}
                                                        value={values.privateKM}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <br/>
                                                    <Typography>{t('businessExpensesVBDetailView.kmLabel', {km: computeKMTraveledMinusPrivat(values.startKM, values.endKM, values.privateKM)})}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {values.managementNote ?
                                                        <>
                                                            <br/>
                                                            <Typography
                                                                variant={"h6"}>{t('businessExpensesVBDetailView.noteHeadline')}</Typography>
                                                            <Typography> {values.managementNote}</Typography>
                                                        </>
                                                        : null}
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant={"h6"} align={"center"}>
                                                        {t('businessExpensesVBDetailView.chooseActivitiesHeadline')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography variant={"h6"} align={"center"}>
                                                        {t('businessExpensesVBDetailView.myActivitiesHeadline')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                                <Grid container item xs={5} direction={"row"}
                                                      alignContent={"flex-start"}
                                                      spacing={3}>
                                                    {
                                                        myActivities.map(act => <Grid
                                                            key={'myActivity' + act.stActivitiyID}
                                                            item container>
                                                            <Grid item xs={10}>
                                                                <Typography>{act.text}</Typography>
                                                                <Typography>{act.customerInfo.customerName}</Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <IconButton size={"small"} color={"primary"}
                                                                            onClick={() => chooseActivity(act)}>
                                                                    <ArrowForwardIosIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>)
                                                    }
                                                </Grid>
                                                <Grid className={classes.activityDivider} item xs={1}><Divider
                                                    orientation={"vertical"}/></Grid>
                                                <Grid container item xs={6} direction={"row"}
                                                      alignContent={"flex-start"}
                                                      spacing={3}>
                                                    <Grid item xs={8}>

                                                        <FormControl fullWidth>
                                                            <TextField
                                                                variant={"outlined"}
                                                                name="extraActivity"
                                                                type={'text'}
                                                                label={t('businessExpensesVBDetailView.extraActivity')}
                                                                value={values.extraActivity}
                                                                onKeyDown={(keyEvent) => {
                                                                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                                                        keyEvent.preventDefault();
                                                                        addExtraActivity(values.extraActivity, setFieldValue)
                                                                    }
                                                                }}
                                                                onChange={handleChange}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <IconButton size={"medium"} color={"primary"}
                                                                    onClick={() => addExtraActivity(values.extraActivity, setFieldValue)}>
                                                            <AddIcon/>
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {
                                                            chosenActivities.map(act => <Grid
                                                                key={'chosenActivity' + act.stActivitiyID} item
                                                                container>
                                                                <Grid item xs={10}>
                                                                    <Typography>{act.text}</Typography>
                                                                    <Typography>{act.customerInfo.customerName}</Typography>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <IconButton size={"small"} color={"primary"}
                                                                                onClick={() => removeActivity(act)}>
                                                                        <ClearIcon/>
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>)
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} style={{display: 'flex'}}>
                                                    <div style={{flex: 1}}></div>
                                                    <Button onClick={() => cancel()}>
                                                        {t('common.cancelBtn')}
                                                    </Button>
                                                    <Button variant={"contained"} disabled={notEditable} type={"submit"}
                                                            color={"primary"}>
                                                        {t(`businessExpensesVBDetailView.${berToEdit ? 'editBtn' : 'applyBtn'}`)}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>)
                                }}
                            </Formik>}
                    </Paper>
                </Grid>
            </Grid>
        </Navigation>
    </>)
}

