import React from 'react';
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

export const allIndustryCodesArr = [
    "0100", "0500", "1000", "1500", "1700", "1900", "2000", "2100", "2300", "2400", "2500", "2600", "2700", "2900", "3000", "3400", "3600", "3700", "4000", "4510", "4521", "4522", "4523", "4524", "4525", "4531", "4532", "4533", "4534", "4541", "4542", "4543", "4544", "4545", "4550", "5000", "5100", "5210", "5220", "5230", "5240", "5250", "5260", "5270", "5500", "6000", "6100", "6200", "6310", "6320", "6330", "6340", "6400", "6500", "7000", "7100", "7200", "7300", "7410", "7420", "7430", "7440", "7450", "7460", "7470", "7480", "7500", "8000", "8500", "9000", "9100", "9200", "9301", "9302", "9303", "9304", "9305"
]

interface IndustryCodeDropdownProps {
    name: string;
    setValue: (value?: string) => void;
    value: string;
    variant?: 'standard' | 'outlined'| 'filled';
}

interface IndustryCodeOption {
    code: string,
    label: string
}

function fetchIndustryCodeOptions() {
    const industryCodeOptions: IndustryCodeOption[] = [];
    allIndustryCodesArr.forEach(code => {
        for (let i = 0; i < 40; i++) {
            const identifier = 'industryCodes.' + code + '.alt' + i;
            const label = i18next.t(identifier);
            if (label === identifier) {
                break;
            }
            industryCodeOptions.push({code, label})
        }
    })
    return industryCodeOptions;
}

export default function IndustryCodeDropdown({value, setValue, name,variant = 'standard'}: IndustryCodeDropdownProps) {
    const {t} = useTranslation();

    const industryCodeOptions: IndustryCodeOption[] = React.useMemo(fetchIndustryCodeOptions, [])
    const valueOption = industryCodeOptions.find(option => option.code === value) || null;

    return <Autocomplete
        onChange={(event: any, newValue: IndustryCodeOption | null) => {
            setValue(newValue?.code);
        }}
        value={valueOption}
        options={industryCodeOptions}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.label === value.label}
        style={{width: "155%"}} //TODO ...warum auch immer das klappt
        renderInput={(params) =>
            <TextField {...params} name={name}
                       label={t('industryCodeDropdown.lbl')}
                       variant={variant}
            />}
    />
}
