import {observable, action, decorate} from 'mobx';
import { IProductListItem } from "./models/IProduct";

const FILTER_KEY_MAP = [
    ['q', 'search'],
    ['c', 'color'],
    [ 'pmin', 'minPrice' ],
    [ 'pmax', 'maxPrice' ],
    [ 'cat_id', 'catId' ],
]

class ProductListStore {
    products: IProductListItem[] = []
    loading = false
    loadedIdx = 0
    loadedAll = false
    filter: any = {
        search: "",
        color: "",
        minPrice: "",
        maxPrice: "",
        catId: "",
    }
    getFilterObject = () => {
        const filterObject: any = {};

        for (const [pkey, fkey] of FILTER_KEY_MAP) {
            if (this.filter[fkey] !== "" && this.filter[fkey] !== undefined) {
                filterObject[pkey] = this.filter[fkey];
            }
        }

        return filterObject;
    }
    setFilterObject = (params: any) => {
        for (const [pkey, fkey] of FILTER_KEY_MAP) {
            if (params[pkey] !== undefined) {
                this.filter[fkey] = params[pkey];
            }
        }
    }
    loadProductData = (productData: IProductListItem[]) => {
        if (this.loadedAll) return;

        if (productData?.length) {
            this.products.push(...productData);
            this.loadedIdx += productData.length;
        } else {
            this.loadedAll = true;
        }
        this.loading = false
    }
}



decorate(ProductListStore, {
    products: observable,
    loading: observable,
    loadProductData: action,
    filter: observable,
})

export default new ProductListStore();
