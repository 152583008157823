import IAction from "../actions/IAction";
import {TOOGLE_DRAWER} from "../actions/UIActions";


export interface IUIReducerState {
    drawerOpen: boolean;
}

const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

export default class UIReducer {
    public static readonly INITIAL_STATE: IUIReducerState = {drawerOpen: vw > 1280};

    public static reducer(
        state: IUIReducerState = UIReducer.INITIAL_STATE,
        action: IAction<any>
    ): IUIReducerState {
        switch (action.type) {
            case TOOGLE_DRAWER:
                return {...state, drawerOpen: ! state.drawerOpen};
            default:
                return state;
        }
    }
}
