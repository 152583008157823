import React, {useRef, useState} from 'react';
import {TouchpointResultType} from "../../../store/models/TouchpointEnums";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@material-ui/core";
import TouchpointTab from "./TouchpointTab";
import {useTranslation} from "react-i18next";
import {ContentState, convertFromHTML, convertToRaw} from "draft-js";
import {stateFromHTML} from 'draft-js-import-html';
import {Field} from "formik";
import {DateTimePicker} from "formik-material-ui-pickers";
import EmailPicker from "../EmailPicker";
import {CustomText, CustomTextPopover} from "../InsertTextPopover";
import {renderToString} from "react-dom/server";
import MUIRichTextEditor from "mui-rte";
import {ImportContacts} from "@material-ui/icons";
import {stateToHTML} from "draft-js-export-html";
import {addHours, isAfter} from "date-fns";
import {ToDo, ToDoType} from "../../../store/models/ToDo";
import {Note, NoteType} from "../../../store/models/Note";
import {useDispatch, useSelector} from "react-redux";
import Contact from "../../../store/models/Contact";
import IMeeting, {MeetingType} from "../../../store/models/IMeeting";
import {GREY} from "../../../BerendsohnMUITheme";
import {useHistory} from "react-router-dom";
import {thunkCreateCalendarEvents} from "../../../store/actions/CalendarEventActions";
import IStore from "../../../store/models/IStore";
import {getMyADMID} from "../../../util/UserUtil";
import {formatDateOnly, formatDateTimeOnly} from "../../Formatter";
import * as Yup from "yup";
import i18n from "../../../i18n";

interface MeetingTabProps {
    touchpointResultType: TouchpointResultType;
    contact?: Contact;
    emailPickerMails: string[];
}

export const meetingTypeOptions = [
    {
        value: MeetingType.PhoneCall,
        label: i18n.t('contactDetailTouchpointTab.meetingTypesDropdownOptions.PHONE_CALL')
    },
    {
        value: MeetingType.VideoCall,
        label: i18n.t('contactDetailTouchpointTab.meetingTypesDropdownOptions.VIDEO_CALL')
    },
    {
        value: MeetingType.InPerson,
        label: i18n.t('contactDetailTouchpointTab.meetingTypesDropdownOptions.IN_PERSON')
    }
]


export default function MeetingTab({touchpointResultType, contact, emailPickerMails}: MeetingTabProps) {
    const {t} = useTranslation();
    const ref = useRef(null)
    const sampleMarkup = ''
    const contentHTML = convertFromHTML(sampleMarkup)
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
    const content = JSON.stringify(convertToRaw(state))
    const [editorState, setEditorState] = useState(content);
    const myADMID = getMyADMID();
    const admData = useSelector((state: IStore) => state.adms.adms.find(adm => adm.sapID === myADMID))
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const history = useHistory();

    const dispatch = useDispatch();

    return (<TouchpointTab
        tabIndex={TouchpointResultType.Meeting}
        activeTab={touchpointResultType}
        validationSchema={Yup.object().shape({
            startDate: Yup.string()
                .nullable()
                .required(t('validation.required')),
        })}
        handleSubmit={async (values) => {
            if (values.bodyEditorState) {
                // @ts-ignore
                values.body = stateToHTML(values.bodyEditorState.getCurrentContent());
            }
            const newMeeting: IMeeting = {
                attendees: values.sendMail ? values.attendees : '',
                endDate: values.endDate,
                meetingType: values.meetingType,
                location: "",
                startDate: values.startDate,
                subject: values.subject,
                body: values.body,
                forCustomer: contact?.stContactID || '',
                forCustomerDisplayName: contact?.companyLine1 || ''
            }


            const newNote: Note = {
                contactNumber: contact?.stContactID || '',
                created: new Date(),
                private: false,
                text: values.note,
                type: NoteType.TouchpointResult,
                meetingDetails: {
                    meetingStartDate: values.startDate,
                    meetingEndDate: values.endDate,
                    meetingType: values.meetingType,
                    todoType: ToDoType.MSOfficeAppointment
                },
                touchpointResultType
            }

            const newToDo: ToDo = {
                admID: "",
                created: new Date(),
                forCustomer: contact?.stContactID,
                text: t('contactDetailTouchpointTab.meetingTypesDropdownOptions.' + values.meetingType) + ' - ' + contact?.companyLine1,
                dueOn: values.startDate,
                type: ToDoType.MSOfficeAppointment,
                meetingProps: {
                    endDate: values.endDate,
                    meetingType: values.meetingType,
                    msMeetingID: 'TODO', //TODO: Add MS Meeting ID in BE
                    withMail: values.sendMail,
                    customerDisplayName: contact?.companyLine1 || '',
                    customerCity: contact?.address.place || '',
                    customerPlz: contact?.address.zipCode || ''
                }
            };
            try {
                await dispatch(thunkCreateCalendarEvents(newMeeting, newToDo, newNote));
            } catch (e) {
                console.error(e)
                return;
            }
            history.goBack();
        }}
        initialValues={{
            note: t('contactDetailTouchpointTab.meetingNoteDefaultValue'),
            startDate: null,
            endDate: null,
            sendMail: false,
            emails: '',
            subject: t('contactDetailTouchpointTab.meetingSubjectDefaultValue', {customerName: contact?.companyLine1}),
            attendees: '',
            body: '',
            bodyEditorState: null,
            meetingType: MeetingType.PhoneCall,
            decisionMaker: false

        }}
    >
        {((values, handleChange, setFieldValue, isSubmitting, errors, touched) => {
                if ((values.startDate && !values.endDate) || isAfter(values.startDate, values.endDate)) {
                    values.endDate = addHours(values.startDate, 1)
                }
                return (<Grid container spacing={3}>
                    <Grid container item md={6} spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel>{t('contactDetailTouchpointTab.meetingTypeLbl')}</InputLabel>
                            <Select
                                name="meetingType"
                                variant={"outlined"}
                                value={values.meetingType}
                                onChange={handleChange}
                                fullWidth
                            >
                                {meetingTypeOptions.map(mT => <MenuItem
                                    key={'meetingTypeOption' + mT.label}
                                    value={mT.value}>{mT.label}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                component={DateTimePicker}
                                inputVariant={"outlined"}
                                name="startDate"
                                value={values.startDate}
                                label={t('contactDetailTouchpointTab.formStartLbl')}
                                format={'dd.MM.yyyy HH:mm'}
                                ampm={false}
                                autoOk
                                error={!!errors.startDate && touched.startDate}
                                helperText={(errors.startDate && touched.startDate) && errors.startDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                component={DateTimePicker}
                                inputVariant={"outlined"}
                                name="endDate"
                                value={values.endDate}
                                label={t('contactDetailTouchpointTab.formEndLbl')}
                                format={'dd.MM.yyyy HH:mm'}
                                ampm={false}
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField
                                multiline={true}
                                rows={3}
                                variant={"outlined"}
                                name="note"
                                label={t('contactDetailTouchpointTab.noteLbl')}
                                value={values.note}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3}>
                        <FormControlLabel
                            control={<Switch checked={values.sendMail} onChange={handleChange} name="sendMail"
                                             color={"primary"}/>}
                            label={t('contactDetailTouchpointTab.sendMailLbl')}
                        />
                    </Grid>
                    <Grid item md={9}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.decisionMaker}
                                    onChange={handleChange}
                                    name="decisionMaker"
                                    color="primary"
                                />
                            }
                            label={t('contactDetailTouchpointTab.decisionMakerLbl')}
                        />
                    </Grid>

                    {values.sendMail &&
                        <>
                            <Grid item xs={12}>
                                <EmailPicker updateValue={mails => setFieldValue('attendees', mails.join(','))}
                                             emails={emailPickerMails}/>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="subject"
                                        variant={"outlined"}
                                        label={t('createMeetingModal.formSubjectLbl')}
                                        value={values.subject}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12}
                                  style={{border: '1px solid', borderColor: GREY, margin: 10, borderRadius: 5}}>
                                <CustomTextPopover
                                    anchor={anchor}
                                    onSubmit={(input, insert) => {
                                        if (insert) {
                                            const data = renderToString(<CustomText admData={admData}
                                                                                    dateString={formatDateOnly(values.startDate)}
                                                                                    timeString={formatDateTimeOnly(values.startDate)}
                                                                                    blockProps={input}/>)
                                            const state = stateFromHTML(data);
                                            const content = JSON.stringify(convertToRaw(state))
                                            setEditorState(content);
                                        }
                                        setAnchor(null)
                                    }}
                                />
                                <InputLabel>{t('createMeetingModal.formBodyLbl')}</InputLabel>
                                <MUIRichTextEditor
                                    ref={ref}
                                    defaultValue={editorState}
                                    label={t('createMeetingModal.formBodyEditorPlaceholder')}
                                    onChange={data => setFieldValue("bodyEditorState", data)}
                                    controls={["title", "bold", "underline", "add-text"]}
                                    customControls={[
                                        {
                                            name: "add-text",
                                            icon: <ImportContacts/>,
                                            type: "callback",
                                            onClick: (_editorState, _name, anchor) => {
                                                setAnchor(anchor)
                                            }
                                        }
                                    ]}
                                />
                                <br/>
                                <br/>
                                <br/>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <Button disabled={isSubmitting} variant={"contained"} color={"primary"}
                                type={"submit"}>{t('common.saveBtn')}</Button>
                    </Grid>
                </Grid>)
            }
        )}
    </TouchpointTab>)
}
