import React, {useState} from 'react';
import SearchIcon from "@material-ui/icons/Search";
import {Box, Button, FormControl, Grid, IconButton, InputAdornment, Slider, Typography} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch} from "react-redux";
import ContactDetailSearch, {emptyContactDetailSearch} from "../../store/models/ContactDetailSearch";
import {thunkDetailSearchContacts} from "../../store/actions/ContactActions";
import Modal from "../Modal";
import {LocationDisabled, MyLocation} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import AddressAutocomplete from './AddressAutocomplete';

interface ContactDetailSearchModalProps {
    currentDetailSearch?: ContactDetailSearch;
    setIsLoading: (isLoading: boolean) => void
}

const useStyles = makeStyles({
    root: {
        marginTop: '17px',
    },
    valueLabel: {
        '& *': {
            background: 'transparent',
            color: '#000',
            fontSize: 14,
            width: 50,

        },
    },
});

export default function ContactLocationSearchModal({currentDetailSearch, setIsLoading}: ContactDetailSearchModalProps) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [useLocation, setUseLocation] = useState((currentDetailSearch && currentDetailSearch?.geoRestriction.useLocation) || false);

    const {t} = useTranslation();

    return (
        <Modal title={t('contactLocationSearchModal.searchContactsHeadline')} fullWidth={true} maxWidth={"md"}
               contentAndButtons={(handleClose) => (
                   <>
                       <DialogContent>
                           <Formik
                               initialValues={currentDetailSearch || emptyContactDetailSearch}
                               validate={values => {
                                   if (values.geoRestriction.address) {
                                       if (typeof values.geoRestriction.address === 'string' && values.geoRestriction.address !== t('contactLocationSearchModal.geoRestriction.myAddress')) {
                                           return {geoRestriction: {address: t('contactLocationSearchModal.selectAddressFromDropdown')}};
                                       }
                                   }
                               }}
                               onSubmit={async (values) => {
                                   setIsLoading(true);
                                   handleClose();
                                   await dispatch(thunkDetailSearchContacts({
                                       ...emptyContactDetailSearch,
                                       geoRestriction: {...values.geoRestriction, useLocation}
                                   }, 1))
                                   setIsLoading(false);
                               }}
                           >
                               {({
                                     values,
                                     handleChange,
                                     handleSubmit,
                                     errors,
                                     setTouched,
                                     resetForm,
                                     setFieldValue,
                                 }) => (
                                   <form id="contactDetailSearchForm" onSubmit={handleSubmit}
                                         onReset={() => resetForm({values: emptyContactDetailSearch})}>
                                       <Box m={3}>
                                           <Grid container spacing={5}>
                                               <Grid item md={12}>
                                                   <Typography
                                                       variant={"h5"}>{t('contactLocationSearchModal.geoRestriction.surroundingHeadline')}</Typography>
                                               </Grid>
                                               <Grid item md={12} container spacing={5}>
                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           {errors.geoRestriction?.address &&
                                                           <div>{errors.geoRestriction!.address}</div>
                                                           }
                                                           <AddressAutocomplete
                                                               name="geoRestriction.address"
                                                               getOptionLabel={(option: any) => {
                                                                   return option && option.hasOwnProperty("address") ? option.address : option
                                                               }}
                                                               beforeChange={() => {
                                                                   setUseLocation(false)
                                                                   setTouched({
                                                                       geoRestriction: {
                                                                           address: true,
                                                                           location: false
                                                                       }
                                                                   })
                                                               }}
                                                               endAdornment={
                                                                   <InputAdornment position="end">
                                                                       <IconButton
                                                                           aria-label={t("contactLocationSearchModal.geoRestriction.myAddress")}
                                                                           onClick={() => {
                                                                               navigator.geolocation.getCurrentPosition(function (position) {
                                                                                   setFieldValue("geoRestriction.location", {
                                                                                       type: "Point",
                                                                                       coordinates: [position.coords.longitude, position.coords.latitude]
                                                                                   })
                                                                                   setUseLocation(true);
                                                                                   setFieldValue("geoRestriction.address", "Mein Standort");
                                                                                   setTouched({
                                                                                       geoRestriction: {
                                                                                           address: true,
                                                                                           location: true
                                                                                       }
                                                                                   });
                                                                               }, (e) => {
                                                                                   setUseLocation(false);
                                                                               });
                                                                           }}>
                                                                           {useLocation ? <MyLocation/> :
                                                                               <LocationDisabled/>}
                                                                       </IconButton>
                                                                   </InputAdornment>
                                                               }
                                                           />
                                                       </FormControl>
                                                   </Grid>

                                                   <Grid item md={6}>
                                                       <FormControl fullWidth>
                                                           <Slider
                                                               name="geoRestriction.distanceKm"
                                                               defaultValue={5}
                                                               value={values.geoRestriction.distanceKm}
                                                               onChange={(event: any, newVal: any) => setFieldValue('geoRestriction.distanceKm', newVal)}
                                                               getAriaValueText={(val) => `${val} KM`}
                                                               aria-labelledby="discrete-slider-always"
                                                               step={null}
                                                               classes={{
                                                                   root: classes.root,
                                                                   valueLabel: classes.valueLabel
                                                               }}
                                                               valueLabelFormat={value => `${value} KM`}
                                                               max={50}
                                                               marks={[
                                                                   {
                                                                       value: 1,
                                                                       label: '1',
                                                                   },
                                                                   {
                                                                       value: 5,
                                                                       label: '5',
                                                                   },
                                                                   {
                                                                       value: 10,
                                                                       label: '10',
                                                                   },
                                                                   {
                                                                       value: 20,
                                                                       label: '20',
                                                                   },
                                                                   {
                                                                       value: 50,
                                                                       label: '50',
                                                                   }
                                                               ]}
                                                               valueLabelDisplay="on"
                                                           />
                                                       </FormControl>
                                                   </Grid>
                                               </Grid>
                                           </Grid>
                                       </Box>
                                   </form>
                               )}
                           </Formik>
                       </DialogContent>
                       <DialogActions>
                           <Button form="contactDetailSearchForm" type={'reset'} onClick={() => {
                               setUseLocation(false);
                           }}
                                   variant={"text"}
                                   color={"primary"}>
                               {t('contactLocationSearchModal.resetFormBtn')}
                           </Button>
                           <Button onClick={handleClose}>
                               {t('common.cancelBtn')}
                           </Button>
                           <Button form="contactDetailSearchForm" type="submit" color={"primary"}>
                               {t('contactLocationSearchModal.searchBtn')}
                           </Button>
                       </DialogActions>
                   </>
               )} openButton={(handleOpen) => (
            <Button
                variant="contained"
                color="primary"
                startIcon={<SearchIcon/>}
                onClick={handleOpen}
            >
                {t('contactLocationSearchModal.openDetailSearchModalBtn')}
            </Button>
        )}/>
    );
}
