export default interface ExtendedVBKpiDto {
    vbNumber: string;
    paisyAEThisMonth: number;
    paisyAEThisYear: number;
    orderRevenueThisMonth: number;
    orderRevenueDesiredToTotal: OrderRevenueDesiredToTotal[];
    desiredOrderRevenueThisMonth: number;
    averageOrderRevenueThisYear: number;
    newContacts: number;
    activeContactsThisYear: number;
    protectedContacts: number;
    assignedContacts: number;
    topsellerVB: TopsellerDto[];
    topsellerCompany: TopsellerDto[];
    ordersInThisMonth?: number
    ordersInThisYear?: number
}

export interface OrderRevenueDesiredToTotal {
    month: number;
    is: number;
    should: number;
}

export interface TopsellerDto {
    _id: string;
    count: number;
}

export interface FunnelKPIDto {
    activeContacts: number;
    followUps: number;
    totalMeetingsThisYear: number;
    contactsGreyProspect: number;
}

export const emptyFunnelKpiDto = {
    activeContacts: 0,
    followUps: 0,
    totalMeetingsThisYear: 0,
    contactsGreyProspect: 0,
}

export const emptyExtendedVBKpiDto: ExtendedVBKpiDto & FunnelKPIDto = {
    vbNumber: '',
    activeContacts: 0,
    followUps: 0,
    totalMeetingsThisYear: 0,
    contactsGreyProspect: 0,
    desiredOrderRevenueThisMonth: 0,
    orderRevenueDesiredToTotal: [],
    orderRevenueThisMonth: 0,
    paisyAEThisMonth: 0,
    paisyAEThisYear: 0,
    averageOrderRevenueThisYear: 0,
    activeContactsThisYear: 0,
    assignedContacts: 0,
    newContacts: 0,
    protectedContacts: 0,
    topsellerCompany: [],
    topsellerVB: [],
}

export type ExtendedManagerKpiDto = ExtendedVBKpiDto & FunnelKPIDto & {
    vbData: VBKPICardDto[]
}

export interface VBKPICardDto {
    vbNumber: string;
    name: string;
    salesTotal: number;
    meetingsNextTenDays: number;
    numberOfOrders: number;
    activeCustomers: number;
    followUps: number;
}
