import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export default function FormikPatchTouched() {
    const { errors, setFieldTouched, isSubmitting, isValidating } = useFormikContext();
    useEffect(() => {
        if (isSubmitting && !isValidating) {
            for (const path of Object.keys(flatten(errors))) {
                setFieldTouched(path, true, false);
            }
        }
    }, [errors, isSubmitting, isValidating, setFieldTouched]);
    return null;
};


function flatten (target : any) {

    function isBuffer (obj : any) {
        return obj &&
            obj.constructor &&
            (typeof obj.constructor.isBuffer === 'function') &&
            obj.constructor.isBuffer(obj)
    }

    function keyIdentity (key : string) {
        return key
    }


    const delimiter = '.'
    const transformKey = keyIdentity
    const output : any = {}

    function step (object: any, prev?: any, currentDepth = 0) {
        currentDepth = currentDepth || 1
        Object.keys(object).forEach(function (key) {
            const value = object[key]
            const isarray = Array.isArray(value)
            const type = Object.prototype.toString.call(value)
            const isbuffer = isBuffer(value)
            const isobject = (
                type === '[object Object]' ||
                type === '[object Array]'
            )

            const newKey = prev
                ? prev + delimiter + transformKey(key)
                : transformKey(key)

            if (!isarray && !isbuffer && isobject && Object.keys(value).length) {
                return step(value, newKey, currentDepth + 1)
            }

            output[newKey] = value
        })
    }

    step(target)

    return output
}