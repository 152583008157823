import React, {useEffect, useState} from "react";
import Navigation from "../components/Navigation";
import {Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import Label from '../components/Label'
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {emptyOrder} from "../store/models/Order";
import {useDispatch} from "react-redux";
import Api from "../store/Api";
import {Link, useParams} from "react-router-dom";
import DetailedOrder from "../store/models/DetailedOrder";
import {formatDate, formatDateObj, formatToCurrency} from "../components/Formatter";
import Kaz from "../store/models/KAZ";
import KazListItem from "../components/KazListItem";

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        padding: theme.spacing(2, 2),
        marginBottom: 10,
        maxWidth: 1200
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'
    },
    paperWithHeader: {
        padding: theme.spacing(1, 3, 3, 3)
    },
    divider: {
        margin: theme.spacing(2, 0, 3, 0)
    },
    orderValue: {
        paddingBottom: 20,
        textAlign: "right"
    },
    salesSupport: {
        textAlign: "right"
    },
    kazHeadline: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    }
}));


function OrderDetailsView() {
    const {orderID} = useParams<{ orderID: string }>();

    const [detailedOrder, setDetailedOrder] = useState<DetailedOrder>({
        order: emptyOrder,
        contactName: '',
        stContactID: ''
    });
    const [kaz, setKaz] = useState<Kaz[]>([]);
    const order = detailedOrder.order;
    const dispatch = useDispatch();
    const sapContactID = order.kundenNummer;
    useEffect(() => {
        Api.getDetailedOrder(orderID).then(orderFromApi => {
            setDetailedOrder(orderFromApi)
        });
        Api.getKAZForOrder(orderID, sapContactID).then(kazFromApi => {
            setKaz(kazFromApi);
        });
    }, [dispatch, orderID, sapContactID])

    const classes = useStyles();
    const {t} = useTranslation();

    function getLabelAndValueIfDefinied(value: string, labelKey: string, withNewLine?: boolean) {
        if (value) {
            return <>
                {t('orderDetailsView.' + labelKey)}<span>{value}</span>
                {withNewLine ? <br/> : null}
            </>
        } else {
            return null;
        }
    }


    function posIndicator(status: string, cancellationReason?: string) {
        if(cancellationReason){
            return <Label color="error">{t('orderDetailsView.positionCancelled')}</Label>
        }
        if (status === "vollständig bearbeitet") {
            return <Label color="success">{t('orderDetailsView.positionCompleted')}</Label>
        }
        if (status === "vollständig bearbeitet") {
            return <Label color="success">{t('orderDetailsView.positionCompleted')}</Label>
        }
        if (status === "teilweise bearbeitet") {
            return <Label color="warning">{t('orderDetailsView.positionPartlyCompleted')}</Label>
        }
        if (status === "nicht bearbeitet") {
            return <Label color="error">{t('orderDetailsView.positionNotProcessed')}</Label>
        }
        if (order.gesamtStatus === "nicht relevant") {
            return <Label color="primary">{t('orderDetailsView.positionNotRelevant')}</Label>
        }
        return <p>Unmapped Status: {status}</p>
    }


    return (<>
        <Navigation backBtn={true} breadcrumbName={'Einstellungen'}>

            <Paper className={classes.paper}>
                {!order ? t('common.loadingContent') : <>
                    <Box minWidth={800} p={6}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h6">
                                    No. {order.auftragsNummer}
                                </Typography>
                                <Typography variant="body1">
                                    {formatDateObj(order.bestellDatum)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" className={classes.orderValue}>
                                    {t('orderDetailsView.orderValue')}
                                </Typography>
                                <Typography variant="body1" className={classes.orderValue}>
                                    {formatToCurrency(order.auftragsNettowert, order.waehrung)}
                                </Typography>
                                <Typography variant="h6" className={classes.salesSupport}>
                                    {t('orderDetailsView.salesSupport')}
                                </Typography>
                                <Typography variant="body1" className={classes.salesSupport}>
                                    {order.innendienst}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box my={4}>
                            <Typography>
                                <Link to={`/contact/${detailedOrder.stContactID}`}>{detailedOrder.contactName}</Link>
                                <br/>
                                {order.nameBesteller}
                                <br/>
                                {order.telefonBesteller}
                                <br/>
                                {t('orderDetailsView.customerNumber')}
                                {order.kundenNummer}
                            </Typography>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        <b>Pos</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{t('orderDetailsView.positionDescription')}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{t('orderDetailsView.positionStatus')}</b>
                                        {order.positions.findIndex(p => p.EKSSperrdatum) >= 0 ?
                                            <>
                                                <br/>
                                                {t('orderDetailsView.positionLockedStatus')}
                                            </>
                                            : null}
                                    </TableCell>
                                    {/*<TableCell align="right">*/}
                                    {/*    <b>{t('orderDetailsView.positionQty')}</b>*/}
                                    {/*</TableCell>*/}
                                    <TableCell align="right">
                                        <b>{t('orderDetailsView.positionValue')}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{t('orderDetailsView.positionCommissionValue')}</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order.positions.map((pos) => (
                                    <TableRow key={pos.posNummer}>
                                        <TableCell align="left">
                                            {pos.posNummer}
                                        </TableCell>
                                        <TableCell>
                                            {pos.materialBezeichnung}
                                            <br/>
                                            {pos.materialNummer} / {pos.menge} {pos.mengenEinheit} / {formatToCurrency(pos.artikelpreisStueck, order.waehrung)}
                                            <br/>
                                            {pos.artikelFarbe}
                                            <br/>
                                            {pos.fashionGroessen && pos.fashionGroessen.length ?
                                                getLabelAndValueIfDefinied(pos.fashionGroessen.join(', '), "fashionGroessen", true)
                                                : null
                                            }
                                            {pos.konfiguration.map((konf) => (
                                                <Typography
                                                    key={'key:' + pos.posNummer + konf.keyTextcode + konf.textcode}
                                                    variant="body2">
                                                    {getLabelAndValueIfDefinied(konf.textcode, "konfTextcode", true)}
                                                    {getLabelAndValueIfDefinied(konf.nameWF, "konfNameWF", true)}
                                                    {konf.veredelung && konf.anzahlFarben ? <>
                                                        {getLabelAndValueIfDefinied(konf.veredelung, "konfVeredelung")}&nbsp;-&nbsp;
                                                        {getLabelAndValueIfDefinied(konf.anzahlFarben, "konfAnzahlFarben", true)}
                                                    </> : null}
                                                    {konf.drkfarben && konf.drkfarben.length && konf.sonderfarben && konf.sonderfarben ? <>
                                                        {getLabelAndValueIfDefinied(konf.drkfarben.join(', '), "konfDRKFarben")}&nbsp;-&nbsp;
                                                        {getLabelAndValueIfDefinied(konf.sonderfarben.join(', '), "konfSonderfarben", true)}
                                                    </> : null}

                                                </Typography>
                                            ))}
                                            {pos.terminart && pos.linie ? <>
                                                {getLabelAndValueIfDefinied(pos.terminart, "terminart")}&nbsp;-&nbsp;
                                                {getLabelAndValueIfDefinied(pos.linie, "linie", true)}
                                            </> : null}
                                            {getLabelAndValueIfDefinied(pos.qMeldungsnummer, 'qMeldung', true)}

                                        </TableCell>
                                        <TableCell align="right">
                                            {posIndicator(pos.gesamtStatusPos, pos.absagegrund)}
                                            {' '}
                                            <br/>
                                            {pos.wunschlieferDatum ? getLabelAndValueIfDefinied(formatDateObj(pos.wunschlieferDatum), 'wunschlieferDatum',true) : null}
                                            {pos.WarenausgangIST ? getLabelAndValueIfDefinied(formatDateObj(pos.WarenausgangIST), 'WarenausgangIST', true) : null}
                                            {pos.absagegrund ? <>
                                                {pos.absagegrund} - {pos.absagegrundBezeichnung}
                                                <br/>
                                            </> : null}
                                            {pos.EKSSperrdatum && pos.EKSSperrgrund?
                                                <>
                                                    <br/>

                                                    <Label color="error">{formatDate(pos.EKSSperrdatum)} - {t('eksSperrgrund.'+pos.EKSSperrgrund)}</Label>
                                                </>
                                                : null}
                                        </TableCell>
                                        {/*<TableCell align="right">*/}
                                        {/*    {pos.menge} x {pos.NettoStueckPreis}*/}
                                        {/*</TableCell>*/}
                                        <TableCell align="right">
                                            {formatToCurrency(pos.posNettowert, order.waehrung)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatToCurrency(pos.provisionsAE, order.waehrung)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell/>
                                    {/*<TableCell/>*/}
                                    <TableCell>
                                        <b>{t('orderDetailsView.orderValue')}</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{formatToCurrency(order.auftragsNettowert, order.waehrung)}</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {kaz.filter(k => k.orderNumber === order.auftragsNummer).length ?
                            <Grid container spacing={3}>
                                <Grid item xs={12} className={classes.kazHeadline}><Typography
                                    variant={"h5"}>{t("orderDetailsView.kazHeadline")}</Typography></Grid>
                                {kaz.filter(k => k.orderNumber === order.auftragsNummer).map(singleKaz =>
                                    <KazListItem kaz={singleKaz}/>
                                )}
                            </Grid>
                            : null}
                    </Box>
                </>}
            </Paper>
        </Navigation>
    </>)
}

export default OrderDetailsView;
