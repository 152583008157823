import React from 'react';
import {useTranslation} from "react-i18next";
import Modal from "../Modal";
import DialogContent from "@material-ui/core/DialogContent";
import {Field, Formik} from "formik";
import {Box, Button, DialogActions, FormControl, Grid, TextField} from "@material-ui/core";
import {DatePicker} from "formik-material-ui-pickers";
import Api from "../../store/Api";
import Contact from "../../store/models/Contact";
import * as Yup from "yup";

interface Props {
    contact: Contact;
    admId: string;
    afterSave: () => void
}

export default function CreateTerminatorFollowUpModal({contact, admId, afterSave}: Props) {
    const {t} = useTranslation();
    return (
        <Modal title={'Wiedervorlage für' + contact.companyLine1} contentAndButtons={(handleClose) => (
            <>
                <DialogContent>
                    <Formik
                        initialValues={{note: '', startDate: null}}
                        validationSchema={Yup.object().shape({
                            startDate: Yup.string()
                                .nullable()
                                .required(t('validation.required')),
                        })}
                        onSubmit={async (values) => {
                            await Api.createTerminatorFollowUp({
                                customerDisplayName: contact.companyLine1,
                                dueOn: values.startDate!,
                                forAdm: admId,
                                note: values.note,
                                forCustomer: contact.stContactID
                            })
                            afterSave();
                            handleClose();
                        }}
                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit,
                          }) => (
                            <form id={"createFollowUpForm"} onSubmit={handleSubmit}>
                                <Box>
                                    <Grid container direction={"column"} spacing={1}>
                                        <Grid item>
                                            <Field
                                                component={DatePicker}
                                                inputVariant={"outlined"}
                                                name="startDate"
                                                value={values.startDate}
                                                label={t('contactDetailTouchpointTab.formStartLbl')}
                                                format={'dd.MM.yyyy'}
                                                ampm={false}
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <TextField
                                                    multiline={true}
                                                    rows={3}
                                                    variant={"outlined"}
                                                    name="note"
                                                    label={t('contactDetailTouchpointTab.noteLbl')}
                                                    value={values.note}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('common.cancelBtn')}
                    </Button>
                    <Button type="submit" form="createFollowUpForm" color={"primary"}>
                        {t('common.saveBtn')}
                    </Button>
                </DialogActions>
            </>
        )} openButton={(handleOpen) => (
            <Button variant="text"
                    onClick={handleOpen}>Wiedervorlage</Button>
        )}/>

    );

}