import {ProductSearchItem} from "../models/ProductSearchItem";
import IAction from "../actions/IAction";
import {GET_PRODUCT_SEARCH_ITEM_SUCCESS} from "../actions/ProductSearchItemActions";


export interface IProductSearchItemReducerState {
    items: ProductSearchItem[]
}

export default class ProductSearchItemReducer {
    public static readonly INITIAL_STATE: IProductSearchItemReducerState = {
        items: []
    }

    public static reducer(
        state: IProductSearchItemReducerState = ProductSearchItemReducer.INITIAL_STATE,
        action: IAction<any>
    ): IProductSearchItemReducerState {
        switch (action.type) {
            case GET_PRODUCT_SEARCH_ITEM_SUCCESS:
                return {...state, items: action.payload}
            default:
                return state
        }
    }
}