import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import {Action} from "redux";
import Api from "../Api";


export const GET_ADMS_SUCCESS = 'GET_ADMS_SUCCESS';
export const GET_ADMS_ERROR = 'GET_ADMS_ERROR';

export const thunkGetAdms = (): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const adms = await Api.getAdms();
        dispatch({
            type: GET_ADMS_SUCCESS,
            payload: adms
        });
    } catch (e) {
        dispatch({
            type: GET_ADMS_ERROR,
            payload: e
        });
    }
};