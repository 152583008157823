import React from 'react';
import DailyAdmReport from "../../store/models/DailyAdmReport";
import {Grid} from "@material-ui/core";
import {formatDateObj, formatDateTimeOnly} from "../Formatter";
import {useTranslation} from "react-i18next";

interface DailyAdmReportSummaryRowProps {
    report: DailyAdmReport;
}

export default function DailyAdmReportSummaryRow({report}:DailyAdmReportSummaryRowProps){
    const {t} = useTranslation();

    return <Grid item container xs={12}>
        <Grid item xs={2}>
            {formatDateObj(report.forDate)}<br/>
            {formatDateTimeOnly(report.startDateTime)} - {formatDateTimeOnly(report.endDateTime)}
        </Grid>
        <Grid item xs={3}>
            {t('dailyAdmReportSummaryRow.distanceStart',{start: report.startKM})}<br/>
            {t('dailyAdmReportSummaryRow.distanceEnd',{end: report.endKM})}
        </Grid>
        <Grid item xs={7}>
            {report.note}
        </Grid>
    </Grid>
}
