import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { observer } from 'mobx-react-lite'
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Typography,
    FormLabel,
} from "@material-ui/core";
import Navigation from "../components/Navigation";
import ProductUserMenu from "../components/ProductUserMenu";
import { ProductList, ProductLoader } from "../components/ProductList";
import { SelectableChipsList, ColorSelect, BudgetSlider } from "../components/ProductFilters";
import useProductList from "../hooks/useProductList";
import Api from "../store/Api";

const useStyles = makeStyles((theme: Theme) => ({
    searchOptionLabel: {
        marginBottom: theme.spacing(2),
    },
    searchButton: {
        marginRight: theme.spacing(2)
    },
}));

export default observer(() => {
    const classes = useStyles();
    const {categoryID} = useParams<{categoryID:string}>();
    const productListStore = useProductList();
    const [categoryList, setCategoryList] = useState([]);
    const products = productListStore.products;
    const categoryData: any = categoryList.find(({ id }) => id === +categoryID);

    const handleProductLoad = async () => {
        if (!productListStore.loading && !productListStore.loadedAll) {
            productListStore.loading = true;
            if (productListStore.filter.minPrice || productListStore.filter.color) {
                await Api.getSearchProducts(productListStore.getFilterObject(), productListStore.loadedIdx).then(products =>
                    productListStore.loadProductData(products)
                );
            } else {
                await Api.getCategoryProducts(categoryID, productListStore.loadedIdx).then(products =>
                    productListStore.loadProductData(products)
                );
            }
        }
    }
    const handleCategoryChange = (id: any) => {
        window.history.pushState(null, '', window.location.href);
        window.location.replace(`/category/${id}`);
    }
    const handleBudgetChange = (_: any, newValue: any) => {
        const [minPrice, maxPrice] = newValue;
        productListStore.filter.minPrice = minPrice;
        productListStore.filter.maxPrice = maxPrice;
    };
    const handleColorChange = (ev: any) => {
        productListStore.filter.color = ev.target.value;
    };
    const handleSearch = async () => {
        productListStore.products = [];
        productListStore.loadedIdx = 0;
        productListStore.loadedAll = false;
        await Api.getSearchProducts(productListStore.getFilterObject(), productListStore.loadedIdx).then((products: any) => {
            productListStore.loadProductData(products)
        });
    }

    useEffect(() => {
        productListStore.products = [];
        productListStore.loadedAll = false;

        (async() => {
            setCategoryList(await Api.getCategoryListByParrentId(0));
        })();
    }, [categoryID, productListStore]);

    return (
        <Navigation breadcrumbName={'Kategorien'}>
            <Grid container spacing={3} >
                <Grid container item justifyContent="flex-end">
                    <ProductUserMenu />
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item>
                        <SelectableChipsList categories={categoryList} selected={categoryID} onChange={handleCategoryChange} />
                    </Grid>

                    {categoryData && categoryData.filter.color &&
                        <Grid item xs={12} md={6}>
                            <FormLabel component="legend" className={classes.searchOptionLabel}>Options</FormLabel>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <ColorSelect value={productListStore.filter.color} onChange={handleColorChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {categoryData && categoryData.filter.price &&
                        <Grid item xs={12} md={6}>
                            <Typography id="budget-slider" gutterBottom>
                                Budget
                            </Typography>
                            <BudgetSlider minPrice={productListStore.filter.minPrice} maxPrice={productListStore.filter.maxPrice} onChange={handleBudgetChange} />
                        </Grid>
                    }

                    {categoryData && (categoryData.filter.minPrice || categoryData.filter.color) &&
                        <Grid item xs={12} md={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                className={classes.searchButton}
                                onClick={() => handleSearch()}
                            >
                                Suchen
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid item>
                    {products ?
                        <>
                            <ProductList products={productListStore.products}  />
                            <ProductLoader loading={productListStore.loading} onLoadProducts={handleProductLoad} />
                        </>
                    : null}
                </Grid>
            </Grid>

        </Navigation>
    )
})
