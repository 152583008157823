import React from 'react';
import useAdmName from "../hooks/useAdmName";

interface IAdmNameProps {
    personalnummer: string
}


export function AdmName({personalnummer}: IAdmNameProps) {
    return <span>{useAdmName(personalnummer)}</span>;
}
