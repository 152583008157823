import React from 'react';
import {Tooltip} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    iconSize: {
        fontSize: '1rem'
    },
}));

interface HelpTextProps {
    children?: React.ReactNode;
    text: string;
    icon?: 'info' | 'help';
}

export const HelpText: React.FunctionComponent<HelpTextProps> = ({text, icon = 'help', children}) => {
    const classes = useStyles();

    return <>&nbsp;<Tooltip enterTouchDelay={50} leaveTouchDelay={3000} title={text}>
        <span>
            {children ? <>{children} &nbsp;</> : null}
            {icon === 'help' ?
                <HelpOutlineIcon className={classes.iconSize}/> :
                <InfoOutlinedIcon className={classes.iconSize}/>
            }
        </span>
    </Tooltip></>
}
