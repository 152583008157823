export function parseSearchParams(params: string) {
    const targetURLSearchParams: any = new URLSearchParams(params);

    return [...targetURLSearchParams.entries()].reduce((acc, [key, val]) => {
        if(acc.hasOwnProperty(key)) {
            if(Array.isArray(acc[key])) {
                acc[key] = [...acc[key], val]
            } else {
                acc[key] = [acc[key], val];
            }
        } else {
            acc[key] = val;
        }

        return acc;
    }, {});
};
