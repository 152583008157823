export default class ContactPerson {
    contactPersonNumber: string;
    stContactPersonID: string;
    contactPersonCrmNumber: string;
    salutation: ContactPersonSalutation | '';
    title: string;
    firstname: string;
    lastname: string;
    "function": ContactPersonFunction | '';
    department: ContactPersonDepartment | '';
    emailAddress: string;
    telephoneNumber: string;
    mobileNumber: string;
    hasNewsletter: boolean;
    deletionFlag: boolean;


    constructor(dto: ContactPersonDTO) {
        const {contactPersonNumber,stContactPersonID, contactPersonCrmNumber, salutation, title, firstname, lastname, department, emailAddress, telephoneNumber, mobileNumber, hasNewsletter, deletionFlag} = dto;
        this.contactPersonNumber = contactPersonNumber
        this.stContactPersonID = stContactPersonID;
        this.contactPersonCrmNumber = contactPersonCrmNumber;
        this.title = title;
        this.firstname = firstname;
        this.lastname = lastname;
        this.emailAddress = emailAddress;
        this.telephoneNumber = telephoneNumber;
        this.mobileNumber = mobileNumber;
        this.hasNewsletter = hasNewsletter;
        this.deletionFlag = deletionFlag;
        this.salutation = salutation;
        this.function = dto.function;
        this.department = department;
    }
}


export interface ContactPersonDTO {
    "contactPersonNumber": string,
    "stContactPersonID": string,
    "contactPersonCrmNumber": string,
    "salutation": ContactPersonSalutation | '',
    "title": string,
    "firstname": string,
    "lastname": string,
    "function": ContactPersonFunction | '',
    "department": ContactPersonDepartment | '',
    "emailAddress": string,
    "telephoneNumber": string,
    "mobileNumber": string,
    "hasNewsletter": boolean,
    "deletionFlag": boolean
}

export enum ContactPersonFunction {
    "001" = "01",
    "002" = "02",
    "003" = "03",
    "004" = "04",
    "005" = "05",
    "006" = "06",
    "007" = "07",
    "008" = "08",
    "009" = "09",
    "010" = "10",
    "011" = "11",
    BE = "BE",
    FV = "FV",
    IH = "IH",
    ZK = "ZK",
    ZR = "ZR",
    ZI = "ZI",
    unknown = "unknown",
}

export enum ContactPersonDepartment {
    "0001" = "0001",
    "0002" = "0002",
    "0003" = "0003",
    "0004" = "0004",
    "0005" = "0005",
    "0006" = "0006",
    "0007" = "0007",
    "0008" = "0008",
    "0009" = "0009",
    "0010" = "0010",
    "unknown" = "unknown"
}

export enum ContactPersonSalutation {
    "0001" = "0001", "0002" = "0002"
}

export const emptyContactPerson: ContactPerson = new ContactPerson({
    "contactPersonNumber": "",
    "stContactPersonID": "",
    "contactPersonCrmNumber": "",
    "salutation": "",
    "department": '',
    "function": '',
    "title": "",
    "firstname": "",
    "lastname": "",
    "emailAddress": "",
    "telephoneNumber": "",
    "mobileNumber": "",
    "hasNewsletter": false,
    "deletionFlag": false
})
