import Navigation from "../components/Navigation";
import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {LIGHT_BLUE, LIGHT_GREEN, YELLOW} from "../BerendsohnMUITheme";
import {useTranslation} from "react-i18next";
import Api from "../store/Api";
import {emptyExtendedVBKpiDto, ExtendedManagerKpiDto, VBKPICardDto} from "../store/models/IExtendedKPIDto";
import OrderTable from "../components/OrderTable";
import SalesFunnels from "../components/SalesFunnels";
import {HelpText} from "../components/HelpText";
import {Link} from "react-router-dom";
import backgroundImage from "../img/backgroundDashboard.svg";
import OrderOverview from '../store/models/OrderOverview';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    backgroundImage: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: 300,
        backgroundSize: 'contain',
        width: '100%'
    },
    cover: {
        width: 151,
    },
    paper: {
        padding: theme.spacing(3),
        width: '100%'
    },
    paperSmallKpi: {
        padding: theme.spacing(3),
        width: '100%',
        height: 130
    },
    paperFirstRow: {
        padding: theme.spacing(2),
        width: '100%',
        height: 300
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'

    },
    salesDataGreen: {
        color: LIGHT_GREEN,
        fontSize: 12,
        textAlign: "center"
    },
    salesDataYellow: {
        color: YELLOW,
        fontSize: 12,
        textAlign: "center"
    },
    salesDataBlue: {
        color: LIGHT_BLUE,
        fontSize: 12,
        textAlign: "center"
    },
    salesDataCurrency: {
        fontSize: 30,
        fontWeight: "normal",
        textAlign: "center",
        paddingBottom: 10
    },
    table: {
        //minWidth: 650,
    },
    tableHead: {
        fontWeight: "bold",
    },
    kpiText: {
        padding: '10px 20px',
        textAlign: "center",
        display: "inline-block",
        borderRadius: 5,
        backgroundColor: 'rgba(242, 242, 242, .6)',
        margin: 10,
    },
    salesPerson: {
        textAlign: 'center',
        fontSize: '12'
    },
    salesPersonIncomingOrderVale: {
        textAlign: 'center',
        fontSize: '16',
        paddingTop: 10,
    },
    detailButton: {
        paddingTop: 20
    },
    divider: {
        margin: theme.spacing(2, 0, 3, 0)
    },
}));

function KpiManagerDashboard() {

    const classes = useStyles();
    const {t} = useTranslation();
    const [kpiDataFromApi, setKPIDataFromApi] = useState<ExtendedManagerKpiDto>({
            ...emptyExtendedVBKpiDto,
            vbData: []
        }
    );

    const [vbKPICardDataFromApi, setVbKPICardDataFromApi] = useState<VBKPICardDto[]>([]);

    const fetchApiKPIs = () => {
        Api.getManagerKPIData().then(dataFromApi =>
            setKPIDataFromApi({
                ...dataFromApi,
            })
        )
    }
    
    const fetchVBKPICards = () => {
        Api.getVBKPICardData().then(cards => setVbKPICardDataFromApi([...cards]))
    }

    const [orderOverviews, setOrderOverviews] = useState<OrderOverview[]>([]);

    const fetchOrderOverviews = () => {
        Api.getOrderOverviewsForSubordinatedAdms(7).then(orderOverviews => setOrderOverviews(orderOverviews))
    }

    useEffect(fetchApiKPIs, []);
    useEffect(fetchVBKPICards, []);
    useEffect(fetchOrderOverviews, []);

    return (<>
        <Navigation breadcrumbName={'Einstellungen'}>
            <Grid container spacing={4}>
                <Grid item md={6}>
                    <Paper className={classes.paperFirstRow}>
                        <Box className={classes.backgroundImage}>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper className={classes.paperFirstRow}>
                        <SalesFunnels potentialCustomers={kpiDataFromApi.contactsGreyProspect}
                                      followUps={kpiDataFromApi.followUps}
                                      meetings={kpiDataFromApi.totalMeetingsThisYear}
                                      activeContacts={kpiDataFromApi.activeContactsThisYear}
                        />
                    </Paper>
                </Grid>
                <Grid item md={3}>
                    <Paper className={classes.paperSmallKpi}>
                        <Typography className={classes.salesDataCurrency}
                                    variant={"h1"}>{kpiDataFromApi.protectedContacts}</Typography>
                        <Typography align="right"
                                    className={classes.salesDataGreen}>{t('kpiSupervisorDashboard.proctedCustomers')}</Typography>
                    </Paper>
                </Grid>
                <Grid item md={3}>
                    <Paper className={classes.paperSmallKpi}>
                        <Typography className={classes.salesDataCurrency}
                                    variant={"h1"}>{kpiDataFromApi.assignedContacts}</Typography>
                        <Typography align="right"
                                    className={classes.salesDataBlue}>{t('kpiSupervisorDashboard.assignedCustomers')}</Typography>
                    </Paper>
                </Grid>
                <Grid item md={3}>
                    <Paper className={classes.paperSmallKpi}>
                        <Typography className={classes.salesDataCurrency}
                                    variant={"h1"}>{kpiDataFromApi.ordersInThisYear}</Typography>
                        <Typography align="right"
                                    className={classes.salesDataYellow}>{t('kpiSupervisorDashboard.ordersInThisYear')}</Typography>
                    </Paper>
                </Grid>
                <Grid item md={3}>
                    <Paper className={classes.paperSmallKpi}>
                        <Typography className={classes.salesDataCurrency}
                                    variant={"h1"}>{kpiDataFromApi.newContacts}</Typography>
                        <Typography align="right"
                                    className={classes.salesDataYellow}>{t('kpiSupervisorDashboard.newCustomers')}
                            <HelpText icon={'info'} text={t('kpiSupervisorDashboard.newCustomersTooltip')}/>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper className={classes.paper}>
                        <h2>{t('kpiSupervisorDashboard.myRegionTopSeller')}
                            <HelpText icon={'info'} text={t('kpiSupervisorDashboard.myTopSellerTooltip')}/>
                        </h2>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell width={30}><b>{t('kpiSupervisorDashboard.myRegionTopSellerRanking')}</b></TableCell>
                                    <TableCell><b>{t('kpiSupervisorDashboard.myRegionTopSellerRanking')}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {kpiDataFromApi.topsellerVB.map((ts, index) =>
                                    <TableRow key={'topsellerVB' + index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{t('productListSearchItems.'+ts._id)}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper className={classes.paper}>
                        <h2>{t('kpiSupervisorDashboard.topsellerOverall')}
                            <HelpText icon={'info'} text={t('kpiSupervisorDashboard.topsellerOverallTooltip')}/>
                        </h2>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell
                                        width={30}><b>{t('kpiSupervisorDashboard.topsellerOverallRanking')}</b></TableCell>
                                    <TableCell><b>{t('kpiSupervisorDashboard.topsellerOverallProducts')}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {kpiDataFromApi.topsellerCompany.map((ts, index) =>
                                    <TableRow key={'topsellerCOmpany' + index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{t('productListSearchItems.'+ts._id)}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item md={12}>
                    <div style={{textAlign: "center"}}>
                        <h2>{t('kpiSupervisorDashboard.myStaff')}</h2>
                    </div>
                </Grid>
                {vbKPICardDataFromApi.map((s) => {
                    return (
                        <Grid item md={4} key={'vbCards' + s.vbNumber}>
                            <Paper className={classes.paper}>
                                <Typography className={classes.salesPerson}><b>{s.name}</b></Typography>
                                <Divider className={classes.divider}/>
                                <Grid container style={{textAlign: "center", fontSize: 16}}>
                                    <Grid item md={6}
                                          style={{paddingTop: 20}}><Box>
                                        {t('kpiSupervisorDashboard.myStaffMeetings')}
                                        <HelpText icon={'info'}
                                                  text={t('kpiSupervisorDashboard.myStaffMeetingsTooltip')}/>
                                    </Box><Box><b>{s.meetingsNextTenDays}</b></Box></Grid>
                                    <Grid item md={6}
                                          style={{paddingTop: 20}}><Box>{t('kpiSupervisorDashboard.myStaffOrders')}
                                        <HelpText icon={'info'}
                                                  text={t('kpiSupervisorDashboard.myStaffOrdersTooltip')}/>
                                    </Box><Box><b>{s.numberOfOrders}</b></Box></Grid>
                                    <Grid item md={6}
                                          style={{paddingTop: 20}}><Box>{t('kpiSupervisorDashboard.myStaffActiveCustomers')}
                                        <HelpText icon={'info'}
                                                  text={t('kpiSupervisorDashboard.myStaffActiveCustomersTooltip')}/>
                                    </Box><Box><b>{s.activeCustomers}</b></Box></Grid>
                                    <Grid item md={6}
                                          style={{paddingTop: 20}}><Box>{t('kpiSupervisorDashboard.myStaffFollowUps')}
                                        <HelpText icon={'info'}
                                                  text={t('kpiSupervisorDashboard.myStaffFollowUpsTooltip')}/>
                                    </Box><Box><b>{s.followUps}</b></Box></Grid>
                                </Grid>
                                <Typography className={classes.detailButton} variant="subtitle2" component="h2"
                                            align="right">
                                    <Button
                                        variant="text"
                                        color={"primary"}
                                        component={Link}
                                        to={`/ordersFor/${s.vbNumber}`}
                                    >
                                        {t('kpiSupervisorDashboard.showOrdersForVB')}
                                    </Button>

                                </Typography>
                            </Paper>
                        </Grid>
                    )

                })}
                <Grid item xs={12}>
                    <OrderTable orderOverviews={orderOverviews}/>
                </Grid>

            </Grid>
        </Navigation>
    </>)
}

export default KpiManagerDashboard;
