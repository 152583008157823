import DailyAdmReport from "../models/DailyAdmReport";
import IAction from "../actions/IAction";
import {
    CREATE_DAR_SUCCESS,
    GET_DAR_FOR_MY_ADMS_SUCCESS,
    GET_MY_DAR_SUCCESS,
    UPDATE_DAR_SUCCESS
} from "../actions/DailyAdmReportActions";


export interface IDailyAdmReportReducerState {
    myReports: DailyAdmReport[]
    reportsForMyADMs: DailyAdmReport[]
}

export default class DailyAdmReportReducer {
    public static readonly INITIAL_STATE: IDailyAdmReportReducerState = {
        myReports: [],
        reportsForMyADMs: [],
    };


    public static reducer(
        state: IDailyAdmReportReducerState = DailyAdmReportReducer.INITIAL_STATE,
        action: IAction<any>
    ): IDailyAdmReportReducerState {
        switch (action.type) {
            case GET_MY_DAR_SUCCESS:
                return {...state, myReports: action.payload}
            case CREATE_DAR_SUCCESS:
                return {...state, myReports: [...state.myReports, action.payload]}
            case UPDATE_DAR_SUCCESS:
                return {
                    ...state,
                    myReports: [...state.myReports.map(r =>
                        r.darNumber === action.payload.darNumber ? action.payload : r)]
                }
            case GET_DAR_FOR_MY_ADMS_SUCCESS:
                return {
                    ...state, reportsForMyADMs: action.payload
                }
            default:
                return state;
        }
    }

}
