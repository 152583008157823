import React from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import Kaz from "../store/models/KAZ";
import {formateDateWithTime} from "./Formatter";
import {useTranslation} from "react-i18next";
import {ST_BACKEND_URL} from "../config";

interface KazListItemProps {
    kaz: Kaz;
}

export default function KazListItem({kaz}: KazListItemProps) {
    const {t} = useTranslation();


    return <Grid item container spacing={3}>
        <Grid item xs={3}>
            <Typography>{formateDateWithTime(kaz.sapTsDay)}</Typography>
        </Grid>
        <Grid item xs={7}>
            <Typography>{kaz.originalFileName}</Typography>
        </Grid>
        <Grid item xs={2}>
            <Button target="_blank"
                    rel="noopener noreferrer"
                    color={"primary"}
                    href={`${ST_BACKEND_URL}/v1/${kaz.uri}`}>
                {t('kazListItem.download')}
            </Button>
        </Grid>
    </Grid>

}