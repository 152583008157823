import React, {useEffect, useState} from 'react';
import {Divider, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import Navigation from "../components/Navigation";
import {addWeeks, compareAsc, endOfWeek, isAfter, isBefore, startOfWeek, subWeeks} from "date-fns";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {formatDateObj} from "../components/Formatter";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {thunkDailyAdmReportsForMyAdms} from "../store/actions/DailyAdmReportActions";
import DailyAdmReport from "../store/models/DailyAdmReport";
import {AdmName} from "../components/AdmName";
import {makeStyles} from "@material-ui/core/styles";
import DailyAdmReportManagerModal from "../components/dailyAdmReport/DailyAdmReportManagerModal";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        minHeight: 600
    },
}));

const startOfWeekEU = (d: Date) => startOfWeek(d, {weekStartsOn: 1})
const endOfWeekEU = (d: Date) => endOfWeek(d, {weekStartsOn: 1})

interface ReportsByAdm {
    admID: string;
    reports: DailyAdmReport[]
}


function sortReportsByAdmForWeek(reports: DailyAdmReport[]): ReportsByAdm[] {
    const reportsByAdm: ReportsByAdm[] = [];
    reports.forEach(rep => {
        let index = reportsByAdm.findIndex(r => r.admID === rep.admID);
        if (index > -1) {
            reportsByAdm[index].reports.push(rep)
        } else {
            reportsByAdm.push({
                admID: rep.admID,
                reports: [rep]
            })
        }
    })
    return reportsByAdm;
}

export default function DailyAdmReportManagerOverview() {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(thunkDailyAdmReportsForMyAdms())
    }, [dispatch]);

    const classes = useStyles();

    const [startOfCurrentWeek, setStartCurrentWeek] = useState<Date>(startOfWeekEU(new Date()));
    const endOfCurrentWeek = endOfWeekEU(startOfCurrentWeek);
    const reports = useSelector((state: IStore) => state.dailyAdmReports.reportsForMyADMs);
    const reportsForCurrentWeek = reports.filter(r => isAfter(r.forDate, startOfCurrentWeek) && isBefore(r.forDate, endOfCurrentWeek))
    const reportsByAdm = sortReportsByAdmForWeek(reportsForCurrentWeek)

    return <Navigation breadcrumbName={''}>
        <Grid container spacing={4}>
            <Grid container item xs={12}>
                <Grid item xs={1}>
                    {compareAsc(subWeeks(new Date(), 12), startOfCurrentWeek) < 0 ?
                        <IconButton onClick={() => setStartCurrentWeek(subWeeks(startOfCurrentWeek, 1))}
                                    aria-label={t('common.back')}>
                            <ArrowBackIcon/>
                        </IconButton>
                        : null}
                </Grid>
                <Grid item xs={10}>
                    <Typography
                        align={"center"}>{formatDateObj(startOfCurrentWeek)} - {formatDateObj(endOfCurrentWeek)}</Typography>
                </Grid>
                <Grid item xs={1}>
                    {compareAsc(addWeeks(startOfCurrentWeek, 1), endOfWeekEU(new Date())) < 0 ?
                        <IconButton onClick={() => setStartCurrentWeek(addWeeks(startOfCurrentWeek, 1))}
                                    aria-label={t('common.forward')}>
                            <ArrowForwardIcon/>
                        </IconButton>
                        : null}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={4} item xs={12}>
                        {reportsByAdm.map(reports =>
                            <Grid key={'reportsByAdm' + reports.admID} item container spacing={3} xs={12}>
                                <Grid item xs={4}><AdmName personalnummer={reports.admID}/></Grid>
                                <Grid item xs={6}>{t('dailyAdmReportManagerOverview.reportCount',{count: reports.reports.length})}</Grid>
                                <Grid item xs={2}><DailyAdmReportManagerModal reports={reports.reports} /></Grid>
                                <Grid item xs={12}><Divider/></Grid>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>

        </Grid>
    </Navigation>
}
