import React from 'react';
import {
    Avatar,
    Box,
    Button,
    DialogActions,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {Field, Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import Modal from "./Modal";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {makeStyles} from "@material-ui/core/styles";
import {blue} from "@material-ui/core/colors";
import Api from "../store/Api";
import {thunkCreateSuccessNotification} from "../store/actions/NotificationActions";
import {Adm} from "../store/models/Adm";
import {DatePicker} from "formik-material-ui-pickers";

interface AssignContactsToAdmModalProps {
    selectedContactIds?: string[],
    adms: Adm[]
}

const useStyles = makeStyles((theme) => {
    return ({
        button: {
            margin: theme.spacing(1),
        },
        container: {
            margin: theme.spacing(1, 1, 3, 1)
        },
        paper: {
            padding: theme.spacing(1, 3),
            margin: theme.spacing(3, 0),
            minHeight: '50%'
        },
        avatar: {
            backgroundColor: blue[300],
        }
    });
});

export default function AssignContactsToAdmModal({
                                                     selectedContactIds = [],
                                                     adms
                                                 }: AssignContactsToAdmModalProps) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <Modal title={t('assignContactsToAdmModal.headline')}
               fullWidth={true} maxWidth={"sm"}
               contentAndButtons={(handleClose) => (
                   <>
                       <DialogContent>
                           <Formik
                               initialValues={{
                                   selectedContactIds,
                                   note: '',
                                   assignToAdmId: '',
                                   assignUntil: null
                               }}
                               onSubmit={(values) => {
                                   if (values.assignUntil) {
                                       Api.assignCustomersToAdm(values.assignToAdmId, values.assignUntil, selectedContactIds, values.note).then(() => {
                                               dispatch(thunkCreateSuccessNotification(t('assignContactsToAdmModal.assignmentSuccess')))
                                               handleClose()
                                           }
                                       )
                                   }
                               }}
                           >
                               {({
                                     values,
                                     handleChange,
                                     handleSubmit,
                                 }) => (
                                   <form id={"assignContactsToAdmFrom"} onSubmit={handleSubmit}>
                                       <Box>
                                           <Grid container direction={"column"} spacing={1}>
                                               <Grid item>
                                                   <FormControl fullWidth>
                                                       <Field
                                                           required
                                                           component={DatePicker}
                                                           name="assignUntil"
                                                           value={values.assignUntil}
                                                           label={t('assignContactsToAdmModal.assignUntilLbl')}
                                                           format={'dd.MM.yyyy'}
                                                           ampm={false}
                                                       />
                                                   </FormControl>
                                               </Grid>
                                               <Grid item>
                                                   <FormControl fullWidth>
                                                       <InputLabel>{t('assignContactsToAdmModal.assignToAdmLbl')}</InputLabel>
                                                       <Select
                                                           value={values.assignToAdmId}
                                                           name={'assignToAdmId'}
                                                           //@ts-ignore
                                                           onChange={handleChange}
                                                       >
                                                           <MenuItem value={''}>&nbsp;</MenuItem>)
                                                           {adms.map(adm => <MenuItem
                                                               key={'assignToAdmID' + adm.sapID}
                                                               value={adm.sapID}>{`${adm.vorname} ${adm.nachname}`}</MenuItem>)}
                                                       </Select>
                                                   </FormControl>
                                               </Grid>
                                               <Grid item>
                                                   <FormControl fullWidth>
                                                       <TextField
                                                           multiline={true}
                                                           fullWidth={true}
                                                           rows={3}
                                                           inputProps={{maxLength: 100}}
                                                           name="note"
                                                           label={t('assignContactsToAdmModal.noteLbl')}
                                                           value={values.note}
                                                           onChange={handleChange}
                                                           required
                                                       />
                                                   </FormControl>
                                               </Grid>
                                           </Grid>
                                       </Box>
                                   </form>
                               )}
                           </Formik>
                       </DialogContent>
                       <DialogActions>
                           <Button onClick={handleClose}>
                               {t('common.cancelBtn')}
                           </Button>
                           <Button type="submit" form="assignContactsToAdmFrom" color={"primary"}>
                               {t('common.saveBtn')}
                           </Button>
                       </DialogActions>
                   </>
               )} openButton={(handleOpen) => (
            <Button
                variant="text"
                onClick={handleOpen}
                className={classes.button}
                startIcon={<Avatar className={classes.avatar}><SaveAltIcon/></Avatar>}
            >
                {t('assignContactsToAdmModal.assignCustomersToBtn')}
            </Button>
        )}/>

    );
}
