import React from 'react';
import {Grid, IconButton, Typography} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {useTranslation} from "react-i18next";
import {addMonths, subMonths} from "date-fns";
import {getMonthToStringArr} from "../Formatter";


interface MeetingListTimeFrameFilerSelectionProps {
    selectedMonth: Date;
    setMonth: (month: Date) => void
}

export default function MeetingListTimeFrameFilerSelection({selectedMonth, setMonth}: MeetingListTimeFrameFilerSelectionProps) {
    const {t} = useTranslation();

    const monthToString = getMonthToStringArr();

    return <Grid container item xs={12}>
        <Grid item xs={1}>
            <IconButton onClick={() => setMonth(subMonths(selectedMonth, 1))}
                        aria-label={t('common.back')}>
                <ArrowBackIcon/>
            </IconButton>
        </Grid>
        <Grid item xs={10}>
            <Typography
                align={"center"}>{monthToString[selectedMonth.getMonth()]} {selectedMonth.getFullYear()}</Typography>
        </Grid>
        <Grid item xs={1}>
            <IconButton onClick={() => setMonth(addMonths(selectedMonth, 1))}
                        aria-label={t('common.forward')}>
                <ArrowForwardIcon/>
            </IconButton>
        </Grid>
    </Grid>
}
