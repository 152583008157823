import * as locale from 'date-fns/locale';

export enum UserRoles {
    ST_AREA_MANAGER = 'ST_AREA_MANAGER',
    ST_COACH = 'ST_COACH',
    ST_CONSULTANT_EXTERNAL = 'ST_CONSULTANT_EXTERNAL',
    ST_CRM = 'ST_CRM',
    ST_CSO = 'ST_CSO',
    ST_SALESSUPPORT = 'ST_SALESSUPPORT',
    ST_SUPERUSER = 'ST_SUPERUSER',
    ST_SALES_CONSULTANT = 'ST_VB',
    ST_TERMINATOR = 'ST_TERMINATOR',
    ST_MANAGER = 'ST_MGR',
    ST_ASSIGNATION = 'ST_ASSIGNATION',
    BER_SUPER_MANAGER = 'BER_SUPER_MANAGER',
    BER_ACCOUNTANT = 'BER_ACCOUNTANT',
    ST_TEST_ROLE = 'DONT_USE_ME',
}

export const readTokenFromCookie = () => document.cookie?.replace(/(?:(?:^|.*;\s*)backendToken\s*=\s*([^;]*).*$)|^.*$/, "$1");
export const removeTokenFromCookie = () => document.cookie?.replace(/(?:(?:^|.*;\s*)backendToken\s*=\s*([^;]*).*$)|^.*$/, "$1 expires=Thu, 01 Jan 1970 00:00:00 GMT");

export const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1].replace("-", "+").replace("_", "/")));
    } catch (e) {
        return null;
    }
};

let userTokenAlreadyLogged = false

export const hasAccess = (requiredRole?: UserRoles[]) => {
    const token = readTokenFromCookie();
    if (!token) return false;
    if(token){
        const parsedToken = parseJwt(token);
        if(! userTokenAlreadyLogged){
            userTokenAlreadyLogged = true;
        }
        if(!parsedToken) return false;
        const {roles} = parsedToken;
        return (
            (!requiredRole ||
                (roles && roles.find((role: UserRoles) => requiredRole.find(rr => rr === role))))
        );
    }
};

export const hasLocale = (locale: string) => {
    return locale === fetchLocaleFromCookie();
}

export function fetchUserDisplayname()  {
    const token = readTokenFromCookie();
    if (!token) return '';
    if(token){
        const parsedToken = parseJwt(token);
        if(!parsedToken) return '';
        const {displayName} = parsedToken;
        return displayName || '';
    }
}

const localeCodeToLocaleObj = {
    "en": locale.enGB,
    "de": locale.de,
    "it": locale.it
}

export function fetchLocaleObjForUser(){
    // @ts-ignore
    return localeCodeToLocaleObj[fetchLocaleFromCookie()] || locale.de;
}

export function fetchLocaleFromCookie()  {
    const token = readTokenFromCookie();
    if (!token) return '';
    if(token){
        const parsedToken = parseJwt(token);
        if(!parsedToken) return '';
        const {locale} = parsedToken;
        return locale || '';
    }
}

export function getMyADMID(){
    const token = readTokenFromCookie();
    if (!token) return '';
    if(token){
        const parsedToken = parseJwt(token);
        if(!parsedToken) return '';
        const {VBNumber = 'A9999'} = parsedToken; //TODO
        return VBNumber || 'A9999';
    }
}
export function getMyUserGroups(){
    const token = readTokenFromCookie();
    if (!token) return '';
    if(token){
        const parsedToken = parseJwt(token);
        if(!parsedToken) return '';
        const {roles = ['not found']} = parsedToken;
        return roles || ['not found'];
    }
}



