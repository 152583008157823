import React from 'react';
import clsx from "clsx";
import {formatDateObjWithDayOfWeek} from "../Formatter";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {LIGHT_BLUE, WHITE} from "../../BerendsohnMUITheme";
import DailyAdmReportUpdateModal from "./DailyAdmReportUpdateModal";
import DailyAdmReport from "../../store/models/DailyAdmReport";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => {
    return ({
        dayCard: {
            padding: theme.spacing(2),
            height: 100,
            borderRadius: 5
        },
        link: {
            color: "black",
            textDecoration: 'none'
        },
        open: {
            backgroundColor: WHITE
        },
        submitted: {
            backgroundColor: LIGHT_BLUE
        },
        clickable: {
            cursor: "pointer"
        }

    });
});

interface DailyAdmReportTileProps {
    forDay: Date;
    dailyAdmReport?: DailyAdmReport
}

export default function DailyAdmReportTile(props: DailyAdmReportTileProps) {

    const classes = useStyles();

    const {forDay, dailyAdmReport} = props;

    const {t}= useTranslation();


    return <DailyAdmReportUpdateModal forDate={forDay} dailyAdmReport={dailyAdmReport} tile={handleOpen =>
        <div className={classes.link}
             onClick={handleOpen}
        >
            <div
                className={clsx(classes.dayCard, {[classes.clickable]: true}, dailyAdmReport ? classes.submitted : classes.open)}>
                {formatDateObjWithDayOfWeek(forDay)}
                <Typography align={"center"}>{t(`dailyAdmReportTile.${dailyAdmReport? 'submittedReport':'openReport'}`)}</Typography>
            </div>
        </div>
    }/>
}
