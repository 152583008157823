import {isAfter, parseISO} from "date-fns";

export function isInFuture(dateString: string) {
    if (!dateString || dateString === '0000-00-00') {
        return false;
    }
    const date = Date.parse(dateString);
    return isAfter(date, Date.now());
}

export function parseISODateOrUndefined(dateStr?: string){
    return dateStr ? parseISO(dateStr) : undefined
}
