import React from 'react';
import {TouchpointResultType} from "../../../store/models/TouchpointEnums";
import {Button, FormControl, Grid, TextField} from "@material-ui/core";
import TouchpointTab from "./TouchpointTab";
import {useTranslation} from "react-i18next";
import {Note, NoteType} from "../../../store/models/Note";
import {thunkAddNote} from "../../../store/actions/NoteActions";
import Contact from "../../../store/models/Contact";
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {Field} from "formik";
import {DatePicker} from "formik-material-ui-pickers";
import * as Yup from "yup";

interface NotReachedTabProps {
    touchpointResultType: TouchpointResultType;
    contact?: Contact;
}

export default function NotReachedTab({touchpointResultType, contact}: NotReachedTabProps) {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const history = useHistory();


    return (<TouchpointTab
        tabIndex={TouchpointResultType.Not_Reached}
        activeTab={touchpointResultType}
        validationSchema={Yup.object().shape({
            note: Yup.string()
                .required(t('validation.required')),
        })}
        handleSubmit={async (values) => {
            const newNote: Note = {
                contactNumber: contact?.stContactID || '',
                created: new Date(),
                private: false,
                text: values.note,
                forDate: values.forDate ? values.forDate : undefined,
                type: NoteType.TouchpointResult,
                touchpointResultType
            }
            await dispatch(thunkAddNote(newNote));
            history.goBack();
        }}
        initialValues={{note: t('contactDetailTouchpointTab.notReachedNoteDefaultValue'), forDate: null}}
    >
        {((values, handleChange, setFieldValue, isSubmitting) =>
                <Grid container direction={"column"} spacing={3}>
                    <Grid item xs={12}>

                        <br/>
                        <Field
                            component={DatePicker}
                            inputVariant={"outlined"}
                            name="forDate"
                            value={values.forDate}
                            label={t('contactDetailTouchpointTab.formForDateLbl')}
                            format={'dd.MM.yyyy'}
                            ampm={false}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                multiline={true}
                                rows={3}
                                name="note"
                                label={t('contactDetailTouchpointTab.noteLbl')}
                                value={values.note}
                                variant={"outlined"}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button disabled={isSubmitting} variant={"contained"} color={"primary"}
                                type={"submit"}>{t('common.saveBtn')}</Button>
                    </Grid>
                </Grid>
        )}
    </TouchpointTab>)
}
