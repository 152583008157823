export default class Address {
    sapCustomerID?: string;
    stContactAddressID?: string;
    companyLine1?: string;
    companyLine2?: string;
    companyLine3?: string;
    companyLine4?: string;
    street: string;
    houseNumber: string;
    place: string;
    zipCode: string;
    country: string;
    postBox?: string;
    postBoxZipCode?: string;
    postBoxPlace?: string;
    postBoxCountry?: string;
    vatNumber?: string;
    taxNumber1?: string;
    taxNumber2?: string;
    taxNumberFatturaPA?: string;
    mailFatturaPA?: string;

    constructor({sapCustomerID, stContactAddressID, companyLine1, companyLine2, companyLine3, companyLine4, street,
                    houseNumber, place, zipCode, country, postBox, postBoxZipCode, postBoxPlace,
                    postBoxCountry, vatNumber, taxNumber1, taxNumber2, taxNumberFatturaPA, mailFatturaPA}: AddressDTO) {
        this.sapCustomerID = sapCustomerID;
        this.stContactAddressID = stContactAddressID;
        this.companyLine1 = companyLine1;
        this.companyLine2 = companyLine2;
        this.companyLine3 = companyLine3;
        this.companyLine4 = companyLine4;
        this.street = street;
        this.houseNumber = houseNumber;
        this.place = place;
        this.zipCode = zipCode;
        this.country = country;
        this.postBox = postBox;
        this.postBoxZipCode = postBoxZipCode;
        this.postBoxPlace = postBoxPlace;
        this.postBoxCountry = postBoxCountry;
        this.vatNumber = vatNumber;
        this.taxNumber1 = taxNumber1;
        this.taxNumber2 = taxNumber2;
        this.taxNumberFatturaPA = taxNumberFatturaPA;
        this.mailFatturaPA = mailFatturaPA;
    }
}

export interface AddressDTO {
    sapCustomerID?: string;
    stContactAddressID?: string;
    companyLine1?: string;
    companyLine2?: string;
    companyLine3?: string;
    companyLine4?: string;
    street: string;
    houseNumber: string;
    place: string;
    zipCode: string;
    country: string;
    postBox?: string;
    postBoxZipCode?: string;
    postBoxPlace?: string;
    postBoxCountry?: string;
    vatNumber?: string;
    taxNumber1?: string;
    taxNumber2?: string;
    taxNumberFatturaPA?: string;
    mailFatturaPA?: string;
}

export enum AddressType {
    DELIVERY = 'delivery', BILLING = 'billing'
}

export const emptyAddress: Address = {
    "companyLine1": '',
    "companyLine2": '',
    "companyLine3": '',
    "companyLine4": '',
    sapCustomerID: '',
    place: '',
    zipCode: '',
    houseNumber: '',
    street: '',
    country: '',
    postBox: '',
    postBoxCountry: '',
    postBoxPlace: '',
    postBoxZipCode: '',
    vatNumber: "",
    taxNumber1: "",
    taxNumber2: "",
    taxNumberFatturaPA: "",
    mailFatturaPA: ""
}
