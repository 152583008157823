import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite'
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    TextField,
    Typography,
    Select
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Navigation from "../components/Navigation";
import ProductUserMenu from "../components/ProductUserMenu";
import { ProductList, ProductLoader } from "../components/ProductList";
import { SelectableChipsList, ColorSelect, BudgetSlider } from "../components/ProductFilters";
import useProductList from "../hooks/useProductList";
import { parseSearchParams } from "../util/ParseSearchParamsUtil";
import { debounce } from "../util/DebounceUtil";
import Api from "../store/Api";


const useStyles = makeStyles((theme: Theme) => ({
    searchFieldContainer: {

    },
    searchField: {
        marginRight: theme.spacing(2)
    },
    searchButton: {
        marginRight: theme.spacing(2)
    },
    searchAdvancedLink: {
        ...theme.typography.body1,
        textTransform: 'initial',
        color: "inherit",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        }
    },
    searchFieldInner: {
        backgroundColor: "white"
    },
    searchDivider: {
        // width: "100%"
        padding: theme.spacing(2, 0)
    },
    searchAdvancedForm: {
        padding: theme.spacing(3, 0)
    },
    searchLineCheckForm: {
        // width: "100%"
        // padding: theme.spacing(2, 0)
        marginBottom: theme.spacing(3)
    },
    searchOptionSelect: {
        backgroundColor: "white",
        minWidth: 180,
    },
    searchOptionLabel: {
        marginBottom: theme.spacing(2),
    }
}));
const getSearchProductsDebounced = debounce(Api.getSearchProducts, 1000);

export default observer(() => {
    const classes = useStyles();
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const productListStore = useProductList();
    const products = productListStore.products;
    const handleSearchTermChange = (ev: any) => {
        const value = ev.target.value;
        if (value && value === productListStore.filter.search) {
            return;
        }

        const url = new URL(window.location.href);
        productListStore.filter.search = ev.target.value;
        productListStore.products = [];
        productListStore.loadedIdx = 0;
        productListStore.loadedAll = false;

        if (ev.target.value) {
            getSearchProductsDebounced(productListStore.getFilterObject(), productListStore.loadedIdx).then((products: any) => {
                if (productListStore.filter.search === value) {
                    url.searchParams.set("q", value);
                    window.history.pushState(null, '', url.toString());
                    productListStore.loadProductData(products);
                }
            });
        } else {
            url.searchParams.delete("q");
            window.history.pushState(null, '', url.toString());
        }

    }
    const handleProductLoad = async () => {
        if (productListStore.filter.search && !productListStore.loading && !productListStore.loadedAll) {
            productListStore.loading = true;
            await Api.getSearchProducts(productListStore.getFilterObject(), productListStore.loadedIdx).then((products: any) =>
                productListStore.loadProductData(products)
            );
        }
    }
    const handleSearch = async () => {
        if (productListStore.filter.search) {
            productListStore.products = [];
            productListStore.loadedIdx = 0;
            productListStore.loadedAll = false;
            await Api.getSearchProducts(productListStore.getFilterObject(), productListStore.loadedIdx).then((products: any) => {
                productListStore.loadProductData(products)
            });
        }
    }

    useEffect(() => {
        const searchParams = parseSearchParams(window.location.search);
        const {q, ...rest} = searchParams;
        productListStore.setFilterObject(searchParams);
        if (Object.entries(rest).length) {
            setShowAdvancedSearch(true);
        }
        if (productListStore.filter.search) {
            productListStore.products = [];
            productListStore.loadedIdx = 0;
            productListStore.loadedAll = false;
            Api.getSearchProducts(productListStore.getFilterObject(), productListStore.loadedIdx).then((products: any) => {
                productListStore.loadProductData(products)
            });
        }
    }, [productListStore]);

    return (
        <Navigation breadcrumbName={'Suchen'}>
            <Grid container spacing={3} >
                <Grid container item justifyContent="space-between">
                    <div className={classes.searchFieldContainer}>
                        <TextField
                            className={classes.searchField}
                            variant="outlined"
                            size="small"
                            id="input-with-icon-textfield"
                            InputProps={{
                                className: classes.searchFieldInner,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            value={productListStore.filter.search}
                            onChange={handleSearchTermChange}
                            onKeyPress={ev => ev.key === 'Enter' && handleSearch()}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            className={classes.searchButton}
                            onClick={() => handleSearch()}
                        >
                            Suchen
                        </Button>
                        <Button className={classes.searchAdvancedLink} onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}>
                            Advanced search
                        </Button>
                    </div>
                    <ProductUserMenu />
                </Grid>
            </Grid>

            <Grid item className={classes.searchDivider} >
                <Divider />
                {showAdvancedSearch &&
                    <>
                        <AdvancedSearch />
                        <Divider />
                    </>
                }
            </Grid>

            <Grid container spacing={3} >
                <Grid item>
                    {products ?
                        <>
                            <ProductList products={productListStore.products}  />
                            <ProductLoader loading={productListStore.loading} onLoadProducts={handleProductLoad} />
                        </>
                    : null}
                </Grid>
            </Grid>

        </Navigation>
    )
});

export const AdvancedSearch = observer(() => {
    const classes = useStyles();
    const productListStore = useProductList();
    const [categoryList, setCategoryList] = useState([]);

    const handleBudgetChange = (_: any, newValue: any) => {
        const [minPrice, maxPrice] = newValue;
        const url = new URL(window.location.href);

        url.searchParams.set("pmin", minPrice);
        url.searchParams.set("pmax", maxPrice);
        window.history.replaceState(null, '', url.toString());
        productListStore.filter.minPrice = minPrice;
        productListStore.filter.maxPrice = maxPrice;
    };
    const handleColorChange = (ev: any) => {
        productListStore.filter.color = ev.target.value;
        const url = new URL(window.location.href);

        if (ev.target.value) {
            url.searchParams.set("c", ev.target.value);
        } else {
            url.searchParams.delete("c")
        }
        window.history.pushState(null, '', url.toString());
    };
    const handleCategoryChange = (id: any) => {
        const url = new URL(window.location.href);

        if (+productListStore.filter.catId === id) {
            productListStore.filter.catId = undefined;
            url.searchParams.delete("cat_id")
        } else if (id) {
            productListStore.filter.catId = id;
            url.searchParams.set("cat_id", id);
        }

        window.history.pushState(null, '', url.toString());
    }

    useEffect(() => {
        (async() => {
            setCategoryList(await Api.getCategoryListByParrentId(0));
        })();
    }, [productListStore]);


    return (
        <Grid container spacing={3} className={classes.searchAdvancedForm}>
            <Grid item xs={12} md={6}>
                <FormControl component="fieldset" className={classes.searchLineCheckForm}>
                    <FormLabel component="legend">Filter</FormLabel>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox name="lineBag" />}
                            label="Linie BAG"
                        />
                        <FormControlLabel
                            control={<Checkbox name="lineEso" />}
                            label="Linie ESO"
                        />
                        <FormControlLabel
                            control={<Checkbox name="lineDigital" />}
                            label="Digital"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography id="budget-slider" gutterBottom>
                    Budget
                </Typography>
                <BudgetSlider minPrice={productListStore.filter.minPrice} maxPrice={productListStore.filter.maxPrice} onChange={handleBudgetChange} />
            </Grid>

            <Grid item xs={12} md={6}>
                <FormLabel component="legend" className={classes.searchOptionLabel}>Options</FormLabel>
                <Grid container spacing={2}>
                    <Grid item>
                        <ColorSelect value={productListStore.filter.color} onChange={handleColorChange} />
                    </Grid>

                    <Grid item>
                        <FormControl variant="outlined" className={classes.searchOptionSelect}>
                            <InputLabel id="search-option-material">Material</InputLabel>
                            <Select labelId="search-option-material" value="" label="Material">
                                <MenuItem value=""><em>None</em></MenuItem>
                                <MenuItem value={"w"}>Wood</MenuItem>
                                <MenuItem value={"m"}>Metal</MenuItem>
                                <MenuItem value={"p"}>Porcelain</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormLabel component="legend" className={classes.searchOptionLabel}>Categories</FormLabel>
                <SelectableChipsList categories={categoryList} selected={productListStore.filter.catId} onChange={handleCategoryChange} />
            </Grid>
        </Grid>
    )
})
