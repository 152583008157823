import React from 'react';
import {Button, Chip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {useTranslation} from "react-i18next";
import DialogActions from "@material-ui/core/DialogActions";


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1, 3),
        margin: theme.spacing(3, 0),
        minHeight: '50%'
    },
    chip: {
        margin: theme.spacing(1)
    }

}));


interface DeleteChipModalProps {
    action: () => void;
    open: boolean;
    setOpen: (open: boolean) => void;
}

function DeleteChipModal(props: DeleteChipModalProps) {
    const {action, open, setOpen} = props;
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };


    return <Dialog open={open} scroll={'paper'} onClose={handleClose}>
        <DialogTitle>{t('deleteModal.title')}</DialogTitle>
        <DialogActions>
            <Button onClick={handleClose}>
                {t('common.cancelBtn')}
            </Button>
            <Button onClick={() => {
                action();
                handleClose();
            }} color={"secondary"}>
                {t('common.deleteBtn')}
            </Button>
        </DialogActions>
    </Dialog>
}

interface ContactListChipProps {
    variant?: 'default' | 'outlined';
    label: string;
    onClick: () => void;
    onDelete?: () => void;
}

export default function ContactListChip(props: ContactListChipProps) {
    const {variant, label, onDelete, onClick} = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    return <>
        <Chip
            label={label}
            className={classes.chip}
            onDelete={onDelete ? () => setOpen(true) : undefined}
            onClick={onClick}
            color="primary"
            variant={variant}
        />
        {
            onDelete ?
                <DeleteChipModal action={onDelete} open={open} setOpen={setOpen}/>
                : null
        }
    </>
}
