import React from 'react';
import {Cell, Pie, PieChart, ResponsiveContainer} from 'recharts';
import {DARK_GREEN, RED, YELLOW} from "../../BerendsohnMUITheme";
import {Grid, Link, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Link as ReactRouterLink} from "react-router-dom";


const COLORS = [DARK_GREEN, YELLOW, RED];

export interface CustomerProcessingChartData {
    customersGreen: number;
    customersYellow: number;
    customersRed: number;
}

interface Props {
    data: CustomerProcessingChartData;
    forProtectedCustomers?: boolean;
}

function ColorIndicator({color}: { color: string }) {
    return <div style={{
        height: 20,
        width: 5,
        marginRight: 5,
        backgroundColor: color,
        float: "left"
    }}/>
}

function createLinkOrTypography(customerListLink: string, customers: number, translation: string) {
    return customers > 0 ? <Link component={ReactRouterLink}
                                 to={'/contactlist/' + customerListLink}>{customers} {translation}</Link> : customers + " " + translation;
}

export default function CustomerProcessingChart({
                                                    data: {
                                                        customersGreen,
                                                        customersYellow,
                                                        customersRed
                                                    },
                                                    forProtectedCustomers,
                                                }: Props) {
    const {t} = useTranslation();
    const translationKey = 'CustomerProcessingChart.' + (forProtectedCustomers ? "protectedCustomers" : "assignedCustomers");
    const customerListPrefix = forProtectedCustomers ? "PROTECTED_" : 'ASSIGNED_';
    const oneIfAllValuesAreZero = (customersGreen) + (customersYellow) + (customersRed) === 0 ? 1 : 0; // if all values are 0, no chart is displayed, so we set it to 1
    const data = [
        {name: 'Group A', value: customersGreen || oneIfAllValuesAreZero},
        {name: 'Group B', value: customersYellow || oneIfAllValuesAreZero},
        {name: 'Group C', value: customersRed || oneIfAllValuesAreZero},
    ];
    return <Grid container>
        <Grid item xs={4}>
            <ResponsiveContainer height={100} width={"100%"}>
                <PieChart>
                    <Pie
                        data={data}
                        innerRadius={27}
                        outerRadius={40}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </Grid>
        <Grid item xs={8}>
            <Typography variant={"h6"}>{t(translationKey + '.headline')}</Typography>
            <Typography variant={"subtitle2"}><ColorIndicator
                color={DARK_GREEN}/>
                {createLinkOrTypography(customerListPrefix+'GREEN', customersGreen, t(translationKey+'.green'))}
            </Typography>
            <Typography variant={"subtitle2"}><ColorIndicator
                color={YELLOW}/>{createLinkOrTypography(customerListPrefix+'YELLOW', customersYellow, t(translationKey+'.yellow'))}
            </Typography>
            <Typography variant={"subtitle2"}><ColorIndicator color={RED}/>
                {createLinkOrTypography(customerListPrefix+'RED', customersRed, t(translationKey+'.red'))}
            </Typography>
        </Grid>

    </Grid>

}
