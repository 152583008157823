import React, {useState} from 'react';
import Navigation from "../components/Navigation";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField
} from "@material-ui/core";
import {useSelector} from "react-redux";
import ContactTable from "../components/ContactTable";
import {useTranslation} from "react-i18next";
import IStore from "../store/models/IStore";
import Contact from "../store/models/Contact";
import Api from "../store/Api";
import AssignContactsToAdmModal from "../components/AssignContactsToAdmModal";
import {Field, Formik} from "formik";
import {DatePicker} from "formik-material-ui-pickers";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => {
    return ({
        paper: {
            padding: theme.spacing(3),
            margin: theme.spacing(3, 0),
        }
    });
});

function AssignationListView() {
    const [selectedContacts, setSelectedContacts] = useState<string[]>([])
    const {t} = useTranslation();
    const classes = useStyles();


    const [customersOfSelectedAdm, setCustomersOfSelectedAdm] = useState<Contact[]>([]);


    const mySubordinatedAdms = useSelector((store: IStore) => store.adms.mySubordinatedAdms);

    const [isLoading, setIsLoading] = useState(false);

    let initialValues = {
        admProtectedFor: '',
        admAssignedTo: '',
        admAssignedUntil: null,
        regionCode: ''
    };
    return (<>
        <Navigation breadcrumbName={t('assignationListView.breadcrumb')}>


            <Formik
                initialValues={initialValues}
                onSubmit={(values, {resetForm}) => {
                    Api.getCustomersForAssignationList(values).then(list => {
                        setCustomersOfSelectedAdm(list)
                        setIsLoading(false)
                    });
                    resetForm({values});
                }}
            >
                {({
                      values,
                      resetForm,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('assignationListView.admProtectedForSelectLbl')}</InputLabel>
                                        <Select
                                            value={values.admProtectedFor}
                                            name={'admProtectedFor'}
                                            //@ts-ignore
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={''}>&nbsp;</MenuItem>)
                                            {mySubordinatedAdms.map(adm => <MenuItem
                                                key={'selectedAdm' + adm.sapID}
                                                value={adm.sapID}>{`${adm.vorname} ${adm.nachname}`}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('assignationListView.admAssignedToSelectLbl')}</InputLabel>
                                        <Select
                                            value={values.admAssignedTo}
                                            name={'admAssignedTo'}
                                            //@ts-ignore
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={''}>&nbsp;</MenuItem>)
                                            {mySubordinatedAdms.map(adm => <MenuItem
                                                key={'admAssignedTo' + adm.sapID}
                                                value={adm.sapID}>{`${adm.vorname} ${adm.nachname}`}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <Field
                                            component={DatePicker}
                                            name="admAssignedUntil"
                                            value={values.admAssignedUntil}
                                            label={t('assignationListView.admAssignedUntilLbl')}
                                            format={'dd.MM.yyyy'}
                                            ampm={false}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            fullWidth={true}
                                            name="regionCode"
                                            label={t('assignationListView.regionCodeLbl')}
                                            value={values.regionCode}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} container spacing={2}>
                                    <div style={{flexGrow: 1, display: 'flex'}}></div>
                                    <Grid item>
                                        <Button onClick={() => resetForm({values: initialValues})}
                                                variant={"text"}
                                                color={"primary"}>
                                            {t('contactSearchPanel.resetFormBtn')}
                                        </Button>
                                        {isLoading ? <CircularProgress/> :
                                            <Button type="submit" variant={"contained"} color={"primary"}>
                                                {t('contactSearchPanel.searchBtn')}
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </form>
                )}
            </Formik>


            <Grid container spacing={3} direction={"column"}>
                <Grid item>
                    {isLoading ? <CircularProgress/> :
                        <ContactTable selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts}
                                      contacts={customersOfSelectedAdm}
                                      checkboxesButton={<AssignContactsToAdmModal
                                          adms={mySubordinatedAdms}
                                          selectedContactIds={selectedContacts}
                                      />}/>
                    }
                </Grid>

            </Grid>
        </Navigation>
    </>)
}

export default AssignationListView;
