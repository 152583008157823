import React from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {ToDo, ToDoType} from "../store/models/ToDo";
import {Checkbox, Divider, Grid, IconButton, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {thunkDeleteToDo, thunkEditToDo} from "../store/actions/ToDoActions";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import {useTranslation} from "react-i18next";
import {formatDateObjAsNumbers, formatDateTimeOnly} from "./Formatter";

const useStyles = makeStyles(theme => ({
    divider: {
        margin: theme.spacing(2, 0, 3, 0)
    },
    todoDone: {
        textDecoration: "line-through",
    }
}));

const ITEM_HEIGHT = 48;

async function editToDo(option: string, todo: ToDo, dispatch: Dispatch<any>) {
    if (option === 'done') {
        todo.done = new Date();
        dispatch(thunkEditToDo(todo))
    }
    if (option === 'reopen') {
        todo.done = undefined;
        dispatch(thunkEditToDo(todo))
    }
    if (option === 'delete') {
        await dispatch(thunkDeleteToDo(todo));
    }
}

function ToDoListItemMenu({todo}: { todo: ToDo }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (option: string) => async () => {
        await editToDo(option, todo, dispatch);
        setAnchorEl(null);
    };


    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {todo.forCustomer &&
                <MenuItem component={Link} to={`/contact/${todo.forCustomer}`}>
                    {t("todoItem.showContactActionLbl")}
                </MenuItem>
                }
                {[ToDoType.Custom, ToDoType.FollowUp, ToDoType.LegacyCrmFollowup, ToDoType.LegacyCrmMeeting, ToDoType.MSOfficeAppointment].includes(todo.type) && !todo.done &&
                <MenuItem onClick={handleSelect('done')}>
                    {t("todoItem.doneActionLbl")}
                </MenuItem>
                }
                {[ToDoType.Custom, ToDoType.FollowUp, ToDoType.LegacyCrmFollowup, ToDoType.LegacyCrmMeeting, ToDoType.MSOfficeAppointment].includes(todo.type) && todo.done &&
                <MenuItem onClick={handleSelect('reopen')}>
                    {t("todoItem.reopenActionLbl")}
                </MenuItem>
                }
                {todo.type === ToDoType.Custom &&
                <MenuItem onClick={handleSelect('delete')}>
                    {t("todoItem.deleteActionLbl")}
                </MenuItem>
                }
                {todo.type === ToDoType.MSOfficeAppointment && !todo.done &&
                <MenuItem component={Link}
                          to={`/contact/${todo.forCustomer}/?tab=touchpoint&action=visitingReport&completes=${todo.todoNumber}`}>
                    {t("todoItem.visitingReportActionLbl")}
                </MenuItem>
                }
                {todo.type === ToDoType.FollowUp && !todo.done &&
                <MenuItem component={Link}
                          to={`/contact/${todo.forCustomer}/?tab=touchpoint&completes=${todo.todoNumber}`}>
                    {t("todoItem.followUpActionLbl")}
                </MenuItem>
                }
            </Menu>
        </>
    )
}


export default function ToDoItem({todo}: { todo: ToDo }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleSelect = (option: string) => () => {
        editToDo(option, todo, dispatch);
    };
    return (<>
        <Grid container spacing={3} style={{minHeight: 72}} className={!!todo.done ? classes.todoDone : ''}>
            {todo.type === ToDoType.Custom ?
                <>
                    <Grid item xs={1}>
                        <Checkbox color={"default"} checked={!!todo.done}
                                  onClick={handleSelect(!!todo.done ? 'reopen' : 'done')}/>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{todo.text}</Typography>
                    </Grid>
                </>
                :
                <>
                    <Grid item xs={3}>
                        <Typography>{todo.dueOn ? formatDateObjAsNumbers(todo.dueOn) : ''} </Typography>
                        <Typography variant={"caption"}>
                            {todo.type === ToDoType.MSOfficeAppointment ?
                                <>
                                    {formatDateTimeOnly(todo.dueOn!)} {todo.meetingProps && todo.meetingProps.endDate && `- ${formatDateTimeOnly(todo.meetingProps?.endDate!)}`}
                                </>
                                : ''
                            }
                            {todo.type === ToDoType.FollowUp && todo.dueOn ?
                                <>
                                    {formatDateTimeOnly(todo.dueOn)}
                                </>
                                : ''
                            }
                        </Typography>

                    </Grid>
                    <Grid item xs={7}>
                        <Typography>
                            {[ToDoType.LegacyCrmFollowup, ToDoType.LegacyCrmMeeting].includes(todo.type) ? '[CRM] ' : ''}
                            {todo.text} </Typography>
                    </Grid>
                </>
            }

            <Grid item xs={2}>
                <ToDoListItemMenu todo={todo}/>
            </Grid>
        </Grid>
        <Divider className={classes.divider}/>
    </>)
}
