import { parseISO } from 'date-fns';

export default class OrderOverview {
  companyLine: string;
  bestellDatum: Date;
  auftragsNummer: string;
  auftragsWert: string;
  auftragsNettowert: string;
  waehrung: string;
  gesamtStatus: string;
  auftragsGrund: string;
  contactName: string;
  kundenNummer: string;
  provisionSum: number;
  VB1: string;
  VB2: string;

  constructor({ companyLine, bestellDatum, auftragsNummer, auftragsWert, auftragsNettowert, waehrung,
                gesamtStatus, auftragsGrund, contactName, kundenNummer, provisionSum, VB1, VB2}: OrderOverviewDTO) {
    this.companyLine = companyLine;
    this.bestellDatum = parseISO(bestellDatum);
    this.auftragsNummer = auftragsNummer;
    this.auftragsWert = auftragsWert;
    this.gesamtStatus = gesamtStatus;
    this.auftragsNettowert = auftragsNettowert;
    this.waehrung = waehrung;
    this.auftragsGrund = auftragsGrund;
    this.contactName = contactName;
    this.kundenNummer = kundenNummer;
    this.provisionSum = provisionSum;
    this.VB1 = VB1;
    this.VB2 = VB2;
  }
}

export interface OrderOverviewDTO {
  companyLine: string;
  bestellDatum: string;
  auftragsNummer: string;
  auftragsWert: string;
  auftragsNettowert: string;
  waehrung: string;
  gesamtStatus: string;
  auftragsGrund: string;
  contactName: string;
  kundenNummer: string;
  provisionSum: number;
  VB1: string;
  VB2: string;
}

export const emptyOrderOverview: OrderOverview = new OrderOverview({
  companyLine: '',
  bestellDatum: '2020-09-18T14:15:17.7264107Z',
  auftragsNummer: '',
  auftragsWert: '',
  auftragsNettowert: '',
  waehrung: '',
  gesamtStatus: '',
  auftragsGrund: '',
  contactName: '',
  kundenNummer: '',
  provisionSum: 0,
  VB1: '',
  VB2: ''
});
