import React, {useEffect, useState} from 'react';
import {Grid, IconButton, Typography} from "@material-ui/core";
import Navigation from "../components/Navigation";
import {addDays, addWeeks, compareAsc, endOfWeek, isSameDay, startOfWeek, subWeeks} from "date-fns";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {formatDateObj} from "../components/Formatter";
import {useTranslation} from "react-i18next";
import DailyAdmReportTile from "../components/dailyAdmReport/DailyAdmReportTile";
import DailyAdmReport from "../store/models/DailyAdmReport";
import {useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {thunkGetMyDailyAdmReports} from "../store/actions/DailyAdmReportActions";

const startOfWeekEU = (d: Date) => startOfWeek(d, {weekStartsOn: 1})
const endOfWeekEU = (d: Date) => endOfWeek(d, {weekStartsOn: 1})

interface DayOfWeek {
    date: Date;
    dailyAdmReport?: DailyAdmReport
}

function fetchDaysForWeek(startOfWeekEUDate: Date, endOfWeekEUDate: Date, reports: DailyAdmReport[]): DayOfWeek[] {
    let loopDate = startOfWeekEUDate;
    const daysInWeek: DayOfWeek[] = [];
    while (loopDate <= endOfWeekEUDate) {
        // eslint-disable-next-line
        const existingReportForThisDay = reports.find(r => isSameDay(r.forDate, loopDate))
        daysInWeek.push({
            date: loopDate,
            dailyAdmReport: existingReportForThisDay
        })
        loopDate = addDays(loopDate, 1);
    }
    return daysInWeek;
}

export default function DailyAdmReportOverview() {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(thunkGetMyDailyAdmReports())
    }, [dispatch]);

    const reports = useSelector((state: IStore) => state.dailyAdmReports.myReports)


    const [startOfCurrentWeek, setStartCurrentWeek] = useState<Date>(startOfWeekEU(new Date()));
    const endOfCurrentWeek = endOfWeekEU(startOfCurrentWeek);

    const daysOfWeek = fetchDaysForWeek(startOfCurrentWeek, endOfCurrentWeek, reports);

    return <Navigation breadcrumbName={''}>
        <Grid container spacing={4}>
            <Grid container item xs={12}>
                <Grid item xs={1}>
                    {compareAsc(subWeeks(new Date(), 12), startOfCurrentWeek) < 0 ?
                        <IconButton onClick={() => setStartCurrentWeek(subWeeks(startOfCurrentWeek, 1))}
                                    aria-label={t('common.back')}>
                            <ArrowBackIcon/>
                        </IconButton>
                        : null}
                </Grid>
                <Grid item xs={10}>
                    <Typography
                        align={"center"}>{formatDateObj(startOfCurrentWeek)} - {formatDateObj(endOfCurrentWeek)}</Typography>
                </Grid>
                <Grid item xs={1}>
                    {compareAsc(addWeeks(startOfCurrentWeek, 1), endOfWeekEU(new Date())) < 0 ?
                        <IconButton onClick={() => setStartCurrentWeek(addWeeks(startOfCurrentWeek, 1))}
                                    aria-label={t('common.forward')}>
                            <ArrowForwardIcon/>
                        </IconButton>
                        : null}
                </Grid>
            </Grid>
            <Grid container spacing={4} item xs={12}>
                {daysOfWeek.map(day =>
                    <Grid key={'weekKey' + day.date.getTime()} item>
                        <DailyAdmReportTile dailyAdmReport={day.dailyAdmReport} forDay={day.date} />
                    </Grid>
                )}
            </Grid>

        </Grid>
    </Navigation>
}
