import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite'
import { currencyFormater } from "../util/CurrencyUtil";
import {
    Grid,
    Slider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    searchOptionSelect: {
        backgroundColor: "white",
        minWidth: 180,
    },
}));

const FILTER_COLOR_LIST = [
    { code: 0, name: "schwarz", hex: "#000" },
    { code: 1, name: "weiß", hex: "#fff" },
    { code: 2, name: "grau", hex: "#9d9d9d" },
    { code: 3, name: "rot", hex: "#d93333" },
    { code: 4, name: "blau", hex: "#0080ff" },
    { code: 5, name: "grün", hex: "#2ecc71" },
    { code: 6, name: "gelb", hex: "#ffea01" },
    { code: 7, name: "orange", hex: "#ffa500" },
    { code: 8, name: "silber", hex: "#ecf0f1" },
    { code: 9, name: "braun", hex: "#9c8061" },
    { code: 10, name: "klar", hex: "#fafafa" },
    { code: 11, name: "lila", hex: "#c926ff" },
    { code: 12, name: "pink", hex: "#ff73ff" },
    { code: 13, name: "gold", hex: "#eabb73" }
];



export const SelectableChipsList = observer(<T extends string|number, A extends { id: T, name: string}>(props: { categories: A[], selected: T, onChange: (id: T) => void }) => {
    const { categories, onChange, selected } = props;

    return (
        <Grid container spacing={1}>
            {categories.map(({ id, name }: A ) =>
                <Grid key={id} item>
                    <Chip label={name} clickable color="primary" variant={String(selected) === String(id) ? 'default' : 'outlined'} onClick={() => {
                        onChange && onChange(id);
                    }} />
                </Grid>
            )}
        </Grid>
    )
});

export const ColorSelect = observer((props: any) => {
    const classes = useStyles();
    const { value, onChange } = props;

    return (
        <FormControl variant="outlined" className={classes.searchOptionSelect}>
            <InputLabel id="search-option-color">Color</InputLabel>
            <Select labelId="search-option-color" label="Color" value={value} onChange={onChange}>
                <MenuItem value=""><em>None</em></MenuItem>
                {FILTER_COLOR_LIST.map(c =>
                    <MenuItem key={c.code} value={c.code}>{c.name}</MenuItem>
                )}
            </Select>
        </FormControl>
    )
});

export const BudgetSlider = observer((props: any) => {
    const { minPrice, maxPrice, onChange } = props;

    return (
        <Slider
            value={[ +minPrice, +maxPrice ]}
            step={0.1}
            aria-labelledby="budget-slider"
            valueLabelDisplay="on"
            marks={[
                { value: 0, label: currencyFormater(0) },
                { value: 100, label: currencyFormater(100) }
            ]}
            onChange={onChange}
        />
    )
});
