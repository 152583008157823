import React, {useEffect, useState} from "react";
import Navigation from "../components/Navigation";
import {FormControl, Grid, InputLabel, MenuItem, Paper, Select} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ToDo} from "../store/models/ToDo";
import Api, {searchPaginationPageSize} from "../store/Api";
import {filterMap, MeetingListFilter} from "../components/meetingList/MeetingListFilter";
import MeetingListFilterSelection from "../components/meetingList/MeetingListFilterSelection";
import MeetingListTimeFrameFilerSelection from "../components/meetingList/MeetingListTimeFrameFilterSelection";
import MeetingListRow from "../components/meetingList/MeetingListRow";
import IStore from "../store/models/IStore";
import Contact from "../store/models/Contact";
import {Pagination} from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(1, 3),
        margin: theme.spacing(3, 0),
        minHeight: '50%'
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        fontWeight: "bold",
    },
    divider: {
        height: 24,
        width: 1
    },
}));


function MeetingListManagerView() {
    const [filterKey, setFilterKey] = useState<MeetingListFilter>(MeetingListFilter.NONE);
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
    const [selectedAdmId, setSelectedAdmId] = useState('');

    const [meetings, setMeetings] = useState<ToDo[]>([]);
    const [contacts, setContacts] = useState<Contact[]>([])
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const dispatch = useDispatch();

    const isContact = (c: Contact | undefined): c is Contact => !!c

    const fetchMeetingsFromApi = () => {
        Api.getMeetingsForAdms(currentMonth.getFullYear(), currentMonth.getMonth(), selectedAdmId, filterKey, page)
            .then(({
                       searchResult,
                       totalCount
                   }) => {
                const contacts = searchResult.map(d => d.contact).filter(isContact);
                setMeetings(searchResult.map(d => d.todo))
                setContacts(contacts)
                setTotalCount(totalCount)
            })
    }

    useEffect(fetchMeetingsFromApi, [dispatch, currentMonth, filterKey, selectedAdmId, page])

    const filteredMeetings = meetings.filter(filterMap[filterKey]).filter((todo) => selectedAdmId === '' || todo.admID === selectedAdmId);//.sort((a, b) => compareAsc(new Date(a.dueOn!), new Date(b.dueOn!)))

    const classes = useStyles();
    const {t} = useTranslation();

    const filterOrderList = (newKey: MeetingListFilter) => () => {
        setFilterKey(newKey)
    }


    const subordinatedAdms = useSelector((state: IStore) => state.adms.mySubordinatedAdms);

    return <>
        <Navigation breadcrumbName={'Aufträge meiner VBs'}>
            <Grid container spacing={3} justifyContent={"center"}>
                <MeetingListTimeFrameFilerSelection selectedMonth={currentMonth} setMonth={setCurrentMonth}/>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>{t('meetingListManagerView.admSelectLbl')}</InputLabel>
                        <Select
                            value={selectedAdmId}
                            //@ts-ignore
                            onChange={(event => setSelectedAdmId(event.target.value))}
                        >
                            <MenuItem value={''}>&nbsp;</MenuItem>)
                            {subordinatedAdms.map(adm => <MenuItem
                                key={'AddressTypeOptions' + adm.sapID}
                                value={adm.sapID}>{`${adm.vorname} ${adm.nachname}`}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}></Grid>
                <MeetingListFilterSelection selectedFilter={filterKey} filterFunc={filterOrderList}/>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell><b>{t('meetingListManagerView.adm')}</b></TableCell>
                                    <TableCell><b>{t('meetingListManagerView.date')}</b></TableCell>
                                    <TableCell><b>{t('meetingListManagerView.dateCreated')}</b></TableCell>
                                    <TableCell><b>{t('meetingListManagerView.customer')}</b><br/>{t('meetingListManagerView.place')}</TableCell>
                                    <TableCell><b>{t('meetingListManagerView.type')}</b><br/>{t('meetingListManagerView.withMail')}
                                    </TableCell>
                                    <TableCell><b>{t('meetingListManagerView.status')}</b></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredMeetings.map((meeting: ToDo) =>
                                    <MeetingListRow key={'meetingListRow' + meeting.todoNumber} meeting={meeting}
                                                    isManagerList={true} selectedAdm={selectedAdmId}
                                                    contact={contacts.find(c => meeting.forCustomer === c.stContactID)}/>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Pagination count={Math.floor(totalCount / searchPaginationPageSize) + 1} page={page}
                                onChange={(e, page) => setPage(page)}/>
                </Grid>
            </Grid>

        </Navigation>
    </>
}

export default MeetingListManagerView;
