import React, {useLayoutEffect, useRef} from 'react';
import { observer } from 'mobx-react-lite'
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
    Divider,
    IconButton,
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    Grid,
    Typography
} from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import useProductUser from "../hooks/useProductUser";

const useStyles = makeStyles((theme: Theme) => ({
    productCard: {
        position: "relative",
    },
    productCardImg: {
        width: "100%",
        padding: theme.spacing(2),
    },
    productCardLink: {
        textDecoration: "none",
        color: "inherit",
    },
    wishlistBtn: {
        position: "absolute",
        top: 0,
        right: 0,
    },
}));

export const ProductList = observer((props: any) => {
    const { products } = props;

    return (
        <Grid container justifyContent="center" spacing={6}>
            {products.map((product: any) =>
                <ProductCard key={product.id} product={product} />
            )}
        </Grid>
    )
})

export const ProductCard = observer((props: any) => {
    const { product } = props;
    const classes = useStyles();
    const productUserStore = useProductUser();
    const wishlisted = productUserStore.wishlistMap[product.id];

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} >
            <Card className={classes.productCard}>
                <CardActionArea>
                    <a className={classes.productCardLink} href={product.link}>
                        <CardMedia className={classes.productCardImg} image={product.img} component="img"/>
                        <Divider/>
                        <CardContent>
                            <Typography>
                                {product.title}
                            </Typography>
                            <Typography>
                                {product.price.toLocaleString(undefined, { style: "currency", currency: "EUR"})}
                            </Typography>
                        </CardContent>
                    </a>
                </CardActionArea>
                <IconButton className={classes.wishlistBtn}>
                    {wishlisted ?
                        <FavoriteIcon onClick={() => productUserStore.removeFromWishlist(product.id)}/>
                        :
                        <FavoriteBorderIcon onClick={() => productUserStore.addToWishlist(product)}/>
                    }
                </IconButton>
            </Card>
        </Grid>
    )
})

export const ProductLoader = (props: any) => {
    const loadEl = useRef<HTMLDivElement>(null);
    const { onLoadProducts, loading } = props;


    useLayoutEffect(() => {
        onLoadProducts()
        document.addEventListener("scroll", async () => {
            if (!loading && loadEl.current) {
                var viewportHeight = document.documentElement.clientHeight;
                var viewportOffset = loadEl.current.getBoundingClientRect();
                if (viewportOffset.bottom <= viewportHeight) {
                    onLoadProducts()
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div ref={loadEl}></div>
}
