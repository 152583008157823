import React, { useCallback, useEffect, useState } from "react";
import { Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Contact from "../../../store/models/Contact";
import { formatDateObj } from "../../Formatter";
import { useTranslation } from "react-i18next";
import CreateNoteModal from "../CreateNoteModal";
import Api from "../../../store/Api";
import { Note } from "../../../store/models/Note";
import TabPanel from "../../TabPanel";
import { ToDo } from "../../../store/models/ToDo";
import ContactHistoryNoteDisplay from "../ContactHistoryNoteDisplay";
import ContactHistoryTodoDisplay from "../ContactHistoryTodoDisplay";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      minHeight: "50%",
      padding: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3),
      margin: theme.spacing(3, 0),
    },
  };
});

interface ContactDetailContactTabProps {
  contact?: Contact;
}

interface ContactHistoryEntry {
  date: Date;
  note?: Note;
  todo?: ToDo;
}

function mapContactHistoryEntries(notes: Note[], doneTodos: ToDo[]) {
  const historyEntries: ContactHistoryEntry[] = [];
  notes.forEach((note) => {
    if (note.created) {
      historyEntries.push({
        date: note.created,
        note,
      });
    }
  });
  doneTodos.forEach((todo) => {
    if (todo.done) {
      historyEntries.push({
        date: todo.done,
        todo,
      });
    }
  });
  return historyEntries.sort((a, b) => b.date.getTime() - a.date.getTime());
}

export default function ContactDetailCommunicationTab({
  contact,
}: ContactDetailContactTabProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [contactHistoryEntries, setContactHistoryEntries] = useState<
    ContactHistoryEntry[]
  >([]);

  const [selectedTab, setTab] = React.useState("notes");

  const updateContactHistoryData = useCallback(async () => {
    if (contact) {
      const notes = await Api.getNotes(contact.stContactID);
      const doneTodos = await Api.getDoneToDos(contact.stContactID);
      setContactHistoryEntries(mapContactHistoryEntries(notes, doneTodos));
    }
  }, [contact]);

  useEffect(() => {
    updateContactHistoryData();
  }, [updateContactHistoryData]);
  return (
    <>
      <Grid
        className={classes.container}
        container
        spacing={6}
        direction={"column"}
      >
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          onChange={(e, val) => setTab(val)}
        >
          <Tab
            value={"notes"}
            label={t("contactDetailCommunicationTab.notesTabLbl")}
          />
          <Tab
            value={"campaigns"}
            label={t("contactDetailCommunicationTab.campaignsTabLbl")}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={"notes"}>
          <Grid container>
            <Grid item container>
              <Grid item xs={12} container>
                <div style={{ flexGrow: 1, display: "flex" }}></div>
                <Grid item>
                  <CreateNoteModal
                    afterCreate={updateContactHistoryData}
                    contactNumber={contact?.stContactID || ""}
                    headline={t(
                      "contactDetailCommunicationTab.createNewNoteModalHeadline"
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            {contactHistoryEntries.map((entry) => (
              <Grid key={entry.date.getTime()} item xs={12}>
                <Paper className={classes.paper}>
                  {entry.note ? (
                    <ContactHistoryNoteDisplay
                      note={entry.note}
                      afterEdit={updateContactHistoryData}
                    />
                  ) : null}
                  {entry.todo ? (
                    <ContactHistoryTodoDisplay todo={entry.todo} />
                  ) : null}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={"campaigns"}>
          <Grid item container>
            {contact?.subscribedCampaigns
              .sort(
                (a, b) =>
                  (a.creationDate && a.creationDate.getTime ? a.creationDate.getTime() : 0) -
                  (b.creationDate && b.creationDate.getTime ? b.creationDate.getTime() : 0)
              )
              .map((sC) => (
                <Grid key={sC.stCampaignId} item xs={12}>
                  <Paper className={classes.paper}>
                    <Grid item container spacing={3} xs={12}>
                      <Grid item xs={4}>
                        <Typography>{sC.campaignName}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        {sC.mailOpeners && sC.mailOpeners.length > 0 ? (
                          <>
                            {sC.mailOpeners.map((mO) => (
                              <Typography key={mO.openedOn.toString()}>
                                {mO.email} - {formatDateObj(mO.openedOn)}
                              </Typography>
                            ))}
                          </>
                        ) : (
                          <Typography>
                            {t("contactDetailCommunicationTab.notOpened")}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </TabPanel>
      </Grid>
    </>
  );
}
