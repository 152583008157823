import React, {useEffect, useState} from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {AssignmentConfig} from "../../../store/models/AssignmentConfig";
import {formatDateObj} from "../../Formatter";
import Api from "../../../store/Api";
import AssignmentConfigModal from "../AssignmentConfigModal";

const useStyles = makeStyles((theme) => {
    return ({
        container: {
            marginTop: theme.spacing(3)
        },
        table: {
            minWidth: 650,
        },
    });
});


export default function BadAssignmentTab() {
    const classes = useStyles();

    const updateConfigs = () => Api.getAssignationConfigsForBAD().then(configs => setAssignmentConfigs(configs))

    useEffect(() => {
        updateConfigs();
    }, [])
    const [assignmentConfigs, setAssignmentConfigs] = useState<AssignmentConfig[]>([])
    return <>
        <Grid className={classes.container} container spacing={6} direction={"column"}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Land Kürzel</TableCell>
                        <TableCell align="right">Zuweisung Termin maximal</TableCell>
                        <TableCell align="right">Zuweisung Wiedervorlage maximal</TableCell>
                        <TableCell align="right">für Graukunden</TableCell>
                        <TableCell align="right">für Interessenten</TableCell>
                        <TableCell align="right">zuletzt bearbeitet</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {assignmentConfigs.map((config) => (
                        <TableRow key={config.locale}>
                            <TableCell component="th" scope="row">
                                {config.locale}
                            </TableCell>
                            <TableCell
                                align="right">{config.assignMeetingsFor} ({config.forMeetings ? 'aktiv' : 'inaktiv'})</TableCell>
                            <TableCell
                                align="right">{config.assignFollowUpsFor} ({config.forFollowUps ? 'aktiv' : 'inaktiv'})</TableCell>
                            <TableCell align="right">{config.forGreyCustomer ? 'Ja' : 'Nein'}</TableCell>
                            <TableCell align="right">{config.forPotentialCustomer ? 'Ja' : 'Nein'}</TableCell>
                            <TableCell align="right">{formatDateObj(config.updatedAt)}</TableCell>
                            <TableCell align="right">
                                <AssignmentConfigModal afterCreate={updateConfigs} assignmentConfig={config}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Grid item xs={12}>
                <AssignmentConfigModal afterCreate={updateConfigs}/>
            </Grid>
        </Grid>
    </>
}