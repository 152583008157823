import React from 'react';
import Label from "../Label";
import i18next from "i18next";

export function ContactOrderStatusIndicator(props: {status: string, cancellationReason?: string}) {
    const {status, cancellationReason} = props;
    if(cancellationReason) {
        return <Label color="error">{i18next.t('contactOrderStatusIndicator.positionCancelled')}</Label>
    }
    if (status === "vollständig bearbeitet") {
        return <Label color="success">{i18next.t('contactOrderStatusIndicator.positionCompleted')}</Label>
    }
    if (status === "teilweise bearbeitet") {
        return <Label color="warning">{i18next.t('contactOrderStatusIndicator.positionPartlyCompleted')}</Label>
    }
    if (status === "nicht bearbeitet") {
        return <Label color="error">{i18next.t('contactOrderStatusIndicator.positionNotProcessed')}</Label>
    }
    if (status === "nicht relevant") {
        return <Label color="primary">{i18next.t('contactOrderStatusIndicator.positionNotRelevant')}</Label>
    }
    return <span>Unmapped Status: {status}</span>
}
