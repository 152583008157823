import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField
} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
import {blue} from "@material-ui/core/colors";
import {AssignmentConfig, emptyAssignmentConfig} from "../../store/models/AssignmentConfig";
import Api from "../../store/Api";
import EditIcon from "@material-ui/icons/Edit";

interface AssignmentConfigModalProps {
    assignmentConfig?: AssignmentConfig;
    afterCreate: () => void;
}

const useStyles = makeStyles((theme) => {
    return ({
        button: {
            margin: theme.spacing(1),
        },
        container: {
            margin: theme.spacing(1, 1, 3, 1)
        },
        paper: {
            padding: theme.spacing(1, 3),
            margin: theme.spacing(3, 0),
            minHeight: '50%'
        },
        avatar: {
            backgroundColor: blue[300],
        }
    });
});


export default function AssignmentConfigModal({assignmentConfig, afterCreate}: AssignmentConfigModalProps) {

    const {t} = useTranslation();
    const classes = useStyles();
    const createNew = !assignmentConfig
    return (
        <Modal maxWidth={"md"} fullWidth={true} title={"Automatische Zuweisungen"} contentAndButtons={(handleClose) => (
            <>
                <DialogContent>
                    <Formik
                        initialValues={createNew ? emptyAssignmentConfig : assignmentConfig}
                        onSubmit={async (values) => {
                            const config: AssignmentConfig = {
                                locale: values.locale,
                                forGreyCustomer: values.forGreyCustomer,
                                forPotentialCustomer: values.forPotentialCustomer,
                                forMeetings: values.forMeetings,
                                forFollowUps: values.forFollowUps,
                                assignMeetingsFor: values.assignMeetingsFor,
                                assignFollowUpsFor: values.assignFollowUpsFor,
                                updatedAt: new Date()
                            }
                            await Api.upsertAssignationConfigForBAD(config)
                            if (afterCreate) {
                                afterCreate();
                            }
                            handleClose();
                        }}
                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit,
                          }) => (
                            <form id="upsertAssignmentConfig" onSubmit={handleSubmit}>
                                <Box m={3}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <FormControl>
                                                <TextField
                                                    name="locale"
                                                    label={'Landkürzel (locale)'}
                                                    value={values.locale}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.forPotentialCustomer}
                                                                       onChange={handleChange}
                                                                       color="primary"
                                                                       name={'forPotentialCustomer'}/>}
                                                    label={"Automatische Zuweisung bei Intressenten?"}
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.forGreyCustomer}
                                                                       onChange={handleChange}
                                                                       color="primary"
                                                                       name={'forGreyCustomer'}/>}
                                                    label={"Automatische Zuweisung bei Graukunden?"}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl>
                                                <TextField
                                                    name="assignFollowUpsFor"
                                                    label={'Zuweisungsdauer bei Wiedervorlagen'}
                                                    value={values.assignFollowUpsFor}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.forFollowUps}
                                                                       onChange={handleChange}
                                                                       color="primary"
                                                                       name={'forFollowUps'}/>}
                                                    label={"Automatische Zuweisung bei Wiedervorlagen aktiv?"}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl>
                                                <TextField
                                                    name="assignMeetingsFor"
                                                    label={'Zuweisungsdauer bei Terminen'}
                                                    value={values.assignMeetingsFor}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.forMeetings}
                                                                       onChange={handleChange}
                                                                       color="primary"
                                                                       name={'forMeetings'}/>}
                                                    label={"Automatische Zuweisung bei Terminen aktiv?"}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}

                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('common.cancelBtn')}
                    </Button>
                    <Button form="upsertAssignmentConfig" type="submit" color={"primary"}>
                        {t('common.saveBtn')}
                    </Button>
                </DialogActions>
            </>
        )} openButton={(handleOpen) => (
            createNew ?
                <Button
                    variant="text"
                    onClick={handleOpen}
                    className={classes.button}
                    startIcon={<Avatar className={classes.avatar}><AddIcon/></Avatar>}
                >
                    neue Config anlegen
                </Button>
                :
                <Button
                    variant="text"
                    onClick={handleOpen}
                    className={classes.button}
                    startIcon={<Avatar className={classes.avatar}><EditIcon/></Avatar>}
                >
                    ändern
                </Button>

        )}/>
    );
}
