import React, {useEffect, useState} from 'react';
import Navigation from "../components/Navigation";
import {Box, Divider, Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {LIGHT_BLUE, LIGHT_GREEN, YELLOW} from "../BerendsohnMUITheme";
import {fetchUserDisplayname, getMyADMID} from "../util/UserUtil";
import {useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import formatCurrencyByADMCountry, {
    formatDashboardDate,
    formatDateObj,
    formatDateObjAsNumbers,
    formatDateTimeOnly
} from "../components/Formatter";
import ToDoItem from "../components/ToDoItem";
import ToDoInput from "../components/ToDoInput";
import {ToDo, ToDoType} from "../store/models/ToDo";
import {useTranslation} from "react-i18next";
import backgroundImage from '../img/backgroundDashboard.svg';
import {compareAsc, compareDesc, endOfDay, isAfter, isFuture, isPast, startOfDay, subMonths, subWeeks} from "date-fns";
import CalendarEvent from "../store/models/CalendarEvent";
import Api from "../store/Api";
import SalesFunnels from "../components/SalesFunnels";
import {HelpText} from "../components/HelpText";
import CalendarEventMenu from "../components/CalendarEventMenu";
import i18next from "i18next";
import useAdmCountry from "../hooks/useAdmCountry";
import DetailedOrder from "../store/models/DetailedOrder";
import DeliveredOrderMenu, {DeliveredPosition} from '../components/DeliveredOrderMenu';
import CustomerProcessingChart from "../components/dashboard/CustomerProcessingChart";
import {DabDigitalOrder} from "../store/models/DabDigitalOrder";
import DabDigitalOrderStatusList from "../components/dashboard/DabDigitalOrderStatusList";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(7, 10),
        width: '100%'
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'
    },
    paperFirstRow: {
        padding: theme.spacing(2),
        width: '100%',
        height: 300
    },
    divider: {
        margin: theme.spacing(2, 0, 3, 0)
    },
    todoBox: {
        height: 375,
        overflowX: "hidden"
    },
    todoBoxWithInput: {
        height: 316,
        overflowX: "hidden"
    },
    backgroundImage: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: 300,
        backgroundSize: 'contain',
        width: '100%'
    },
    kpiText: {
        padding: '10px 20px',
        textAlign: "center",
        display: "inline-block",
        borderRadius: 5,
        backgroundColor: 'rgba(242, 242, 242, .6)',
        margin: 10,
    },
    salesDataGreen: {
        color: LIGHT_GREEN,
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center"
    },
    salesDataYellow: {
        color: YELLOW,
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center"
    },
    salesDataBlue: {
        color: LIGHT_BLUE,
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center"
    },
    salesDataCurrency: {
        fontSize: 30,
        fontWeight: "normal",
        textAlign: "center",
        paddingBottom: 10
    },
}));

const fetchDisplayTextForCalendarEvent = (cE: CalendarEvent) => {
    if (cE.extensions && cE.extensions.length) {
        return cE.extensions[0].forCustomerDisplayName
    }
    return cE.subject
}
const fetchMeetingTypeForCalendarEvent = (cE: CalendarEvent) => {
    if (cE.extensions && cE.extensions.length) {
        return `(${i18next.t('msOfficeMeetingTypes.' + cE.extensions[0].meetingType)})`
    }
}
const fetchCustomerIDFromCalendarEvent = (cE: CalendarEvent) => {
    if (cE.extensions && cE.extensions.length) {
        return cE.extensions[0].forCustomer
    }
    return null;
}

type DashboardMeeting = CalendarEvent | ToDo;


function convertDetailedOrdersToDeliveredPositions(detailedOrders: DetailedOrder[]): DeliveredPosition[] {
    const result: DeliveredPosition[] = [];
    detailedOrders.forEach(dO => {
        dO.order.positions.forEach(p => {
            if (p.WarenausgangIST && isAfter(p.WarenausgangIST, subWeeks(new Date(), 4))) {
                result.push({
                    materialBezeichnung: p.materialBezeichnung,
                    posNummer: p.posNummer,
                    WarenausgangIST: p.WarenausgangIST,
                    auftragsNummer: dO.order.auftragsNummer,
                    nameBesteller: dO.order.nameBesteller,
                    kundenNummer: dO.order.kundenNummer,
                    stContactID: dO.stContactID,
                    contactName: dO.contactName
                })
            }
        })
    })
    //@ts-ignore
    return result.sort((a, b) => compareDesc(a.WarenausgangIST, b.WarenausgangIST))
}


function DashboardView() {
    const classes = useStyles();

    const {t} = useTranslation();

    const todos = useSelector((state: IStore) => state.todos.todos);

    const calendarEvents = useSelector((state: IStore) => state.calendarEvent.calendarEvents);
    const legacyCrmMeetings = todos.filter(t => t.type === ToDoType.LegacyCrmMeeting && (t.dueOn && isFuture(startOfDay(t.dueOn))) && (!t.done || isFuture(endOfDay(t.done))));


    const dashboardMeetings: DashboardMeeting[] = [...calendarEvents, ...legacyCrmMeetings].sort((a, b) => {
        const startA = a instanceof CalendarEvent ? a.start : a.dueOn;
        const startB = b instanceof CalendarEvent ? b.start : b.dueOn;
        return compareAsc(startA!, startB!)
    })
    const visitingReportTodos = todos.filter(t => [ToDoType.MSOfficeAppointment, ToDoType.LegacyCrmMeeting].includes(t.type) && (t.dueOn && isPast(startOfDay(t.dueOn))) && (!t.done || isFuture(endOfDay(t.done)))).sort((a, b) => compareDesc(a.dueOn!, b.dueOn!));
    const followUpTodos = todos.filter(t => [ToDoType.FollowUp, ToDoType.LegacyCrmFollowup].includes(t.type) && (!t.done || isFuture(endOfDay(t.done)))).sort((a, b) => compareAsc(a.dueOn!, b.dueOn!));
    const customTodos = todos.filter(t => t.type === ToDoType.Custom);

    const msOfficeTodos = todos.filter(t => t.type === ToDoType.MSOfficeAppointment);

    const [digitalOrderStatusList, setDigitalOrderStatusList] = useState<DabDigitalOrder[]>([]);
    const [deliveredPositions, setDeliveredPositions] = useState<DeliveredPosition[]>([])

    const [kpiDataFromApi, setKPIDataFromApi] = useState({
            activeContacts: 0,
            contactsGreyProspect: 0,
            activeContactsThisMonth: 0,
            followUps: 0,
            totalMeetingsThisYear: 0,
            orderCount: 0,
            paisyAE: 0,
        }
    );

    const fetchApiKPIs = () => {
        Api.getDashboardKPIs().then(dataFromApi =>
            setKPIDataFromApi({
                ...dataFromApi,
            })
        )
    }

    const [customerProcessingData, setCustomerProcessingData] = useState({
        assignedCustomersProcessingData: {
            customersGreen: 0,
            customersYellow: 0,
            customersRed: 0
        },
        protectedCustomersProcessingData: {
            customersGreen: 0,
            customersYellow: 0,
            customersRed: 0
        }
    });

    const fetchOrders = () => {
        Api.getOrdersWithWarenausgangLastFourWeeks(getMyADMID()).then(data =>
            setDeliveredPositions(convertDetailedOrdersToDeliveredPositions(data))
        )
    }

    const fetchDigitalOrderStatusFromDab = () => {
        Api.getDigitalOrderStatusFromDab(getMyADMID()).then(data => {
            setDigitalOrderStatusList(data)
        })
    }

    const fetchCustomersProcessingData = () => {
        Api.getCustomerProcessingStats().then(setCustomerProcessingData)
    }

    const admCountry = useAdmCountry(getMyADMID());

    useEffect(fetchApiKPIs, []);
    useEffect(fetchOrders, [])
    useEffect(fetchDigitalOrderStatusFromDab, [])
    useEffect(fetchCustomersProcessingData, [])


    return (<>
        <Navigation breadcrumbName={fetchUserDisplayname()}>
            <Grid container spacing={4}>
                <Grid item md={6}>
                    <Paper className={classes.paperFirstRow}>
                        <Box className={classes.backgroundImage}>
                            <Typography>{formatDashboardDate()}</Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper className={classes.paperFirstRow}>
                        <SalesFunnels potentialCustomers={kpiDataFromApi.contactsGreyProspect}
                                      followUps={followUpTodos.filter(todo => !todo.done && todo.dueOn && !isFuture(subMonths(todo.dueOn, 6))).length}
                                      meetings={calendarEvents.length}
                                      activeContacts={kpiDataFromApi.activeContacts}
                        />
                    </Paper>
                </Grid>
                <Grid item md={4}>
                    <Paper className={classes.smallPaper}>
                        <Typography className={classes.salesDataCurrency}
                                    variant={"h1"}>{formatCurrencyByADMCountry(kpiDataFromApi.paisyAE, admCountry)}</Typography>
                        <Typography align="right"
                                    className={classes.salesDataGreen}>
                            <HelpText icon={'info'} text={t('dashboardView.kpiPaisyAETooltip')}>
                                {t('dashboardView.kpiPaisyAE')}
                            </HelpText>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item md={4}>
                    <Paper className={classes.smallPaper}>
                        <Typography className={classes.salesDataCurrency}
                                    variant={"h1"}>{kpiDataFromApi.orderCount}</Typography>
                        <Typography align="right"
                                    className={classes.salesDataYellow}>
                            <HelpText icon={'info'} text={t('dashboardView.kpiOrdersTooltip')}>
                                {t('dashboardView.kpiOrders')}
                            </HelpText>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item md={4}>
                    <Paper className={classes.smallPaper}>
                        <Typography className={classes.salesDataCurrency}
                                    variant={"h1"}>{kpiDataFromApi.activeContactsThisMonth}</Typography>
                        <Typography align="right"
                                    className={classes.salesDataBlue}>
                            <HelpText icon={'info'} text={t('dashboardView.kpiContactsTooltip')}>
                                {t('dashboardView.kpiContacts')}
                            </HelpText>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper className={classes.smallPaper}>
                        <CustomerProcessingChart forProtectedCustomers={true} data={customerProcessingData.protectedCustomersProcessingData}/>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper className={classes.smallPaper}>
                        <CustomerProcessingChart data={customerProcessingData.assignedCustomersProcessingData} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.smallPaper}>
                        <Typography variant={"h6"}>{t('dashboardView.meetingTodoHeadline')}</Typography>
                        <Divider className={classes.divider}/>
                        <Box className={classes.todoBox}>
                            {dashboardMeetings.map(dM => {
                                    if (dM instanceof CalendarEvent) {
                                        const cE = dM;
                                        let stCustomerID = fetchCustomerIDFromCalendarEvent(cE);
                                        let todo;
                                        if (stCustomerID) {
                                            todo = msOfficeTodos.find(t => t.meetingProps?.msMeetingID === cE.id);
                                        }
                                        return <React.Fragment key={cE.id}>
                                            <Grid container spacing={3} style={{minHeight: 72}}>
                                                <Grid item xs={4}>
                                                    <Typography>{formatDateObjAsNumbers(cE.start)} </Typography>
                                                    <Typography
                                                        variant={"caption"}> {formatDateTimeOnly(cE.start)} - {formatDateTimeOnly(cE.end)} {fetchMeetingTypeForCalendarEvent(cE)}</Typography>
                                                </Grid>
                                                <Grid item xs={stCustomerID ? 6 : 8}>
                                                    <Typography>{fetchDisplayTextForCalendarEvent(cE)} </Typography>
                                                    {cE.location.address ?
                                                        <Typography
                                                            variant={"caption"}>{cE.location.address.postalCode} {cE.location.address.city} </Typography>
                                                        : null}
                                                </Grid>
                                                {stCustomerID ? <Grid item xs={2}>
                                                    <CalendarEventMenu stContactId={stCustomerID} todoToDelete={todo}/>
                                                </Grid> : null}
                                            </Grid>
                                            <Divider className={classes.divider}/>
                                        </React.Fragment>
                                    } else {
                                        return <React.Fragment key={dM.todoNumber}>
                                            <Grid container spacing={3} style={{minHeight: 72}}>
                                                <Grid item xs={4}>
                                                    <Typography>{formatDateObjAsNumbers(dM.dueOn!)} </Typography>
                                                    <Typography
                                                        variant={"caption"}> {formatDateTimeOnly(dM.dueOn!)} </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{dM.text} </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <CalendarEventMenu stContactId={dM.forCustomer!} todoToDelete={dM}/>
                                                </Grid>
                                            </Grid>
                                            <Divider className={classes.divider}/>
                                        </React.Fragment>
                                    }
                                }
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.smallPaper}>
                        <Typography variant={"h6"}>{t('dashboardView.followUpTodoHeadline')}</Typography>
                        <Divider className={classes.divider}/>
                        <Box className={classes.todoBox}>
                            {followUpTodos.map(todo =>
                                <ToDoItem key={todo.todoNumber} todo={todo}/>
                            )}
                        </Box>

                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.smallPaper}>
                        <Typography variant={"h6"}>{t('dashboardView.visitingReportTodoHeadline')}</Typography>
                        <Divider className={classes.divider}/>
                        <Box className={classes.todoBox}>
                            {visitingReportTodos.map(todo =>
                                <ToDoItem key={todo.todoNumber} todo={todo}/>
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.smallPaper}>
                        <Typography variant={"h6"}>{t('dashboardView.customTodoHeadline')}</Typography>
                        <Divider className={classes.divider}/>
                        <Box className={classes.todoBoxWithInput}>
                            {customTodos.map(todo =>
                                <ToDoItem key={todo.todoNumber} todo={todo}/>
                            )}
                        </Box>
                        <ToDoInput/>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.smallPaper}>
                        <Typography variant={"h6"}>{t('dashboardView.deliveredOrdersHeadline')}</Typography>
                        <Divider className={classes.divider}/>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography>{t('dashboardView.materialBezeichnung')}</Typography>
                                <Typography>{t('dashboardView.warenausgangIST')}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>{t('dashboardView.contactName')}</Typography>
                                <Typography>{t('dashboardView.nameBesteller')}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>{t('dashboardView.kundenNummer')}</Typography>
                                <Typography>{t('dashboardView.auftragsNummer')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider className={classes.divider}/>
                            </Grid>
                        </Grid>
                        <Box className={classes.todoBox}>
                            {deliveredPositions.map(dP =>
                                <Grid key={dP.auftragsNummer + dP.posNummer} container spacing={2}
                                      style={{minHeight: 62}}>
                                    <Grid item xs={4}>
                                        <Typography>{dP.materialBezeichnung}</Typography>
                                        <Typography>{formatDateObj(dP.WarenausgangIST)}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>{dP.contactName}</Typography>
                                        <Typography>{dP.nameBesteller}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>{dP.kundenNummer}</Typography>
                                        <Typography>{dP.auftragsNummer}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <DeliveredOrderMenu deliveredPosition={dP}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider className={classes.divider}/>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <DabDigitalOrderStatusList entries={digitalOrderStatusList}/>
                </Grid>
            </Grid>
        </Navigation>
    </>)
}

export default DashboardView;
