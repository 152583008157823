import React from 'react';
import {FormControl, Grid, IconButton, TextField} from '@material-ui/core';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {Formik} from "formik";
import {ToDo, ToDoType} from "../store/models/ToDo";
import {thunkAddToDo} from "../store/actions/ToDoActions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";


export default function ToDoInput() {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    return <Formik
        initialValues={{todoText: ''}}
        onSubmit={async (values, {resetForm}) => {
            if (values.todoText && values.todoText.trim().length) {
                const newToDo: ToDo = {
                    admID: "",
                    created: new Date(),
                    text: values.todoText,
                    type: ToDoType.Custom,
                };
                dispatch(thunkAddToDo(newToDo));
            }
            resetForm();
        }}
    >
        {({
              values,
              handleChange,
              handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={10}>
                        <FormControl fullWidth>
                            <TextField
                                name="todoText"
                                required
                                label={t('toDoInput.toDoTextLbl')}
                                value={values.todoText}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton component="span" onClick={() => handleSubmit()}>
                            <AddCircleIcon fontSize={"large"} color={"primary"}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </form>
        )}

    </Formik>
}
