import {computed, observable, action, decorate} from 'mobx';
import {ProductDetailStore} from './ProductDetail';



class ProductOfferStore {
    id?: string
    positions: any[] = []
    createdOn = new Date()
    sentOn?: Date
    productData: any = {}
    loaded = false
    changed = false

    autocomleteResult = []
    loadingAutocomplete = false
    refreshingContacts = false
    sendingOffer = false
    sendingNewsletter = false
    contactsQuery = ''

    title = ''
    clientData = {
        sapId: '',
        crmId: '',
        company: '',
        name: '',
        address: '',
        postcode: '',
    }
    emailData = {
        to: '',
        subject: '',
        message: ''
    }
    sign = {
        data: '',
        image: ''
    }
    newsletterToolData = {
        email: '',
        greeting: '',
        template: ''
    }
    _newsletterToolData = {
        contactName: '',
        selectedGreeting: ''
    }

    searchContact(query='') {
    }
    refreshContacts() {
    }
    selectAutocomplete(match: any) {
        const contact = match.contactPersons.find((p: any) => p.function === "BE") || match.contactPersons.find((p: any) => p.function === "IH");
        const contactName = contact ? [contact.title, contact.firstname, contact.lastname].filter(v => v.length).join(" ") : this.clientData.name;
        const newsletterContactName = contact ? [contact.title, contact.lastname].filter(v => v.length).join(" ") : this._newsletterToolData.contactName;
        const address = match.address;
        this.clientData = {
            sapId: match.sapCustomerID,
            crmId: match.crmCustomerID,
            company: [match.companyLine1, match.companyLine2, match.companyLine3].filter(v => v.length).join(" "),
            name: contactName,
            address: [address.street, address.houseNumber].filter(v => v.length).join(" ") || this.clientData.address,
            postcode: [address.country, address.zipCode, address.place].filter(v => v.length).join(" ") || this.clientData.postcode
        };
        this.emailData = {
            to: contact ? contact.emailAddress : this.emailData.to,
            subject: `Angebot Nr. ${this.id}`,
            message: "Hallo " + contactName + ",\n\nNachricht...\n"
        };
        this._newsletterToolData = {
            contactName: newsletterContactName,
            selectedGreeting: this._newsletterToolData.selectedGreeting
        };
        this.newsletterToolData = {
            email: contact ? contact.emailAddress : this.newsletterToolData.email,
            greeting: `${this._newsletterToolData.selectedGreeting} ${this._newsletterToolData.contactName}`,
            template: this.newsletterToolData.template
        };
    }

    loadOfferData = (offerData: any) => {
        const {id, title, createdOn, sentOn, positions, clientData, productData, emailData, sign} = offerData;
        this.id = id;
        this.title = title;
        this.createdOn = new Date(createdOn);
        this.productData = productData;
        if (sentOn) this.sentOn = new Date(sentOn);
        if (clientData) this.clientData = clientData;
        if (emailData) this.emailData = emailData;
        if (sign) this.sign = sign;
        this.loaded = true;
        this.addPositions(positions);
    }
    getOfferData() {
        let {title, positions, clientData, emailData, productData} = this;
        positions = positions.map(pos => {
            const { productId, printOptions, textileQtyPerSize, productImgIdxMap } = pos;
            const productDataItem = productData[productId];
            const productImgIdx = Object.keys(productImgIdxMap).map(k => +k).filter(k => productImgIdxMap[k]);
            const priceTiers = pos.priceTiers.map(({amount, multiplier, price, commission}: any) => ({
                amount,
                price,
                multiplier: +((multiplier / 100) + 1).toFixed(3),
                commission: +(commission.toFixed(2))
            }));
            return {
                productId,
                printOptions,
                textileQtyPerSize,
                productImgIdx,
                priceTiers,
                productData: {
                    ...productDataItem.data,
                    name: productDataItem.name,
                    decription: productDataItem.decription,
                    printOptions: Object.keys(printOptions).map(areaId => {
                        const areaData = productDataItem.printOptions[areaId];
                        const optionId = printOptions[areaId];

                        return {
                            id: areaId,
                            name: areaData.name,
                            print: {
                                id: optionId,
                                name: areaData.options[optionId]
                            }
                        };
                    })
                }
            };
        });
        return { title, positions, clientData, emailData, sign: {} };
    }
    createOfferFromCart(cart: any[] = [], productData: any) {
        const newOfferStore = new ProductOfferStore();
        newOfferStore.productData = productData;
        newOfferStore.addPositions(cart);
        newOfferStore.changed = true;

        return newOfferStore.getOfferData();
    }
    downloadPDF() {
    }
    sendOffer() {
    }
    sendNewsletter() {
    }
    addPositions = (positions: any[] = []) => {
        // instantiate Positions
        const newPositions = positions.map(item => new Position({
            ...item,
            productData: this.productData[item.productId]
        }));

        this.positions.push(...newPositions);
    }
    addPositionsFromCart(cart: any[] = [], productData: any) {
        this.productData = Object.assign(this.productData, productData);
        this.addPositions(cart);
        this.changed = true;
    }
    movePositionUp = (idx=1) => {
        this.positions.splice(idx - 1, 2, this.positions[idx], this.positions[idx - 1]);
    }
    movePositionDown = (idx=0) => {
        this.positions.splice(idx, 2, this.positions[idx + 1], this.positions[idx]);
    }
    removePosition = (idx=0) => {
        this.positions.splice(idx, 1);
    }
}

decorate(ProductOfferStore, {
    id: observable,
    positions: observable,
    createdOn: observable,
    sentOn: observable,
    productData: observable,
    loaded: observable,
    changed: observable,
    autocomleteResult: observable,
    loadingAutocomplete: observable,
    refreshingContacts: observable,
    sendingOffer: observable,
    sendingNewsletter: observable,
    contactsQuery: observable,
    title: observable,
    clientData: observable,
    emailData: observable,
    sign: observable,
    newsletterToolData: observable,
    _newsletterToolData: observable,
    searchContact: action,
    refreshContacts: action,
    selectAutocomplete: action,
    loadOfferData: action,
    getOfferData: action,
    createOfferFromCart: action,
    downloadPDF: action,
    sendOffer: action,
    sendNewsletter: action,
    addPositions: action,
    addPositionsFromCart: action,
    movePositionUp: action,
    movePositionDown: action,
    removePosition: action,
})

export default new ProductOfferStore();



class Position {
    productId = null
    printOptions: any = {}
    priceTiers: any[] = []
    productData: any = null
    productImgIdxMap = null

    constructor(positionData: any) {
        const {amount, userMult, printOptions, textileQtyPerSize, productId, productData, productImgIdx=[1], priceTiers} = positionData;
        if (productId) {
            let newPriceTiers;
            if (priceTiers) {
                newPriceTiers = priceTiers.map(({amount, multiplier}: any) => {
                    return new PriceTier({
                        amount,
                        multiplier,
                        selectedPrintOptions: printOptions,
                        productData: productData,
                    });
                });
            } else {
                const priceTier = new PriceTier({
                    amount,
                    multiplier: 1,
                    selectedPrintOptions: printOptions,
                    productData: productData,
                });
                if (userMult) priceTier.multiplier = userMult;
                newPriceTiers = [priceTier];
            }

            Object.assign(this, {
                printOptions,
                productId,
                productData,
                priceTiers: newPriceTiers,
                textileQtyPerSize: textileQtyPerSize,
                productImgIdxMap: productImgIdx.reduce((acc: any, idx: number) => {
                    acc[idx] = true;
                    return acc;
                }, {})
            });
        }

    }

    get printOptionsList() {
        if (!this.productData) return [];
        return Object.keys(this.printOptions).filter(akey => this.printOptions[akey]).map((akey) => {
            const {name, options} = this.productData.printOptions[akey];
            const optionId = this.printOptions[akey];
            const option = options[optionId];
            return { aname: name, oname: option};
        });
    }
    get selectedPrintOptionsValid() {
        const selectPrintOptions = this.printOptions;
        const printOptions = this.productData.printOptions;
        return Object.keys(selectPrintOptions).every(pkey => {
            const optionId = selectPrintOptions[pkey];
            const { options } = printOptions[pkey];
            return options && (options[optionId] || optionId === null);
        });
    }

    movePriceTierUp = (idx=1) => {
        this.priceTiers.splice(idx - 1, 2, this.priceTiers[idx], this.priceTiers[idx - 1]);
    }

    movePriceTierDown = (idx=0) => {
        this.priceTiers.splice(idx, 2, this.priceTiers[idx + 1], this.priceTiers[idx]);
    }

    removePriceTier = (idx=0) => {
        if (this.priceTiers.length > 1) this.priceTiers.splice(idx, 1);
    }

    copyPriceTier = (idx=0) => {
        const newPriceTier = this.priceTiers[idx].copy();
        this.priceTiers.splice(idx + 1, 0, newPriceTier);
    }
}

decorate(Position, {
    priceTiers: observable,
    productData: observable,
    productImgIdxMap: observable,
    printOptionsList: computed,
    selectedPrintOptionsValid: computed,
    movePriceTierUp: action,
    movePriceTierDown: action,
    removePriceTier: action,
    copyPriceTier: action,
})



class PriceTier {
    _multiplier: number | "" = 0
    _productDetailStore: ProductDetailStore

    constructor({multiplier, ...data}: any) {
        const productDetailStore = new ProductDetailStore();
        if (data) Object.assign(productDetailStore, data);
        this._multiplier = +((multiplier - 1) * 100).toFixed(3) || 0;
        this._productDetailStore = productDetailStore;
    }

    copy = () => {
        const {amount, selectedPrintOptions, productData} = this._productDetailStore;
        if (productData) {
            const newPriceTier = new PriceTier({ amount, selectedPrintOptions, productData });
            newPriceTier._multiplier = this._multiplier;
            return newPriceTier;
        }
    }

    get price() {
        const price = this._productDetailStore?.price;
        if (price) {
            const multiplier = this._multiplier ? this._multiplier : 0;
            const {full, kjPrice} = price;
            const final = full * Number(((multiplier / 100) + 1).toFixed(3));
            return { full: final, item: final / this.amount, itemKJ: kjPrice / this.amount };
        } else {
            return null;
        }
    }
    get amount() {
        return this._productDetailStore?.amount;
    }
    set amount(value) {
        this._productDetailStore.amount = value;
    }
    get minAmount() {
        return this._productDetailStore?.minAmount;
    }
    get multiplier() {
        return this._multiplier;
    }
    set multiplier(value) {
        value = parseInt(String(value), 10);
        this._multiplier = value || value === 0 ? value : "";
    }
    get commission() {
        const commissionMod = this._multiplier >= 0 ? 200 : 400;
        const commission = Math.min((5000 + commissionMod * Number(this._multiplier)) / (100 + Number(this._multiplier)), 100);
        return commission;
    }
}

decorate(PriceTier, {
    _multiplier: observable,
    _productDetailStore: observable,
    price: computed,
    amount: computed,
    minAmount: computed,
    multiplier: computed,
    commission: computed,
})
