import React, {useEffect, useState} from 'react';
import {
    Badge,
    ButtonGroup,
    Button,
    Grid,
    Popover,
    Card,
    CardMedia,
    CardActionArea,
    Divider,
    IconButton,
    Typography
} from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {makeStyles, Theme} from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite'
import useProductUser from "../hooks/useProductUser";
import useProductOffer from "../hooks/useProductOffer";
import Api from "../store/Api";



const useStyles = makeStyles((theme: Theme) => ({
    productMenu: {
        padding: theme.spacing(0.5, 2)
    },
    cartCard: {
        position: "relative",
        border: "none"
    },
    cartCardContainer: {
        padding: theme.spacing(1.5)
    },
    cartCardText: {
        textAlign: "right",
    },
    cartCardRemove: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    cartPriceContainer: {
        display: "flexWrap",
        justifyContent: "space-between",
        padding: theme.spacing(2)
    },
    cartCardPrice: {
        marginBottom: theme.spacing(1.5)
    }
}));



export const ProductUserMenu = observer(() => {
    const [cartAnchorEl, setCart] = useState<HTMLButtonElement | null>(null);
    const productUserStore = useProductUser();
    const productOfferStore = useProductOffer();
    const { wishlist, cart } = productUserStore;
    const classes = useStyles();
    useEffect(() => {
        productUserStore.loadWishlist();
        productUserStore.loadCart();
    }, [productUserStore]);
    const cartOpen = Boolean(cartAnchorEl);

    const handleCartClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCart(event.currentTarget);
    };
    const handleCartClose = () => {
        setCart(null);
    };
    const handleNewOffer = async () => {
        let productIDs = cart.reduce((acc: any, { productId }: any) => {
            if (!acc[productId]) {
                acc[productId] = true;
            }
            return acc;
        }, {});
        productIDs = Object.keys(productIDs);
        const productData = await Api.getProducts(productIDs);
        const offerData = productOfferStore.createOfferFromCart(cart, productData);
        const newOfferID = await Api.createProductOffer(offerData);
        
        window.history.pushState(null, '', window.location.href);
        window.location.replace(`/offer/${newOfferID}`);
    };

    return (
        <Card className={classes.productMenu} variant="outlined">
            <a href="/wishlist">
                <IconButton>
                        <Badge badgeContent={wishlist.length} color="primary">
                            <FavoriteIcon/>
                        </Badge>
                </IconButton>
            </a>
            <IconButton onClick={handleCartClick}>
                <Badge badgeContent={cart.length} color="primary">
                    <ShoppingCartIcon/>
                </Badge>
            </IconButton>
            {cart.length > 0 &&
                <Popover
                    id="user-cart-popover"
                    open={cartOpen}
                    anchorEl={cartAnchorEl}
                    onClose={handleCartClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {cart.map((item: any) =>
                        <Card variant="outlined" key={item.id} className={classes.cartCard}>
                            <CardActionArea className={classes.cartCardContainer}>
                                <Grid container>
                                    <Grid item sm={2}>
                                        <CardMedia image={item.productImg} component="img"/>
                                    </Grid>
                                    <Grid className={classes.cartCardText} item sm={6}>
                                        <Typography variant="body2">
                                            {item.productName}
                                        </Typography>
                                        <Typography variant="body2">
                                            {`${item.amount.toLocaleString()} St.`}
                                        </Typography>
                                    </Grid>
                                    <Grid className={classes.cartCardText} item sm={3}>
                                        <Typography variant="subtitle2">
                                            {item.price.toLocaleString(undefined, { style: "currency", currency: 'EUR'})}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={1} />
                                </Grid>
                            </CardActionArea>
                            <IconButton
                                className={classes.cartCardRemove}
                                onClick={() => productUserStore.removeFromCart(item.id)}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        </Card>
                    )}
                    <Divider />
                    <div className={classes.cartPriceContainer}>
                        <Typography className={`${classes.cartCardText} ${classes.cartCardPrice}`} variant="h6">
                            {productUserStore.cartTotalPrice.toLocaleString(undefined, { style: "currency", currency: 'EUR'})}
                        </Typography>
                        <ButtonGroup color="primary" aria-label="outlined button group">
                            <Button onClick={handleNewOffer}>
                                Neues Angebot erstellen
                            </Button>
                            <Button onClick={() => productUserStore.clearCart()}>
                                <DeleteForeverIcon />
                            </Button>
                        </ButtonGroup>
                    </div>
                </Popover>
            }
        </Card>
    )
})

export default ProductUserMenu;
