import React, {useState} from 'react';
import Contact from "../../../store/models/Contact";
import {
    Avatar,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Paper,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {blue, green} from "@material-ui/core/colors";
import PlaceIcon from '@material-ui/icons/Place';
import PeopleIcon from '@material-ui/icons/People';
import ContactPersonEditModal from "../ContactPersonEditModal";
import formatToCurrency, {formatDate, formatDateObj, formatDateOnly} from "../../Formatter";
import Api from "../../../store/Api";
import ContactEditModal from "../ContactEditModal";
import ContactAddressEditModal from "../ContactAddressEditModal";
import {AddressType, emptyAddress} from "../../../store/models/Address";
import {
    thunkCreateContactAddress,
    thunkCreateContactPerson,
    thunkDeleteContactAddress,
    thunkDeleteContactPerson,
    thunkGetContact,
    thunkUpdateAndRefreshContactPerson,
    thunkUpdateContactAddress
} from "../../../store/actions/ContactActions";
import TabPanel from "../../TabPanel";
import ContactPerson, {emptyContactPerson} from "../../../store/models/ContactPerson";
import {AdmName} from "../../AdmName";
import DeleteModal from "../../DeleteModal";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import Order from "../../../store/models/Order";
import {format, isSameYear, subMonths} from "date-fns";
import IndustryCode from "../../IndustryCode";
import SendIcon from "@material-ui/icons/Send";
import ContactPersonFunction from "../../ContactPersonFunction";
import ContactPersonDepartment from "../../ContactPersonDepartment";
import AssignContactToAdm from "../../AssignContactToAdm";
import {hasAccess, UserRoles} from "../../../util/UserUtil";
import EditContactNoteModal from "../EditContactNoteModal";
import useAdmName from "../../../hooks/useAdmName";
import CustomerSurveyPanel from "../CustomerSurveyPanel";

interface ContactDetailAddressTabProps {
    contact?: Contact;
    orders: Order[],
    canDisplayAndEditFullContact: boolean;
}

const useStyles = makeStyles((theme) => ({
    salesDataCurrency: {
        fontSize: 30
    },
    salesDataYear: {
        color: green[300]
    },
    paper: {
        padding: theme.spacing(3)
    },
    paperWithHeader: {
        padding: theme.spacing(1, 3, 3, 3)
    },
    secondPaper: {},
    divider: {
        margin: theme.spacing(2, 0, 3, 0)
    },
    salesDataContainer: {
        marginTop: theme.spacing(3)
    },
    icon: {
        color: blue[300],
    },
    avatar: {
        backgroundColor: blue[300],
    },
    button: {
        margin: theme.spacing(1),
    },
}));

function ContactDetailAddressKPIs({orders}: { orders: Order[] }) {
    const {t} = useTranslation();
    const classes = useStyles();
    if (!orders.length) {
        return <Grid item xs={12}><Paper className={classes.paper}><p>{t('contactDetailAddressKPIs.noOrderData')}</p>
        </Paper></Grid>
    }
    let date = new Date(Date.now());
    const orderSumByYear = [];

    for (var i = 0; i < 4; i++) {
        const yearString = format(date, 'yyyy')
        orderSumByYear.push({
            name: yearString,
            // eslint-disable-next-line
            value: orders.filter(order => isSameYear(order.bestellDatum, date))
                .reduce((sum, current) => sum + +current.auftragsNettowert, 0)
        })
        date = subMonths(date, 12);
    }
    return <> {orderSumByYear.reverse().map(data => (
        <Grid item md={3} key={data.name}>
            <Paper className={classes.paper}>
                <Typography className={classes.salesDataCurrency}
                            variant={"h1"}>{formatToCurrency(data.value, 'ABC')}</Typography>
                <Typography className={classes.salesDataYear}>{data.name}</Typography>
            </Paper>
        </Grid>
    ))}</>

}

function getClickableLink(link: string) {
    return link.startsWith("http://") || link.startsWith("https://") ?
        link
        : `http://${link}`;
}


export default function ContactDetailAddressTab({
                                                    contact,
                                                    orders,
                                                    canDisplayAndEditFullContact
                                                }: ContactDetailAddressTabProps) {
    const classes = useStyles();

    const {t} = useTranslation();
    const [selectedAddressTab, setAddressTab] = React.useState(AddressType.BILLING);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const sendToSAP = (contactID: string) => async () => {
        setLoading(true)
        await Api.sendContactToSAP(contactID);
        dispatch(thunkGetContact(contactID));
        setLoading(false)
    }
    const lastNoteEditedAdmName = useAdmName(contact?.note?.lastEditAdmID || '');
    return <>
        <Grid className={classes.salesDataContainer} container spacing={6}>
            {contact?.sapCustomerID && canDisplayAndEditFullContact ?
                <ContactDetailAddressKPIs orders={orders}/> :
                (contact?.stContactID && canDisplayAndEditFullContact ?
                    <>
                        {loading ?
                            <div style={{paddingRight: 40}}>
                                <CircularProgress/>
                            </div> :
                            <Grid item xs={12}><Paper className={classes.paper}>
                                <Button
                                    variant="text"
                                    onClick={sendToSAP(contact?.stContactID)}
                                    className={classes.button}
                                    startIcon={<Avatar className={classes.avatar}><SendIcon/></Avatar>}
                                >
                                    {t('contactDetailAddressTab.sendToSapBtn')}
                                </Button></Paper></Grid>
                        }
                    </>
                    : null)
            }
        </Grid>
        <Grid container spacing={6}>
            <Grid item md={6}>
                <Paper className={classes.paperWithHeader}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography variant={"h6"}>
                                <IconButton href={Api.fetchGoogleMapsAddressLink(contact)} target="_blank">
                                    <PlaceIcon className={classes.icon}/>
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant={"h6"} style={{paddingTop: 13}}>
                                {t('contactDetailAddressTab.addressData')}</Typography>

                        </Grid>
                        <Grid item xs={1}>
                            {canDisplayAndEditFullContact ?
                                <ContactEditModal contact={contact!} isEdit={true}/>
                                : null}
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider}/>
                    <Typography variant={"body2"}>{contact?.sapCustomerID}</Typography>
                    <br/>
                    <Typography variant={"body1"}>{contact?.companyLine1}</Typography>
                    <Typography variant={"body1"}>{contact?.companyLine2}</Typography>
                    <Typography variant={"body1"}>{contact?.companyLine3}</Typography>
                    <Typography variant={"body1"}>{contact?.companyLine4}</Typography>
                    <Typography
                        variant={"body1"}>{contact?.address?.street} {contact?.address?.houseNumber}</Typography>
                    <Typography variant={"body1"}>{contact?.address?.zipCode} {contact?.address?.place}</Typography>
                    <Typography variant={"body1"}>{contact?.address?.country}</Typography>
                    {contact?.industryCode ? <>
                            <Divider className={classes.divider}/>
                            <IndustryCode code={contact?.industryCode}/></>
                        : null}
                    <Divider className={classes.divider}/>
                    <Typography variant={"body1"}><a
                        href={'mailto:' + contact?.contactDetails?.emailAddress}>{contact?.contactDetails?.emailAddress}</a></Typography>
                    <Typography
                        variant={"body1"}>{contact?.contactDetails?.telephoneNumber ? 'Tel. ' + contact?.contactDetails?.telephoneNumber : ''}</Typography>
                    <Typography
                        variant={"body1"}>{contact?.contactDetails?.mobileNumber ? 'Mobile ' + contact?.contactDetails?.mobileNumber : ''}</Typography>
                    <Typography
                        variant={"body1"}>{contact?.contactDetails?.faxNumber ? 'Fax ' + contact?.contactDetails?.faxNumber : ''}</Typography>

                    {contact?.contactDetails?.url ?
                        <>
                            <Divider className={classes.divider}/>
                            <Typography variant={"body1"}>URL: <a target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  href={getClickableLink(contact?.contactDetails?.url)}>{contact?.contactDetails?.url}</a></Typography>
                        </> : null}
                    <Typography
                        variant={"body1"}>{t('contactDetailAddressTab.hasNewsletter')} {t(`common.${contact?.hasNewsletter ? 'yes' : 'no'}`)}</Typography>
                    {contact?.foundingYear && contact?.foundingYear > 0 ?
                        <Typography
                            variant={"body1"}>{t('contactDetailAddressTab.foundingYear')} {contact?.foundingYear}</Typography>
                        : null}
                </Paper>
                <Paper className={classes.paperWithHeader} style={{marginTop: 20}}>
                    <Grid container>
                        <Grid item>
                            <Typography variant={"h6"}
                                        style={{paddingTop: 13}}>{t('contactDetailAddressTab.customerProtectionData')}</Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider}/>
                    {contact?.kundenschutzBis && contact?.kundenschutzBis !== '0000-00-00' &&
                        <Typography
                            variant={"body1"}>{t('contactDetailAddressTab.protectedUntil')}&nbsp;{formatDate(contact?.kundenschutzBis)}</Typography>
                    }
                    {contact?.VB &&
                        <><Typography variant={"body1"}>{t('contactDetailAddressTab.protectedFor')}&nbsp;<AdmName
                            personalnummer={contact?.VB}/></Typography>
                            <br/></>
                    }
                    {contact?.zugewiesenerVB &&
                        <Typography variant={"body1"}>{t('contactDetailAddressTab.assignedFor')}&nbsp;<AdmName
                            personalnummer={contact?.zugewiesenerVB}/></Typography>
                    }
                    {contact?.zugewiesenBis && contact?.zugewiesenBis !== '0000-00-00' &&
                        <Typography
                            variant={"body1"}>{t('contactDetailAddressTab.protectedUntil')}&nbsp;{formatDate(contact?.zugewiesenBis)}</Typography>
                    }

                </Paper>
                <Paper className={classes.paperWithHeader} style={{marginTop: 20}}>
                    <CustomerSurveyPanel stContactID={contact?.stContactID!}/>
                </Paper>
            </Grid>
            <Grid item md={6}>
                <Paper className={classes.paperWithHeader}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography variant={"h6"} style={{paddingTop: 13}}>

                                <PeopleIcon style={{marginRight: 10}}
                                            className={classes.icon}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant={"h6"}
                                        style={{paddingTop: 13}}>{t('contactDetailAddressTab.contactPersonData')}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            {canDisplayAndEditFullContact ?
                                <ContactPersonEditModal contactPerson={emptyContactPerson}
                                                        headline={t('contactDetailAddressTab.newContactPersonModalHeadline')}
                                                        action={(contactPerson) => thunkCreateContactPerson(contact?.stContactID!, contactPerson)}
                                                        isEdit={false}/>
                                : null}
                        </Grid>
                    </Grid>
                    {contact?.contactPersons?.filter((cP:ContactPerson)=>! cP.deletionFlag).map(cP => (
                        <div key={cP.stContactPersonID}>
                            <Divider className={classes.divider}/>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography variant={"body1"}>{cP.firstname} {cP.lastname}</Typography>
                                    <ContactPersonFunction functionVal={cP.function}/>
                                    <ContactPersonDepartment departmentVal={cP.department}/>
                                    <Typography
                                        variant={"body1"}>{cP.telephoneNumber ? 'Tel. ' + cP.telephoneNumber : ''}</Typography>
                                    <Typography variant={"body1"}>{cP.mobileNumber}</Typography>
                                    <Typography variant={"body1"}><a
                                        href={'mailto:' + cP.emailAddress}>{cP.emailAddress}</a></Typography>
                                    <Typography
                                        variant={"body1"}>{t('contactDetailAddressTab.hasNewsletter')} {t(`common.${cP?.hasNewsletter ? 'yes' : 'no'}`)}</Typography>

                                </Grid>
                                <Grid item xs={1}>
                                    {canDisplayAndEditFullContact ?
                                        <>
                                            <ContactPersonEditModal contactPerson={cP}
                                                                    headline={t('contactDetailAddressTab.editContactPersonModalHeadline')}
                                                                    action={(contactPerson) => thunkUpdateAndRefreshContactPerson(contact?.stContactID, contactPerson)}
                                                                    isEdit={true}/>
                                            <br/>
                                            <DeleteModal
                                                action={() => dispatch(thunkDeleteContactPerson(contact?.stContactID, cP.stContactPersonID))}/>
                                        </>
                                        : null}
                                </Grid>
                            </Grid>

                        </div>
                    ))}
                </Paper>
                <Paper className={classes.paperWithHeader} style={{marginTop: 20}}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant={"h6"}
                                        style={{paddingTop: 13}}>{t('contactDetailAddressTab.differingAddressData')}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            {canDisplayAndEditFullContact ?
                                <ContactAddressEditModal isEdit={false} address={emptyAddress}
                                                         headline={t('contactDetailAddressTab.newAddressModalHeadline')}
                                                         action={(address, addressType) => thunkCreateContactAddress(contact?.stContactID!, address, addressType)}/>
                                : null}
                        </Grid>
                    </Grid>
                    <Tabs
                        value={selectedAddressTab}
                        indicatorColor="primary"
                        onChange={(e, val) => setAddressTab(val)}
                    >
                        <Tab value={AddressType.BILLING}
                             label={t('contactDetailAddressTab.differingAddressDataTabBilling')}/>
                        <Tab value={AddressType.DELIVERY}
                             label={t('contactDetailAddressTab.differingAddressDataTabDelivery')}/>
                    </Tabs>

                    <TabPanel value={selectedAddressTab} index={AddressType.BILLING}>
                        {contact?.differingBillingAddresses?.map(bA => (
                            <div key={'dBA-' + bA.stContactAddressID}>
                                <Divider className={classes.divider}/>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Typography variant={"body1"}>{bA.companyLine1}</Typography>
                                        <Typography variant={"body1"}>{bA.companyLine2}</Typography>
                                        <Typography variant={"body1"}>{bA.companyLine3}</Typography>
                                        <Typography variant={"body1"}>{bA.companyLine4}</Typography>
                                        <Typography
                                            variant={"body1"}>{bA.street} {bA.houseNumber}</Typography>
                                        <Typography variant={"body1"}>{bA.zipCode} {bA.place}</Typography>
                                        <Typography variant={"body1"}>{bA.country}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {canDisplayAndEditFullContact ?
                                            <>
                                                <ContactAddressEditModal isEdit={true}
                                                                         forAddressType={AddressType.BILLING}
                                                                         address={bA}
                                                                         headline={t('contactDetailAddressTab.editBillingAddressModalHeadline')}
                                                                         action={(address, addressType) => thunkUpdateContactAddress(contact?.stContactID, address, addressType)}/>
                                                <br/>
                                                <DeleteModal
                                                    action={async () => await dispatch(thunkDeleteContactAddress(contact?.stContactID, bA))}/>
                                            </>
                                            : null}
                                    </Grid>
                                </Grid>

                            </div>
                        ))}
                    </TabPanel>
                    <TabPanel value={selectedAddressTab} index={AddressType.DELIVERY}>
                        {contact?.differingDeliveryAddresses?.map(bA => (
                            <div key={'dDA-' + bA.stContactAddressID}>
                                <Divider className={classes.divider}/>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Typography variant={"body1"}>{bA.companyLine1}</Typography>
                                        <Typography variant={"body1"}>{bA.companyLine2}</Typography>
                                        <Typography variant={"body1"}>{bA.companyLine3}</Typography>
                                        <Typography variant={"body1"}>{bA.companyLine4}</Typography>
                                        <Typography
                                            variant={"body1"}>{bA.street} {bA.houseNumber}</Typography>
                                        <Typography variant={"body1"}>{bA.zipCode} {bA.place}</Typography>
                                        <Typography variant={"body1"}>{bA.country}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {canDisplayAndEditFullContact ?
                                            <>
                                                <ContactAddressEditModal isEdit={true}
                                                                         forAddressType={AddressType.DELIVERY}
                                                                         address={bA}
                                                                         headline={t('contactDetailAddressTab.editDeliveryAddressModalHeadline')}
                                                                         action={async (address, addressType) => {
                                                                             thunkUpdateContactAddress(contact?.stContactID, address, addressType)
                                                                             if (contact) {
                                                                                 await thunkGetContact(contact.stContactID)
                                                                             }
                                                                         }}/>
                                                <br/>
                                                <DeleteModal
                                                    action={async () => await dispatch(thunkDeleteContactAddress(contact?.stContactID, bA))}/>
                                            </>
                                            : null}
                                    </Grid>
                                </Grid>

                            </div>
                        ))}
                    </TabPanel>
                </Paper>
            </Grid>
            {canDisplayAndEditFullContact && contact ?
                <Grid item xs={12}>
                    <Paper className={classes.paperWithHeader}>
                        <Grid container spacing={3}>
                            <Grid item xs={11}>
                                <Typography variant={"h6"}
                                            style={{paddingTop: 13}}>{t('contactDetailAddressTab.noteHeadline')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <EditContactNoteModal oldNote={contact.note ? contact.note.note : ''}
                                                      stContactID={contact.stContactID}/>
                            </Grid>
                            {contact.note ?
                                <Grid item container xs={12}>
                                    <Grid item xs={12}>
                                        <Typography style={{whiteSpace: 'pre-wrap'}}>{contact.note.note}</Typography>
                                        <Typography
                                            variant={"caption"}>{t('contactDetailAddressTab.noteCaption', {
                                            admName: lastNoteEditedAdmName,
                                            date: formatDateOnly(contact.note.lastEditDate)
                                        })}</Typography>
                                    </Grid>
                                </Grid>
                                : null}
                        </Grid>
                    </Paper>
                </Grid>
                : null}
            {(contact?.additionalCrmInfo && contact?.additionalCrmInfo.length) || (contact?.contactNotes && contact.contactNotes.length) ?
                <Grid item xs={12}>
                    <Paper className={classes.paperWithHeader}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"}
                                            style={{paddingTop: 13}}>{t('contactDetailAddressTab.additionalCrmInfoHeadline')}</Typography>
                            </Grid>
                            {contact?.contactNotes && contact.contactNotes.length ?
                                <>
                                    <Grid item container xs={12}>
                                        {contact.contactNotes.map((cN, index) =>
                                            <Grid key={'contactNote' + index} item xs={12}>
                                                {cN}
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                                : null}
                            {contact?.additionalCrmInfo && contact?.additionalCrmInfo.length ?
                                <>
                                    <Grid item container xs={12}>
                                        <Grid item xs={3}>
                                            {t('contactDetailAddressTab.crmInfoName')}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {t('contactDetailAddressTab.crmInfoBirthday')}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {t('contactDetailAddressTab.crmInfoEMail')}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {t('contactDetailAddressTab.crmInfoPhone')}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {t('contactDetailAddressTab.crmInfoMobilephone')}
                                        </Grid>
                                    </Grid>
                                    {contact?.additionalCrmInfo.map(aCI =>
                                        <Grid key={aCI.contactPersonCrmNumber} item container xs={12}>
                                            <Grid item xs={3}>
                                                {aCI.salutation} {aCI.firstname} {aCI.lastname}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {aCI.birthday ? formatDateObj(aCI.birthday) : null}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {aCI.emailAddress}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {aCI.telephoneNumber}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {aCI.mobileNumber}
                                            </Grid>
                                        </Grid>
                                    )}
                                    {contact.additionalCrmInfo?.filter(aCI => aCI.articleWishes)!.map((aCI, index) =>
                                        <Grid key={'articleWishes' + index} item container xs={12}>
                                            <Grid item xs={3}>
                                                {t('contactDetailAddressTab.articleWishes')}
                                            </Grid>
                                            <Grid>
                                                {aCI.articleWishes}
                                            </Grid>
                                        </Grid>)}
                                </>
                                : null}
                        </Grid>
                    </Paper>
                </Grid>
                : null}
            {contact && hasAccess([UserRoles.ST_ASSIGNATION, UserRoles.ST_SUPERUSER]) ?
                <AssignContactToAdm contact={contact}/>
                : null}
        </Grid>
    </>
}
