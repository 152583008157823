import Navigation from "../components/Navigation";
import {Button, Grid, Paper} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getMyADMID, getMyUserGroups} from "../util/UserUtil";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(7, 10),
        width: '100%'
    },
}));

function clearCookie() {
    // @ts-ignore
    // eslint-disable-next-line
    document.cookie.replace(/(?:(?:^|.*;\s*)backendToken\s*=\s*([^;]*).*$)|^.*$/, name => window.location.hostname.split('.').reverse()
        // @ts-ignore
        // eslint-disable-next-line
        .reduce(domain => (domain = domain.replace(/^\.?[^.]+/, ''), document.cookie = `${name}=;max-age=0;path=/;domain=${domain}`, domain), window.location.hostname));
    window.location.reload();
}

function SecretServiceView() {


    const classes = useStyles();

    const vbNumber = getMyADMID();
    const roles = getMyUserGroups();

    return (<>
        <Navigation breadcrumbName={'Einstellungen'}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Button color={"primary"} onClick={() => clearCookie()}>Refresh Login</Button>
                        <p>My Adm ID: {vbNumber}</p>
                        <p>My Groups</p>
                        {roles.map((r: string) =>
                            <p key={r}>{r}</p>
                        )}
                    </Paper>
                </Grid>

            </Grid>
        </Navigation>
    </>)
}

export default SecretServiceView;
