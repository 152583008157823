import React from 'react';
import {useTranslation} from "react-i18next";
import {ToDo} from "../../store/models/ToDo";
import {isFuture, isPast} from "date-fns";
import Label from "../Label";

export default function MeetingIndicator(props: { meeting: ToDo }) {
    const {t} = useTranslation();
    const {meeting} = props;
    if (meeting.done) {
        return <Label color="success">{t('meetingIndicator.meetingDone')}</Label>
    }
    if (meeting.dueOn && isFuture(new Date(meeting.dueOn))) {
        return <Label color="primary">{t('meetingIndicator.meetingOpen')}</Label>
    }
    if (meeting.dueOn && isPast(new Date(meeting.dueOn))) {
        return <Label color="warning">{t('meetingIndicator.openVisitingReport')}</Label>
    }
    return <Label color="success">{t('meetingIndicator.meetingDone')}</Label>
}
