import React from 'react';
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export default function ContactPersonFunction({functionVal}: {functionVal: string}) {
    const {t} = useTranslation();
    const identifier = `contactPersonFunctionOptions.${functionVal}`;
    const translation = t(identifier);
    const displayVal = translation !== identifier ? translation : functionVal;
    return <Typography variant={"body1"}>{displayVal}</Typography>
}
