import React, {useEffect, useState} from 'react';
import Navigation from "../components/Navigation";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ContactSearchPanel from "../components/contactSearch/ContactSearchPanel";
import ContactDetailSearch, {emptyContactDetailSearch} from "../store/models/ContactDetailSearch";
import {buildUserFeedbackStringFromSearchResult} from "../util/SearchUtil";
import ContactTable from "../components/ContactTable";
import {Pagination} from "@material-ui/lab";
import Api from "../store/Api";
import {useTranslation} from "react-i18next";
import Contact from "../store/models/Contact";
import {AdmName} from "../components/AdmName";
import {isBefore, isPast, startOfDay} from "date-fns";
import DisplayTerminatorFollowUpsModal from "../components/terminator/DisplayTerminatorFollowUpsModal";
import CreateTerminatorFollowUpModal from "../components/terminator/CreateTerminatorFollowUpModal";
import {Link} from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CreateTerminatorMeetingModal from "../components/terminator/CreateTerminatorMeetingModal";
import {fetchEmailsFromADMS} from "../util/ContactUtil";
import {useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {AdmFollowUps} from "../store/models/TerminatorCustomerLists";


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(7, 10),
        width: '100%'
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'

    }
}));


function TerminatorMeetingView() {
    const [searchResult, setSearchResult] = useState<Contact[]>([])
    const [page, setPage] = useState(1)
    const [totalResults, setTotalResults] = useState(0)
    const [currentDetailSearch, setCurrentDetailSearch] = useState(emptyContactDetailSearch);
    const [selectedContacts, setSelectedContacts] = useState<string[]>([])
    const [selectedAdm, setSelectedAdm] = useState("");
    const [admsToTerminate, setAdmsToTerminate] = useState<string[]>([]);


    const [followUpsByAdm, setFollowUpsByAdm] = useState<AdmFollowUps[]>([])

    const [searchPaginationPageSize, setSearchPaginationPageSize] = useState(50);

    function updateFollowUpsAndAdms(withAdms: boolean) {
        Api.getTerminatorCustomerLists().then(customerList => {
            if (withAdms) {
                setAdmsToTerminate(customerList.followUpsByAdm.map(f => f.admId));
            }
            setFollowUpsByAdm(customerList.followUpsByAdm);
        });
    }

    useEffect(() => {
        updateFollowUpsAndAdms(true);
    }, [])

    const followUpsForSelectedAdm: AdmFollowUps | undefined = followUpsByAdm.find(a => a.admId === selectedAdm);
    let now = new Date();
    const dueFollowUps = followUpsForSelectedAdm ?
        followUpsForSelectedAdm.followUps.filter(fU => !fU.done && isBefore(startOfDay(fU.dueOn), now))
        : []

    async function updateState(searchObj: ContactDetailSearch, totalResults: number, searchResult: Contact[], page: number, pageSize: number) {
        await setCurrentDetailSearch(searchObj)
        await setTotalResults(totalResults);
        await setSearchResult(searchResult);
        await setPage(page);
        await setSearchPaginationPageSize(pageSize);
    }

    const searchContacts = async (searchObj: ContactDetailSearch, page: number) => {
        const {searchResult, totalResults} = await Api.searchAsTerminator(searchObj,selectedAdm, page, 50);
        await updateState(searchObj, totalResults, searchResult, page, 50);
    }

    const displayTerminatorFollowUps = async () => {
        if (followUpsForSelectedAdm && followUpsForSelectedAdm.followUps) {

            let stContactIds = followUpsForSelectedAdm.followUps.filter(f => isPast(startOfDay(f.dueOn)) && !f.done).map(f => f.stCustomerId);
            const contacts: Contact[] = await Api.getContactsAsTerminator(stContactIds)
            await updateState(emptyContactDetailSearch, contacts.length, contacts, 1, contacts.length)
        }
    }

    const switchPage = async (pageNumber: number) => {
        setIsLoading(true);
        if (currentDetailSearch) {
            await searchContacts(currentDetailSearch, pageNumber);
        }
        setIsLoading(false);
    }
    const adms = useSelector((store: IStore) => store.adms.adms.filter(adm => adm.sapID === selectedAdm));

    const emailPickerMailsFromAdm = [...fetchEmailsFromADMS(adms)];

    const classes = useStyles();

    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    return (<>
        <Navigation breadcrumbName={''}>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Paper className={classes.smallPaper}>

                        <FormControl fullWidth>
                            <InputLabel>{t('assignationListView.admSelectLbl')}</InputLabel>
                            <Select
                                value={selectedAdm}
                                //@ts-ignore
                                onChange={(event => setSelectedAdm(event.target.value))}
                            >
                                <MenuItem value={''}>&nbsp;</MenuItem>)
                                {admsToTerminate.map(adm => <MenuItem
                                    key={'selectedAdm' + adm}
                                    value={adm}><AdmName personalnummer={adm}/></MenuItem>)}
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
                {selectedAdm ?
                    <>

                        <Grid item xs={6}>
                            <Paper className={classes.smallPaper}>
                                {followUpsForSelectedAdm ?
                                    <>
                                        <p>{followUpsForSelectedAdm.followUps.length} Wiedervorlagen,
                                            davon {dueFollowUps.length} fällig</p>
                                        <DisplayTerminatorFollowUpsModal admId={selectedAdm}
                                                                         afterFollowUpDone={()=>updateFollowUpsAndAdms(false)}
                                                                         followUps={followUpsForSelectedAdm.followUps}/>
                                        <Button variant={'text'}
                                                onClick={() => displayTerminatorFollowUps()}>fällige Wiedervorlagen in
                                            Suche
                                            öffnen</Button>
                                    </> :
                                    <p>Noch keine Wiedervorlagen für diesen VB gespeichert</p>
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <ContactSearchPanel currentDetailSearch={currentDetailSearch}
                                                searchAction={(searchValues) => searchContacts(searchValues, 1)}/>
                        </Grid>
                        <Grid item container spacing={3}>
                            <Grid item md={8}>
                                <Typography> {buildUserFeedbackStringFromSearchResult(totalResults, '', currentDetailSearch, true, t)}</Typography>
                            </Grid>
                            <div style={{flexGrow: 1, display: 'flex'}}>
                            </div>
                            <Grid item xs={12}>
                                {isLoading ? <CircularProgress/> :
                                    <ContactTable selectedContacts={selectedContacts}
                                                  setSelectedContacts={setSelectedContacts}
                                                  hideCheckboxes={true}
                                                  statusIndicatorForAdmID={selectedAdm}
                                                  detailsActions={(c) => <>

                                                      <CreateTerminatorMeetingModal admId={selectedAdm} contact={c}
                                                                                    emailPickerMailsFromAdm={emailPickerMailsFromAdm}/>
                                                      <br/>
                                                      <CreateTerminatorFollowUpModal afterSave={()=>updateFollowUpsAndAdms(false)} contact={c} admId={selectedAdm}/>
                                                      <br/>
                                                      <Button
                                                          variant="text"
                                                          component={Link}
                                                          to={`/contact/${c.stContactID}`}
                                                          target={'_blank'}
                                                          endIcon={<ArrowForwardIosIcon/>}
                                                      >
                                                          Anzeigen
                                                      </Button>
                                                  </>}
                                                  contacts={searchResult}/>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Pagination count={Math.floor(totalResults / searchPaginationPageSize) + 1} page={page}
                                            onChange={(e, page) => switchPage(page)}/>
                            </Grid>
                        </Grid>

                    </>
                    : null}
            </Grid>
        </Navigation>
    </>)
}

export default TerminatorMeetingView;