import React from "react";
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import {LIGHT_BLUE, LIGHT_GREEN, RED, YELLOW} from "../../BerendsohnMUITheme";
import formatCurrencyByADMCountry from "../Formatter";

interface FilledLineChartProps {
    data: any[];
    dataValues?: string[];
    dataValuesTranslation?: {dataKey:string, translation:string}[];
    admCountry?: string;
    yAxisDomain?: [number,number]
}

const COLORS = [LIGHT_GREEN, LIGHT_BLUE, RED, YELLOW,];

export class FilledLineChart extends React.Component<FilledLineChartProps> {
    render() {
        const {data, dataValues = ["value"],dataValuesTranslation, admCountry = '',yAxisDomain} = this.props;



        return (
            <div style={{width: "100%", height: 400}}>
                <ResponsiveContainer>
                    <AreaChart
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <defs>
                            {dataValues.map((dV, index) => (
                                <linearGradient
                                    key={"gradientDef" + dV}
                                    id={"gradientDef" + index}
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="50%"
                                        stopColor={COLORS[index % COLORS.length]}
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor={COLORS[index % COLORS.length]}
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            ))}
                        </defs>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name"/>
                        {yAxisDomain ?
                        <YAxis domain={yAxisDomain}/> :
                        <YAxis />
                        }
                        <Tooltip
                            formatter={(value : any, name:string) => [formatCurrencyByADMCountry(value,admCountry),dataValuesTranslation?.find(dVT=>dVT.dataKey === name)?.translation ]}
                        />
                        <Legend
                        formatter={value => dataValuesTranslation?.find(dVT=>dVT.dataKey === value)?.translation}
                        />
                        {dataValues.map((dV, index) => (
                            <Area
                                key={"area" + dV}
                                type="monotone"
                                dataKey={dV}
                                stroke={COLORS[index % COLORS.length]}
                                fillOpacity={1}
                                fill={"url(#gradientDef" + index + ")"}
                            />
                        ))}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
