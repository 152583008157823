import React from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {IconButton} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {ToDo} from "../store/models/ToDo";
import {thunkDeleteToDo} from "../store/actions/ToDoActions";
import {thunkGetCalendarEvents} from "../store/actions/CalendarEventActions";
import DeleteModal from "./DeleteModal";

const ITEM_HEIGHT = 48;


export default function CalendarEventMenu({stContactId, todoToDelete}: { stContactId: string, todoToDelete?: ToDo }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const dispatch = useDispatch();

    const deleteToDo = async () => {
        if (todoToDelete) {
            await dispatch(thunkDeleteToDo(todoToDelete));
            await dispatch(thunkGetCalendarEvents());
            handleClose();
        }
    }

    const {t} = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem component={Link}
                          to={`/contact/${stContactId}/`}>
                    {t("calendarEventMenu.showCustomer")}
                </MenuItem>
                {todoToDelete ?
                    <DeleteModal action={deleteToDo} deleteButton={ (handleOpen)=>
                        <MenuItem onClick={handleOpen}>
                            {t("calendarEventMenu.deleteToDo")}
                        </MenuItem>
                    }/>
                    : null}
            </Menu>
        </>
    )
}


