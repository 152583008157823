import IAction from "../actions/IAction";

import CalendarEvent from "../models/CalendarEvent";
import {GET_CALENDAREVENTS_SUCCESS} from "../actions/CalendarEventActions";

export interface ICalendarEventReducerState {
    calendarEvents: CalendarEvent[];
}

export default class CalendarEventReducer {
    public static readonly INITIAL_STATE: ICalendarEventReducerState = {
        calendarEvents: [],
    };

    public static reducer(
        state: ICalendarEventReducerState = CalendarEventReducer.INITIAL_STATE,
        action: IAction<any>
    ): ICalendarEventReducerState {
        switch (action.type) {
            case GET_CALENDAREVENTS_SUCCESS:
                return {...state, calendarEvents: action.payload};
            default:
                return state;
        }
    }
}
