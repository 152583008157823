import React, {useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Modal from "../Modal";
import DialogContent from "@material-ui/core/DialogContent";
import {Field, Formik} from "formik";
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@material-ui/core";
import useAdmName from "../../hooks/useAdmName";
import Contact from "../../store/models/Contact";
import {MeetingType} from "../../store/models/IMeeting";
import {DateTimePicker} from "formik-material-ui-pickers";
import {meetingTypeOptions} from "../contactDetail/contactTouchpoint/MeetingTab";
import EmailPicker from "../contactDetail/EmailPicker";
import {GREY} from "../../BerendsohnMUITheme";
import {CustomText, CustomTextPopover} from "../contactDetail/InsertTextPopover";
import {renderToString} from "react-dom/server";
import {formatDateOnly, formatDateTimeOnly} from "../Formatter";
import {stateFromHTML} from "draft-js-import-html";
import {ContentState, convertFromHTML, convertToRaw} from "draft-js";
import MUIRichTextEditor from "mui-rte";
import {ImportContacts} from "@material-ui/icons";
import IStore from "../../store/models/IStore";
import Api from "../../store/Api";
import {stateToHTML} from "draft-js-export-html";
import {fetchEmailsFromContact} from "../../util/ContactUtil";
import * as Yup from "yup";

interface Props {
    contact: Contact
    admId: string;
    emailPickerMailsFromAdm: string[]
}

export default function CreateTerminatorMeetingModal({contact, admId, emailPickerMailsFromAdm}: Props) {
    const {t} = useTranslation();
    const ref = useRef(null)
    const sampleMarkup = ''
    const contentHTML = convertFromHTML(sampleMarkup)
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
    const content = JSON.stringify(convertToRaw(state))
    const [editorState, setEditorState] = useState(content);
    const admData = useSelector((state: IStore) => state.adms.adms.find(adm => adm.sapID === admId))
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);


    const emailPickerMails = [...fetchEmailsFromContact(contact), ...emailPickerMailsFromAdm]
    const prefillEmails = admData?.contactDetails?.emailAddress ? [admData.contactDetails.emailAddress] : undefined;

    return (
        <Modal fullWidth={true} maxWidth={"md"}
               title={'Meeting als ' + useAdmName(admId) + ' für' + contact.companyLine1}
               contentAndButtons={(handleClose) => (
                   <>
                       <DialogContent>
                           <Formik
                               validationSchema={Yup.object().shape({
                                   startDate: Yup.string()
                                       .nullable()
                                       .required(t('validation.required')),
                                   endDate: Yup.string()
                                       .nullable()
                                       .required(t('validation.required')),
                               })}
                               onSubmit={async (values) => {
                                   if (values.bodyEditorState) {
                                       // @ts-ignore
                                       values.body = stateToHTML(values.bodyEditorState.getCurrentContent());
                                   }


                                   await Api.createTerminatorMeeting({
                                       startDate: values.startDate!,
                                       endDate: values.endDate!,
                                       forCustomer: contact.stContactID,
                                       forAdm: admId,
                                       note: values.note,
                                       sendMail: values.sendMail,
                                       attendees: values.attendees,
                                       meetingType: values.meetingType,
                                       subject: values.subject,
                                       body: values.body
                                   })
                                   handleClose();
                               }}
                               initialValues={{
                                   note: '',
                                   startDate: null,
                                   endDate: null,
                                   sendMail: false,
                                   emails: '',
                                   subject: t('contactDetailTouchpointTab.meetingSubjectDefaultValue', {customerName: contact?.companyLine1}),
                                   attendees: '',
                                   body: '',
                                   bodyEditorState: null,
                                   meetingType: MeetingType.PhoneCall,
                                   decisionMaker: false

                               }}
                           >
                               {({
                                     values,
                                     handleChange,
                                     handleSubmit,
                                     setFieldValue
                                 }) => (
                                   <form id={"createMeetingForm"} onSubmit={handleSubmit}>
                                       <Box>
                                           <Grid container direction={"column"} spacing={1}>

                                               <Grid container item md={6} spacing={3}>
                                                   <Grid item xs={12}>
                                                       <InputLabel>{t('contactDetailTouchpointTab.meetingTypeLbl')}</InputLabel>
                                                       <Select
                                                           name="meetingType"
                                                           variant={"outlined"}
                                                           value={values.meetingType}
                                                           onChange={handleChange}
                                                           fullWidth
                                                       >
                                                           {meetingTypeOptions.map(mT => <MenuItem
                                                               key={'meetingTypeOption' + mT.label}
                                                               value={mT.value}>{mT.label}</MenuItem>)}
                                                       </Select>
                                                   </Grid>
                                                   <Grid item xs={6}>
                                                       <Field
                                                           component={DateTimePicker}
                                                           inputVariant={"outlined"}
                                                           name="startDate"
                                                           value={values.startDate}
                                                           label={t('contactDetailTouchpointTab.formStartLbl')}
                                                           format={'dd.MM.yyyy HH:mm'}
                                                           ampm={false}
                                                           autoOk
                                                       />
                                                   </Grid>
                                                   <Grid item xs={6}>
                                                       <Field
                                                           component={DateTimePicker}
                                                           inputVariant={"outlined"}
                                                           name="endDate"
                                                           value={values.endDate}
                                                           label={t('contactDetailTouchpointTab.formEndLbl')}
                                                           format={'dd.MM.yyyy HH:mm'}
                                                           ampm={false}
                                                       />
                                                   </Grid>
                                               </Grid>
                                               <Grid item md={6}>
                                                   <FormControl fullWidth>
                                                       <TextField
                                                           multiline={true}
                                                           rows={3}
                                                           variant={"outlined"}
                                                           name="note"
                                                           label={t('contactDetailTouchpointTab.noteLbl')}
                                                           value={values.note}
                                                           onChange={handleChange}
                                                           required
                                                       />
                                                   </FormControl>
                                               </Grid>
                                               <Grid item md={3}>
                                                   <FormControlLabel
                                                       control={<Switch checked={values.sendMail}
                                                                        onChange={handleChange} name="sendMail"
                                                                        color={"primary"}/>}
                                                       label={t('contactDetailTouchpointTab.sendMailLbl')}
                                                   />
                                               </Grid>
                                               <Grid item md={9}>
                                                   <FormControlLabel
                                                       control={
                                                           <Checkbox
                                                               checked={values.decisionMaker}
                                                               onChange={handleChange}
                                                               name="decisionMaker"
                                                               color="primary"
                                                           />
                                                       }
                                                       label={t('contactDetailTouchpointTab.decisionMakerLbl')}
                                                   />
                                               </Grid>

                                               {values.sendMail &&
                                                   <>
                                                       <Grid item xs={12}>
                                                           <EmailPicker
                                                               updateValue={mails => setFieldValue('attendees', mails.join(','))}
                                                               emails={emailPickerMails}
                                                               prefillEmails={prefillEmails}/>
                                                       </Grid>
                                                       <Grid item md={12}>
                                                           <FormControl fullWidth>
                                                               <TextField
                                                                   name="subject"
                                                                   variant={"outlined"}
                                                                   label={t('createMeetingModal.formSubjectLbl')}
                                                                   value={values.subject}
                                                                   onChange={handleChange}
                                                               />
                                                           </FormControl>
                                                       </Grid>
                                                       <Grid item md={12}
                                                             style={{
                                                                 border: '1px solid',
                                                                 borderColor: GREY,
                                                                 margin: 10,
                                                                 borderRadius: 5
                                                             }}>
                                                           <CustomTextPopover
                                                               anchor={anchor}
                                                               onSubmit={(input, insert) => {
                                                                   if (insert) {
                                                                       const data = renderToString(<CustomText
                                                                           admData={admData}
                                                                           dateString={formatDateOnly(values.startDate!)}
                                                                           timeString={formatDateTimeOnly(values.startDate!)}
                                                                           blockProps={input}/>)
                                                                       const state = stateFromHTML(data);
                                                                       const content = JSON.stringify(convertToRaw(state))
                                                                       setEditorState(content);
                                                                   }
                                                                   setAnchor(null)
                                                               }}
                                                           />
                                                           <InputLabel>{t('createMeetingModal.formBodyLbl')}</InputLabel>
                                                           <MUIRichTextEditor
                                                               ref={ref}
                                                               defaultValue={editorState}
                                                               label={t('createMeetingModal.formBodyEditorPlaceholder')}
                                                               onChange={data => setFieldValue("bodyEditorState", data)}
                                                               controls={["title", "bold", "underline", "add-text"]}
                                                               customControls={[
                                                                   {
                                                                       name: "add-text",
                                                                       icon: <ImportContacts/>,
                                                                       type: "callback",
                                                                       onClick: (_editorState, _name, anchor) => {
                                                                           setAnchor(anchor)
                                                                       }
                                                                   }
                                                               ]}
                                                           />
                                                           <br/>
                                                           <br/>
                                                           <br/>
                                                       </Grid>
                                                   </>
                                               }
                                           </Grid>
                                       </Box>
                                   </form>
                               )}
                           </Formik>
                       </DialogContent>
                       <DialogActions>
                           <Button onClick={handleClose}>
                               {t('common.cancelBtn')}
                           </Button>
                           <Button type="submit" form="createMeetingForm" color={"primary"}>
                               {t('common.saveBtn')}
                           </Button>
                       </DialogActions>
                   </>
               )} openButton={(handleOpen) => (
            <Button variant="contained"
                    color={"primary"}
                    onClick={handleOpen}>Termin</Button>
        )}/>

    );

}