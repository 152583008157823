import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";
import {LIGHT_BLUE} from "../../BerendsohnMUITheme";

interface PercentagePieChartProps {
    value: number;
    color?: string;
}

export default class PercentagePieChart extends React.Component<PercentagePieChartProps> {
    renderActiveShape = (props: any): any => {
        const {
            cx,
            cy,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
            payload,
        } = props;


        return (
            <g>
                <text
                    x={cx}
                    y={cy}
                    dy={8}
                    textAnchor="middle"
                    style={{fontSize: 40}}
                    fill={fill}
                >
                    {Math.round(payload.value)}%
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}

                />
            </g>
        );
    };

    render() {
        const {value, color} = this.props;

        const COLORS = ["#eeeeee", color ? color : LIGHT_BLUE];

        function fetchColor(index: number) {
            return COLORS[index % COLORS.length]
        }

        const data = [
            {
                name: "",
                value: 100 - value,
            },
            {
                name: `${value} %`,
                value,
            },
        ];

        return (
            <ResponsiveContainer height={200} width={"100%"}>
                <PieChart>
                    <Pie
                        activeIndex={1}
                        activeShape={this.renderActiveShape}
                        data={data}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey={"value"}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={"pieChart" + entry}
                                fill={fetchColor(index)}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        );
    }
}
