import React from "react";
import INotification from "../../store/models/INotification";
import {Alert} from "@material-ui/lab";
import {format} from "date-fns";

interface NotificationAlertProps {
  notification: INotification;
  beforeDismiss: (err: INotification) => void;
}

export default class NotificationAlert extends React.Component<
  NotificationAlertProps
> {
  render() {
    const { beforeDismiss, notification } = this.props;
    const { type, timestamp, message } = notification;
    const formattedMessage = `${format(
        timestamp,
        'dd.MM.yyyy HH:mm'
    )}  ${message}`;

    return (
      <Alert color={type} onClose={() => beforeDismiss(notification)}>
        {formattedMessage}
      </Alert>
    );
  }
}
