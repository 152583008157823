import IAction from "../actions/IAction";
import {
    CREATE_CONTACT_ADDRESS,
    CREATE_CONTACT_PERSON,
    DETAIL_SEARCH_CONTACTS, GET_CONTACT_FAILED,
    GET_CONTACT_SUCCESS,
    GET_CONTACTS_SUCCESS,
    SEARCH_CONTACTS,
    UPDATE_CONTACT,
    UPDATE_CONTACT_ADDRESS, UPDATE_CONTACT_NOTE,
    UPDATE_CONTACT_PERSON
} from "../actions/ContactActions";
import Contact from "../models/Contact";
import ContactPerson from "../models/ContactPerson";
import Address, {AddressType} from "../models/Address";
import ContactDetailSearch from "../models/ContactDetailSearch";

export interface IContactReducerState {
    contacts: Contact[];
    searchResult: Contact[];
    lastSearchTerm: string,
    currentDetailSearch?: ContactDetailSearch;
    currentContact?: Contact;
    lastSearchWasDetail: boolean;
    totalResults: number;
    page: number;
    withDistanceKm?: boolean;

}

export default class ContactReducer {
    public static readonly INITIAL_STATE: IContactReducerState = {
        contacts: [],
        searchResult: [],
        lastSearchTerm: '',
        currentDetailSearch: undefined,
        currentContact: undefined,
        lastSearchWasDetail: false,
        totalResults: 0,
        page: 1,
        withDistanceKm:false
    };

    public static reducer(
        state: IContactReducerState = ContactReducer.INITIAL_STATE,
        action: IAction<any>
    ): IContactReducerState {
        switch (action.type) {
            case GET_CONTACTS_SUCCESS:
                return {...state, contacts: action.payload};
            case GET_CONTACT_SUCCESS:
                return {...state, currentContact: {...action.payload}}
            case GET_CONTACT_FAILED:
                return {...state, currentContact: undefined}
            case SEARCH_CONTACTS:
                return {
                    ...state,
                    searchResult: [...action.payload.searchResult],
                    lastSearchWasDetail: false,
                    page: action.payload.page,
                    lastSearchTerm: action.payload.searchTerm,
                    totalResults: action.payload.totalResults
                }
            case DETAIL_SEARCH_CONTACTS:
                return {
                    ...state,
                    searchResult: [...action.payload.searchResult],
                    lastSearchTerm: '',
                    lastSearchWasDetail: true,
                    currentDetailSearch: {...action.payload.searchObj},
                    page: action.payload.page,
                    totalResults: action.payload.totalResults,
                    withDistanceKm: action.payload.withDistanceKm
                }
            case UPDATE_CONTACT_NOTE:
                return {...state, currentContact: {...action.payload}}
            case UPDATE_CONTACT_PERSON:
                return updateContactPerson(state, action.payload)
            case CREATE_CONTACT_PERSON:
                return createContactPerson(state, action.payload)
            case UPDATE_CONTACT_ADDRESS:
                return updateContactAddress(state, action.payload)
            case UPDATE_CONTACT:
                return {...state, currentContact: {...action.payload}};
            case CREATE_CONTACT_ADDRESS:
                return createNewContactAddress(state, action.payload)
            default:
                return state;
        }
    }
}

function updateContactPerson(state: IContactReducerState, contactPerson: ContactPerson): IContactReducerState {
    const {currentContact} = state;
    if (!currentContact || !currentContact.contactPersons || !contactPerson) {
        return state;
    }
    let newContactPersonsArr: ContactPerson[] = [...currentContact.contactPersons
        .map(cp => cp.stContactPersonID !== contactPerson.stContactPersonID ? cp : contactPerson)]
    return {...state, currentContact: {...currentContact, contactPersons: newContactPersonsArr}}
}

function createContactPerson(state: IContactReducerState, contactPerson: ContactPerson): IContactReducerState {
    const {currentContact} = state;
    if (!currentContact) {
        return state;
    }
    let newContactPersonsArr: ContactPerson[] = [];
    newContactPersonsArr = [...currentContact.contactPersons, contactPerson]
    return {...state, currentContact: {...currentContact, contactPersons: newContactPersonsArr}}
}

interface ContactAddressPayload {
    address: Address;
    addressType: AddressType
}

function updateContactAddress(state: IContactReducerState, {address, addressType}: ContactAddressPayload) {
    const {currentContact} = state;
    if (!currentContact || !address) {
        return state;
    }

    const oldContactAddressArr = addressType === AddressType.DELIVERY ? currentContact.differingDeliveryAddresses : currentContact.differingBillingAddresses;
    const newContactAddressArr: Address[] = [...oldContactAddressArr
        .map(a => a.stContactAddressID !== address.stContactAddressID ? a : address)]

    return {
        ...state,
        currentContact: {
            ...currentContact,
            [addressType === AddressType.DELIVERY ? 'differingDeliveryAddresses' : 'differingBillingAddresses']: newContactAddressArr
        }
    }
}

function createNewContactAddress(state: IContactReducerState, {address, addressType}: ContactAddressPayload) {
    const {currentContact} = state;
    if (!currentContact) {
        return state;
    }
    const oldContactAddressArr = addressType === AddressType.DELIVERY ? currentContact.differingDeliveryAddresses : currentContact.differingBillingAddresses;
    let newContactAddressArr: Address[] = [...oldContactAddressArr, address];
    return {
        ...state,
        currentContact: {
            ...currentContact,
            [addressType === AddressType.DELIVERY ? 'differingDeliveryAddresses' : 'differingBillingAddresses']: newContactAddressArr
        }
    }
}
