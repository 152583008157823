import {combineReducers, Reducer, ReducersMapObject} from "redux";

import IStore from "../models/IStore";
import ContactReducer from "./ContactReducer";
import UIReducer from "./UIReducer";
import NotificationsReducer from "./NotificationsReducer";
import CalendarEventReducer from "./CalendarEventReducer";
import ContactListReducer from "./ContactListReducer";
import AdmReducer from "./AdmReducer";
import ToDoReducer from "./ToDoReducer";
import NoteReducer from "./NoteReducer";
import BusinessExpenseReportRecuder from "./BusinessExpenseReportRecuder";
import DailyAdmReportReducer from "./DailyAdmReportReducer";
import AdmNameReducer from "./AdmNameReducer";
import ProductSearchItemReducer from "./ProductSearchItemReducer";


const reducerMap: ReducersMapObject = {
    contact: ContactReducer.reducer,
    UI: UIReducer.reducer,
    notifications: NotificationsReducer.reducer,
    calendarEvent: CalendarEventReducer.reducer,
    contactLists: ContactListReducer.reducer,
    adms: AdmReducer.reducer,
    admNames: AdmNameReducer.reducer,
    todos: ToDoReducer.reducer,
    notes: NoteReducer.reducer,
    businessExpenseReports:BusinessExpenseReportRecuder.reducer,
    dailyAdmReports: DailyAdmReportReducer.reducer,
    productSearchItems: ProductSearchItemReducer.reducer
};

export default combineReducers(reducerMap) as Reducer<IStore>;
