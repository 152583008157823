import React, {useState} from 'react';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import {Rating} from "@material-ui/lab";
import Api from "../../store/Api";
import {getMyADMID} from "../../util/UserUtil";


interface Props {
    stCustomerID: string;
    updateEntries: () => void
}

export default function AddCustomerSurveyEntryModal({stCustomerID, updateEntries}: Props) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    return (
        <Modal title={t('addCustomerSurveyEntryModal.headline')} contentAndButtons={(handleClose) => (
            <>
                <DialogContent>
                    <Formik
                        initialValues={{note: '', rating: 2, sentEvaluationLink: false}}
                        onSubmit={async (values) => {
                            setLoading(true)
                            await Api.addCustomerSurveysForCustomer(stCustomerID, values.rating, values.note, getMyADMID(), values.sentEvaluationLink)
                            await updateEntries();
                            setLoading(false)
                            handleClose();
                        }}
                        validationSchema={Yup.object().shape({
                            note: Yup.string()
                                .required(t('validation.required')),
                        })}
                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit,
                              errors,
                              touched
                          }) => (
                            <form id="addCustomerSurveyForm" onSubmit={handleSubmit}>
                                <Box m={3}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>

                                            <Typography
                                                component="legend">{t('addCustomerSurveyEntryModal.ratingLabel')}</Typography>
                                            <Rating
                                                name="rating"
                                                value={values.rating}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    multiline={true}
                                                    fullWidth={true}
                                                    rows={3}
                                                    name="note"
                                                    label={t('addCustomerSurveyEntryModal.noteLabel')}
                                                    value={values.note}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.sentEvaluationLink}
                                                                   onChange={handleChange}
                                                                   color="primary"
                                                                   name={'sentEvaluationLink'}/>}
                                                label={t('addCustomerSurveyEntryModal.sentLinkLabel')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions>
                    {loading ?
                        <div style={{paddingRight: 40}}>
                            <CircularProgress/>
                        </div> :
                        <>
                            <Button onClick={handleClose}>
                                {t('common.cancelBtn')}
                            </Button>

                            <Button form="addCustomerSurveyForm" type="submit" color={"primary"}>
                                {t('common.saveBtn')}
                            </Button>
                        </>
                    }

                </DialogActions>
            </>)} openButton={(handleOpen) => (
            <IconButton component="span" onClick={handleOpen}>
                <AddCircleIcon fontSize={"large"} color={"primary"}/>
            </IconButton>
        )}
        />
    );
}
