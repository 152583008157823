import React from 'react';
import {Box, Button, DialogActions, FormControl, Grid, TextField} from "@material-ui/core";
import {Formik} from "formik";
import DialogContent from "@material-ui/core/DialogContent";
import {useDispatch} from "react-redux";
import ContactDetailSearch, {emptyContactDetailSearch} from "../../store/models/ContactDetailSearch";
import {buildSearchStringFromQuery} from "../../util/SearchUtil";
import {thunkSaveNewContactList} from "../../store/actions/ContactListActions";
import Modal from "../Modal";
import {useTranslation} from "react-i18next";

interface SaveAsContactListModalProps {
    detailSearch?: ContactDetailSearch,
}

export default function SaveAsContactListModal({detailSearch=emptyContactDetailSearch}: SaveAsContactListModalProps) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    return (
        <Modal title={t('saveAsContactListModal.saveAsContactListModalHeadline')} contentAndButtons={(handleClose) => (
            <>
                <DialogContent>
                    <Formik
                        initialValues={{name: '', query: detailSearch,selectedContactIds:[]}}
                        onSubmit={(values) => {
                            dispatch(thunkSaveNewContactList(values))
                            handleClose();
                        }}
                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit,
                          }) => (
                            <form id={"saveAsContactListForm"} onSubmit={handleSubmit}>
                                <Box>
                                    <Grid container direction={"column"} spacing={1}>
                                        <Grid item>
                                            <FormControl>
                                                <TextField
                                                    name="name"
                                                    label={t('saveAsContactListModal.listNameLbl')}
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <p>{t('saveAsContactListModal.contactSearchLbl')}&nbsp;{buildSearchStringFromQuery(detailSearch)}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t('common.cancelBtn')}
                    </Button>
                    <Button type="submit" form="saveAsContactListForm" color={"primary"}>
                        {t('common.saveBtn')}
                    </Button>
                </DialogActions>
            </>
        )} openButton={(handleOpen) => (
            <Button variant="contained"
                    onClick={handleOpen}>{t('saveAsContactListModal.openSaveAsContactListModal')}</Button>
        )}/>

    );
}
