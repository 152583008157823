import React, {useCallback, useEffect, useState} from 'react';
import {Divider, Grid, Typography} from "@material-ui/core";
import AddCustomerSurveyEntryModal from "./AddCustomerSurveyEntryModal";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {CustomerSurveyForCustomer} from "../../store/models/CustomerSurvey";
import Api from "../../store/Api";
import {formateDateWithTime} from "../Formatter";
import {Rating} from "@material-ui/lab";
import {AdmName} from "../AdmName";


const useStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(2, 0, 3, 0)
    },

}));


interface Props {
    stContactID: string
}

export default function CustomerSurveyPanel({stContactID}: Props) {

    const classes = useStyles();
    const {t} = useTranslation();

    const [surveysWithEntries, setSurveysWithEntries] = useState<CustomerSurveyForCustomer[]>([])

    const updateCustomerSurveys = useCallback(async () => {
        const surveys = await Api.getCustomerSurveysForCustomer(stContactID);
        setSurveysWithEntries(surveys);
    }, [stContactID])

    useEffect(() => {
        updateCustomerSurveys()
    }, [updateCustomerSurveys])

    return <>
        <Grid container>
            <Grid item xs={10}>
                <Typography variant={"h6"}
                            style={{paddingTop: 13}}>{t('customerSurveyPanel.headline')}</Typography>
            </Grid>
            <Grid item xs={2}>
                <AddCustomerSurveyEntryModal stCustomerID={stContactID} updateEntries={updateCustomerSurveys}/>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
            </Grid>
            {surveysWithEntries.map(survey =>
                <React.Fragment key={survey.survey.customerSurveyID}>
                    {survey.entries.map(e => <React.Fragment key={e.customerSurveyEntryNumber}>
                            <Grid item xs={4}>{survey.survey.name}</Grid>
                            <Grid item xs={3}>{formateDateWithTime(e.created)}</Grid>
                            <Grid item xs={2}><AdmName personalnummer={e.byAdm}/></Grid>
                            <Grid item
                                  xs={3}>{t('customerSurveyPanel.' + (e.sentEvaluationLink ? 'linkSent' : 'linkNotSent'))}</Grid>
                            <Grid item xs={4}><Rating value={e.result} readOnly/></Grid>
                            <Grid item xs={8}><Typography variant={"caption"}
                                                          style={{whiteSpace: 'pre-wrap'}}>{e.note}</Typography></Grid>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </Grid>

    </>
}