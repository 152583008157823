import Navigation from "../components/Navigation";
import {
    Grid,
    Card,
    CardActionArea,
    CardContent,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { format } from 'date-fns'

import { useTranslation } from "react-i18next";

import Typography from '@material-ui/core/Typography';

import { useDispatch } from "react-redux";
import Api from "../store/Api";
import { fetchLocaleFromCookie } from "../util/UserUtil";
import { Link } from "react-router-dom";
import IPost from "../store/models/IPost";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2, 2),
        width: '100%'
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'

    },
    chip: {
        margin: theme.spacing(2, 2, 2, 0)
    },
    root: {
        maxWidth: 345,
        margin: theme.spacing(2, 0)
    },
    media: {
        height: 180,
    },
    link: {
        color: 'light-blue',
        textDecoration: 'none'
    }
}));

function AcademyListView() {
    const [posts, setPosts] = useState<IPost[]>([]);
    const dispatch = useDispatch();
    useEffect(() => {
        Api.getAcademyPosts(fetchLocaleFromCookie()).then(postsFromApi => {
            setPosts(postsFromApi);
        })
    }, [dispatch])

    const classes = useStyles();
    const { t } = useTranslation();

    return (<>
        <Navigation breadcrumbName={'Einstellungen'}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={"h6"}>{t('academy.title')}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {posts.map((post, i) =>
                    <Grid item xs={3} key={i}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <Link to={`/academy/${post.id}`} className={classes.link}>
                                    <CardContent>
                                        <Typography gutterBottom variant={"h6"}>
                                            {post.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                        {format(new Date(post.published_at), 'dd.MM.yyyy')}
                                    </Typography>
                                    </CardContent>
                                </Link>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Navigation>
    </>)
}

export default AcademyListView;