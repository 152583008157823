import Navigation from "../components/Navigation";
import {Button, Divider, Grid, Paper, Typography} from "@material-ui/core";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {formatDateObj, formatDateTimeOnly} from "../components/Formatter";
import {BusinessExpenseReportStatus} from "../store/models/BusinessExpenseReport";
import {AdmName} from "../components/AdmName";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {YELLOW} from "../BerendsohnMUITheme";
import BusinessExpenseManagerFeedbackModal from "../components/businessExpense/BusinessExpenseManagerFeedbackModal";
import {useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {
    thunkGetBusinessExpenseReportsForMyADMs,
    thunkUpdateBusinessExpenseReportStatusAsManager
} from "../store/actions/BusinessExpenseReportActions";
import {isAfter, startOfDay, startOfMonth, subMonths} from "date-fns";

const useStyles = makeStyles(theme => ({
    paperSmallKpi: {
        padding: theme.spacing(3),
        width: '100%',
        height: 100
    },
    paper: {
        padding: theme.spacing(3)
    },
    kpiValue: {
        fontSize: 24
    },
    kpiLabel: {
        fontSize: 14
    },
    kmInput: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    },
    activityDivider: {
        minHeight: 300
    },
    buttons: {
        margin: 10
    }
}));

// const dummyKPIData = {
//     orders: 2,
//     meetings: 4,
//     followUps: 3,
//     revenue: 2345.78
// }


export default function BusinessExpensesManagerVBDetail() {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const history = useHistory();
    const goBack = () => {
        history.goBack();
    }

    const {berNumber} = useParams<{ berNumber: string }>();

    const changeBerStatus = (status: 'accept' | 'decline' | 'requestReview') => async (note: string) => {
        await dispatch(thunkUpdateBusinessExpenseReportStatusAsManager(status, berNumber, note))
        goBack();
    }
    const acceptReport = async () => {
        await dispatch(thunkUpdateBusinessExpenseReportStatusAsManager("accept", berNumber))
        goBack();
    }


    // const kpiData = dummyKPIData;
    const businessExpenseReport = useSelector((state: IStore) => state.businessExpenseReports.managmentReports.find(r => r.berNumber === berNumber))

    const isEditable = businessExpenseReport && isAfter(businessExpenseReport.forDate, startOfDay(startOfMonth(subMonths(new Date(), 6))))

    useEffect(() => {
        dispatch(thunkGetBusinessExpenseReportsForMyADMs())
    }, [dispatch])

    return (<>
        <Navigation backBtn={true} breadcrumbName={''}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {businessExpenseReport ?
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant={"h5"}><AdmName
                                        personalnummer={businessExpenseReport.admID}/> ({businessExpenseReport.admID})
                                        - {formatDateObj(businessExpenseReport.forDate)}</Typography>
                                </Grid>
                                <Grid item md={5}>
                                    <Typography
                                        variant={"h6"}>{t('businessExpensesManagerVBDetail.workingHoursHeadline')}</Typography>
                                    <Typography>{t('businessExpensesManagerVBDetail.workingHoursStart')} {formatDateTimeOnly(businessExpenseReport.startWorkDate)}</Typography>
                                    <Typography>{t('businessExpensesManagerVBDetail.workingHoursEnd')} {formatDateTimeOnly(businessExpenseReport.endWorkDate)}</Typography>
                                    <br/>
                                    <Typography
                                        variant={"h6"}>{t('businessExpensesManagerVBDetail.mileageHeadline')}</Typography>
                                    <Typography>{t('businessExpensesManagerVBDetail.mileageStart')} {t('businessExpensesManagerVBDetail.kmLabel', {km: businessExpenseReport.startKM})}</Typography>
                                    <Typography>{t('businessExpensesManagerVBDetail.mileageEnd')} {t('businessExpensesManagerVBDetail.kmLabel', {km: businessExpenseReport.endKM})}</Typography>
                                    <Typography>{t('businessExpensesManagerVBDetail.mileagePrivat')} {t('businessExpensesManagerVBDetail.kmLabel', {km: businessExpenseReport.privateKM})}</Typography>
                                    {businessExpenseReport.managementNote ?
                                        <>
                                            <br/>
                                            <Typography
                                                variant={"h6"}>{t('businessExpensesManagerVBDetail.noteHeadline')}</Typography>
                                            <Typography> {businessExpenseReport.managementNote}</Typography>
                                        </>
                                        : null}
                                </Grid>
                                <Grid className={classes.activityDivider} item xs={1}><Divider
                                    orientation={"vertical"}/></Grid>
                                <Grid container item md={6} direction={"row"} alignContent={"flex-start"} spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant={"h6"}>{t('businessExpensesManagerVBDetail.activityHeadline')}</Typography>
                                    </Grid>
                                    {businessExpenseReport.activities.map(act => <React.Fragment
                                        key={act.stActivitiyID}>
                                        <Grid item xs={12}><Divider/></Grid>
                                        <Grid item xs={12}>{act.text} - {act.customerInfo.customerName}</Grid>
                                        <Grid item
                                              xs={12}>{act.customerInfo.street} {act.customerInfo.houseNumber} {act.customerInfo.zipCode} {act.customerInfo.place}</Grid>
                                    </React.Fragment>)}
                                </Grid>
                                <Grid item xs={12} style={{display: 'flex'}}>
                                    <div style={{flex: 1}}></div>
                                    <Button className={classes.buttons} onClick={() => goBack()}>
                                        {t('common.cancelBtn')}
                                    </Button>
                                    {isEditable ?
                                        <>
                                            {
                                                businessExpenseReport
                                                    .managementStatus !== BusinessExpenseReportStatus.NEEDS_REVIEW ?
                                                    <BusinessExpenseManagerFeedbackModal
                                                        headline={t('businessExpensesManagerVBDetail.needsReviewReportModalHeadline')}
                                                        afterCreate={changeBerStatus("requestReview")}
                                                        openButton={(open) =>
                                                            <Button className={classes.buttons}
                                                                    onClick={open}
                                                                    variant={"contained"}
                                                                    style={{backgroundColor: YELLOW}}>
                                                                {t('businessExpensesManagerVBDetail.needsReviewBtn')}
                                                            </Button>
                                                        }/>
                                                    : null
                                            }
                                            {
                                                businessExpenseReport
                                                    .managementStatus !== BusinessExpenseReportStatus.DECLINED ?
                                                    <BusinessExpenseManagerFeedbackModal
                                                        headline={t('businessExpensesManagerVBDetail.declineReportModalHeadline')}
                                                        afterCreate={changeBerStatus("decline")}
                                                        openButton={(open) =>
                                                            <Button className={classes.buttons}
                                                                    onClick={open}
                                                                    variant={"contained"}
                                                                    color={"secondary"}>
                                                                {t('businessExpensesManagerVBDetail.declineBtn')}
                                                            </Button>
                                                        }/>
                                                    : null
                                            }
                                            {businessExpenseReport.managementStatus !== BusinessExpenseReportStatus.ACCEPTED ?
                                                <Button className={classes.buttons} variant={"contained"}
                                                        onClick={acceptReport}
                                                        color={"primary"}>
                                                    {t('businessExpensesManagerVBDetail.acceptBtn')}
                                                </Button>
                                                : null
                                            }
                                        </>
                                        : null}
                                </Grid>
                            </Grid>
                            : null}
                    </Paper>
                </Grid>
            </Grid>
        </Navigation>
    </>)
}

