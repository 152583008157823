export function debounce<F extends (...args: any[]) => any>(func: F, waitFor: number = 1000) {
    let timeout: any;

    return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => resolve(func(...args)), waitFor)
    })
};
