import ContactDetailSearch from "../store/models/ContactDetailSearch";

export function fetchValuesFromSearchObj(searchObj: ContactDetailSearch) {
    const fieldTermMapping = [
        {field: "companyLine", searchTerm: searchObj.companyLine},
        {field: "sapCustomerID", searchTerm: searchObj.sapCustomerID},
        {field: "VB", searchTerm: searchObj.VB},
        {field: "zugewiesenerVB", searchTerm: searchObj.zugewiesenerVB},
        {field: "address.street", searchTerm: searchObj.address?.street},
        {field: "address.place", searchTerm: searchObj.address?.place},
        {field: "address.zipCode", searchTerm: searchObj.address.zipCode},
        {field: "contactDetails.emailAddress", searchTerm: searchObj.contactDetails?.emailAddress}
    ];
    return fieldTermMapping;
}


export function buildSearchStringFromQuery(currentDetailSearch: ContactDetailSearch) {
    let searchedFields = '';
    const fieldsWithValue = fetchValuesFromSearchObj(currentDetailSearch)
        .filter(obj => obj.searchTerm && obj.searchTerm.trim() !== '');
    if (fieldsWithValue.length) {
        fieldsWithValue.forEach(({field, searchTerm}, index) => {
            searchedFields += `${field} = "${searchTerm}" `
            if (index < fieldsWithValue.length - 1) {
                searchedFields += 'und '
            }
        })
    } else {
        searchedFields += '"*"';
    }
    return searchedFields;
}

export function buildUserFeedbackStringFromSearchResult(searchResultLength: number, lastSearchTerm: string, currentDetailSearch: ContactDetailSearch | undefined, lastSearchWasDetail: boolean, t: (key: string, vars?: any) => string) {
    if (!lastSearchTerm && !lastSearchWasDetail) {
        return t('searchUtil.noSearchActive');
    }
    if (lastSearchTerm) {
        return t('searchUtil.simpleSearch', {lastSearchTerm, searchResultLength});
    }
    if (currentDetailSearch) {
        const searchedFields = buildSearchStringFromQuery(currentDetailSearch);
        return t('searchUtil.detailSearch', {searchResultLength, searchedFields})
    }
    return '';
}
