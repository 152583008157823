import React, {useEffect} from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import LoginView from "./views/LoginView";
import ContactView from "./views/ContactView";
import {createTheme, CssBaseline, ThemeProvider} from "@material-ui/core";
import DashboardView from "./views/DashboardView";
import ContactDetailsView from "./views/ContactDetailsView";
import ProductDetailsView from "./views/ProductDetailsView";
import CategoryView from "./views/CategoryView";
import OfferView from "./views/OfferView";
import OfferListView from "./views/OfferListView";
import WishlistView from "./views/WishlistView";
import WishlistListView from "./views/WishlistListView";
import SearchView from "./views/SearchView";
import KpiDashboardView from "./views/KpiDashboardView";
import KpiManagerDashboard from "./views/KpiManagerDashboard";
import OrderView from "./views/OrderView";
import OrderDetailsView from "./views/OrderDetailsView";
import PrivateRoute from "./components/PrivateRoute";
import {berendsohnMuiTheme} from "./BerendsohnMUITheme";
import TerminatorMeetingView from "./views/TerminatorMeetingView";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from "date-fns";
import ContactListsView from "./views/ContactListsView";
import HelpAndFeedbackView from "./views/HelpAndFeedbackView";
import {useDispatch} from "react-redux";
import {initApp} from "./store/actions/AppInitActions";
import BusinessExpensesOverview from "./views/BusinessExpensesOverview";
import BusinessExpensesVBDetailView from "./views/BusinessExpensesVBDetailView";
import BusinessExpensesManagerOverview from "./views/BusinessExpensesManagerOverview";
import BusinessExpensesManagerVBList from "./views/BusinessExpensesManagerVBList";
import BusinessExpensesManagerVBDetail from "./views/BusinessExpensesManagerVBDetail";
import BusinessExpensesAccountantOverview from "./views/BusinessExpensesAccountantOverview";
import ScrollToTop from "./util/ScrollToTop";
import SecretServiceView from "./views/SecretServiceView";
import MeetingListView from "./views/MeetingListView";
import MeetingListManagerView from "./views/MeetingListManagerView";
import DailyAdmReportOverview from "./views/DailyAdmReportOverview";
import DailyAdmReportManagerOverview from "./views/DailyAdmReportManagerOverview";
import AssignationListView from "./views/AssignationListView";
import {fetchLocaleObjForUser} from "./util/UserUtil";
import AcademyListView from './views/AcademyListView';
import AcademyDetailsView from './views/AcademyDetailsView';
import BerensohnAdminDashboardView from "./views/BerensohnAdminDashboardView";

export class LocalizedUtils extends DateFnsUtils {
    getDateTimePickerHeaderText(date: Date) {
        return format(date, "dd.MM", {locale: this.locale});
    }
}

function App() {
    const theme = createTheme(berendsohnMuiTheme);
    console.debug('sales tool frontend build', process.env.REACT_APP_BUILD_ID || 'CUSTOM')
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initApp())
    }, [dispatch]);
    // @ts-ignore
    const dateFnsLocale = fetchLocaleObjForUser();
    return (
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={dateFnsLocale}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <BrowserRouter>
                    <ScrollToTop/>
                    <PrivateRoute exact path={'/'} component={DashboardView}/>
                    <PrivateRoute path={'/dashboard'} component={DashboardView}/>
                    <PrivateRoute path={'/terminatorMeetings'} component={TerminatorMeetingView}/>
                    <Route exact path={'/login'} component={LoginView}/>
                    <PrivateRoute exact path={'/contact'} component={ContactView}/>
                    <PrivateRoute exact path={'/contactlist'} component={ContactListsView}/>
                    <PrivateRoute exact path={'/contactlist/:preselectedList'} component={ContactListsView}/>
                    <PrivateRoute path={'/contact/:stContactID'} component={ContactDetailsView}/>
                    <PrivateRoute path={'/help'} component={HelpAndFeedbackView}/>
                    <PrivateRoute exact path={'/search/product'} component={SearchView}/>
                    <PrivateRoute path={'/product/:productID'} component={ProductDetailsView}/>
                    <PrivateRoute path={'/category/:categoryID'} component={CategoryView}/>
                    <PrivateRoute path={'/offer/:offerID'} component={OfferView}/>
                    <PrivateRoute exact path={'/offerlist'} component={OfferListView}/>
                    <PrivateRoute exact path={'/wishlist'} component={WishlistView}/>
                    <PrivateRoute path={'/wishlist/:wishlistID'} component={WishlistView}/>
                    <PrivateRoute exact path={'/wishlist-list'} component={WishlistListView}/>
                    <PrivateRoute path={'/kpi-dashboard'} component={KpiDashboardView}/>
                    <PrivateRoute path={'/kpi-manager-dashboard'} component={KpiManagerDashboard}/>
                    <PrivateRoute exact path={'/order'} component={OrderView}/>
                    <PrivateRoute exact path={'/ordersFor/:admID'} component={OrderView}/>
                    <PrivateRoute path={'/order/:orderID'} component={OrderDetailsView}/>
                    <PrivateRoute exact path={'/businessExpenses'} component={BusinessExpensesOverview}/>
                    <PrivateRoute path={'/businessExpenses/:forDate/:berNumber?'}
                                  component={BusinessExpensesVBDetailView}/>
                    <PrivateRoute exact path={'/businessExpensesManager'} component={BusinessExpensesManagerOverview}/>
                    <PrivateRoute exact path={'/businessExpensesAccountant'}
                                  component={BusinessExpensesAccountantOverview}/>
                    <PrivateRoute exact path={'/businessExpensesManager/:forAdm/:forMonth/:forYear'}
                                  component={BusinessExpensesManagerVBList}/>
                    <PrivateRoute exact path={'/businessExpensesManager/detail/:berNumber'}
                                  component={BusinessExpensesManagerVBDetail}/>
                    <PrivateRoute exact path={'/secret/service'}
                                  component={SecretServiceView}/>
                    <PrivateRoute exact path={'/meetings'}
                                  component={MeetingListView}/>
                    <PrivateRoute exact path={'/vbMeetings'}
                                  component={MeetingListManagerView}/>
                    <PrivateRoute exact path={'/dailyAdmReport'}
                                  component={DailyAdmReportOverview}/>
                    <PrivateRoute exact path={'/dailyAdmReportManager'}
                                  component={DailyAdmReportManagerOverview}/>
                    <PrivateRoute exact path={'/assignationList'}
                                  component={AssignationListView}/>
                    <PrivateRoute exact path={'/academy'}
                                  component={AcademyListView}/>
                    <PrivateRoute exact path={'/academy/:postID'}
                                  component={AcademyDetailsView}/>
                    <PrivateRoute exact path={'/bad/overview'}
                                  component={BerensohnAdminDashboardView}/>
                </BrowserRouter>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}


export default App;
