import Contact, {ContactDTO} from "./Contact";

export class SearchResult {
    searchResult: Contact[];
    totalResults: number;
    withDistanceKm: boolean;


    constructor({searchResult, totalResults,withDistanceKm}: SearchResultDTO) {
        this.searchResult = searchResult.map(c => new Contact(c));
        this.totalResults = totalResults;
        this.withDistanceKm = withDistanceKm;
    }
}

export interface SearchResultDTO {
    searchResult: ContactDTO[];
    totalResults: number;
    withDistanceKm: boolean;
}
