import React, {useState} from 'react';
import {Button, ButtonGroup, Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Contact from "../../../store/models/Contact";
import {useTranslation} from "react-i18next";
import Order from "../../../store/models/Order";
import {TouchpointResultType} from "../../../store/models/TouchpointEnums";
import NotReachedTab from "../contactTouchpoint/NotReachedTab";
import FollowUpTab from "../contactTouchpoint/FollowUpTab";
import MeetingTab from "../contactTouchpoint/MeetingTab";
import VisitingReportTab from "../contactTouchpoint/VisitingReportTab";
import {fetchEmailsFromADMS, fetchEmailsFromContact} from "../../../util/ContactUtil";
import {useSelector} from "react-redux";
import IStore from "../../../store/models/IStore";
import useQuery from "../../../hooks/useQuery";

const useStyles = makeStyles((theme) => {
    return ({
        container: {
            marginTop: theme.spacing(3)
        },
        paper: {
            padding: theme.spacing(3)
        },
        divider: {
            margin: theme.spacing(2, 0, 3, 0)
        },
        innerAccordion: {
            padding: theme.spacing(3)
        },
        touchpointTypeBtn: {
            margin: theme.spacing(1)
        }
    });
});

interface ContactDetailTouchpointTabProps {
    contact?: Contact;
    orders: Order[]
}

export default function ContactDetailTouchpointTab({contact, orders}: ContactDetailTouchpointTabProps) {
    const classes = useStyles();
    const {t} = useTranslation();
    const action = useQuery().get('action');
    const [touchpointResultType, setTouchpointResultType] = useState(action === 'visitingReport' ? TouchpointResultType.Visiting_Report : TouchpointResultType.Not_Reached);
    const adms = useSelector((store: IStore) => store.adms.adms);

    const emailPickerMails = [...fetchEmailsFromContact(contact), ...fetchEmailsFromADMS(adms)];

    return <div className={classes.container}>
        <Paper className={classes.paper}>
            <Grid container spacing={6} direction={"column"}>
                <Grid item container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} style={{paddingTop: 13}}>
                            {t('contactDetailTouchpointTab.touchpointTypeHeadline')}</Typography>
                        <ButtonGroup color="primary">
                            {Object.values(TouchpointResultType).map((tRT: TouchpointResultType) =>
                                <Button key={tRT}
                                        variant={touchpointResultType === tRT ? 'contained' : 'outlined'}
                                        onClick={() => setTouchpointResultType(tRT)}
                                        color={"primary"}>{t('contactDetailTouchpointTab.touchpointResultTypeBtn.' + tRT)}</Button>
                            )}
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <NotReachedTab touchpointResultType={touchpointResultType}
                                       contact={contact}/>
                        <FollowUpTab touchpointResultType={touchpointResultType}
                                     contact={contact}
                                     emailPickerMails={emailPickerMails}/>
                        <MeetingTab touchpointResultType={touchpointResultType}
                                    contact={contact}
                                    emailPickerMails={emailPickerMails}/>
                        <VisitingReportTab touchpointResultType={touchpointResultType}
                                           contact={contact}
                                           emailPickerMails={emailPickerMails}/>
                        {/*<StatusChangeTab touchpointResultType={touchpointResultType} contact={contact}/>*/}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </div>
}
