import Activity, {ActivityDTO} from "./Activity";
import {parseISO} from "date-fns";
import {parseISODateOrUndefined} from "../../util/DateUtil";

export default class BusinessExpenseReport {
    berNumber?: string;
    admID: string;
    created?: Date;
    lastChange?: Date;
    forDate: Date;
    startWorkDate: Date;
    endWorkDate: Date;
    managementNote?: string;
    startKM: number;
    endKM: number;
    privateKM: number;
    managementStatus: BusinessExpenseReportStatus
    activities: Activity[];


    constructor({berNumber, admID, created, lastChange, forDate, startWorkDate, endWorkDate, managementNote, startKM, endKM, privateKM, managementStatus, activities = []}: BusinessExpenseReportDTO) {
        this.berNumber = berNumber;
        this.admID = admID;
        this.created = parseISODateOrUndefined(created);
        this.lastChange = parseISODateOrUndefined(lastChange);
        this.forDate = parseISO(forDate);
        this.startWorkDate = parseISO(startWorkDate);
        this.endWorkDate = parseISO(endWorkDate);
        this.managementNote = managementNote;
        this.startKM = startKM;
        this.endKM = endKM;
        this.privateKM = privateKM;
        this.managementStatus = managementStatus;
        this.activities = activities.map(aDTO => new Activity(aDTO));
    }
}

export interface BusinessExpenseReportDTO {
    berNumber?: string;
    admID: string;
    created?: string;
    lastChange?: string;
    forDate: string;
    startWorkDate: string;
    endWorkDate: string;
    managementNote?: string;
    startKM: number;
    endKM: number;
    privateKM: number;
    managementStatus: BusinessExpenseReportStatus
    activities: ActivityDTO[];
}

export enum BusinessExpenseReportStatus {
    REQUESTED = 'REQUESTED',
    NEEDS_REVIEW = 'NEEDS_REVIEW',
    DECLINED = 'DECLINED',
    ACCEPTED = 'ACCEPTED'

}
