import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import {Action} from "redux";
import {hasAccess} from "../../util/UserUtil";
import {thunkGetAdms} from "./AdmActions";
import {thunkGetToDos} from "./ToDoActions";
import {thunkGetAdmNames} from "./AdmNameActions";
import {thunkGetProductSearchItems} from "./ProductSearchItemActions";


interface InitAppOptions {
    clientWidth: "xl" | "md" | "sm" | "xs" | "lg"
}


export const initApp = (options?: InitAppOptions): ThunkAction<void, IStore, null, Action<string>> => async dispatch => {
    try {
        if (hasAccess()) {
            await dispatch(thunkGetAdms());
            await dispatch(thunkGetAdmNames());
            await dispatch(thunkGetToDos());
            await dispatch(thunkGetProductSearchItems());
        }
    } catch (e) {
        console.log(e)
    }
};
