import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import {Action} from "redux";
import {Note} from "../models/Note";
import {thunkCreateSuccessNotification} from "./NotificationActions";
import Api from "../Api";
import i18next from "i18next";
import {thunkGetToDos} from "./ToDoActions";


export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_ERROR = 'GET_NOTES_ERROR';

export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
export const ADD_NOTE_ERROR = 'ADD_NOTE_ERROR';

export const EDIT_NOTE_SUCCESS = 'EDIT_NOTE_SUCCESS';
export const EDIT_NOTE_ERROR = 'EDIT_NOTE_ERROR';

export const thunkGetNotes = (): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const notes = await Api.getNotes();
        dispatch({
            type: GET_NOTES_SUCCESS,
            payload: notes
        });
    } catch (e) {
        dispatch({
            type: GET_NOTES_ERROR,
            payload: e
        });
    }
};


export const thunkAddNote = (noteToAdd: Note): ThunkAction<void,
    IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const note : Note = await Api.addNote(noteToAdd);
        if(note.completesTodo){
           await dispatch(thunkGetToDos());
        }
        await dispatch(thunkCreateSuccessNotification(i18next.t('noteActions.createNoteSuccess')));
        dispatch({
            type: ADD_NOTE_SUCCESS,
            payload: note
        });
    } catch (e) {
        dispatch({
            type: ADD_NOTE_ERROR,
            payload: e
        });
    }
};
