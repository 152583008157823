import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

interface ModalProps {
    title: string;
    contentAndButtons: (handleClose: () => void) => React.ReactNode,
    openButton: (handleOpen: () => void) => React.ReactNode,
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    beforeClose?: () => void,
}

export default function Modal(props: ModalProps) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        props.beforeClose && props.beforeClose();
        setOpen(false);
    };
    const {fullWidth = false, maxWidth = 'sm'} = props;
    return (
        <>
            {props.openButton(handleOpen)}
            <Dialog open={open} scroll={'paper'} onClose={handleClose} aria-labelledby="form-dialog-title"
                    fullWidth={fullWidth} maxWidth={maxWidth}>
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                {props.contentAndButtons(handleClose)}
            </Dialog>
        </>
    );
}
