import React from 'react';
import {Grid, IconButton, Paper} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {formatDateObj, formatToCurrency} from "./Formatter";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import {CommissonValue, OrderStatusIndicator} from "../views/OrderView";
import {useTranslation} from "react-i18next";
import {AdmName} from "./AdmName";
import {Link} from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import OrderOverview from '../store/models/OrderOverview';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345
    },
    root1: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        marginBottom: 20
    },
    smallPaper: {
        padding: theme.spacing(2),
        width: '100%'
    },
    label: {
        marginLeft: theme.spacing(1)
    },
    container: {
        marginTop: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(1, 3),
        margin: theme.spacing(3, 0),
        minHeight: '50%'
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        fontWeight: "bold",
    },
    inputContainer: {
        flexGrow: 1,
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5)
    },
    divider: {
        height: 24,
        width: 1
    },
    imageIcon: {
        alignItems: "center",
        justify: "center",
        height: 200
    }

}));

interface OrderTableProps {
    orderOverviews: OrderOverview[]
}

export default function OrderTable(props: OrderTableProps) {
    const {orderOverviews} = props;
    const classes = useStyles();
    const {t} = useTranslation();

    return <TableContainer component={Paper}>
        <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell><b>{t('orderTable.admName')}</b></TableCell>
                    <TableCell><b>{t('orderTable.company')}</b><br/>{t('orderTable.orderDate')}</TableCell>
                    <TableCell><b>{t('orderTable.orderNumber')}</b><br/>{t('orderTable.orderType')}</TableCell>
                    <TableCell><b>{t('orderTable.orderValue')}</b><br/>{t('orderTable.commission')}</TableCell>
                    <TableCell><b>{t('orderTable.orderStatus')}</b></TableCell>
                    <TableCell><b>{t('orderTable.details')}</b></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {orderOverviews.map((order) =>
                    <TableRow key={order.auftragsNummer}>
                        <TableCell component="th" scope="row">
                            <Grid container>
                                <Grid item xs={11}>
                                    <AdmName personalnummer={order.VB1 ? order.VB1 : order.VB2}/>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Grid container>
                                <Grid item xs={11}>
                                    {order.contactName}<br/>
                                    {formatDateObj(order.bestellDatum)}<br/>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Grid container>
                                <Grid item xs={11}>
                                    {order.auftragsNummer}<br/>
                                    {order.auftragsGrund}<br/>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Grid container>
                                <Grid item xs={11}>
                                    {formatToCurrency(order.auftragsNettowert, order.waehrung)}<br/>
                                    <CommissonValue order={order} classes={classes}/>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Grid container>
                                <Grid item xs={11}>
                                    <OrderStatusIndicator order={order} classes={classes}/>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton aria-label="details" component={Link}
                                        to={`/order/${order.auftragsNummer}`}>
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer>
}
