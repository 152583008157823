import IAction from "../actions/IAction";
import {AdmName} from "../models/AdmName";
import {GET_ADM_NAMES_SUCCESS} from "../actions/AdmNameActions";


export interface IAdmNameReducerState {
    admNames: AdmName[];
}

export default class AdmNameReducer {
    public static readonly INITIAL_STATE: IAdmNameReducerState = {
        admNames: [],
    };

    public static reducer(
        state: IAdmNameReducerState = AdmNameReducer.INITIAL_STATE,
        action: IAction<any>
    ): IAdmNameReducerState {
        switch (action.type) {
            case GET_ADM_NAMES_SUCCESS:
                return {...state, admNames: action.payload};
            default:
                return state;
        }
    }
}

