import React from 'react';
import Modal from "../Modal";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, DialogActions, Divider, Grid} from "@material-ui/core";
import {TerminatorFollowUp} from "../../store/models/TerminatorCustomerLists";
import {formatDateOnly} from "../Formatter";
import {compareAsc} from "date-fns";
import Api from "../../store/Api";
import {Link} from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

interface Props {
    admId: string;
    followUps: TerminatorFollowUp[]
    afterFollowUpDone: () => void
}

export default function DisplayTerminatorFollowUpsModal({admId, followUps, afterFollowUpDone}: Props) {
    const sortedFollowUps = followUps.sort((a, b) => compareAsc(a.dueOn!, b.dueOn!))

    async function completeFollowUp(followUpNumber?: string) {
        if (followUpNumber) {
            await Api.completeTerminatorFollowUp(followUpNumber);
            afterFollowUpDone()
        }
    }

    return (
        <Modal title={'Wiedervorlagen für ' + admId} contentAndButtons={(handleClose) => (
            <>
                <DialogContent>
                    <Grid container>
                        {sortedFollowUps.map(a =>
                            <React.Fragment key={a.terminatorFollowUpNumber}>
                                <Grid item xs={2}>
                                    {formatDateOnly(a.dueOn)}
                                </Grid>
                                <Grid item xs={3}>
                                    {a.done ? <s>{a.customerDisplayName}</s> : a.customerDisplayName}
                                </Grid>
                                <Grid item xs={4}>
                                    {a.note}
                                </Grid>
                                <Grid item xs={3}>
                                    {a.done ? <p>Abgeschlossen</p> :
                                        <>
                                            <Button color={"secondary"}
                                                    onClick={() => completeFollowUp(a.terminatorFollowUpNumber)}>Abschließen</Button>
                                            <br/>
                                            <Button
                                                variant="text"
                                                component={Link}
                                                to={`/contact/${a.stCustomerId}`}
                                                target={'_blank'}
                                                endIcon={<ArrowForwardIosIcon/>}
                                            >
                                                Anzeigen
                                            </Button>
                                        </>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider style={{margin: "10px 0"}}/>
                                </Grid>

                            </React.Fragment>)}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Schließen
                    </Button>
                </DialogActions>
            </>
        )} openButton={(handleOpen) => (
            <Button variant="contained" color={"primary"}
                    onClick={handleOpen}>Anzeigen</Button>
        )}/>

    );

}