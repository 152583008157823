import React from 'react';
import {MeetingDetails} from "../../store/models/Note";
import {ToDoType} from "../../store/models/ToDo";
import {Grid, Typography} from "@material-ui/core";
import {formatDateObj, formateDateWithTime} from "../Formatter";
import {useTranslation} from "react-i18next";

interface Props {
    meetingDetails: MeetingDetails
}

export default function MeetingDetailsDisplay({meetingDetails}: Props) {

    const {t} = useTranslation();

    return <Grid item xs={12} container>
        {
            meetingDetails.todoType === ToDoType.MSOfficeAppointment ?
                <Grid item xs={6}>
                    <Typography style={{whiteSpace: "pre-wrap"}}>
                        {t('contactDetailCommunicationTab.meetingPlaned', {
                            start: formateDateWithTime(meetingDetails.meetingStartDate),
                            end: meetingDetails.meetingEndDate ? formateDateWithTime(meetingDetails.meetingEndDate) : ""
                        })}
                    </Typography>
                    <Typography style={{whiteSpace: "pre-wrap"}}>
                        {t('contactDetailCommunicationTab.meetingTypeLbl')}
                        {t('contactDetailCommunicationTab.meetingTypes.' + meetingDetails.meetingType)}
                    </Typography>
                </Grid>
                : null
        }
        {
            meetingDetails.todoType === ToDoType.FollowUp ?
                <Grid item xs={6}>
                    <Typography style={{whiteSpace: "pre-wrap"}}>
                        {t('contactDetailCommunicationTab.followUpPlaned', {
                            start: formatDateObj(meetingDetails.meetingStartDate),
                        })}
                    </Typography>
                </Grid>
                : null
        }
    </Grid>

}