import {parseISO} from "date-fns";

export default class Activity {
    stActivitiyID: string;
    text: string;
    date: Date;
    customerInfo: CustomerInfo;
    isCustomActivity: boolean;


    constructor({stActivitiyID, text, date, customerInfo, isCustomActivity}: ActivityDTO) {
        this.stActivitiyID = stActivitiyID;
        this.text = text;
        this.date = parseISO(date);
        this.customerInfo = new CustomerInfo(customerInfo);
        this.isCustomActivity = isCustomActivity
    }
}

export interface ActivityDTO {
    stActivitiyID: string;
    text: string;
    date: string;
    customerInfo: CustomerInfoDTO;
    isCustomActivity: boolean;
}

class CustomerInfo {
    customerName: string;
    street: string;
    houseNumber: string;
    place: string;
    zipCode: string;
    country: string;


    constructor({customerName, street, houseNumber, place, zipCode, country}: CustomerInfoDTO) {
        this.customerName = customerName;
        this.street = street;
        this.houseNumber = houseNumber;
        this.place = place;
        this.zipCode = zipCode;
        this.country = country;
    }
}

interface CustomerInfoDTO {
    customerName: string;
    street: string;
    houseNumber: string;
    place: string;
    zipCode: string;
    country: string;
}
