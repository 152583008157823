import Contact from "../store/models/Contact";
import {DARK_BLUE, DARK_GREEN, GREY, LIGHT_BLUE, LIGHT_GREEN, RED, YELLOW} from "../BerendsohnMUITheme";
import {getMyADMID, hasAccess, UserRoles} from "./UserUtil";
import {Adm} from "../store/models/Adm";
import {isInFuture} from "./DateUtil";

export function fetchStatusIndicatorColor(contact: Contact, statusIndicatorForAdmID?: string) {
    const isLocked = contact.customerLock && contact.customerLock !== "" && contact.customerLock !== "00";
    if (isLocked) {
        return RED;
    }
    const isPotentialCustomer = !contact.sapCustomerID;
    if (isPotentialCustomer) {
        return YELLOW;
    }

    const customerProtectionDateInFuture = contact.kundenschutzBis && isInFuture(contact.kundenschutzBis);
    const hasValidCustomerProtection = contact.VB && contact.VB !== "" && customerProtectionDateInFuture;
    const forAdm = statusIndicatorForAdmID || getMyADMID();
    if (hasValidCustomerProtection) {
        const isMyCustomer = contact.VB === forAdm;
        if (isMyCustomer) {
            return DARK_GREEN;
        } else {
            return DARK_BLUE;
        }
    }

    const assignedToDateInFutur = contact.zugewiesenBis && isInFuture(contact.zugewiesenBis);
    const notAssignedToDeletedADM = contact.zugewiesenerVB !== 'A9999';
    const hasValidAssignment = contact.zugewiesenerVB && contact.zugewiesenerVB !== "" && notAssignedToDeletedADM && assignedToDateInFutur;
    if (hasValidAssignment) {
        const isAssignedToMe = contact.zugewiesenerVB === forAdm;
        if (isAssignedToMe) {
            return LIGHT_GREEN;
        } else {
            return LIGHT_BLUE;
        }
    }
    return GREY;
}


export function fetchEmailsFromContact(contact?: Contact): string[] {
    const result: string[] = [];
    if (contact) {
        if (contact.contactDetails?.emailAddress) {
            result.push(contact.contactDetails.emailAddress);
        }
        if (contact.contactPersons) {
            contact.contactPersons.forEach(cP => {
                if (cP.emailAddress && !result.includes(cP.emailAddress)) {
                    result.push(cP.emailAddress);
                }
            })
        }
    }
    return result;
}

export function fetchEmailsFromADMS(adms: Adm[]): string[] {
    const result: string[] = [];
    adms.forEach(adm => {
        if (adm.contactDetails.emailAddress && !adm.inactive) {
            result.push(adm.contactDetails.emailAddress)
        }
    });
    return result;
}

export function canVBDisplayAndEditContact(contact?: Contact): boolean {
    let myADMID = getMyADMID();

    if (contact) {
        const isProtected = (!!contact.VB && isInFuture(contact.kundenschutzBis))
        const isAssigned = (!!contact.zugewiesenerVB && contact.zugewiesenerVB !== "A9999" && !!contact.zugewiesenBis && isInFuture(contact.zugewiesenBis))
        const isAssignedToMe = isAssigned && contact.zugewiesenerVB === myADMID;
        const isProtectedForMe = isProtected && contact.VB === myADMID;
        return (!isProtected && !isAssigned) || isProtectedForMe || isAssignedToMe || hasAccess([UserRoles.ST_SUPERUSER, UserRoles.ST_AREA_MANAGER, UserRoles.ST_CRM, UserRoles.ST_TERMINATOR,UserRoles.ST_SALESSUPPORT,UserRoles.ST_MANAGER])
    }
    return false;
}
