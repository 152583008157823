import React from 'react';
import {Redirect} from "react-router";

function LoginView(props: any) {
    const BACKEND_URI = process.env.REACT_APP_ST_BACKEND_URL || "http://localhost:4000";
    const jwt = document.cookie.replace(/(?:(?:^|.*;\s*)backendToken\s*=\s*([^;]*).*$)|^.*$/, "$1");
    if (jwt) {
        return <Redirect
            to={{pathname: "/dashboard", state: {referrer: props.location}}}
        />
    } else {
            window.location.href= `${BACKEND_URI}/aadLogin`;
            return <></>;
    }

}

export default LoginView;