import React from "react";
import {Redirect, RouteProps} from "react-router";
import {hasAccess, UserRoles} from "../util/UserUtil";
// @ts-ignore  there are no types for this lib
import {ApmRoute} from '@elastic/apm-rum-react'

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<any>;
    forRole?: UserRoles[];
}

export default function PrivateRoute(props: PrivateRouteProps) {
    const {component: Component, forRole, ...rest}: PrivateRouteProps = props;
    // const user = useUser();
    return (
        <ApmRoute
            {...rest}
            render={(props: any) =>
                hasAccess(forRole) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{pathname: "/login", state: {referrer: props.location}}}
                    />
                )
            }
        />
    );
}
