import React from 'react';
import {Formik, FormikValues} from "formik";
import TabPanel from "../../TabPanel";
import {FormikErrors} from "formik/dist/types";
import {TouchpointResultType} from "../../../store/models/TouchpointEnums";

interface TouchpointTabProps {
    validate?: (values: FormikValues) => void | object | Promise<FormikErrors<FormikValues>>;
    handleSubmit: (values: FormikValues) => void;
    initialValues: FormikValues;
    activeTab: TouchpointResultType;
    tabIndex: string;
    validationSchema?: any;
    children: (values: FormikValues, handleChange: (e: React.ChangeEvent<any>) => void, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, isSubmitting: boolean, errors?: any, touched?: any) => JSX.Element
}

export default function TouchpointTab(props: TouchpointTabProps) {
    return (
        <TabPanel value={props.activeTab} index={props.tabIndex}>
            <Formik
                initialValues={props.initialValues}
                enableReinitialize={true}
                onSubmit={props.handleSubmit}
                validate={props.validate}
                validationSchema={props.validationSchema ? props.validationSchema : {}}
            >
                {({
                      values,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                      errors,
                      touched
                  }) => (
                    <form onSubmit={handleSubmit}>
                        {props.children(values, handleChange, setFieldValue, isSubmitting, errors,touched)}
                    </form>
                )}
            </Formik>
        </TabPanel>
    )
}
