import React, {FunctionComponent, useEffect, useState} from 'react'
import {Grid, InputLabel, MenuItem, Select} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import {fetchUserDisplayname, getMyADMID} from "../../util/UserUtil";

type TCustomTextData = {
    name?: string
    template?: string
}

type TAnchor = HTMLElement | null

interface ICustomTextPopoverProps {
    anchor: TAnchor;
    onSubmit: (data: TCustomTextData, insert: boolean) => void
}

type TCustomTextPopoverState = {
    anchor: TAnchor
    isCancelled: boolean
}

const cardPopverStyles = makeStyles({
    root: {
        padding: 10,
        maxWidth: 350
    },
    textField: {
        width: "100%"
    }
})


const templateOptions = [
    {
        label: 'Termin',
        value: 'coldCall'
    }
]


function fetchTemplateByTemplateKey(templateKey: string, dateString: string, timeString: string, phoneNumber: string, emailAddress: string) {
    switch (templateKey) {
        case 'coldCall':
            return <>
                <p>vielen Dank für Ihr Interesse an unseren Produkten. In unserem persönlichen Gespräch präsentiere ich
                    Ihnen gerne eine Auswahl individuell auf Sie zugeschnittener Werbelösungen. Unser Angebot umfasst:
                    Werbeartikel, Geschenke, Unternehmenskleidung, Geschäftsausstattung (Visitenkarten, Briefpapier,
                    etc.) und digitale Produkte (Webseiten). Alles aus einer Hand - alles für Ihren professionellen
                    Auftritt. Ich freue mich auf unser Gespräch.<br/>
                    <br/>
                    Unser Termin findet am {dateString} um {timeString} Uhr statt.<br/>
                    Sollten Sie am vereinbarten Termin verhindert sein oder noch Fragen, Wünsche oder Mitteilungen
                    haben,
                    informieren Sie mich bitte unter folgender Telefonnummer:{phoneNumber}<br/>
                    <br/>
                    Ich wünsche Ihnen noch einen erfolgreichen Tag.<br/>
                    <br/>
                    Mit freundlichen Grüßen<br/>
                    {fetchUserDisplayname()}<br/>
                    <br/>
                    Berendsohn AG<br/>
                    Friesenweg 22<br/>
                    22763 Hamburg<br/>
                    T {phoneNumber}<br/>
                    <a href={`mailto:${emailAddress}`}>{emailAddress}</a><br/>
                    <a href={'https://www.berendsohn.com'}>www.berendsohn.com</a>
                </p>
            </>
        case 'martinaSundermannText':
            return <>
                <p>
                    vielen Dank für das angenehme und aufschlussreiche Gespräch.
                    <br/>
                    <br/>
                    In unserem persönlichen Treffen werde ich Ihnen gerne eine Auswahl an maßgeschneiderten
                    Werbelösungen vorstellen, die perfekt auf Ihre Bedürfnisse abgestimmt sind.
                    <br/>
                    <br/>
                    Unser Portfolio umfasst Werbeartikel, Geschenkideen, Firmenbekleidung, Geschäftsausstattung (wie
                    Visitenkarten, Briefpapier usw.) sowie digitale Lösungen (Webseiten, Local SEO,
                    KI-Chatbots, Online-Shops und vieles mehr). Alles aus einer Hand – für einen professionellen
                    Auftritt.
                    <br/>
                    <br/>
                    Unser Termin ist für den {dateString} um {timeString} Uhr angesetzt.
                    <br/>
                    <br/>
                    Sollten Sie den Termin nicht wahrnehmen können oder noch Fragen, Wünsche oder Anmerkungen haben,
                    kontaktieren Sie mich bitte unter der Telefonnummer:
                    <br/>
                    <br/>
                    <b>0151 / 19 13 59 52.</b>
                    <br/>
                    <br/>
                    Um den Termin optimal vorzubereiten, wäre es hilfreich, wenn Sie kurz dieses Formular ausfüllen
                    könnten:
                    <br/>
                    <br/>
                    https://forms.office.com/e/25wN6H3g9G.
                    <br/>
                    <br/>
                    Die Angaben sind selbstverständlich freiwillig.
                    <br/>
                    <br/>
                    <br/>
                    Ich freue mich auf unser Treffen! 🙂
                    <br/>
                    <br/>
                    <br/>
                    Mit freundlichen Grüßen I Best regards
                    <br/>
                    <br/>
                    <b>Martina Sundermann</b>
                    <br/>
                    <br/>
                    Teammanagerin Sales Agents & Trainerin
                    <br/>
                    <br/>
                    Berendsohn AG<br/>
                    Friesenweg 22<br/>
                    22763 Hamburg<br/>
                    T +49 15119135952<br/>
                    martina.sundermann@berendsohn.com<br/>
                    www.berendsohn.com
                </p>
            </>
        default:
            return ''
    }
}

// special case for Martina Sundermann to use her custom meeting text (TODO refactor to feature or change editor so we can paste content from MS Word)
if(getMyADMID() === "AA828"){
    templateOptions.push({
        label: 'Martina Meeting',
        value: 'martinaSundermannText'
    })
}

export const CustomText: FunctionComponent<any> = (props) => {
    const {blockProps, admData, timeString, dateString} = props

    const phoneNumber = admData?.contactDetails.mobileNumber || admData?.contactDetails.telephoneNumber
    const emailAddress = admData?.contactDetails.emailAddress;


    return (<>
            <p>{blockProps.name}</p>
            {fetchTemplateByTemplateKey(blockProps.template, dateString, timeString, phoneNumber, emailAddress)}
        </>
    )
}

export const CustomTextPopover: FunctionComponent<ICustomTextPopoverProps> = (props) => {
    const classes = cardPopverStyles(props)
    const [state, setState] = useState<TCustomTextPopoverState>({
        anchor: null,
        isCancelled: false
    })
    const [data, setData] = useState<TCustomTextData>({template: 'coldCall', name: 'Sehr geehrte Damen und Herren,'})

    useEffect(() => {
        setState({
            anchor: props.anchor,
            isCancelled: false
        })
    }, [props.anchor])

    const handleChange = (event: React.ChangeEvent<any>) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }
    const textFieldProps = {
        onChange: handleChange,
    }

    return (
        <Popover
            anchorEl={state.anchor}
            open={state.anchor !== null}
            onExited={() => {
                props.onSubmit(data, !state.isCancelled)
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={12}>
                    <TextField
                        {...textFieldProps}
                        label="Anrede und Name"
                        name="name"
                        fullWidth
                        value={data.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Vorlage</InputLabel>
                    <Select
                        {...textFieldProps}
                        name="template"
                        value={data.template}
                        fullWidth
                        label={'Template'}
                    >
                        {templateOptions.map(tO => <MenuItem
                            key={'template' + tO.label}
                            value={tO.value}>{tO.label}</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end">
                    <Button onClick={() => {
                        setState({
                            anchor: null,
                            isCancelled: true
                        })
                    }}
                    >
                        <CloseIcon/>
                    </Button>
                    <Button onClick={() => {
                        setState({
                            anchor: null,
                            isCancelled: false
                        })
                    }}
                    >
                        <DoneIcon/>
                    </Button>
                </Grid>
            </Grid>
        </Popover>
    )
}
