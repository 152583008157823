
export default interface INotification {
  type: NotificationType;
  timestamp: Date;
  message: string;
  id?: string;
}

export enum NotificationType {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success"
}

export const emptyNotification :INotification = {
  type: NotificationType.INFO,
  timestamp: new Date(),
  message: '',
  id: '',
}
