import React, {useState} from 'react';
import {Button, CircularProgress, IconButton} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Modal from "./Modal";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTranslation} from "react-i18next";

interface DeleteModalProps {
    action: () => void;
    deleteButton?: (handleOpen: () => void) => React.ReactNode;
}

export default function DeleteModal(props: DeleteModalProps) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const submit = (handleClose: () => void) => async () => {
        setLoading(true)
        await props.action();
        setLoading(false)
        handleClose();
    }
    return (
        <>
            <Modal
                contentAndButtons={(handleClose) => (
                    <DialogActions>
                        {loading ?
                            <div style={{paddingRight: 40}}>
                                <CircularProgress/>
                            </div> :
                            <>
                                <Button onClick={handleClose}>
                                    {t('common.cancelBtn')}
                                </Button>
                                <Button onClick={submit(handleClose)} color={"secondary"}>
                                    {t('common.deleteBtn')}
                                </Button>
                            </>}
                    </DialogActions>
                )}
                openButton={
                    props.deleteButton ? props.deleteButton :
                        (handleOpen) => (

                            <IconButton component="span" onClick={handleOpen}>
                                {<DeleteIcon color={"secondary"}/>}
                            </IconButton>

                        )}
                title={t('deleteModal.title')}
            />
        </>
    );
}
