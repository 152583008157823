import React, {CSSProperties, useState} from 'react';

import {useCSVReader} from 'react-papaparse';
import {Button, Typography} from "@material-ui/core";
import Api from "../../store/Api";

const styles = {
    csvReader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    } as CSSProperties,
    browseFile: {
        width: '20%',
    } as CSSProperties,
};

interface ProductListItem {
    consolidationNumber: string
    matchcode: string;
    articleNumber: string;
    name: string;
}

function parseProductListItemsFromData(data: []): ProductListItem[] {
    return data.map((val) => {
        const consoldiationNumberWithLeadingZeros: string = val[0];
        return ({
            consolidationNumber: consoldiationNumberWithLeadingZeros.replace(/^0+/, ''),
            matchcode: val[1],
            articleNumber: val[2],
            name: val[3]
        });
    })
}

export default function UploadProductListCSV() {
    const {CSVReader} = useCSVReader();

    const [productListItems, setProductListItems] = useState<ProductListItem[]>([]);

    function uploadData() {
        Api.replaceProductItemListFromBAD(productListItems);
        setProductListItems([]);
    }

    return (
        <CSVReader
            onUploadAccepted={(results: any) => {
                setProductListItems(parseProductListItemsFromData(results.data.slice(1)))
            }}
        >
            {({
                  getRootProps,
              }: any) => (
                <>
                    <div style={styles.csvReader}>
                        <Button color={'primary'} variant={'contained'} {...getRootProps()} style={styles.browseFile}>
                            Datei einlesen
                        </Button>
                    </div>
                    {productListItems.length ?
                        <>
                            <Typography>Gefunden: {productListItems.length} Items</Typography>
                            <Typography>Beispiel: </Typography>
                            <Typography>
                                <b>VERDICHTUNG:</b> {productListItems[0].consolidationNumber}, <b>MATCHCODE:</b>{productListItems[0].matchcode},
                                <b>ARTIKELNUMMER:</b> {productListItems[0].articleNumber}, <b>BEZEICHNUNG:</b> {productListItems[0].name}
                            </Typography>
                            <Button color={"secondary"} variant={'contained'} onClick={uploadData}>
                                Daten in der Datenbank updaten
                            </Button>
                        </>
                        : null}
                </>
            )}
        </CSVReader>
    );
}