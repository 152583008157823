import {thunkDismissNotification} from "../../store/actions/NotificationActions";
import {useDispatch, useSelector} from "react-redux";
import NotificationAlert from "./NotificationAlert";
import React from "react";
import INotification from "../../store/models/INotification";
import IStore from "../../store/models/IStore";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
    return ({
        notificationListContainer: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
        },

        notificationList: {
            display: "flex",
            position: "fixed",
            flexDirection: "column",
            opacity: 0.9,
            width: "80%",
        },
    });
});


export default function NotificationList() {
    const dispatch = useDispatch();
    const notifications = useSelector((state: IStore) => state.notifications.notifications);
    const classes = useStyles();
    if (!notifications || notifications.length === 0) return null;
    else
        return (
            <div className={classes.notificationListContainer}>
                <div className={classes.notificationList}>
                    {notifications.map((n) => {
                        return (
                            <NotificationAlert
                                notification={n}
                                beforeDismiss={(err: INotification) => dispatch(thunkDismissNotification(err))}
                                key={n.id}
                            />
                        );
                    })}
                </div>
            </div>
        );
}
