import {useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import {AdmName} from "../store/models/AdmName";

export default function useAdmName(personalnummer: string) {
    const admNames: AdmName[] = useSelector((store: IStore) => store.admNames.admNames)
    const admName = admNames.find(aD => aD.admID === personalnummer);
    if (admName) {
        return admName.fullName;
    } else {
        return personalnummer;
    }
}
