import {observable, computed, action, decorate} from 'mobx';
import { v4 as uuid } from "uuid";
import IProduct from "../store/models/IProduct";

export class ProductDetailStore {
    retail = false
    previewAvailible = false
    userMult = 0
    selectedPrintOptions: Record<string, any> = {}
    withoutPrint = false
    selectedProductOptions: Record<string, any> = {}
    _amount: any = ''
    amountPerSize: any = {}
    productData?: IProduct
    _amountTimeoutId?: ReturnType<typeof setTimeout>

    get selectedPrintOptionsList() {
        return Object.values(this.selectedPrintOptions).filter(v => v);
    }
    get selectedProductOptionsList() {
        return Object.keys(this.selectedProductOptions).filter(k => this.selectedProductOptions[k]);
    }
    get selectedPrintOptionsValid() {
        const {selectedPrintOptionsList, productData} = this;
        const priceDetailPrint = productData?.priceDetail.print;

        return priceDetailPrint ? selectedPrintOptionsList.every(po => priceDetailPrint[po]) : null;
    }
    get amount() {
        if (this.productData?.textileOptions.length) {
            return Object.keys(this.amountPerSize).reduce((acc, p) => acc + this.amountPerSize[p], 0) || this._amount;
        } else {
            return this._amount;
        }
    }
    set amount(_value) {
        const value = parseInt(_value, 10);
        // value = value > this.minAmount ? value : this.minAmount;
        if (this.productData?.textileOptions.length) {
            if (value) {
                const textileOptions = this.productData.textileOptions;
                const perSize = Math.floor(value / textileOptions.length);
                const firstSize = value - (textileOptions.length - 1) * perSize;
                let [first, ...rest] = textileOptions;
                const amountPerSize = rest.reduce((acc, p) => {
                    acc[p['sku']] = perSize;
                    return acc;
                }, { [first['sku']]: firstSize });

                this.amountPerSize = amountPerSize;
            } else {
                this.amountPerSize = {};
            }
        } else {
            this._amount = value ? value : '';

            // Recalc amount if packaging unit > 1
            const priceDetail = this.productData?.priceDetail;
            if (priceDetail && priceDetail.packagingUnit > 1) {
                const {packagingUnit} = priceDetail;
                if (this._amountTimeoutId) clearTimeout(this._amountTimeoutId);
                this._amountTimeoutId = setTimeout(() => {
                    const newValue = Math.round(value / packagingUnit) * packagingUnit;
                    if (this._amount !== newValue) this._amount = newValue;
                }, 2 * 2000);
            }
        }
    }
    get minAmount() {
        const {selectedPrintOptionsList, selectedPrintOptionsValid, withoutPrint, productData} = this;

        if (!selectedPrintOptionsValid) {
            return null;
        }

        if (this.retail) return 1;

        const priceDetail = productData?.priceDetail;
        if (priceDetail) {
            const printCount = selectedPrintOptionsList.map(p => priceDetail.print[p].perItem[0][0]);

            if (priceDetail.printRequired) {
                if (!printCount.length) return null;
                return Math.max(priceDetail.perItem[0][0], priceDetail.packagingUnit, priceDetail.minPrintQty, ...printCount);
            } else {
                if (printCount.length > 0) {
                    return Math.max(priceDetail.perItem[0][0], priceDetail.packagingUnit, priceDetail.minPrintQty, ...printCount);
                } else if (withoutPrint) {
                    return Math.max(priceDetail.perItem[0][0], priceDetail.packagingUnit);
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }
    get maxAmount() {
        const {minAmount, productData} = this;
        const priceDetail = productData?.priceDetail;
        if (minAmount !== null && priceDetail) {
            const lastAmount = priceDetail.perItem.filter((p: number[]) => p[0] > 0).slice(-1)[0][0] * 2;
            return lastAmount < 5000 ? 5000 : lastAmount;
        } else {
            return null;
        }
    }
    get price() {
        const {amount, userMult, minAmount, productData, selectedPrintOptionsList, selectedPrintOptionsValid, selectedProductOptionsList} = this;
        const priceDetail = productData?.priceDetail;
        if (!amount || amount < (minAmount as number) || !selectedPrintOptionsValid || !priceDetail) return null;

        const itemPriceInitial = this.retail ? priceDetail.perItem[0][1] : 0;
        const itemPrice = priceDetail.perItem.reduce((acc, [q, p]) => (q > 0 && p > 0) && amount >= q ? p : acc, itemPriceInitial) * priceDetail.multiplier;
        if (itemPrice === 0) return null; // early return if there was a zero price per item

        const printItemPrice = selectedPrintOptionsList.reduce<Record<string, number>>((acc, pkey) => {
            const printOptionPrice = priceDetail.print[pkey].perItem.reduce((acc, [q, p]) => (q > 0 && p > 0) && amount >= q  ? p : acc, 0) * priceDetail.multiplier;
            acc[pkey] = +printOptionPrice.toFixed(2);
            acc._total += printOptionPrice;
            return acc;
        }, { _total: 0 });
        printItemPrice.total = +printItemPrice._total.toFixed(2);
        const printSetupPrice = selectedPrintOptionsList.reduce<Record<string, number>>((acc, pkey) => {
            const printSetupPrice = Math.ceil(priceDetail.print[pkey].setup);
            acc[pkey] = printSetupPrice;
            acc.total += printSetupPrice;
            return acc;
        }, { total: 0 });
        const printHandlingPrice = Math.ceil(selectedPrintOptionsList.reduce((acc, pkey) => acc + priceDetail.print[pkey].handling, 0));

        const optionItemPrice = selectedProductOptionsList.reduce((acc, okey: any) =>
            acc + priceDetail.options[okey].perItem.reduce((acc, [q, p]) => (q > 0 && p > 0) && amount >= q  ? p : acc, priceDetail.options[okey].perItem[0][1]),
        0);
        const optionSetupPrice = selectedProductOptionsList.reduce((acc, okey: any) => acc + priceDetail.options[okey].setup, 0);

        let totalItemPrice = +(itemPrice + printItemPrice._total + optionItemPrice + priceDetail.handling * priceDetail.multiplier + priceDetail.gema + priceDetail.sparkling + priceDetail.deposit).toFixed(2) * amount;
        if (this.retail) totalItemPrice *= 1.19;

        let shippingPrice;
        if (this.retail) {
            shippingPrice = 6.95;
        } else if (totalItemPrice <= priceDetail.shipping[0][0]) {
            shippingPrice = priceDetail.shipping[0][1] as number;
        } else if (totalItemPrice <= priceDetail.shipping[1][0]) {
            shippingPrice = priceDetail.shipping[1][1] as number;
        } else {
            shippingPrice = priceDetail.shipping[2][1] as number;
        }

        const userMultiplier = (100 + userMult) / 100;
        const basePrice = totalItemPrice + printSetupPrice.total + printHandlingPrice + optionSetupPrice + shippingPrice;
        const totalPrice = priceDetail.finalMultiplier ? basePrice * priceDetail.finalMultiplier * userMultiplier : basePrice * userMultiplier;

        return {
            perItem: {
                item: +(itemPrice + optionItemPrice).toFixed(2),
                print: printItemPrice,
                handling: priceDetail.handling * priceDetail.multiplier,
                gema: priceDetail.gema,
                sparkling: priceDetail.sparkling,
                deposit: priceDetail.deposit
            },
            setup: printSetupPrice,
            handling: printHandlingPrice,
            shipping: +(shippingPrice).toFixed(2),
            full: +totalPrice.toFixed(2),
            kjPrice: +basePrice.toFixed(2)
        };
    }

    getCartItem(userDesignId?: string) {
        if (this.productData) {
            const {amount, userMult, price, amountPerSize, selectedPrintOptions} = this;
            if (!price) return null;
            const {name, images, imagePath, sku, textileOptions} = this.productData;

            const textileOptionsMap = textileOptions.reduce((acc, opt) => {
                acc[opt.sku] = opt.textileSize;
                return acc;
            }, {});
            const textileQtyPerSize = Object.keys(amountPerSize).reduce((acc: any, sku) => {
                acc[sku] = {
                    size: textileOptionsMap[sku],
                    qty: amountPerSize[sku]
                };
                return acc;
            }, {});

            const item = {
                id: uuid(),
                productId: sku,
                amount,
                userMult,
                textileQtyPerSize: Object.keys(textileQtyPerSize).length ? textileQtyPerSize : null,
                price: price.full,
                productName: name,
                productImg: `${imagePath}${images[0]}`,
                printOptions: selectedPrintOptions,
                userDesignId
            };

            return item;
        }
    }

    getWishlistItem() {
        if (this.productData) {
            const {name, images, imagePath, sku, link} = this.productData;
            return {
                id: sku,
                img: `${imagePath}${images[0]}`,
                title: name,
                link
            };
        }
    }

    // Actions
    loadProductData = (productData?: IProduct) => {
        if (productData) {
            this.productData = productData;
        }
    }
    selectPrintOption = (areaId?: string, optionId?: string) => {
        const value = areaId && this.selectedPrintOptions[areaId] === optionId ? null : optionId;

        if (!areaId || !optionId) {
            this.withoutPrint = !this.withoutPrint;
            this.selectedPrintOptions = {};
        } else {
            this.withoutPrint = false;
            this.selectedPrintOptions = this.productData?.priceDetail.canCombinePrintOptions ? {...this.selectedPrintOptions, [areaId]: value} : {[areaId]: value};
        }

        // Set min amount
        if (!this.amount || this.amount < (this.minAmount as number)) {
            this.amount = this.minAmount;
        }
    }
    toggleProductOption = (optionId: string) => {
        this.selectedProductOptions = {
            ...this.selectedProductOptions,
            [optionId]: !this.selectedProductOptions[optionId]
        };
    }
}



decorate(ProductDetailStore, {
    userMult: observable,
    selectedPrintOptions: observable,
    withoutPrint: observable,
    selectedProductOptions: observable,
    _amount: observable,
    amountPerSize: observable,
    productData: observable,
    selectedPrintOptionsList: computed,
    selectedProductOptionsList: computed,
    selectedPrintOptionsValid: computed,
    amount: computed,
    minAmount: computed,
    maxAmount: computed,
    price: computed,
    loadProductData: action,
    selectPrintOption: action,
    toggleProductOption: action,
})

export default new ProductDetailStore();
