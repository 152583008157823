import React from 'react';
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {useSelector} from "react-redux";
import IStore from "../../store/models/IStore";
import {ProductSearchItem} from "../../store/models/ProductSearchItem";


interface ProductItemListDropdownProps {
    name: string;
    setValue: (value?: string) => void;
    value: string;
    variant?: 'standard' | 'outlined' | 'filled';
}

interface ProductItemOption {
    consolidationNumber: string,
    label: string
}

function fetchProductItemListOptions(items: ProductSearchItem[]) {
    const result: ProductItemOption[] = [];
    items.forEach(p => {

        const consolidationNumber = p.consolidationNumber;
        const label = i18next.t('productListSearchItems.' + consolidationNumber);
        result.push({consolidationNumber, label})

    })
    return result.sort((one, two) => (one.label < two.label ? -1 : 1));
}

export default function ProductItemListDropdown({
                                                    value,
                                                    setValue,
                                                    name,
                                                    variant = 'standard'
                                                }: ProductItemListDropdownProps) {
    const {t} = useTranslation();

    const productSearchItems = useSelector((state: IStore) => state.productSearchItems.items)

    const productItemOptions: ProductItemOption[] = React.useMemo(() => fetchProductItemListOptions(productSearchItems), [productSearchItems])
    const valueOption = productItemOptions.find(option => option.consolidationNumber === value) || null;


    return <Autocomplete
        onChange={(event: any, newValue: ProductItemOption | null) => {
            setValue(newValue?.consolidationNumber);
        }}
        value={valueOption}
        options={productItemOptions}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.label === value.label}
        style={{width: "155%"}} //TODO ...warum auch immer das klappt
        renderInput={(params) =>
            <TextField {...params} name={name}
                       label={t('contactSearchPanel.hasOrderedProductLbl')}
                       variant={variant}
            />}
    />
}
