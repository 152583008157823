import React, {useEffect, useState} from 'react';
import Navigation from "../components/Navigation";
import {useTranslation} from "react-i18next";
import {addDays, addMonths, compareAsc, endOfMonth, startOfMonth, subMonths} from "date-fns";
import {Button, Grid, IconButton, Typography} from "@material-ui/core";
import {formatDateForURL, formatDateObjWithDayOfWeek, getMonthToStringArr} from "../components/Formatter";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {LIGHT_BLUE, LIGHT_GREEN, RED, WHITE, YELLOW} from "../BerendsohnMUITheme";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Link} from "react-router-dom";
import BusinessExpenseReport, {BusinessExpenseReportStatus} from "../store/models/BusinessExpenseReport";
import {thunkGetMyBusinessExpenseReports} from "../store/actions/BusinessExpenseReportActions";
import {useDispatch, useSelector} from "react-redux";
import IStore from "../store/models/IStore";
import Api from "../store/Api";


const useStyles = makeStyles((theme) => {
    return ({
        dayCard: {
            padding: theme.spacing(2),
            height: 100,
            borderRadius: 5
        },
        link: {
            color: "black",
            textDecoration: 'none'
        },
        open: {
            backgroundColor: WHITE
        },
        requested: {
            backgroundColor: LIGHT_BLUE
        },
        needsReview: {
            backgroundColor: YELLOW
        },
        approved: {
            backgroundColor: LIGHT_GREEN
        },
        declined: {
            backgroundColor: RED
        },
        clickable: {
            cursor: "pointer"
        }

    });
});

function fetchDaysByWeek(currentMonth: Date, endOfThisMonthDate: Date, reports: BusinessExpenseReport[]) {
    let loopDate = currentMonth;
    const daysByWeek = [];
    let weekInMonth = currentMonth.getDay() > 1 ? 1 : 0;
    while (loopDate <= endOfThisMonthDate) {
        // eslint-disable-next-line
        const report = reports.find(r => formatDateForURL(new Date(r.forDate)) === formatDateForURL(loopDate));

        const dayOfWeek = loopDate.getDay();
        if (dayOfWeek === 1) {
            weekInMonth++;
        }
        if (dayOfWeek < 1 || dayOfWeek > 5) {
            loopDate = addDays(loopDate, 1);
            continue;
        }
        // eslint-disable-next-line
        const currentWeekIndex = daysByWeek.findIndex(w => w.week === weekInMonth);
        if (currentWeekIndex > -1) {
            daysByWeek[currentWeekIndex].days.push({date: loopDate, dayOfWeek: dayOfWeek, report});
        } else {
            daysByWeek.push({
                week: weekInMonth,
                days: [{date: loopDate, dayOfWeek: dayOfWeek, report}]
            })
        }
        loopDate = addDays(loopDate, 1);
    }
    return daysByWeek;
}


function DownloadExpenseReportForYear(props: { year: number }) {
    const {year} = props;
    const {t} = useTranslation();
    return <Button variant={"contained"} color={"primary"}
                   href={Api.createBusinessExpenseDownloadLink(year)} target={"_blank"}
    >{t('downloadExpenseReportForYear.btnText', {year})}</Button>;
}

export default function BusinessExpensesOverview() {
    const {t} = useTranslation();
    const [currentMonth, setCurrentMonth] = useState<Date>(startOfMonth(Date.now()));
    const classes = useStyles();

    const dispatch = useDispatch();

    const endOfThisMonthDate = endOfMonth(currentMonth);
    const daysByWeek = useSelector((state: IStore) => fetchDaysByWeek(currentMonth, endOfThisMonthDate, state.businessExpenseReports.myReports));

    const thisYear = (new Date()).getFullYear();
    const lastYear = thisYear - 1;


    useEffect(() => {
        dispatch(thunkGetMyBusinessExpenseReports());
    }, [dispatch])

    const monthToString = getMonthToStringArr();

    function fetchIndicatorColor(report?: BusinessExpenseReport) {
        if (report) {
            switch (report.managementStatus) {
                case BusinessExpenseReportStatus.ACCEPTED:
                    return 'approved';
                case BusinessExpenseReportStatus.REQUESTED:
                    return 'requested';
                case BusinessExpenseReportStatus.NEEDS_REVIEW:
                    return 'needsReview';
                case BusinessExpenseReportStatus.DECLINED:
                    return "declined"
                default:
                    return 'open';
            }
        }
        return 'open';
    }

    function fetchStatusText(report?: BusinessExpenseReport) {
        if (report) {
            switch (report.managementStatus) {
                case BusinessExpenseReportStatus.ACCEPTED:
                    return t("businessExpenseReportStatus.ACCEPTED");
                case BusinessExpenseReportStatus.REQUESTED:
                    return t("businessExpenseReportStatus.REQUESTED");
                case BusinessExpenseReportStatus.NEEDS_REVIEW:
                    return t("businessExpenseReportStatus.NEEDS_REVIEW");
                case BusinessExpenseReportStatus.DECLINED:
                    return t("businessExpenseReportStatus.DECLINED")
                default:
                    return t("businessExpenseReportStatus.OPEN");
            }
        }
        return t("businessExpenseReportStatus.OPEN");
    }

    return <Navigation breadcrumbName={''}>
        <Grid container spacing={3}>
            <Grid container item xs={12}>
                <Grid item xs={1}>
                    <IconButton onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
                                aria-label={t('common.back')}>
                        <ArrowBackIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={10}>
                    <Typography
                        align={"center"}>{monthToString[currentMonth.getMonth()]} {currentMonth.getFullYear()}</Typography>
                </Grid>
                <Grid item xs={1}>
                    {compareAsc(addMonths(currentMonth, 1), endOfMonth(new Date())) < 0 ?
                        <IconButton onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
                                    aria-label={t('common.forward')}>
                            <ArrowForwardIcon/>
                        </IconButton>
                        : null}
                </Grid>
            </Grid>
            {daysByWeek.map(week => {
                    const weekOffset = 1 + (5 - week.days.length) * 2;
                    return <Grid key={'weekKey' + week.week} container item xs={12} spacing={3}>
                        {/*@ts-ignore*/}
                        <Grid item xs={week.week === 1 ? weekOffset : 1}></Grid>
                        {week.days.map(day =>
                            <Grid key={'weekKey' + day.date.getTime()} item xs={2}>
                                <Link className={classes.link}
                                      to={'/businessExpenses/' + formatDateForURL(day.date) + (day.report ? '/' + day.report.berNumber : '')}>
                                    <div
                                        className={clsx(classes.dayCard, {[classes.clickable]: true}, classes[fetchIndicatorColor(day.report)])}>
                                        {formatDateObjWithDayOfWeek(day.date)}
                                        <Typography align={"center"}>{fetchStatusText(day.report)}</Typography>
                                    </div>
                                </Link>
                            </Grid>
                        )}
                        {/*@ts-ignore*/}
                        <Grid item xs={week.week === week.length ? weekOffset : 1}></Grid>
                    </Grid>;
                }
            )}

            <Grid container item xs={12}
                  direction="row"
                  justifyContent="space-evenly"
                  style={{marginTop: 40}}
            >
                <Grid item>
                    <DownloadExpenseReportForYear year={lastYear}/>
                </Grid>
                <Grid item>
                    <DownloadExpenseReportForYear year={thisYear}/>
                </Grid>
            </Grid>
        </Grid>
    </Navigation>
}
