import IAction from "../actions/IAction";
import {Note} from "../models/Note";
import {ADD_NOTE_SUCCESS, GET_NOTES_SUCCESS} from "../actions/NoteActions";


export interface INoteReducerState {
    notes: Note[];
}

export default class NoteReducer {
    public static readonly INITIAL_STATE: INoteReducerState = {
        notes: [],
    };

    public static reducer(
        state: INoteReducerState = NoteReducer.INITIAL_STATE,
        action: IAction<any>
    ): INoteReducerState {
        switch (action.type) {
            case GET_NOTES_SUCCESS:
                return {...state, notes: action.payload};
            case ADD_NOTE_SUCCESS:
                return {...state, notes: [...state.notes, action.payload]};
            default:
                return state;
        }
    }
}
