// @ts-ignore
import MuseoWoff from './fonts/museo/museosans-500.woff';
import {CSSProperties} from "@material-ui/core/styles/withStyles";
import {blue} from "@material-ui/core/colors";


export const DARK_GREEN = '#4CAF50';
export const LIGHT_GREEN = '#64DD17';
export const DARK_BLUE = '#3949AB';
export const LIGHT_BLUE = '#64B6F7';
export const GREY = '#BDBDBD';
export const RED = '#E53935';
export const YELLOW = '#FFD600';
export const WHITE = '#ffffff'


const museo: CSSProperties['@font-face'] = {
    fontFamily: 'Museo',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
    local('Museo'),
    local('Museo-Regular'),
    url(${MuseoWoff}) format('woff2')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const berendsohnMuiTheme = {
    typography: {
        fontFamily: 'Museo, Arial',
    },
    palette: {
        primary: {
            main: blue[300],
            contrastText: WHITE
        },

    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                // @ts-ignore
                '@font-face': [museo!],
            },
        },
        MUIRichTextEditor: {
            root: {
                marginTop: 20,
                width: "80%"
            },
            editor: {
                // borderBottom: "1px solid gray"
            }
        }
    },
}
