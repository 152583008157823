import {MeetingType} from "./IMeeting";
import {parseISO} from "date-fns";
import {parseISODateOrUndefined} from "../../util/DateUtil";

export enum ToDoType {
    MSOfficeAppointment = 'MSOfficeAppointment',
    FollowUp = 'FollowUp',
    LegacyCrmMeeting = 'LegacyCrmMeeting',
    LegacyCrmFollowup = 'LegacyCrmFollowup',
    LegacyCrmHistoricToDo = 'LegacyCrmHistoricToDo',
    ActionTrigger = 'ActionTrigger',
    Custom = 'Custom',
}

export enum ActionTriggerType {
    VisitReport = 'VisitReport'
}

export interface ActionTrigger {
    name: string;
    type: ActionTriggerType
}

export class ToDo {
    todoNumber?: string;
    text: string;
    created: Date;
    done?: Date;
    type: ToDoType;
    admID: string;
    forCustomer?: string;
    dueOn?: Date;
    meetingProps?: MeetingProps;
    actionTrigger?: ActionTriggerType;

    constructor({
                    todoNumber,
                    text,
                    created,
                    done,
                    type,
                    admID,
                    forCustomer,
                    dueOn,
                    meetingProps,
                    actionTrigger,
                }: ToDoDTO) {
        this.todoNumber = todoNumber;
        this.text = text;
        this.created = parseISO(created);
        this.done = parseISODateOrUndefined(done);
        this.type = type;
        this.admID = admID;
        this.forCustomer = forCustomer;
        this.dueOn = parseISODateOrUndefined(dueOn);
        this.meetingProps = meetingProps ? new MeetingProps(meetingProps) : undefined;
        this.actionTrigger = actionTrigger;
    }
}

export interface ToDoDTO {
    todoNumber?: string;
    text: string;
    created: string;
    done?: string;
    type: ToDoType;
    admID: string;
    forCustomer?: string;
    dueOn?: string;
    meetingProps?: MeetingPropsDTO;
    actionTrigger?: ActionTriggerType;
}


export class MeetingProps {
    endDate: Date;
    msMeetingID: string;
    meetingType: MeetingType;
    withMail: boolean;
    customerDisplayName: string;
    customerPlz: string;
    customerCity: string;


    constructor({
                    endDate,
                    msMeetingID,
                    meetingType,
                    withMail,
                    customerDisplayName,
                    customerPlz,
                    customerCity
                }: MeetingPropsDTO) {
        this.endDate = parseISO(endDate);
        this.msMeetingID = msMeetingID;
        this.meetingType = meetingType;
        this.withMail = withMail;
        this.customerDisplayName = customerDisplayName;
        this.customerPlz = customerPlz;
        this.customerCity = customerCity;
    }
}

export interface MeetingPropsDTO {
    endDate: string;
    msMeetingID: string;
    meetingType: MeetingType;
    withMail: boolean;
    customerDisplayName: string;
    customerPlz: string;
    customerCity: string;
}


export const emptyTodo: ToDo = {
    admID: "",
    created: new Date("2020-09-18T14:15:17.7264107Z"),
    text: "",
    type: ToDoType.Custom
}
