import {ThunkAction} from "redux-thunk";
import IStore from "../models/IStore";
import {Action} from "redux";
import Api from "../Api";
import BusinessExpenseReport from "../models/BusinessExpenseReport";


export const GET_BER_FOR_MY_ADMS_SUCCESS = "GET_BER_FOR_MY_ADMS_SUCCESS";
export const GET_BER_FOR_MY_ADMS_ERROR = "GET_BER_FOR_MY_ADMS_ERROR";

export const GET_BER_AS_ACCOUNTANT_SUCCESS = "GET_BER_AS_ACCOUNTANT_SUCCESS";
export const GET_BER_AS_ACCOUNTANT_ERROR = "GET_BER_AS_ACCOUNTANT_ERROR";

export const UPDATE_BER_STATUS_SUCCESS = "UPDATE_BER_STATUS_SUCCESS";
export const UPDATE_BER_STATUS_ERROR = "UPDATE_BER_STATUS_ERROR";

export const UPDATE_BER_STATUS_AS_ACCOUNTANT_SUCCESS = "UPDATE_BER_STATUS_AS_ACCOUNTANT_SUCCESS";
export const UPDATE_BER_STATUS_AS_ACCOUNTANT_ERROR = "UPDATE_BER_STATUS_AS_ACCOUNTANT_ERROR";

export const GET_MY_BER_SUCCESS = "GET_MY_BER_SUCCESS";
export const GET_MY_BER_ERROR = "GET_MY_BER_ERROR";

export const CREATE_MY_BER_SUCCESS = "CREATE_MY_BER_SUCCESS";
export const CREATE_MY_BER_ERROR = "CREATE_MY_BER_ERROR";

export const UPDATE_MY_BER_SUCCESS = "UPDATE_MY_BER_SUCCESS";
export const UPDATE_MY_BER_ERROR = "UPDATE_MY_BER_ERROR";


export const thunkGetBusinessExpenseReportsForMyADMs = (): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const reports = await Api.getBusinessExpenseReportsForMyADMs();
        dispatch({
            type: GET_BER_FOR_MY_ADMS_SUCCESS,
            payload: reports
        });
    } catch (e) {
        dispatch({
            type: GET_BER_FOR_MY_ADMS_ERROR,
            payload: e
        });
    }
};

export const thunkGetBusinessExpenseReportsAsAccountant = (): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const reports = await Api.getBusinessExpenseReportsAsAccountant();
        dispatch({
            type: GET_BER_AS_ACCOUNTANT_SUCCESS,
            payload: reports
        });
    } catch (e) {
        dispatch({
            type: GET_BER_AS_ACCOUNTANT_ERROR,
            payload: e
        });
    }
};


export const thunkUpdateBusinessExpenseReportStatusAsManager = (status: 'accept' | 'decline' | 'requestReview', berNumber: string,note?: string): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const report = await Api.updateBusinessExpenseReportStatusAsManager(berNumber, status,note)
        dispatch({
            type: UPDATE_BER_STATUS_SUCCESS,
            payload: report
        });
    } catch (e) {
        dispatch({
            type: UPDATE_BER_STATUS_ERROR,
            payload: e
        });
    }
};

export const thunkUpdateBusinessExpenseReportStatusAsAccountant = (status: 'accept' | 'decline' | 'requestReview', berNumber: string): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const report = await Api.updateBusinessExpenseReportStatusAsAccountant(berNumber, status)
        dispatch({
            type: UPDATE_BER_STATUS_AS_ACCOUNTANT_SUCCESS,
            payload: report
        });
    } catch (e) {
        dispatch({
            type: UPDATE_BER_STATUS_AS_ACCOUNTANT_ERROR,
            payload: e
        });
    }
};


export const thunkGetMyBusinessExpenseReports = (): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const reports = await Api.getMyBusinessExpenseReports();
        dispatch({
            type: GET_MY_BER_SUCCESS,
            payload: reports
        });
    } catch (e) {
        dispatch({
            type: GET_MY_BER_ERROR,
            payload: e
        });
    }
};


export const thunkCreateBusinessExpenseReport = (ber: BusinessExpenseReport): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        await Api.postMyBusinessExpenseReport(ber);
        dispatch({
            type: CREATE_MY_BER_SUCCESS,
            payload: ber
        });
    } catch (e) {
        dispatch({
            type: CREATE_MY_BER_ERROR,
            payload: e
        });
    }
};

export const thunkUpdateBusinessExpenseReport = (ber: BusinessExpenseReport): ThunkAction<void, IStore,
    null,
    Action<string>> => async dispatch => {
    try {
        const report = await Api.updateMyBusinessExpenseReport(ber)
        dispatch({
            type: UPDATE_MY_BER_SUCCESS,
            payload: report
        });
    } catch (e) {
        dispatch({
            type: UPDATE_MY_BER_ERROR,
            payload: e
        });
    }
};
